require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function(root, factory) {

	if (root === null) {
		throw new Error('Google-maps package can be used only in browser');
	}

	if (typeof define === 'function' && define.amd) {
		define(factory);
	} else if (typeof exports === 'object') {
		module.exports = factory();
	} else {
		root.GoogleMapsLoader = factory();
	}

})(typeof window !== 'undefined' ? window : null, function() {


	'use strict';


	var googleVersion = '3.18';

	var script = null;

	var google = null;

	var loading = false;

	var callbacks = [];

	var onLoadEvents = [];

	var originalCreateLoaderMethod = null;


	var GoogleMapsLoader = {};


	GoogleMapsLoader.URL = 'https://maps.googleapis.com/maps/api/js';

	GoogleMapsLoader.KEY = null;

	GoogleMapsLoader.LIBRARIES = [];

	GoogleMapsLoader.CLIENT = null;

	GoogleMapsLoader.CHANNEL = null;

	GoogleMapsLoader.LANGUAGE = null;

	GoogleMapsLoader.REGION = null;

	GoogleMapsLoader.VERSION = googleVersion;

	GoogleMapsLoader.WINDOW_CALLBACK_NAME = '__google_maps_api_provider_initializator__';


	GoogleMapsLoader._googleMockApiObject = {};


	GoogleMapsLoader.load = function(fn) {
		if (google === null) {
			if (loading === true) {
				if (fn) {
					callbacks.push(fn);
				}
			} else {
				loading = true;

				window[GoogleMapsLoader.WINDOW_CALLBACK_NAME] = function() {
					ready(fn);
				};

				GoogleMapsLoader.createLoader();
			}
		} else if (fn) {
			fn(google);
		}
	};


	GoogleMapsLoader.createLoader = function() {
		script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = GoogleMapsLoader.createUrl();

		document.body.appendChild(script);
	};


	GoogleMapsLoader.isLoaded = function() {
		return google !== null;
	};


	GoogleMapsLoader.createUrl = function() {
		var url = GoogleMapsLoader.URL;

		url += '?callback=' + GoogleMapsLoader.WINDOW_CALLBACK_NAME;

		if (GoogleMapsLoader.KEY) {
			url += '&key=' + GoogleMapsLoader.KEY;
		}

		if (GoogleMapsLoader.LIBRARIES.length > 0) {
			url += '&libraries=' + GoogleMapsLoader.LIBRARIES.join(',');
		}

		if (GoogleMapsLoader.CLIENT) {
			url += '&client=' + GoogleMapsLoader.CLIENT + '&v=' + GoogleMapsLoader.VERSION;
		}

		if (GoogleMapsLoader.CHANNEL) {
			url += '&channel=' + GoogleMapsLoader.CHANNEL;
		}

		if (GoogleMapsLoader.LANGUAGE) {
			url += '&language=' + GoogleMapsLoader.LANGUAGE;
		}

		if (GoogleMapsLoader.REGION) {
			url += '&region=' + GoogleMapsLoader.REGION;
		}

		return url;
	};


	GoogleMapsLoader.release = function(fn) {
		var release = function() {
			GoogleMapsLoader.KEY = null;
			GoogleMapsLoader.LIBRARIES = [];
			GoogleMapsLoader.CLIENT = null;
			GoogleMapsLoader.CHANNEL = null;
			GoogleMapsLoader.LANGUAGE = null;
			GoogleMapsLoader.REGION = null;
			GoogleMapsLoader.VERSION = googleVersion;

			google = null;
			loading = false;
			callbacks = [];
			onLoadEvents = [];

			if (typeof window.google !== 'undefined') {
				delete window.google;
			}

			if (typeof window[GoogleMapsLoader.WINDOW_CALLBACK_NAME] !== 'undefined') {
				delete window[GoogleMapsLoader.WINDOW_CALLBACK_NAME];
			}

			if (originalCreateLoaderMethod !== null) {
				GoogleMapsLoader.createLoader = originalCreateLoaderMethod;
				originalCreateLoaderMethod = null;
			}

			if (script !== null) {
				script.parentElement.removeChild(script);
				script = null;
			}

			if (fn) {
				fn();
			}
		};

		if (loading) {
			GoogleMapsLoader.load(function() {
				release();
			});
		} else {
			release();
		}
	};


	GoogleMapsLoader.onLoad = function(fn) {
		onLoadEvents.push(fn);
	};


	GoogleMapsLoader.makeMock = function() {
		originalCreateLoaderMethod = GoogleMapsLoader.createLoader;

		GoogleMapsLoader.createLoader = function() {
			window.google = GoogleMapsLoader._googleMockApiObject;
			window[GoogleMapsLoader.WINDOW_CALLBACK_NAME]();
		};
	};


	var ready = function(fn) {
		var i;

		loading = false;

		if (google === null) {
			google = window.google;
		}

		for (i = 0; i < onLoadEvents.length; i++) {
			onLoadEvents[i](google);
		}

		if (fn) {
			fn(google);
		}

		for (i = 0; i < callbacks.length; i++) {
			callbacks[i](google);
		}

		callbacks = [];
	};


	return GoogleMapsLoader;

});

},{}],2:[function(require,module,exports){
(function (global){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * Singleton (static) object for the main application.
 * The variable HAJK2 is registred in the the global scope.
 * @class HAJK2
 * @global
 */
(global.HAJK2 = (function () {
  'use strict';
  
  var ApplicationView = require('views/application'),
    cssModifier = require('utils/cssmodifier'),
    configPath = '/mapservice/settings/config/map_1',
    layersPath = '/mapservice/settings/config/layers',
    req,
    elem,
    that = {},
    internal = {}
    ;

  internal.load = function (config, bookmarks) {
    var application = new ApplicationView(config, bookmarks);
    if (config && config.map && config.map.colors) {
      cssModifier.configure(config.map.colors);
    }
    //Thememap selection need to force a rerender of rootnode
    application.render(true);
  };

  internal.init = function (config) {
    internal.load(config);
  };

  internal.parseQueryParams = function () {
    var o = {};
    document.location
      .search
      .replace(/(^\?)/, '')
      .split('&')
      .forEach(param => {
        var a = param.split('=');
        o[a[0]] = a[1];
      });
    return o;
  };

  internal.mergeConfig = function (a, b) {
    var x = parseFloat(b.x),
      y = parseFloat(b.y),
      z = parseInt(b.z),
      l = b.l;

    if (isNaN(x)){
      x = a.map.center[0];
    }
    if (isNaN(y)){
      y = a.map.center[1];
    }
    if (isNaN(z)){
      z = a.map.zoom;
    }

    // The parameters s and v can also be specified through the url. These are decoded and used in searchbar.jsx
    // for snabbsok.
    a.map.center[0] = x;
    a.map.center[1] = y;
    a.map.zoom = z;

    if (l) {
      l = l.split(',');
      a.layers.filter(layer => {
        layer.visibleAtStart = false;
        return typeof l.find(str => str === layer.id) === 'string';
      }).forEach(layer => {
        layer.visibleAtStart = true;
      });
    }
    return a;
  };

  internal.overrideGlobalInfoBox = function (layer, mapLayer){
    layer.infobox = mapLayer.infobox;
    return layer;
  }

  internal.filterByLayerSwitcher = function (config, layers) {
    function f (groups, layer) {
      groups.forEach(group => {
        var mapLayer = group.layers.find(l => l.id === layer.id);

        if (mapLayer) {
          layer.drawOrder = mapLayer.drawOrder;

          if(mapLayer.infobox && mapLayer.infobox.length != 0){
            layer = internal.overrideGlobalInfoBox(layer, mapLayer);
          }
          
          if (layer.visibleAtStart !== undefined) {
            layer.visibleAtStart = mapLayer.visibleAtStart;
          }
          filtered.push(layer);
        }

        if (group.hasOwnProperty('groups')) {
          f(group.groups, layer);
        }
      });
    }

    var filtered = [];

    layers.forEach(layer => {
      var baseLayer = config.baselayers.find(l => l.id === layer.id);
      if (baseLayer) {
        layer.drawOrder = 0;
        filtered.push(layer);
      }
    });

    layers.forEach(layer => {
      f(config.groups, layer);
    });
    return filtered;
  };

  internal.getADSpecificSearchLayers = function(){
    $.ajax({
      url: "/mapservice/config/ADspecificSearch",
      method: 'GET',
      contentType: 'application/json',
      success: (data) => {
  
      },
      error: (message) => {
        callback(message);
      }
    });
  }

 /**
   * Overrides global search configuration and uses layers specified in mapconfiguration to do a search
   */
  internal.overrideGlobalSearchConfig = function(searchTool, data) {

    var configSpecificSearchLayers = searchTool.options.layers;
    var searchLayers = data.wfslayers.filter(layer => {
      if(configSpecificSearchLayers.find(x => x.id == layer.id)){
        return layer;
      }
    });
      return searchLayers  
  };
  /**
   * Load config and start the application.
   * @memberof HAJK2
   * @alias start
   * @instance
   * @param {object} config - configuration object for the application.
   * @param {function} done - callback to trigger when the application is loaded.
   */
  that.start = function (config, done) {
    function load_map (map_config) {

      var layers = $.getJSON(config.layersPath || layersPath);

      layers.done(data => {
        // Set <title> in HTML if map has a title property in JSON config
        if (map_config.hasOwnProperty('map') && map_config.map.hasOwnProperty('title')) {
          document.title = map_config.map.title;
        }

        var layerSwitcherTool = map_config.tools.find(tool => {
          return tool.type === 'layerswitcher';
        });


        var searchTool = map_config.tools.find(tool => {
          return tool.type === 'search';
        });
      
        var editTool = map_config.tools.find(tool => {
          return tool.type === 'edit';
        });   

        if (layerSwitcherTool) {
          let layers = [];
          let _data = {
            wmslayers: data.wmslayers || [],
            wmtslayers: data.wmtslayers || [],
            vectorlayers: data.vectorlayers || [],
            arcgislayers: data.arcgislayers || [],
            extendedwmslayers: data.extendedwmslayers || []
          };

          _data.wmslayers.forEach(l => l.type = 'wms');
          _data.wmtslayers.forEach(l => l.type = 'wmts');
          _data.vectorlayers.forEach(l => l.type = 'vector');
          _data.arcgislayers.forEach(l => l.type = 'arcgis');
          _data.extendedwmslayers.forEach(l => l.type = 'extended_wms');
          
          layers = data.wmslayers
          .concat(_data.extendedwmslayers)
          .concat(_data.wmtslayers)
          .concat(_data.vectorlayers)
          .concat(_data.arcgislayers);
          map_config.layers = internal.filterByLayerSwitcher(layerSwitcherTool.options, layers);
          
          map_config.layers.sort((a, b) => a.drawOrder === b.drawOrder ? 0 : a.drawOrder < b.drawOrder ? -1 : 1);
        }

        if (searchTool) {
          if(searchTool.options.layers == null){
            data.wfslayers = data.wfslayers;
            searchTool.options.sources = data.wfslayers;
          }
          else {
            if(searchTool.options.layers.length != 0 ){ 
              var wfslayers = internal.overrideGlobalSearchConfig(searchTool, data);
              searchTool.options.sources = wfslayers;
              data.wfslayers = wfslayers;
            }
            else {
              searchTool.options.sources = data.wfslayers;
            }
          }
        }
        

        if (editTool) {
          editTool.options.sources = data.wfstlayers;
        }

        internal.init(
        internal.mergeConfig(map_config, internal.parseQueryParams())
        );
        if (done) done(true);
      })
      .fail(() => {
        if (done) { done(false, 'Kartans lagerkonfiguration kunde inte laddas in.'); }
      });
    }    
    config = config || {};
    $.getJSON(config.configPath || configPath)
      .done(load_map)
      .fail(() => {
        if (done) { done(false, 'Kartans konfiguration kunde inte laddas in'); }
      });
  };

  return that;
}()));


}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"utils/cssmodifier":"utils/cssmodifier","views/application":"views/application"}],"alert":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
class AlertView extends React.Component {

  constructor() {
    super();
  }

  /**
   * Render the View
   */
  render() {
    var options = this.props.options;
    if (options.confirm) {
      return !options.visible ? false : (
        React.createElement("div", {className: "modal"}, 
          React.createElement("div", {className: "modal-dialog"}, 
            React.createElement("div", {className: "modal-content"}, 
              React.createElement("div", {className: "modal-header"}, 
                React.createElement("h4", {className: "modal-title"}, "Bekräfta")
              ), 
              React.createElement("div", {className: "modal-body"}, 
                React.createElement("p", null, 
                  options.message.split('\n').map(function(text, i) {
                    return (
                      React.createElement("span", {key: i}, 
                        React.createElement("span", null, text), 
                        React.createElement("br", null)
                      )
                    )
                  })
                )
              ), 
              React.createElement("div", {className: "modal-footer"}, 
                React.createElement("button", {type: "button", onClick: options.denyAction, className: "btn btn-default"}, "Avbryt"), " ", 
                React.createElement("button", {type: "button", onClick: options.confirmAction, className: "btn btn-primary"}, "OK")
              )
            )
          )
        )
      )
    } else {
      return !options.visible ? false : (
        React.createElement("div", {className: "modal"}, 
          React.createElement("div", {className: "modal-dialog"}, 
            React.createElement("div", {className: "modal-content"}, 
              React.createElement("div", {className: "modal-header"}, 
                React.createElement("h4", {className: "modal-title"}, "Meddelande")
              ), 
              React.createElement("div", {className: "modal-body"}, 
                React.createElement("p", null, 
                  options.message.split('\n').map(function(text, i) {
                    return (
                      React.createElement("span", {key: i}, 
                        React.createElement("span", null, text), 
                        React.createElement("br", null)
                      )
                    )
                  })
                )
              ), 
              React.createElement("div", {className: "modal-footer"}, 
                React.createElement("button", {type: "button", onClick: options.onClick, className: "btn btn-default"}, "OK")
              )
            )
          )
        )
      )
    }
  }
}

/**
 * AlertView module.<br>
 * Use <code>require('views/alert')</code> for instantiation.
 * @module AlertView-module
 * @returns {AlertView}
 */
module.exports = AlertView;
},{}],"collections/layers":[function(require,module,exports){
var types = {
  "wms": require('layers/wmslayer'),
  "vector": require('layers/wfslayer'),
  "wmts": require('layers/wmtslayer'),
  "data": require('layers/datalayer'),
  "arcgis": require('layers/arcgislayer'),
  "extended_wms": require('layers/extendedwmslayer')
};

/**
 * Prototype for creating a layer collecton.
 * @class LayerCollection
 * @augments external:"Backbone.Collection"
 */
var LayerCollection = {

  /**
   * Add layer to openlayers map
   * @instance
   * @param {Layer} layer - Layer model to add
   */
  addToMap: function(layer) {
    var map = this.shell.get('map').getMap()
    ,   olLayer = layer.getLayer();

    layer.set("olMap", map);
    layer.set("shell", this.shell);

    var visibleAtStart = false;
    var found = false;
    for(var i=0; i < this.mapGroups.length; i++){
      for (var j=0; j < this.mapGroups[i].layers.length; j++){
        if (layer.get("id") == this.mapGroups[i].layers[j].id){
          visibleAtStart = this.mapGroups[i].layers[j].visibleAtStart;
          found = true;
          break;
        }
      }
    }

    if(!found) {
      for (var i = 0; i < this.baseLayers.length; i++) {
        if (layer.get("id") == this.baseLayers[i].id){
          visibleAtStart = this.baseLayers[i].visibleAtStart;
          found = true;
          break;
        }
      }
    }

    layer.setVisible(visibleAtStart);
    layer.getLayer().setVisible(visibleAtStart);

    if (olLayer) {
      map.addLayer(olLayer);
    }
  },

  update: function(layers){
    for(var i = 0; i < layers.length; i++){
      this.forEach(mapLayer => {
        var savedLayer = layers[i];
        if(savedLayer.id === mapLayer.id){
          mapLayer.layer.setVisible(savedLayer.visibleAtStart);
          mapLayer.setVisible(savedLayer.visibleAtStart);
        }
      });
    }
  },
  /**
   * Remove layer from openlayers map
   * @instance
   * @param {Layer} layer - Layermodel to remove
   */
  removeFromMap: function(layer) {
    var map = this.shell.get('map').getMap()
    ,   olLayer = layer.getLayer();

    if (olLayer) {
      map.removeLayer(olLayer);
    }
  },

  /**
   * Generates a model for this layer
   * @instance
   * @param {object} args
   * @param {object} properties
   * @return {object} config
   */
  mapWMTSConfig: function(args, properties) {
    var config = {
      type: 'wmts',
      options: {
        id: args.id,
        name: args.id,
        caption: args.caption,
        visible: args.visibleAtStart === false ? false : true,
        extent: properties.mapConfig.extent,
        queryable: false,
        opacity: args.opacity || 1,
        format: 'image/png',
        wrapX: false,
        url: args.url,
        layer: args.layer,
        matrixSet: args.matrixSet,
        style: args.style,
        projection: args.projection,
        origin: args.origin,
        resolutions: args.resolutions,
        matrixIds: args.matrixIds,
        attribution: args.attribution,
      }
    };
    return config;
  },

  /**
   * Generates a model for this layer
   * @instance
   * @param {object} args
   * @param {object} properties
   * @return {object} config
   */
  mapWMSConfig: function(args, properties) {
    
    function getLegendUrl() {

      var proxy =  HAJK2.wmsProxy || "";

      if (args.legend === "") { 
        args.legend =  `${proxy}${args.url}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=32&HEIGHT=32&LAYER=${args.layers[0]}`
      }

      //If legend is a GetLegendGraphic call, use proxy otherwise not
      if(args.legend.indexOf("GetLegendGraphic") != -1){
        args.legend = proxy + args.legend;
      }
    
      var protocol = /^http/.test(args.legend) ? '' : 'http://';
      return protocol + args.legend;
    }

    var config = {
      type : "wms",
      options: {
        "id": args.id,
        "url": (HAJK2.wmsProxy || "") + args.url,
        "name": args.id,
        "caption": args.caption,
        "visible": args.visibleAtStart,
        "opacity": args.opacity || 1,
        "queryable": args.queryable === false ? false : true,
        "information": args.infobox,
        "resolutions": properties.mapConfig.resolutions,
        "projection": properties.mapConfig.projection || "EPSG:3006",
        "origin": properties.mapConfig.origin,
        "extent": properties.mapConfig.extent,
        "singleTile": args.singleTile || false,
        "imageFormat": args.imageFormat || "image/png",
        "serverType": args.serverType || "geoserver",
        "attribution": args.attribution,
        "searchUrl": args.searchUrl,
        "searchPropertyName": args.searchPropertyName,
        "searchDisplayName": args.searchDisplayName,
        "searchOutputFormat": args.searchOutputFormat,
        "searchGeometryField": args.searchGeometryField,
        "legend" : [{
          "Url": getLegendUrl(args),
          "Description" : "Teckenförklaring"
        }],
        "params": {
          "LAYERS": args.layers.join(','),
          "FORMAT": args.imageFormat,
          "VERSION": "1.1.0",
          "SRS": properties.mapConfig.projection || "EPSG:3006",
          "TILED": args.tiled
        },
        "infoVisible": args.infoVisible || false,
        "infoTitle": args.infoTitle,
        "infoText": args.infoText,
        "infoUrl": args.infoUrl,
        "infoUrlText": args.infoUrlText,
        "infoOwner": args.infoOwner
      }
    };
    
    if (args.searchFields && args.searchFields[0]) {
      config.options.search = {
        "url": (HAJK2.searchProxy || "") + args.url.replace('wms', 'wfs'),
        "featureType": args.layers[0].split(':')[1] || args.layers[0].split(':')[0],
        "propertyName": args.searchFields.join(','),
        "displayName": args.displayFields ? args.displayFields : (args.searchFields[0] || "Sökträff"),
        "srsName": properties.mapConfig.projection || "EPSG:3006"
      };
    }

    return config;
  },

  mapExtendedWMSConfig: function (args, properties) {
    const createLegendConfig = (url, layer) => {
      let strippedUrl = url ? url.split("?")[0] : args.url;
      let legendUrl = `${strippedUrl}?REQUEST=GetLegendGraphic&VERSION=${args.version}&FORMAT=image/png&WIDTH=32&HEIGHT=32&LAYER=${layer.name}&STYLE=${layer.style}&legend_options=forceLabels:on`;
      let protocol = /^http/.test(legendUrl) ? '' : 'http://';
      
      return {
        Url: protocol + legendUrl,
        Description: layer.name
      };
    };

    var config = {
      type : args.type,
      options: {
        "id": args.id,
        "url": (HAJK2.wmsProxy || "") + args.url,
        "name": args.id,
        "caption": args.caption,
        "visible": args.visibleAtStart,
        "opacity": 1,
        "queryable": true,
        "information": args.infobox,
        "resolutions": properties.mapConfig.resolutions,
        "projection": args.projection || properties.mapConfig.projection || "EPSG:3006",
        "origin": properties.mapConfig.origin,
        "extent": properties.mapConfig.extent,
        "singleTile": args.singleTile || false,
        "imageFormat": args.imageFormat || "image/png",
        "serverType": args.serverType || "geoserver",
        "attribution": args.attribution,
        "legend": args.layers.map((l) => createLegendConfig(args.legend, l)),
        "layersconfig": args.layers,
        "params": {
          "LAYERS": args.layers.map(function (l) { return l.name; }).join(','),
          "STYLES": args.layers.map(function (l) { return l.style || ""; }).join(','),
          "FORMAT": args.imageFormat,
          //Openlayers stödjer ej SWEREF 99  i wms verion 1.3.0
          //Vi har överlagring av funktion för tile men inte för single tile
          "VERSION": args.singleTile || false ? '1.1.0': args.version, 
          "TILED": args.tiled,
          "INFO_FORMAT": args.infoFormat
        },
        "infoVisible": args.infoVisible || false,
        "infoTitle": args.infoTitle,
        "infoText": args.infoText,
        "infoUrl": args.infoUrl,
        "infoUrlText": args.infoUrlText,
        "infoOwner": args.infoOwner
      }
    };

    if (args.searchFields && args.searchFields[0]) {
      config.options.search = {
        "url": (HAJK2.searchProxy || "") + args.url.replace('wms', 'wfs'),
        "featureType": args.layers[0].split(':')[1] || args.layers[0].split(':')[0],
        "propertyName": args.searchFields.join(','),
        "displayName": args.displayFields ? args.displayFields : (args.searchFields[0] || "Sökträff"),
        "srsName": properties.mapConfig.projection || "EPSG:3006"
      };
    }
    return config;
  },

  mapDataConfig: function(args) {

    var config = {
      type : "data",
      options: {
        "id": args.id,
        "url": (HAJK2.wfsProxy || "") + args.url,
        "name": args.id,
        "caption": args.caption,
        "visible": args.visibleAtStart,
        "opacity": 1,
        "queryable": args.queryable === false ? false : true,
        "extent": args.extent,
        "projection": args.projection
      }
    };

    return config;
  },

  mapWFSConfig: function(args) {

    var config = {
      type : "vector",
      options: {
        "id": args.id,
        "dataFormat": args.dataFormat,
        "name": args.id,
        "caption": args.caption,
        "visible": args.visibleAtStart,
        "opacity": args.opacity,
        "serverType": "arcgis",
        "loadType": "ajax",
        "projection": args.projection,
        "fillColor": args.fillColor,
        "lineColor": args.lineColor,
        "lineStyle": args.lineStyle,
        "lineWidth": args.lineWidth,
        "url": args.url,
        "queryable": args.queryable,
        "information": args.infobox,
        "icon": args.legend,
        "symbolXOffset": args.symbolXOffset,
        "symbolYOffset": args.symbolYOffset,
        "labelAlign": args.labelAlign,
        "labelBaseline": args.labelBaseline,
        "labelSize": args.labelSize,
        "labelOffsetX": args.labelOffsetX,
        "labelOffsetY": args.labelOffsetY,
        "labelWeight": args.labelWeight,
        "labelFont": args.labelFont,
        "labelFillColor": args.labelFillColor,
        "labelOutlineColor": args.labelOutlineColor,
        "labelOutlineWidth": args.labelOutlineWidth,
        "labelAttribute": args.labelAttribute,
        "showLabels": args.showLabels,
        "featureId": "FID",
        "legend": [{
          "Url": args.legend,
          "Description": args.caption
        }],
        "params": {
          "service": "WFS",
          "version": "1.1.0",
          "request": "GetFeature",
          "typename": args.layer,
          "srsname": args.projection,
          "bbox": ""
        },
        "infoVisible": args.infoVisible || false,
        "infoTitle": args.infoTitle,
        "infoText": args.infoText,
        "infoUrl": args.infoUrl,
        "infoUrlText": args.infoUrlText,
        "infoOwner": args.infoOwner
      }
    };

    return config;
  },

  mapArcGISConfig: function(args) {

    function getLegendUrl() {
      if (!Array.isArray(args.legend)) {
        if (/^data/.test(args.legend)) {
          args.legend = args.legend.split('#');
        } else if (!/^http/.test(args.legend)) {
          args.legend = 'http://' + args.legend;
        }
      }
      return args.legend;
    }

    var config = {
      type : "arcgis",
      options: {
        "id": args.id,
        "url": args.url,
        "name": args.id,
        "caption": args.caption,
        "visible": args.visibleAtStart,
        "queryable": args.queryable === false ? false : true,
        "singleTile": args.singleTile === false ? false : true,
        "extent": args.extent,
        "information": args.infobox,
        "projection": args.projection,
        "opacity": args.opacity,
        "attribution": args.attribution,
        "params": {
          "LAYERS": 'show:' + args.layers.join(',')
        },
        "legend" : [{
          "Url":  getLegendUrl(args),
          "Description" : "Teckenförklaring"
        }],
        "infoVisible": args.infoVisible || false,
        "infoTitle": args.infoTitle,
        "infoText": args.infoText,
        "infoUrl": args.infoUrl,
        "infoUrlText": args.infoUrlText,
        "infoOwner": args.infoOwner
      }
    };

    return config;
  },

  /**
   * Generates a model for this layer
   * @instance
   * @param {object} args
   * @param {object} properties
   * @return {Layer} layer
   */
  model: function (args, properties) {
    var config = false;
    if (args.type === "wms") {
      config = LayerCollection.mapWMSConfig(args, properties);
    }

    if(args.type === "extended_wms") {
      config = LayerCollection.mapExtendedWMSConfig(args, properties);
    }

    if (args.type === "wmts") {
      config = LayerCollection.mapWMTSConfig(args, properties);
    }
    if (args.type === "data") {
      config = LayerCollection.mapDataConfig(args);
    }
    if (args.type === "arcgis") {
      config = LayerCollection.mapArcGISConfig(args);
    }

    if (args.type === "vector") {
      config = LayerCollection.mapWFSConfig(args);
    }
    var Layer = types[config.type];
    if (Layer) {
      return new Layer(config.options, config.type);
    } else {
      throw "Layer type not supported: " + config.type;
    }
  },

  /**
   * Constructor method
   * @instance
   * @param {object} options
   * @param {object} args
   */
  initialize: function (options, args) {

    this.shell = args.shell;
    this.initialConfig = options;
    var toolConfig = args.tools;

    var layerSwitcherTool = args.tools.find(tool => tool.type === "layerswitcher");
    this.mapGroups = layerSwitcherTool.options.groups;
    this.baseLayers = layerSwitcherTool.options.baselayers;

    _.defer(_.bind(function () {

      this.forEach(this.addToMap, this);

    }, this));

    this.on("add", this.addToMap, this);
    this.on("remove", this.removeFromMap, this);
  },

  /**
   * Get the objects data state as json-friendly representation.
   * @instance
   * @return {object} state
   */
  toJSON: function () {
    return this.initialConfig.map(layer => {
      var found = this.find(collectionLayer => collectionLayer.get('id') === layer.id);
      if (found) {
        layer.visibleAtStart = found.get('visible');
      }
      return layer;
    });
  }
};

/**
 * Layer collection module.<br>
 * Use <code>require('collections/layercollection')</code> for instantiation.
 * @module LayerCollection-module
 * @returns {LayerCollection}
 */
module.exports = Backbone.Collection.extend(LayerCollection);

},{"layers/arcgislayer":"layers/arcgislayer","layers/datalayer":"layers/datalayer","layers/extendedwmslayer":"layers/extendedwmslayer","layers/wfslayer":"layers/wfslayer","layers/wmslayer":"layers/wmslayer","layers/wmtslayer":"layers/wmtslayer"}],"collections/tools":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Tool          = require('tools/tool')
,   LayerSwitcher = require('tools/layerswitcher')
,   InfoClick     = require('tools/infoclick')
,   Bookmark      = require('tools/bookmark')
,   Search        = require('tools/search')
,   Coordinates   = require('tools/coordinates')
,   Export        = require('tools/export')
,   Draw          = require('tools/draw')
,   Edit          = require('tools/edit')
,   Anchor        = require('tools/anchor')
,   Buffer        = require('tools/buffer')
,   StreetView    = require('tools/streetview')
,   Information   = require('tools/information')
,   Location      = require('tools/location')
,   Routing       = require('tools/routing')
,   Preset        = require('tools/preset')
,   Measure        = require('tools/measure');

/**
 * @description
 *
 *   Prototype for a tool collection object.
 *   The tool collection holds references to the tool modules used by the application.
 *   Any communication between tools must occur through this model.
 *
 * @class
 * @augments external:"Backbone.Collection"
 * @param {object} options
 * @param {object} args
 */
var ToolCollection = {
  /**
   * Generates a model for this tool.
   * @instance
   * @param {object} args - arguments
   * @return {Tool} tool
   */
  model: function (args) {
      switch (args.type) {
        case "layerswitcher":
            return new LayerSwitcher(args.options);
        case "infoclick":
            return new InfoClick(args.options);
        case "bookmark":
            return new Bookmark(args.options);
        case "search":
          return new Search(args.options);
        case "coordinates":
            return new Coordinates(args.options);
        case "export":
            return new Export(args.options);
        case "draw":
            return new Draw(args.options);
        case "edit":
            return new Edit(args.options);
        case "anchor":
            return new Anchor(args.options);
        case "buffer":            
            return new Buffer(args.options);
        case "routing":
            return new Routing(args.options);
        case "streetview":
            return new StreetView(args.options);
        case "information":
            return new Information(args.options);
        case "selection":
            return new Selection(args.options);
        case "location":
            return new Location(args.options);
        case "preset":
            return new Preset(args.options);
        case "measure":
            return new Measure(args.options);
        default:
            throw "Tool not supported " + args.type;
      }
  },

  initialize: function (tools, args) {
    this.shell = args.shell;
    setTimeout(() => {
      this.configure();
    }, 0);
  },

  configure: function() {
    this.forEach(tool => {
      tool.set("shell", this.shell)
    });
  },

  /**
   * Get the objects data state as json-friendly representation.
   * @instance
   * @return {object} state
   */
  toJSON: function () {
    var json = Backbone.Collection.prototype.toJSON.call(this);
    delete json.shell;
    _.each(this.models, (tool, i) => {
      json[i] = tool.toJSON();
    });
    return json;
  }
};

/**
 * Tool collection module.<br>
 * Use <code>require('collections/toolcollection')</code> for instantiation.
 * @module ToolCollection-module
 * @returns {ToolCollection}
 */
module.exports = Backbone.Collection.extend(ToolCollection);

},{"tools/anchor":"tools/anchor","tools/bookmark":"tools/bookmark","tools/buffer":"tools/buffer","tools/coordinates":"tools/coordinates","tools/draw":"tools/draw","tools/edit":"tools/edit","tools/export":"tools/export","tools/infoclick":"tools/infoclick","tools/information":"tools/information","tools/layerswitcher":"tools/layerswitcher","tools/location":"tools/location","tools/measure":"tools/measure","tools/preset":"tools/preset","tools/routing":"tools/routing","tools/search":"tools/search","tools/streetview":"tools/streetview","tools/tool":"tools/tool"}],"components/backgroundswitcher":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var InfoButton = require('components/infobutton');

/**
 * @class
 */
var BackgroundSwitcherView = {

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function () {
    return {
      displayMode: 'hidden',
      displayModeClass: 'fa fa-angle-right arrow'
    }
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.backgroundSwitcherModeChanged();
    this.props.model.on('change:backgroundSwitcherMode', () => {
      this.backgroundSwitcherModeChanged()
    });
    this.props.model.on('change:showInfo', this.onShowInfoChanged, this);
    this.setState({
      selected: this.props.model.get('background'),
      showInfo: this.props.model.get('showInfo')
    })
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.off('change:backgroundSwitcherMode');
    this.props.model.off('change:showInfo', this.onShowInfoChanged, this);
  },

  /**
   * Event handler for background switcher mode changes
   * @instance
   */
  backgroundSwitcherModeChanged: function () {
    var mode = this.props.model.get('backgroundSwitcherMode')
    ,   cls  = (this.props.model.get('backgroundSwitcherMode') === 'hidden') ? 'fa fa-angle-right arrow' : 'fa fa-angle-up arrow'
    ;
    this.setState({
      displayMode: mode,
      displayModeClass: cls
    });
  },

  /**
   * Set black background
   * @instance
   */
  setBlackBackground: function () {
    this.clear();
    $('#map').css({background: 'black'});
    this.setState({
      "selected" : 'black'
    });
    this.props.model.set('background', 'black');
  },

  /**
   * Set white background
   * @instance
   */
  setWhiteBackground: function () {
    this.clear();
    $('#map').css({background: 'white'});
    this.setState({
      "selected" : 'white'
    });
    this.props.model.set('background', 'white');
  },

  /**
   * Hide current background layer
   * @instance
   */
  clear: function() {
    this.props.layers.forEach(baselayer => {
      baselayer.setVisible(false);
      baselayer.getLayer().setVisible(false);
    });
  },

  /**
   * Set background layer
   * @instance
   * @param {Layer} layer
   */
  setBackgroundLayer: function (layer) {
    $('#map').css({background: 'white'});
    this.props.layers.forEach(baselayer => {
      var visible = baselayer.id === layer.id;
      baselayer.setVisible(visible);
      baselayer.getLayer().setVisible(visible);
    });
    this.setState({
      "selected" : layer.id
    })
    this.props.model.set('background', layer.id);
  },

  /**
   * Set visibility of background layer
   * @instance
   */
  setVisibility: function() {
    this.props.model.set('backgroundSwitcherMode',
      this.props.model.get('backgroundSwitcherMode') === 'hidden' ? '' : 'hidden'
    );
  },

  /**
   * Check if given layer is the selected layer
   * @instance
   * @param {Layer} layer
   */
  getSelected: function (layer) {
    if (this.state && this.state.selected) {
      if (this.state.selected === layer.get('id')) {
        return true;
      }
    } else {
      return this.props.layers.filter(l =>
        l.getVisible() && l.id === layer.id
      ).length === 1;
    }
  },

  /**
   * On show info change event handler.
   * @instance
   */
  onShowInfoChanged: function () {
    this.setState({ showInfo: this.props.model.get('showInfo') });
  },

  /**
   * Toggle info visibility
   * @instance
   */
  toggleInfo: function (e, index) {
    e.stopPropagation();
    this.state.showInfo != index ? this.props.model.set('showInfo', index) : this.props.model.set('showInfo', undefined);
  },

  /**
   * Render the layers component.
   * @instance
   * @return {external:ReactElement}
   */
  renderLayers: function () {
    return (
      this.props.layers.map((layer, i) => {
        var index = "background-layer-" + i
        ,   checked = this.getSelected(layer)
        ,   infoIndex = i
        ,   infoExpanded  = this.state.showInfo
        ,   infoVisible = layer.get('infoVisible');
        return (
          React.createElement("li", {key: index}, 
            React.createElement("input", {id: index, name: "background", type: "radio", checked: checked, onChange: (e) => this.setBackgroundLayer(layer)}), 
            React.createElement("label", {htmlFor: index}, layer.get('caption')), 

            React.createElement("span", {className: infoVisible ? "visible" : "hidden", onClick: (e) => this.toggleInfo(e, i)}, 
              React.createElement(InfoButton, {key: index, index: index})
            ), 
          
            React.createElement("div", {className: infoExpanded === infoIndex ? "dropdown" : "hidden"}, 
              React.createElement("p", {className: "info-title"}, layer.get('infoTitle')), 
              React.createElement("p", {className: "info-text"}, layer.get('infoText')), 
              React.createElement("a", {className: "info-text", href: layer.get('infoUrl'), target: "_blank"}, layer.get('infoUrl')), React.createElement("br", null), 
              React.createElement("i", {className: "info-text"}, layer.get('infoOwner') ? "Ägare: " + layer.get('infoOwner') : "")
            )
          )
        );
      })
    )
  },

  /**
   * Render an extra/special layer to the background switcher
   * Possible values are "black" and "white".
   * @instance
   * @return {external:ReactElement}
   */
  renderExtraLayer: function(mode) {
    var shouldRender = true
    ,   changeMethod = () => {}
    ,   caption = ""
    ,   checked = false
    ;

    if (mode === 'black') {
      shouldRender = this.props.model.get("backgroundSwitcherBlack");
      changeMethod = this.setBlackBackground;
      caption = "Svart bakgrund";
      checked = this.state.selected === "black";
    }

    if (mode === 'white') {
      shouldRender = this.props.model.get("backgroundSwitcherWhite");
      changeMethod = this.setWhiteBackground;
      caption = "Vit bakgrund";
      checked = this.state.selected === "white";
    }

    if (shouldRender) {
      let id = Math.round(Math.random() * 1E8);
      return (
        React.createElement("li", {key: id}, 
          React.createElement("input", {id: id, name: "background", type: "radio", checked: checked, onChange: () => changeMethod.call(this)}), 
          React.createElement("label", {htmlFor: id}, caption)
        )
      )
    }
    return null;
  },

  /**
   * Render the background switcher component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    return (
      React.createElement("div", {className: "background-switcher"}, 
        React.createElement("h3", {onClick: this.setVisibility}, React.createElement("span", {className: this.state.displayModeClass}), " Bakgrundskartor"), 
        React.createElement("ul", {className: this.state.displayMode}, 
          this.renderLayers(), 
          this.renderExtraLayer("black"), 
          this.renderExtraLayer("white")
        )
      )
    );
  }
};

/**
 * BackgroundSwitcherView module.<br>
 * Use <code>require('views/backgroundswitcher')</code> for instantiation.
 * @module BackgroundSwitcherView-module
 * @returns {BackgroundSwitcherView}
 */
module.exports = React.createClass(BackgroundSwitcherView);

},{"components/infobutton":"components/infobutton"}],"components/colorpicker":[function(require,module,exports){
var ColorPicker = {
  /*
   * @property {Array{string}} colors
   */
  colors: [
    "rgb(77, 77, 77)",
    "rgb(153, 153, 153)",
    "rgb(255, 255, 255)",
    "rgb(244, 78, 59)",
    "rgb(254, 146, 0)",
    "rgb(252, 220, 0)",
    "rgb(219, 223, 0)",
    "rgb(164, 221, 0)",
    "rgb(104, 204, 202)",
    "rgb(15, 175, 255)",
    "rgb(174, 161, 255)",
    "rgb(253, 161, 255)",
    "rgb(51, 51, 51)",
    "rgb(128, 128, 128)",
    "rgb(204, 204, 204)",
    "rgb(211, 49, 21)",
    "rgb(226, 115, 0)",
    "rgb(252, 196, 0)",
    "rgb(176, 188, 0)",
    "rgb(104, 188, 0)",
    "rgb(22, 165, 165)",
    "rgb(0, 156, 224)",
    "rgb(123, 100, 255)",
    "rgb(250, 40, 255)",
    "rgb(0, 0, 0)",
    "rgb(102, 102, 102)",
    "rgb(179, 179, 179)",
    "rgb(159, 5, 0)",
    "rgb(196, 81, 0)",
    "rgb(251, 158, 0)",
    "rgb(128, 137, 0)",
    "rgb(25, 77, 51)",
    "rgb(12, 121, 125)",
    "rgb(0, 98, 177)",
    "rgb(101, 50, 148)",
    "rgb(171, 20, 158)"
  ],
  /*
   * Abort any operation and deselect any tool
   * when the components unmounts.
   * @return {objct} state
   */
  getInitialState: function() {
    return {
      color: this.props.model.get(this.props.property)
    };
  },
  /*
   * @override
   */
  componentWillReceiveProps: function () {
    // TODO:
    // The stack trace seems messed up here.
    // The value in the model is not correct at the time of render,
    // the model switches the values and keeps the last set value.
    // This is solved by setTimeout 0, to put the call
    // at the bottom of the stack. But anyway, its considered a bug.
    setTimeout(() => {
      this.setState({
        color: this.props.model.get(this.props.property)
      });
    }, 0);
  },
  /*
   * Set the current color of the component.
   * @param {ol.event} event
   */
  setColor: function (event) {
    var reg   = /rgb[a]?\(.*\)/
    ,   value = reg.exec(event.target.style.background)

    if (value && value[0]) {
      this.setState({
        color: value[0]
      });
      this.props.onChange(value[0]);
    }
  },
  /*
   * Get current color.
   * @return {string} color
   */
  getColor: function () {
    return this.state.color;
  },
  /*
   * Render the color map component.
   * @return {React.Component} component
   */
  renderColorMap: function () {
    return this.colors.map((color, i) => {
      var black = "rgb(0, 0, 0)"
      ,   gray  = "rgb(150, 150, 150)"
      ,   white = "rgb(255, 255, 255)"
      ,   style = {
        width:        "22px",
        height:       "22px",
        display:      "inline-block",
        margin:       "2px",
        background:   color,
        border:       color === this.state.color ?
                      color === black ? "2px solid " + gray : "2px solid " + black :
                      color === white ? "2px solid " + gray : "2px solid " + color
      };
      return React.createElement("div", {onClick: this.setColor, key: i, style: style})
    });
  },
  /*
   * Render the colorpicker tool.
   * @return {React.Component} component
   */
  render: function () {
    var colorMap = this.renderColorMap();
    var noColor = this.props.noColor
    ? (
        React.createElement("div", null, 
          React.createElement("label", {htmlFor: "no-color"}, "Ingen bakgrund"), " ", 
          React.createElement("input", {checked: this.state.color === "rgba(0, 0, 0, 0)", id: "no-color", onChange: () => {
            this.setColor({
              target: {
                style: {
                  background: "rgba(0, 0, 0, 0)"
                }
              }
            })
          }, type: "checkbox"})
        )
      )
    : "";
    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "swatch"}, 
          colorMap
        ), 
        noColor
      )
    )
  }
};

module.exports = React.createClass(ColorPicker);
},{}],"components/fabutton":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var FaButton = React.createClass({displayName: "FaButton",

  getDefaultProps: function () {
    return {
      onClick: function () { },
      customClass: '',
      icon: '',
      right: false
    };
  },

  render: function () {
    var onClick = this.props.onClick;
    var btnClassName = 'btn';
    var faClassName = 'fa ' + this.props.icon;

    if (this.props.right) {
       btnClassName += ' btn-right';
    }
    if (this.props.customClass) {
      btnClassName += ' ' + this.props.customClass;
    }
    return (
      React.createElement("div", {className: btnClassName, title: this.props.title, onClick: onClick}, 
        React.createElement("i", {className: faClassName})
      )
    );
  }
});

module.exports = FaButton;
},{}],"components/featureinfo":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
var FeatureInfoView = {
  /**
   * Convert object to markdown
   * @instance
   * @param {object} object to transform
   * @return {string} markdown
   */
  objectAsMarkdown: function (o) {
    return Object
      .keys(o)
      .reduce((str, next, index, arr) =>
        /^geom$|^geometry$|^the_geom$/.test(arr[index]) ?
        str : str + `**${arr[index]}**: ${o[arr[index]]}\r`
      , "");
  },

  /**
   * Render the feature info component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function name() {
    if (!this.props.info) {
      return false;
    }

    var html = ""
    ,   icon = ''
    ,   info = this.props.info.information
    ;

    if (typeof info === 'object') {
      info = this.objectAsMarkdown(this.props.info.information);
    }

    html = marked(info, { sanitize: false, gfm: true, breaks: true });

    if (this.props.info.iconUrl != '') {
      icon = React.createElement("img", {src: this.props.info.iconUrl});
    }

    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "header"}, icon, React.createElement("h1", null, this.props.info.caption)), 
        React.createElement("div", {className: "information"}, 
          React.createElement("span", {dangerouslySetInnerHTML: {__html: html}})
        )
      )
    );
  }
};

/**
 * BackgroundSwitcherView module.<br>
 * Use <code>require('views/backgroundswitcher')</code> for instantiation.
 * @module BackgroundSwitcherView-module
 * @returns {BackgroundSwitcherView}
 */
module.exports = React.createClass(FeatureInfoView);
},{}],"components/infobutton":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var InfoButton = React.createClass({displayName: "InfoButton",
  render: function () {
    var title = this.props.checked ? "Dölj tooltip" : "Visa lagerinformation";
    var className = this.props.checked ? "fa fa-info-circle" : "fa fa-info-circle";
    var fontSize = this.props.checked ? "18px" : "18px";
    return (
       React.createElement("span", {className: "clickable pull-right", title: title, style: { position: 'relative', top: '3px', marginRight: '14px'}}, 
          React.createElement("i", {className: className, style: { fontSize: fontSize}})
       )
    );
  }
});

module.exports = InfoButton;
},{}],"components/information":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
var InformationView = {

  componentDidMount: function() {
    this.props.model.on('change:display', () => {
      this.setState({
        display: this.props.model.get('display')
      })
    });
    this.setState({
      display: this.props.model.get('display')
    });
  },

  componentWillUnMount: function() {
    this.props.model.off('change:display');
  },

  /**
   * Close the infomraiton box
   * @instance
   * @return {external:ReactElement}
   */
  close: function () {
    this.props.model.set('display', false);
  },

  /**lo
   * Render the legend item component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    if (this.state && this.state.display) {
      var infoContent = this.props.model.get('text');
      if(this.props.model.get('base64EncodeForInfotext')){
        infoContent = atob(infoContent);
      }

      return (
        React.createElement("div", {id: "blanket"}, 
          React.createElement("div", {id: "container"}, 
            React.createElement("div", {key: "a", id: "header"}, this.props.model.get('headerText'), 
              React.createElement("i", {className: "fa fa-times pull-right clickable panel-close", id: "close", onClick: this.close})
            ), 
            React.createElement("div", {id: "body-wrapper"}, 
              React.createElement("div", {key: "b", id: "body", dangerouslySetInnerHTML: {__html: infoContent}})
            )
          )
        )
      );
    } else {
      return null;
    }
  }
};

/**
 * LegendView module.<br>
 * Use <code>require('views/legend')</code> for instantiation.
 * @module LegendView-module
 * @returns {InformationView}
 */
module.exports = React.createClass(InformationView);

},{}],"components/legendbutton":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LegendButton = React.createClass({displayName: "LegendButton",
  render: function () {
    var title = this.props.checked ? "Dölj teckenförklaring" : "Visa teckenförklaring";
    var className = this.props.checked ? "fa fa-angle-up" : "fa fa-list";
    var fontSize = this.props.checked ? "26px" : "16px";
    return (
       React.createElement("span", {className: "clickable pull-right", title: title, style: { position: 'relative', top: '3px'}}, 
          React.createElement("i", {className: className, style: { fontSize: fontSize}})
       )
    );
  }
});

module.exports = LegendButton;
},{}],"components/legenditem":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LegendItem = React.createClass({displayName: "LegendItem",
  render: function () {
    if (Array.isArray(this.props.icon)) {
      var images = this.props.icon.map((blob, i) => {
        var a = blob.split('&');
        return (
          React.createElement("div", {key: i}, 
            React.createElement("div", null, a[1] || ""), 
            React.createElement("img", {className: "media-object", src: a[0], alt: "legend"})
          )
        )
      });
      return (React.createElement("div", null, images));
    }
      return (
        React.createElement("div", {className: "media"}, 
          React.createElement("img", {className: "media-object", src: this.props.icon, alt: "legend"})
        )
      );

  }
});

module.exports = LegendItem;
},{}],"components/legend":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LegendItem = require('components/legenditem');

/**
 * @class
 */
var LegendView = {
  /**
   * Render the legend item component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    return (
      React.createElement("div", {className: "legend"}, 
      
        this.props.legends.map((legend, index) =>
          React.createElement(LegendItem, {key: "legend_" + index, icon: legend.Url, text: legend.Description})
        )
      
      )
    );
  }
};

/**
 * LegendView module.<br>
 * Use <code>require('views/legend')</code> for instantiation.
 * @module LegendView-module
 * @returns {LegendView}
 */
module.exports = React.createClass(LegendView);
},{"components/legenditem":"components/legenditem"}],"components/searchbar":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var SelectionToolbar = require('components/selectiontoolbar');
var SearchResultGroup = require('components/searchresultgroup');

/**
 * @class
 */
var SearchBarView = {
  /**
   * @property {string} valueBar
   * @instance
   */
  valueBar: undefined,

  /**
   * @property {number} timer
   * @instance
   */
  timer: undefined,

  /**
   * @property {number} loading
   * @instance
   */
  loading: 0,

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function () {
    return {
      visible: false,
      displayPopup: this.props.model.get('displayPopupBar'),
      haveUrlSearched: false,
      updateCtr: 2,
      sAndVSearch: false
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {        
    this.valueBar = this.props.model.get('valueBar');
    if (this.props.model.get('barItems')) {
      this.setState({
        showResults: true,
        result: {
          status: 'success',
          items: this.props.model.get('barItems')
        }
      });
    }

    this.props.model.on("change:displayPopupBar", () => {
      this.setState({
        displayPopup: this.props.model.get('displayPopupBar')
      });
    });

    var str
      , result
      , typeName;

    // get s and v
    var paramGet = function (name) {
      var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    var s = paramGet('s');
    var v = paramGet('v');

    if (s == null) {
      this.props.model.set('filter', '*');
    } else {
      var filterName = '*';
      this.props.model.get('sources').map((wfslayer, i) => {
        if (s.toUpperCase() == wfslayer.caption.toUpperCase()) {
          filterName = wfslayer.caption;
        }
      });
      this.props.model.set('filter', filterName);
    }

    if ((!this.state.haveUrlSearched) && typeof v !== 'undefined' && v != null) {
      var field = document.getElementById("searchbar-input-field");
      field.value = v;
      this.valueBar = v;
      this.props.model.set('valueBar', this.valueBar);
      this.setState({
        valueBar: this.valueBar,
        minimized: false,
        force: true,
        sAndVSearch: true
      });
      this.props.model.set('force', true);
      this.search() // always search on url-query
    }
  },

  componentDidUpdate: function () {
    var hit = document.getElementById('hit-0-group-0');
    if (!this.state.haveUrlSearched && hit != null && this.state.sAndVSearch) {
      try {
        hit.click();
        this.state.haveUrlSearched = true;
        this.state.sAndVSearch = false;
      } catch (err) {
      }
    }
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
    this.props.model.get('layerCollection') ?
      this.bindLayerVisibilityChange() :
      this.props.model.on('change:layerCollection', this.bindLayerVisibilityChange);
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.get('layerCollection').each((layer) => {
      layer.off("change:visible", this.search);
    });
    this.props.model.off('change:layerCollection', this.bindLayerVisibilityChange);
    this.props.model.off("change:displayPopupBar");
  },

  /**
   * Clear the search result.
   * @instance
   */
  clear: function () {
    if (typeof $("#sokRensa") !== "undefined") {
      $("#sokRensa").click();
    }
    this.valueBar = "";
    this.props.model.set('valueBar', "");
    this.props.model.clear();
    this.setState({
      loading: false,
      showResults: true,
      result: []
    });
  },

  /**
   * Handle key down event, this will set state.
   * @instance
   * @param {object} event
   */
  handleKeyDown: function (event) {
    this.props.model.set('filter', '*');
    this.state.sAndVSearch = false;
    this.state.haveUrlSearched = true;
    if (event.keyCode === 13 && event.target.value.length < 5) {
      event.preventDefault();
      this.props.model.set('valueBar', event.target.value);
      this.setState({
        force: true
      });
      this.props.model.set('force', true);
      this.state.sAndVSearch = false;
      this.search();
    }
  },

  toggleMinimize: function () {
    this.setState({
      minimized: !this.state.minimized
    });
  },

  /**
   * Perform a search in the model to update results.
   * @instance
   */
  update: function () {
    this.props.model.search();
  },

  /**
   * Search requested information.
   * @instance
   * @param {object} event
   */
  search: function (event) {
    this.setState({
      loading: true
    });
    this.loading = Math.random();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      var loader = this.loading;
      this.props.model.abort();
      this.props.model.search(result => {
        var state = {
          loading: false,
          showResults: true,
          result: result
        };
        if (loader !== this.loading) {
          state.loading = true;
        }
        this.setState(state);
      }, true);
    }, 200);
  },

  /**
   * Bind an event handler to layer visibility change.
   * If a layer changes visibility the result vill update.
   * @instance
   */
  bindLayerVisibilityChange: function () {
    this.props.model.get('layerCollection').each((layer) => {
      layer.on("change:visible", () => {
        //this.update(); // causes a search to be done everytime a layer's visibility changes. Then it only searches in adresser and fastighet
      });
    });
  },

  /**
   * Set search filter and perform a search.
   * @instance
   * @param {string} type
   * @param {object} event
   *
   */
  setFilter: function (event) {
    this.props.model.set('filter', event.target.value);
    this.search();
  },

  /**
   * Render the search options component.
   * @instance
   * @return {external:ReactElement}
   */
  renderOptions: function () {
    var settings = this.props.model.get('settings')
      , sources = this.props.model.get('sources')
    ;
    return (
      React.createElement("div", null, 
        React.createElement("div", null, 
          React.createElement("span", null, "Sök: "), " ", 
          React.createElement("select", {value: this.props.model.get('filter'), onChange: (e) => {
            this.setFilter(e)
          }}, 
            React.createElement("option", {value: "*"}, "-- Alla --"), 
            
              (() => {
                return sources.map((wfslayer, i) => {
                  return (
                    React.createElement("option", {key: i, value: wfslayer.caption}, 
                      wfslayer.caption
                    )
                  )
                })
              })()
            
          )
        )
      )
    );
  },

  onChangeDisplayPopup: function (e) {
    this.props.model.set("displayPopupBar", e.target.checked);
  },

  exportSelected: function (type) {
    this.props.model.export(type);
  },

  searchOnInput: function(event) {
    if(this.state.sAndVSearch){
      return; // Internet Explorer calls this function before the sAndVSearch can finish. We therefore have to return
      // until the sAndVSearch is finished.
    }
    this.props.model.set('filter', '*');
    this.valueBar = event.target.value;
    this.props.model.set('valueBar', this.valueBar);
    this.setState({
      valueBar: this.valueBar,
      minimized: false,
      force: false
    });
    this.props.model.set('force', false);
    if (this.refs.searchInput.value.length > 3) {
      this.search();
    } else {
      this.setState({
        loading: false
      });
    }
  },

  /**
   * Render the result component.
   * @instance
   * @return {external:ReactElement}
   */
  renderResults: function () {
    var groups = this.props.model.get('barItems');

    const resultsCount = groups.reduce((result, group) => result + group.hits.length, 0);

    const enable_checkbox = this.props.model.get('enableViewTogglePopupInSnabbsok');
    const checkbox = (
      React.createElement("div", null, 
        React.createElement("input", {type: "checkbox", id: "display-popup", ref: "displayPopup", onChange: (e) => {
          this.onChangeDisplayPopup(e)
        }, checked: this.state.displayPopup}), 
        React.createElement("label", {htmlFor: "display-popup"}, "Visa information")
      )
    );

    const resultStyle = {
      display: this.state.minimized ? 'none' : 'block'
    }

    return (
      React.createElement("div", {className: "searchbar-area", key: "searchbar-results"}, 
        
          groups && groups.length > 0
            ? (
              React.createElement("div", null, 
                React.createElement("div", {className: "searchbar-results"}, 
                  React.createElement("h3", {id: "searchbar-results-title"}, 
                    "Sökresultat", 
                    resultsCount > 0 ? React.createElement("span", {className: "search-results-total-count"}, "(", resultsCount, ")") : null
                  ), 
                  React.createElement("div", {id: "searchbar-results-list", style: resultStyle}, 
                    enable_checkbox ? checkbox : null, 
                    
                      groups.map((item, i) => {
                        var id = "group-" + i;
                        return (
                          React.createElement(SearchResultGroup, {
                            isBar: "yes", 
                            id: id, 
                            key: id, 
                            result: item, 
                            numGroups: groups.length, 
                            model: this.props.model, 
                            parentView: this, 
                            map: this.props.model.get('map')})
                        );
                      })
                    
                  )
                ), 
                React.createElement("div", {onClick: this.toggleMinimize, className: "search-results-toggle-minimze"}, 
                  this.state.minimized
                    ? React.createElement("span", null, "Visa ", React.createElement("span", {className: "fa fa-angle-down clickable arrow"}))
                    : React.createElement("span", null, "Dölj ", React.createElement("span", {className: "fa fa-angle-up clickable arrow"}))
                  
                )
              )
            ) : (
              React.createElement("div", {className: "searchbar-results-no-results"}, "Sökningen gav inget resultat.")
            )
        
      )
    );
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var valueBar = this.props.model.get('valueBar')
      , showResults = this.props.model.shouldRenderResult(true)
      , options = this.renderOptions();

    const Loading = (
      React.createElement("div", {id: "searchbar-loading-spinner"}, 
        React.createElement("span", {className: "sr-only"}, "Laddar..."), 
        React.createElement("i", {className: "fa fa-refresh fa-spin fa-2x fa-fw"})
      )
    )

    const shouldRenderSearchResults = this.refs.searchInput && (this.refs.searchInput.value.length > 3 || this.props.model.get('force') && this.refs.searchInput.value.length > 0);

    if(this.refs.searchInput) {
    } else {
    }


    const AlertSearchBar = (
      React.createElement("p", {className: "alert alert-info", id: "alertSearchbar"}, 
        "Skriv minst fyra tecken för att påbörja automatisk sökning. Tryck på ", React.createElement("b", null, "retur"), " för att forcera en sökning."
      )
    )

    const inputClassName = this.state.loading || (showResults && !this.state.loading && shouldRenderSearchResults) ? 'form-control searchbar-input-field-active' : 'form-control';
    const buttonClassName = this.state.loading || (showResults && !this.state.loading && shouldRenderSearchResults) ? 'input-group-addon searchbar-search-button-active' : 'input-group-addon';

    return (
      React.createElement("div", {className: "search-tools"}, 
        React.createElement("div", {className: "input-group", id: "searchbar-search-area"}, 
          valueBar ? React.createElement("div", {id: "searchbar-input-field-clear", onClick: () => {
            this.clear()
          }}) : null, 
          React.createElement("input", {
            id: "searchbar-input-field", 
            type: "text", 
            ref: "searchInput", 
            className: inputClassName, 
            placeholder: "Sök i kartan...", 
            value: valueBar, 
            onKeyDown: this.handleKeyDown, 
            onChange: this.searchOnInput}), 
          React.createElement("div", {id: "searchbar-search-button", className: buttonClassName}, 
            React.createElement("i", {className: "fa fa-search"})
          )
        ), 
        
          showResults
            ? this.state.loading
            ? Loading
            : shouldRenderSearchResults
              ? this.renderResults()
              : AlertSearchBar
            : null
        
      )
    );
  }
};
/**
 *
 * Ta bort sök alternative ovanför {results} above
 *         <div className="search-options">{options}</div>
 */



/**
 * SearchBarView module.<br>
 * Use <code>require('components/searchbar')</code> for instantiation.
 * @module SearchBarView-module
 * @returns {SearchBarView}
 */
module.exports = React.createClass(SearchBarView);

},{"components/searchresultgroup":"components/searchresultgroup","components/selectiontoolbar":"components/selectiontoolbar"}],"components/searchresultgroup":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var shiftIsDown = false;
var ctrlIsDown = false;

window.onkeydown = (e) => {
  shiftIsDown = e.shiftKey;
  ctrlIsDown = e.ctrlKey;
}

window.onkeyup  = (e) => {
  shiftIsDown = e.shiftKey;
  ctrlIsDown = e.ctrlKey;
}

/**
 * @class
 */
SearchResultGroup = {

  componentDidMount: function () {

    var groups = $(ReactDOM.findDOMNode(this)).find('.group');

    groups.click(function() {
      $(this).next().toggleClass('hidden');
    });

    if (Array.isArray(this.props.model.get('selectedIndices'))) {
      _.each(groups, group => {
        var items = this.props.model.get('selectedIndices').filter(item => group.id === item.group);
        if (items.length > 0) {
          items.forEach(item => {

            var nth = item.index + 1
            ,   elem = $(group).next().find('div:nth-child(' + nth + ')');

            elem.addClass('selected');
          });
        }
      });
    }
  },

  handleClick: function (hit, index, event) {

    var element = $(event.target)
    ,   parent = $(ReactDOM.findDOMNode(this))
    ,   group = parent.find('.group');

    var item = {
      index: index,
      hits: this.props.result.hits,
      hit: hit,
      id: group[0].id
    };
    
    if (shiftIsDown) {

      let topIndex = 0;
      let items = [];
      let i;

      parent.find('.selected').each(function (e, i) {                
        topIndex = $(this).attr("data-index");
      });                

      i = topIndex;

      for (; i <= index; i++) {              
        items.push({
          index: i,
          hits: this.props.result.hits,
          hit: this.props.result.hits[i],
          id: group[0].id
        });        
      }

      items.forEach(item => {
        this.props.model.append(item);
        parent.find(`div[data-index=${item.index}]`).addClass("selected");
      });

    } else if (ctrlIsDown) {
      if (element.hasClass('selected')) {
        this.props.model.detach(item);
      } else {
        this.props.model.append(item);
      }
    } else {
      $('.search-results').find('.selected').each(function (e) {
        $(this).removeClass('selected');
      });
      this.props.model.focus(item, this.props.isBar == "yes");
    }

    if (!shiftIsDown) {
      if (element.hasClass('selected'))
        element.removeClass('selected');
      else
        element.addClass('selected');
    }

    if (isMobile) {
      if (this.props.parentView) {
        if (this.props.parentView.props.navigationPanel) {
          this.props.parentView.props.navigationPanel.minimize();
        }
      }
    }

  },

  render: function () {

    var id = this.props.id
    ,   groupStyleClass = this.props.numGroups === 1 ? "" : "hidden"
    ;

    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "group", id: this.props.id}, this.props.result.layer, 
          React.createElement("span", {className: "label"}, this.props.result.hits.length)
        ), 
        React.createElement("div", {className: groupStyleClass}, 
          
            this.props.result.hits.map((hit, i) => {
              function getTitle(property) {
                if (Array.isArray(property)) {
                  return property.map(item => hit.getProperties()[item]).join(', ');
                } else {
                  return hit.getProperties()[property] || property
                }
              }
              var hitId = "hit-" + i + "-" + id
              ,   title = getTitle(this.props.result.displayName)
              ,   index = i
              ;
              return (React.createElement("div", {id: hitId, key: hitId, index: i, "data-index": i, onClick: this.handleClick.bind(this, hit, i)}, title));
            })
          
        )
      )
    );
  }
};

module.exports = React.createClass(SearchResultGroup);

},{}],"components/search":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var SelectionToolbar = require('components/selectiontoolbar');
var SearchResultGroup = require('components/searchresultgroup');

/**
 * @class
 */
var SearchView = {
  /**
   * @property {string} value
   * @instance
   */
  value: undefined,

  /**
   * @property {number} timer
   * @instance
   */
  timer: undefined,

  /**
   * @property {number} loading
   * @instance
   */
  loading: 0,

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false,
      displayPopup: this.props.model.get('displayPopup')
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.value = this.props.model.get('value');
    if (this.props.model.get('items')) {
      this.setState({
        showResults: true,
        result: {
          status: 'success',
          items: this.props.model.get('items')
        }
      });
    }

    this.props.model.on("change:displayPopup", () => {
      this.setState({
        displayPopup: this.props.model.get('displayPopup')
      });
    });
    this.props.model.on("change:url", () => {
      this.setState({
        downloadUrl: this.props.model.get("url")
      });
    });
    this.props.model.on("change:downloading", () => {
      this.setState({
        downloading: this.props.model.get("downloading")
      });
    });
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
    this.props.model.get('layerCollection') ?
      this.bindLayerVisibilityChange() :
      this.props.model.on('change:layerCollection', this.bindLayerVisibilityChange);
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.get('layerCollection').each((layer) => {
      layer.off("change:visible", this.search);
    });
    this.props.model.off('change:layerCollection', this.bindLayerVisibilityChange);
    this.props.model.off("change:displayPopup");
    this.props.model.off("change:url");
    this.props.model.off("change:downloading");
  },

  /**
   * Clear the search result.
   * @instance
   */
  clear: function () {
    this.value = "";
    this.props.model.set('value', "");
    this.props.model.set('searchTriggered', false);
    this.props.model.clear();
    if(!isMobile && typeof $("#snabbsokRensa") !== "undefined") {
      $("#snabbsokRensa").click();
    }

    if(document.getElementById("alertSearchbar") != null) {
      document.getElementById("alertSearchbar").remove();
    }

    this.setState({
      loading: true,
      showResults: true,
      result: []
    });

    if (!isMobile && $('#searchbar-input-field').length != 0){
      $('#searchbar-input-field')[0].value = ""
    }
  },

  /**
   * Handle key down event, this will set state.
   * @instance
   * @param {object} event
   */
  handleKeyDown: function (event) {
    if (event.keyCode === 13 && event.target.value.length < 5) {
      event.preventDefault();
      this.props.model.set('value', event.target.value);
      this.setState({
        force: true
      });
      this.props.model.set('force', true);
      this.search();
    }
  },

  /**
   * Perform a search in the model to update results.
   * @instance
   */
  update: function() {
    this.props.model.search();
  },

  /**
   * Search requested information.
   * @instance
   * @param {object} event
   */
  search: function (event) {
    this.props.model.set('searchTriggered', true);
    this.setState({
      loading: true
    });
    this.loading = Math.random();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      var loader = this.loading;
      this.props.model.abort();
      this.props.model.search(result => {
        var state = {
          loading: false,
          showResults: true,
          result: result
        };
        if (loader !== this.loading) {
          state.loading = true;
        }
        this.setState(state);
      }, false);
    }, 200);
  },

  /**
   * Bind an event handler to layer visibility change.
   * If a layer changes visibility the result vill update.
   * @instance
   */
  bindLayerVisibilityChange : function () {
    this.props.model.get('layerCollection').each((layer) => {
      layer.on("change:visible", () => {
        this.update();
      });
    });
  },

  /**
   * Set search filter and perform a search.
   * @instance
   * @param {string} type
   * @param {object} event
   */
  setFilter: function (event) {
    this.props.model.set('filter', event.target.value);
    this.search();
  },

  /**
   * Render the search options component.
   * @instance
   * @return {external:ReactElement}
   */
  renderOptions: function () {
    var settings = this.props.model.get('settings')
    ,   sources = this.props.model.get('sources')
    ,   filterVisible = this.props.model.get('filterVisible')
    ,   filterVisibleBtn = null
    ;
    if (filterVisible) {
      filterVisibleBtn = (
        React.createElement("div", null, 
          React.createElement("input", {
            id: "filter-visible", 
            type: "checkbox", 
            checked: this.props.model.get('filterVisibleActive'), 
            onChange: (e) => {
              this.props.model.set('filterVisibleActive', e.target.checked);
              this.setState({
                filterVisibleActive: e.target.checked
              });
            }}
          ), " ", 
          React.createElement("label", {htmlFor: "filter-visible"}, "Sök i alla synliga lager")
        )
      );
    }
    return (
      React.createElement("div", null, 
        React.createElement("p", null, 
          React.createElement("span", null, "Sök: "), " ", 
          React.createElement("select", {value: this.props.model.get('filter'), onChange: (e) => { this.setFilter(e) }}, 
            React.createElement("option", {value: "*"}, "--  Alla  --"), 
            
              (() => {
                return sources.map((wfslayer, i) => {
                  return (
                    React.createElement("option", {key: i, value: wfslayer.caption}, 
                      wfslayer.caption
                    )
                  )
                })
              })()
            
          )
        ), 
        filterVisibleBtn
      )
    );
  },

  onChangeDisplayPopup: function (e) {
    this.props.model.set("displayPopup", e.target.checked);
  },

  exportSelected: function(type) {
    this.props.model.export(type);
  },

  /**
   * Render the result component.
   * @instance
   * @return {external:ReactElement}
   */
  renderResults: function () {

    var groups = this.props.model.get('items')
    ,   excelButton  = null
    ,   kmlButton  = null
    ,   downloadLink  = null
    ;

    if (this.props.model.get('kmlExportUrl')) {
      kmlButton = (
        React.createElement("button", {className: "btn btn-default icon-button", onClick: (e) => this.exportSelected('kml')}, 
          React.createElement("i", {className: "kml"})
        )
      )
    }

    if (this.props.model.get('excelExportUrl')) {
      excelButton = (
        React.createElement("button", {className: "btn btn-default icon-button", onClick: (e) => this.exportSelected('excel')}, 
          React.createElement("i", {className: "excel"})
        )
      )
    }

    //skapar en länk med url till nedladdning av export. Visar Spara
    //först när url finns.
    if (this.props.model.get("downloading")) {
      downloadLink = React.createElement("a", {href: "#"}, "Hämtar...")

    } else if (this.props.model.get("url")) {
      downloadLink = React.createElement("a", {href: this.props.model.get("url")}, "Hämta sökresultat")
    } else {
      downloadLink = null;
    }


    return (
      React.createElement("div", {className: "search-results", key: "search-results"}, 
        React.createElement("h3", null, "Sökresultat"), 
        React.createElement("div", null, 
          React.createElement("input", {type: "checkbox", id: "display-popup", ref: "displayPopup", onChange: (e) => {this.onChangeDisplayPopup(e)}, checked: this.state.displayPopup}), 
          React.createElement("label", {htmlFor: "display-popup"}, "Visa information"), 
          React.createElement("span", {className: "pull-right"}, excelButton, " ", kmlButton), 
          React.createElement("div", null, downloadLink)
        ), 
        
          (() => {
            if (groups && groups.length > 0) {
              return groups.map((item, i) => {
                var id = "group-" + i;
                return (
                  React.createElement(SearchResultGroup, {
                        isBar: "no", 
                        id: id, 
                        key: id, 
                        result: item, 
                        numGroups: groups.length, 
                        model: this.props.model, 
                        parentView: this, 
                        map: this.props.model.get('map')})
                );
              });
            } else {
              return (React.createElement("div", null, "Sökningen gav inget resultat."));
            }
          })()
        
      )

    );
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var results = null
    ,   value = this.props.model.get('value')
    ,   showResults = this.props.model.shouldRenderResult(false)
    ,   options = this.renderOptions();

    if (showResults) {
      if (this.state.loading) {
        results = (
          React.createElement("p", null, 
            React.createElement("span", {className: "sr-only"}, "Laddar..."), 
            React.createElement("i", {className: "fa fa-refresh fa-spin fa-3x fa-fw"})
          )
        );
      } else {
        if ((this.refs.searchInput &&
             this.refs.searchInput.value.length > 3) ||
             this.props.model.get('force')) {
               results = this.renderResults();
        } else {
          results = (
            React.createElement("p", {className: "alert alert-info"}, 
              "Skriv minst fyra tecken för att påbörja automatisk sökning. Tryck på ", React.createElement("b", null, "retur"), " för att forcera en sökning."
            )
          )
        }
      }
    }

    var search_on_input = (event) => {
      this.value = event.target.value;
      this.props.model.set('value', this.value);
      this.setState({
        value: this.value,
        force: false
      });
      this.props.model.set('force', false);
      if (this.refs.searchInput.value.length > 3) {
        this.search();
      } else {
        this.setState({
          loading: false
        });
      }
      this.props.model.set("downloading", null);
      this.props.model.set("url", null);
    };

    var search_on_click = (event) => {
      this.setState({
        force: true
      });
      this.props.model.set('force', true);
      this.search();

      this.props.model.set("downloading", null);
      this.props.model.set("url", null);
    };

    var selectionToolbar = this.props.model.get('selectionTools')
      ? React.createElement(SelectionToolbar, {model: this.props.model.get('selectionModel')})
      : null;

    return (
      React.createElement("div", {className: "search-tools"}, 
        React.createElement("div", {className: "form-group"}, 
          options, 
          selectionToolbar, 
          React.createElement("div", {className: "input-group"}, 
            React.createElement("div", {className: "input-group-addon"}, 
              React.createElement("i", {className: "fa fa-search"})
            ), 
            React.createElement("input", {
              type: "text", 
              ref: "searchInput", 
              className: "form-control", 
              placeholder: "Ange söktext..", 
              value: value, 
              onKeyDown: this.handleKeyDown, 
              onChange: search_on_input})
          ), 
          React.createElement("div", {className: "clearfix"}, 
            React.createElement("span", {className: "info-text clearfix"}, "Inled sökningen med * för att söka på delar av en text.")
          )
        ), 
        React.createElement("button", {onClick: search_on_click, type: "submit", className: "btn btn-main"}, "Sök"), " ", 
        React.createElement("button", {onClick: this.clear, type: "submit", className: "btn btn-main", id: "sokRensa"}, "Rensa"), 
        results
      )
    );
  }
};

/**
 * SearchView module.<br>
 * Use <code>require('components/search')</code> for instantiation.
 * @module SearchView-module
 * @returns {SearchView}
 */
module.exports = React.createClass(SearchView);

},{"components/searchresultgroup":"components/searchresultgroup","components/selectiontoolbar":"components/selectiontoolbar"}],"components/selectiontoolbar":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');

/**
 * @class
 */
var SelectionPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function () {
    return {
      activeTool: this.props.model.get('activeTool')
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentWillMount: function () {
    this.props.model.on('change:activeTool', () => {
      this.setState({
        activeTool: this.props.model.get('activeTool')
      });
    });
  },

  componentWillUnmount() {
    this.props.model.setActiveTool('');
    this.props.model.off('change:activeTool');
  },

  activateTool: function (name) {
    if (this.props.model.get('activeTool') === name) {
      this.props.model.setActiveTool(undefined);
    } else {
      this.props.model.setActiveTool(name);
    }
  },

  getClassNames: function (type) {
    return this.state.activeTool === type
      ? "btn btn-primary"
      : "btn btn-default";
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    var anchor = this.props.model.get('anchor');

    return (
      React.createElement("div", {className: "selection-toolbar"}, 
        React.createElement("div", null, "Sök baserat på markering i kartan"), 
        React.createElement("div", {className: "btn-group btn-group-lg"}, 
          React.createElement("button", {onClick: () => this.activateTool('drawSelection'), type: "button", className: this.getClassNames('drawSelection'), title: "Markera efter polygon"}, 
            React.createElement("i", {className: "fa iconmoon-yta icon"})
          ), 
          React.createElement("button", {onClick: () => this.activateTool('multiSelect'), type: "button", className: this.getClassNames('multiSelect'), title: "Markera flera objekt"}, 
            React.createElement("i", {className: "fa fa-plus icon"})
          )

        ), 
        React.createElement("div", {className: "btn btn-link", onClick: (e) => {
            e.preventDefault();
            this.props.model.abort();
          }}, "Rensa markering")
      )
    );
  }
};

/**
 * SelectionPanelView module.<br>
 * Use <code>require('views/anchorpanel')</code> for instantiation.
 * @module SelectionPanelView-module
 * @returns {SelectionPanelView}
 */
module.exports = React.createClass(SelectionPanelView);

},{"views/panel":"views/panel"}],"layers/arcgislayer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

function toParamString(obj) {
  return Object.keys(obj).map(k => `${k}=${obj[k]}`).join('&');
}

var LayerModel = require('layers/layer');

/**
 * @typedef {Object} ArcGISLayer~ArcGISLayerProperties
 * @property {string} url
 * @property {string} projection - Default: EPSG:3006
 * @property {number} opacity - Default: 1
 */
var ArcGISLayerProperties = {
  url: "http://ksdgis.se/arcgis/rest/services/hpl/MapServer",
  projection: "EPSG:3006",
  opacity: 0.8,
  extent: [413888.8487813738, 6581993.154569996, 416840.2595669881, 6584784.713516495],
  singleTile: false
};

/**
 * Layer to be used as a display layer wich loads its content from ArcGIS server MapExport.
 * @class ArcGISLayer
 * @param {WmsLayer~WmsLayerProperties} options
 * @param {string} type
 */
var ArcGISLayer = {

  /**
   * @property {ArcGISLayer~ArcGISLayerProperties} defaults - Default properties
   * @instance
   */
  defaults: ArcGISLayerProperties,

  /**
   * @property {bool} validInfo - Default: true
   * @instance
   */
  validInfo: true,

  initialize: function () {
    LayerModel.prototype.initialize.call(this);
    var extent = this.get('extent');
    if (Array.isArray(extent)) {
      extent = extent.map((c, i) => {
        const b = 1E5;
        const v = parseFloat(c);
        return isNaN(v) ? 0 : i < 2 ? v - b : v + b;
      });
    }
    if (this.get('singleTile')) {
      this.layer = new ol.layer.Image({
        extent: extent,
        opacity: this.get('opacity'),
        visible: this.get('visible'),
        name: this.get('name'),
        projection: this.get('projection'),
        source: new ol.source.ImageArcGISRest({
          attributions: this.getAttributions(),
          url: this.get('url'),
          params: this.get('params')
        })
      });
    } else {
      this.layer = new ol.layer.Tile({
        extent: extent,
        opacity: this.get('opacity'),
        visible: this.get('visible'),
        name: this.get('name'),
        projection: this.get('projection'),
        source: new ol.source.TileArcGISRest({
          attributions: this.getAttributions(),
          url: this.get('url'),
          params: this.get('params')
        })
      });
    }

    this.layer.getSource().on('tileloaderror', e => {
      this.tileLoadError();
    });

    this.layer.getSource().on('tileloadend', e => {
      this.tileLoadOk();
    });

    this.layer.on('change:visible', (e) => {
      if (!this.get('visible')) {
        this.tileLoadOk();
      }
    });

    this.layer.getSource().set('url', this.get('url'));
    this.set("type", "arcgis");
  },

  /**
   * Parse response from Identify request
   * @instance
   * @param {object} data - response data from request
   * @param {function} callback
   */
  parseFeatueInfoResponse: function(data, callback) {

    if (data && data.results && Array.isArray(data.results)) {

      if (data.results.length === 0) {
        callback();
      }

      data.results.forEach(result => {
        var features = new ol.format.EsriJSON().readFeatures(result);
        callback(features);
      });

    } else {
      callback();
    }
  },

  /**
   * Generate request query params.
   * @instance
   * @param {external:"ol.coordinate"} coordinate
   * @return {object} query params
   */
  getQueryParams: function(coordinate) {

    var layers = this.get('params')['LAYERS'].replace('show', 'visible')
    ,   size   = this.get('map').getMap().getSize()
    ,   extent = this.get('map').getMap().getView().calculateExtent(size).join(',')
    ,   geom   = coordinate[0] + "," + coordinate[1]
    ,   imgd   = size.concat([96]).join(',')
    ;

    return {
      geometryType: "esriGeometryPoint",
      geometry: geom,
      tolerance: 10,
      layers: layers,
      mapExtent: extent,
      imageDisplay: imgd,
      returnGeometry: true,
      f: 'json'
    };
  },

  /**
   * Load feature information.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {external:"ol.style"} style
   */
  getFeatureInformation: function (params) {

    var url = this.get('url');
    url += "/identify?";
    url += toParamString(this.getQueryParams(params.coordinate));

    $.ajax({
      url: url,
      dataType: 'json',
      success: (data) => {
        this.parseFeatueInfoResponse(data, params.success);
      },
      error: (rsp) => {
        params.error();
      }
    });
  },

  /**
   * Triggers when a tile fails to load.
   * @instance
   */
  tileLoadError: function () {
    this.set("status", "loaderror");
  },

  /**
   * Triggers when a tile loads.
   * @instance
   */
  tileLoadOk: function () {
    this.set("status", "ok");
  }

};

/**
 * ArcGISLayer module.<br>
 * Use <code>require('layer/arcgislayer')</code> for instantiation.
 * @module ArcGISLayer-module
 * @returns {ArcGISLayer}
 */
module.exports = LayerModel.extend(ArcGISLayer);

},{"layers/layer":"layers/layer"}],"layers/datalayer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var helper = (function () {
  return {
    featureArrayToObject: function (a, k) {
      var o = {};
      _.each(a, (i) => {
        o[i.getProperties()[k]] = i
      });
      return o;
    },
    move: function (origin, updatee) {
      origin.getGeometry().setCoordinates(
        updatee.getGeometry().getCoordinates()
      );
    },
    updateProps: function (origin, updatee) {
      origin.setProperties(
        _.extend(updatee.getProperties(), { messages: origin.getProperties().messages })
      );
    }
  };
}());

var LayerModel = require('layers/layer');

/**
 * @typedef {Object} WfsLayer~WfsLayerPropertiesParams
 * @property {string} service - Type of service @default WFS.
 * @property {string} version - Version of the WFS-protocol.
 * @property {string} request - Type of request to perform.
 * @property {string} typename - Name of the featureclass to query.
 * @property {string} outputFormat - Version ov the output format eg: GML2, GML3.
 * @property {string} srsname - SRID of the coordinatesystem eg: EPSG:3007.
 * @property {Array} bbox - Bounding box of wich to restrict the query.
 */

/**
 * @typedef {Object} WfsLayer~WfsLayerProperties
 * @property {string} url
 * @property {external:"ol.source"} vectorSurce
 * @property {external:"ol.source"} imageSource
 * @property {Array} filterFeatures
 * @property {bool} filterApplied Default: false
 * @property {WfsLayer~WfsLayerPropertiesParams} params
 */
var DataLayerProperties = {
  url: "",
  caption: "",
  vectorSource: undefined,
  imageSource: undefined,
  filterFeatures: [],
  filterApplied: false,
  params: {
    service: "WFS",
    version: "",
    request: "",
    typename: "",
    outputFormat: "",
    srsname: "",
    bbox: []
  }
};

/**
 * @description
 *
 *   Layer to be used as a display layer wich loads its features from a WFS-service.
 *   Currently this is supported for both geoserver and ArcGIS for Server.
 *
 * @class WfsLayer
 * @todo Add this layertype in the admintool for creation.
 * @param {WfsLayer~WfsLayerProperties} options
 * @param {string} type
 */
var DataLayer = {
  /**
  * @property {WfsLayer~WfsLayerProperties} defaults - Default properties
  * @instance
  */
  defaults: DataLayerProperties,

  initialize: function () {
    LayerModel.prototype.initialize.call(this);

    this.vectorSource = new ol.source.Vector({
      loader: (extent) => {

        setInterval(() => {
          $.ajax({
            url: this.get('url'),
            success: (vehicleFeatureCollection) => {

              var source = this.getLayer().getSource().getSource()
              ,   format = new ol.format.GeoJSON()
              ,   projection = this.get('map').getCRS();

              vehicleFeatureCollection = JSON.stringify(vehicleFeatureCollection);
              vehicleFeatureCollection = format.readFeatures(vehicleFeatureCollection, {
                dataProjection: "EPSG:4326",
                featureProjection: projection
              });

              vehicleFeatureCollection = vehicleFeatureCollection.filter(feature => {
                var f = false;
                var p = feature.getProperties();

                if (p.destination !== "" || p.transportMode === 3) {
                  f = true;
                }

                return f;
              });

              if (this.initiallyLoaded) {
                this.update(vehicleFeatureCollection, source);
              } else {
                source.addFeatures(vehicleFeatureCollection);
                this.initiallyLoaded = true;
              }

            }
          });
        }, 1000)

      },
      strategy: ol.loadingstrategy.fixed
    });

    this.imageSource = new ol.source.ImageVector({
      source: this.vectorSource,
      style: feature => {
        var icon = mode => {
          switch (mode) {
            case 2: return "http://karta.varmlandstrafik.se/icons/bus-icon.png";
            case 3: return "assets/icons/taxi.png";
            case 5: return "http://karta.varmlandstrafik.se/icons/train-icon.png";
          }
        };
        return [
          new ol.style.Style({
            image: new ol.style.Icon({
              src: icon(feature.getProperties().transportMode)
            })
          })
        ]
      }
    });

    this.layer = new ol.layer.Image({
      caption: this.get('caption'),
      name: this.get('name'),
      visible: this.get("visible"),
      source: this.imageSource
    });

    this.set("type", "data");
  },

  /**
   * Update features in layer.
   * @param {Array<object>} vehicleFeatureCollection - Array of vechicles
   * @param {object} source - Layer source to update
   */
  update: function (vehicleFeatureCollection, source) {
    // Walk through the collection of new vechicles and update
    // the corresponding source vechicle if found.
    // If the vechicle is not found in the source, add it.
    // If the vechicle is found in the source but is not present collection, remove it.
    var features = source.getFeatures();
    var updates  = helper.featureArrayToObject(vehicleFeatureCollection, 'vehicleRef');
    var presents = helper.featureArrayToObject(features, 'vehicleRef');

    _.each(vehicleFeatureCollection, function (fromService) {
      var id = fromService.getProperties()['vehicleRef'];
      if (presents.hasOwnProperty(id)) {
        helper.move(presents[id], fromService); // Move
        helper.updateProps(presents[id], fromService);
      } else {
        source.addFeature(fromService); // Add
      }
    });

    _.each(features, (feature) => {
      var id = feature.getProperties()['vehicleRef'];
      if (!updates.hasOwnProperty(id)) {
        source.removeFeature(feature); // Remove
      }
    });
  },

 /**
  * getSource - Get the source of this laer
  * @instance
  * @return {external:"ol.source"} style
  */
  getSource: function () {
    return this.vectorSource;
  },

 /**
  * updateLayer - Add features to this layer source
  * @instance
  * @param {Array<{external:"ol.feature"}>} feature
  */
  updateLayer: function (features) {
    this.getSource().addFeatures(features);
  },

 /**
  * refresh - redraw the layer
  * @instance
  */
  refresh: function () {
    this.imageSource.setStyle(this.imageSource.getStyle());
  }

};

/**
 * WfsLayer module.<br>
 * Use <code>require('layer/wfslayer')</code> for instantiation.
 * @module WfsLayer-module
 * @returns {WfsLayer}
 */
module.exports = LayerModel.extend(DataLayer);

},{"layers/layer":"layers/layer"}],"layers/extendedwmslayer":[function(require,module,exports){
(function (global){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LayerModel = require('layers/layer');
var {customGetTileUrl, customGetFeatureInformationUrl} = require('oloverrides/wmsurl');
/**
 * @typedef {Object} WmsLayer~WmsLayerProperties
 * @property {string} url
 * @property {string} projection - Default: EPSG:3007
 * @property {string} serverType - argis | geoserver. Default: geoserver
 * @property {number} opacity - Default: 1
 * @property {string} status - Load status for layer. Default: ok
 * @property {object} params
 */
var WmsLayerProperties = {
  url: "",
  projection: "EPSG:3007",
  serverType: 'geoserver',
  opacity: 1,
  status: "ok",
  params: {}
};

/**
 * @description
 *
 * Layer to be used as a display layer wich loads its content WMS-service.
 * This layer type is supported for both geoserver and ArcGIS for Server.
 *
 * @class WmsLayer
 * @param {WmsLayer~WmsLayerProperties} options
 * @param {string} type
 */
var WmsLayer = {

  /**
   * @property {WmsLayer~WmsLayerProperties} defaults - Default properties
   * @instance
   */
  defaults: WmsLayerProperties,

  /**
   * @property {bool} validInfo - Default: true
   * @instance
   */
  validInfo: true,

  initialize: function () {
    LayerModel.prototype.initialize.call(this);

    let parmas  = this.get('params');

    var source = {
      url: this.get('url'),
      params: parmas,
      projection: this.get('projection'),
      serverType: this.get('serverType'),
      imageFormat: this.get('imageFormat'),
      attributions: this.getAttributions()
    };

    var infoClickSource = {
      url: this.get('url'),
      params: Object.assign({}, parmas),
      projection: this.get('projection'),
      serverType: this.get('serverType'),
      imageFormat: this.get('imageFormat'),
      attributions: this.getAttributions()
    };

    this.queryableLayerNames =  this.get('layersconfig').filter((l) => l.queryable).map((l) => l.name).join(',');
    this.set('queryable', this.queryableLayerNames.length > 0);
    
    if (this.get('resolutions') &&
      this.get('resolutions').length > 0 &&
      this.get('origin') &&
      this.get('origin').length > 0) {
        source.tileGrid = new ol.tilegrid.TileGrid({
          resolutions: this.get('resolutions'),
          origin: this.get('origin')
        });
        source.extent = this.get('extent')
    }

    if (this.get('singleTile')) {
      this.layer = new ol.layer.Image({
        name: this.get('name'),
        visible: this.get('visible'),
        queryable:  this.get('queryable'),
        caption: this.get('caption'),
        opacity: this.get("opacity"),
        source: new ol.source.ImageWMS(source)
      });
    } else {
      this.layer = new ol.layer.Tile({
        name: this.get('name'),
        visible: this.get('visible'),
        queryable: this.get('queryable'),
        caption: this.get('caption'),
        opacity: this.get("opacity"),
        source: new ol.source.TileWMS(source)
      });
      if (source.params.VERSION == "1.3.0") {
        //Openlayers stöder ej sweref 99 TM när wms version 1.3.0 används
        //För att komma runt detta har vi skapat en egen getTileUrl funktion.
        this.layer.getSource().setTileUrlFunction(customGetTileUrl.bind(this.layer.getSource()));
      }
    }

    this.set("wmsCallbackName", "wmscallback" + Math.floor(Math.random() * 1000) + 1);
    global.window[this.get("wmsCallbackName")] = _.bind(this.getFeatureInformationReponse, this);

    this.layer.getSource().on('tileloaderror', e => {
      this.tileLoadError();
    });

    this.layer.getSource().on('tileloadend', e => {
      this.tileLoadOk();
    });

    this.layer.on('change:visible', (e) => {
      if (!this.get('visible')) {
        this.tileLoadOk();
      }
    });

    this.layer.getSource().set('url', this.get('url'));
    this.set("type", "wms");
  },
  
  removeProxyFromURLIfPresent : function(url){
    
        var http = url.lastIndexOf("http://");
        var https = url.lastIndexOf("https://");
    
        if(http > https){
          index = http;
        }
        else {
          index = https;
        }
       
        if(index != -1){
          return url.substr(index);
        }
        else {
          return url;
        }
      },

  /**
   * Load feature information.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {external:"ol.style"} style
   */
  getFeatureInformation: function (args) {
    /*
     let url = this.layer.getSource().getGetFeatureInfoUrl(params.coordinate,
        params.resolution,
        params.projection,
        {
          'INFO_FORMAT': this.get('serverType') === "arcgis" ? 'application/geojson' : 'application/json',
          'feature_count': 100
        });
     */
    let sourceConfig = this.get('params');
    let url = customGetFeatureInformationUrl({
      source: this.layer.getSource(),
      layers: this.queryableLayerNames,
      coordinate: args.coordinate,
      resolution:  args.resolution,
      projection: args.projection,
      isSingleTile: this.get('singleTile'),
      params: {
        'INFO_FORMAT': sourceConfig.INFO_FORMAT,
        'feature_count': 100
      }
    });
    //GML
    //Plain text
    if (url) {
      url = this.removeProxyFromURLIfPresent(url);
      this.featureInformationCallback = args.success;
      if (HAJK2.searchProxy) {
        url = encodeURIComponent(url);
      }

      var request = $.ajax({
        url: HAJK2.searchProxy + url,
        success: (data, status, xhr) => {
          let type = xhr.getResponseHeader("Content-Type").split(';')[0]
          switch(type.toLowerCase()) {
            case 'text/xml':
            case 'application/vnd.ogc.gml':{
              let features = new ol.format.GML().readFeatures(data);
              this.featureInformationCallback(features, this.getLayer());
              break;
            }
            case 'application/geojson':
            case 'application/json': {
              let features = new ol.format.GeoJSON().readFeatures(data);
              this.featureInformationCallback(features, this.getLayer());
              break;
            }
            case 'text/plain':
              let fakeFeature = new ol.Feature({
                 geometry:new ol.geom.Point(args.coordinate)
              });
              fakeFeature.setProperties({
                text: data
              });
              this.featureInformationCallback([fakeFeature], this.getLayer());
              break;
            default:
              console.log("Unsupported response type:", type, data);
              break;
          }
        }
      });
      request.fail(args.error);
    }

  },

  /**
   * Triggers when a tile fails to load.
   * @instance
   */
  tileLoadError: function () {
    this.set("status", "loaderror");
  },

  /**
   * Triggers when a tile loads.
   * @instance
   */
  tileLoadOk: function () {
    this.set("status", "ok");
  },

  /**
   * Parse response and trigger registred feature information callback.
   * @param {XMLDocument} respose
   * @instance
   */
  getFeatureInformationReponse: function (response) {
    try {
        var features = new ol.format.GeoJSON().readFeatures(response);
      this.featureInformationCallback(features, this.getLayer());
      } catch (e) {
        console.error(e);
    }
  }
};

/**
 * WmsLayer module.<br>
 * Use <code>require('layer/wmslayer')</code> for instantiation.
 * @module WmsLayer-module
 * @returns {WmsLayer}
 */
module.exports = LayerModel.extend(WmsLayer);

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"layers/layer":"layers/layer","oloverrides/wmsurl":"oloverrides/wmsurl"}],"layers/highlightlayer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LayerModel = require('layers/layer');

/**
 * HighlightLayerProperties object
 * @typedef {Object} HighlightLayer~HighlightLayerProperties
 * @property {external:ol.source} source
 * @property {string} name
 * @property {external:ol.layer} selectedLayer
 */
var HighlightLayerProperties = {  
  source: undefined,
  name: "highlight-wms",
  selectedLayer: undefined,
  markerImg: 'assets/icons/marker.png'
};

/**
 * Prototype for creating a highlightlayer.
 * @class HighlightLayer
 * @augments Layer
 * @param {HighlightLayer~HighlightLayerProperties} options
 * @param {string} type
 */
var HighlightLayer = {
  /**
   * @property {HighlightLayer~HighlightLayerProperties} defualts - Default properties
   */
  defaults: HighlightLayerProperties,

  getDefaultStyle: function () {
    return new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(20, 20, 255, 0.8)',
        width: 4
      }),
      image: new ol.style.Icon({
        anchor: this.get('anchor'),
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: this.get('markerImg'),
        imgSize: this.get('imgSize')
      })
    });
  },

  initialize: function (props) {
    LayerModel.prototype.initialize.call(this);

    this.set({
      anchor: props.anchor,
      imgSize: props.imgSize,
      markerImg: props.markerImg,
      source: new ol.source.Vector({}),
      queryable: false,
      visible: true,
      type: "highlight"
    });

    this.layer = new ol.layer.Vector({
      id: props.id || "",
      visible: true,
      name: this.get('name'),
      source: this.get('source'),
      style: props.style || this.getDefaultStyle()
    });
  },

  /**
   * Remove all features from the highlight layer.
   * @instance
   */
  clearHighlight: function () {
    var source = this.get('source');
    source.clear();
  },

  /**
   * Add a feature to the highlight layer.
   * @instance
   * @param {external:ol.Feature} feature
   */
  addHighlight: function (feature, clear, style) {
    var source = this.get('source');
    this.set('visible', true);
    if (clear && source.getFeatures().length > 0) {
      this.clearHighlight();
    }
    feature.setStyle(style || this.layer.getStyle());
    source.addFeature(feature);
  },

  /**
   * Remove a feature from the highlight layer.
   * @instance
   * @param {external:ol.Feature} feature
   */
  removeHighlight: function (feature) {
    var f = this.get('source').getFeatures().find(f => f.getId() === feature.getId());
    if (f) {
      this.get('source').removeFeature(f);
    }
  },

  /**
   * Set selected layer.
   * @param {external:ol.layer} layer
   * @instance
   */
  setSelectedLayer: function (layer) {
    this.set('selectedLayer', layer);
    this.get('selectedLayer').on("change:visible", (visibility) => {
      this.selectedLayerChanged();
    });
  },

  /**
   * Event handler, fires when the selected layer changes.
   * @instance
   * @param {object} options
   * @param {object} args
   */
  selectedLayerChanged: function () {
    var visible = this.get('selectedLayer').get('visible');
    this.set('visible', visible);
  },

  getFeatures: function() {
    return this.get('source').getFeatures();
  }

};

/**
 * HighlightLayer module.<br>
 * Use <code>require('layer/highlightlayer')</code> for instantiation.
 * @module HighlightLayer-module
 * @returns {HighlightLayer}
 */
module.exports = LayerModel.extend(HighlightLayer);

},{"layers/layer":"layers/layer"}],"layers/layer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Legend = require('components/legend')
,   LegendButton = require('components/legendbutton')
,   InfoButton = require('components/infobutton');

/**
 * HighlightLayerProperties object
 * @typedef {Object} Layer~LayerModelProperties
 * @property {string} name
 * @property {string} caption
 * @property {bool} visible
 * @property {external:"ol.layer"} layer
 */
var LayerModelProperties = {
  name: "",
  caption: "",
  visible: false,
  layer: undefined
};

/**
 * Prototype for creating a layer.
 * @description Base class of layers, do not use this class to instantiate layers.
 * @class Layer
 * @augments {external:"Backbone.Model"}
 */
var Layer = {
  /**
   * @instance
   * @property {Layer~LayerModelProperties} - default properties
   */
  defaults: LayerModelProperties,

  /**
   * Load JSON data from script tag.
   * @instance
   * @param {string} url
   */
  loadJSON: function (url) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    script.onload = () => { document.head.removeChild(script) };
    document.head.appendChild(script);
  },

  initialize: function () {
    this.initialState = _.clone(this.attributes);
    this.on('change:shell', function (sender, shell) {
      this.set("map", shell.get("map"));
    }, this);
  },

  /**
   * Get label visibility.
   * @instance
   * @return {boolean} visibility
   */
  getLabelVisibility: function () {
    return this.get('labelVisibility');
  },

  /**
   * Get label visibility.
   * @instance
   * @return {LegendView} legend
   */
  getLegend: function () {
    return this.get("legend");
  },

  /**
   * Get name.
   * @instance
   * @return {string} name
   */
  getName: function () {
    return this.get("name");
  },

  /**
   * Get caption.
   * @instance
   * @return {string} caption
   */
  getCaption: function () {
    return this.get("caption");
  },

  /**
   * Get visible.
   * @instance
   * @return {bool} visible
   */
  getVisible: function () {
    return this.get("visible");
  },

  /**
   * Get ol layer.
   * @instance
   * @return {external:ol.layer} layer
   */
  getLayer: function () {
    return this.layer || this.get("layer");
  },

  /**
   * Get info visibility.
   * @instance
   * @return {boolean} infoVisible
   */
  getInfoVisible: function () {
    return this.get("infoVisible");
  },

  /**
   * Get info title.
   * @instance
   * @return {string} infoTitle
   */
  getInfoTitle: function () {
    return this.get("infoTitle");
  },

  /**
   * Get info text.
   * @instance
   * @return {string} infoText
   */
  getInfoText: function () {
    return this.get("infoText");
  },

  /**
   * Get info url.
   * @instance
   * @return {string} infoUrl
   */
  getInfoUrl: function () {
    return this.get("infoUrl");
  },
 
  /**
   * Get info url text.
   * @instance
   * @return {string} infoUrlText
   */
  getInfoUrlText: function () {
    return this.get("infoUrlText");
  },

  /**
   * Get info owner.
   * @instance
   * @return {string} infoOwner
   */
  getInfoOwner: function () {
    return this.get("infoOwner");
  },

  /**
   * Set label visibility.
   * @instance
   * @param {bool} visibility
   * @return {undefined}
   */
  setVisible: function (visible) {
    this.set("visible", visible);
  },

  /**
   * Get flat JSON-friendly representation of this instance.
   * @instance
   * @return {object} JSON-representation.
   */
  toJSON: function () {
    var json = _.clone(this.initialState);
    delete json.options;
    json.visible = this.get('visible');
    return json;
  },

  /**
   * Get legend components
   * @instance
   * @return {external:ReactElement} components.
   */
  getLegendComponents: function (settings) {
      var legendComponents = {
        legendButton: null,
        legendPanel: null,
        infoButton: null
      };

      var legendProps = {
        showLegend: settings.legendExpanded,
        legends: this.getLegend(),
        layer: this.getLayer()
      };

      var legendButtonProps = {
        checked: settings.legendExpanded
      };

      var infoButtonProps = {
        checked: settings.infoExpanded
      };

      if (this.getLegend()) {
        legendComponents.legendPanel = React.createElement(Legend, legendProps);
        legendComponents.legendButton = React.createElement(LegendButton, legendButtonProps);
        legendComponents.infoButton = React.createElement(InfoButton, infoButtonProps);
      }

      return legendComponents;
  },

  /**
   * Get extended components
   * @instance
   * @deprecated
   */
  getExtendedComponents: function (settings) {
    return {
      legend: this.getLegendComponents(settings)
    }
  },

  /**
   * Create attribution array
   * @instance
   * @return {Array<external:"ol.Attribution">} attributions
   */
  getAttributions: function() {
    if (this.get('attribution')) {
      return [
        new ol.Attribution({
          html: this.get('attribution')
        })
      ]
    }
  }
};

module.exports = Backbone.Model.extend(Layer);
},{"components/infobutton":"components/infobutton","components/legend":"components/legend","components/legendbutton":"components/legendbutton"}],"layers/wfslayer":[function(require,module,exports){
(function (global){
var LayerModel = require('layers/layer');

module.exports = LayerModel.extend({

  defaults: {
    url: "",
    featureId: "FID",
    serverType: "geoserver",
    dataFormat: "WFS",
    params: {
      service: "",
      version: "",
      request: "",
      typename: "",
      outputFormat: "",
      srsname: "",
      bbox: ""
    },
    showLabels: true
  },

  featureMap: {},

  reprojectFeatures: function(features, from, to) {      
    if (Array.isArray(features)) {
      features.forEach(feature => {
        if (feature.getGeometry().getCoordinates) {
          let coords = feature.getGeometry().getCoordinates();
          try {
            switch (feature.getGeometry().getType()) {
              case 'Point':
                feature.getGeometry().setCoordinates(ol.proj.transform(coords, from, to));
                break;
              case 'LineString':
                feature.getGeometry().setCoordinates(coords.map(coord => ol.proj.transform(coord, from, to)));
                break;
              case 'Polygon':
                feature.getGeometry().setCoordinates([coords[0].map(coord => ol.proj.transform(coord, from, to))]);
                break;
            }
          } catch (e) {
            console.error("Coordinate transformation error.", e);
          }
        }
      });
    }
  },

  addFeatures: function (data, format) {
    var features = []
    ,   parser
    ,   to = this.get('olMap').getView().getProjection().getCode()
    ,   from = this.get('projection');

    if (format === "wfs") {
      parser = new ol.format.WFS({
        gmlFormat: this.get('params').version === "1.0.0" ? new ol.format.GML2() : undefined
      });
    }

    if (format === "geojson") {
      parser = new ol.format.GeoJSON();
    }

    if (parser) {
      features = parser.readFeatures(data);
    }

    if (to !== from) {
      this.reprojectFeatures(features, from, to);
    }

    this.get("source").addFeatures(features);
  },

  loadAJAX: function (url, format) {
    url = HAJK2.wfsProxy + url;
    $.get(url, (features) => {
      this.addFeatures(features, format || "wfs");
    });
  },
  
  getStyle: function (feature, resolution) {

    const icon = this.get("icon");
    const fillColor = this.get("fillColor");
    const lineColor = this.get("lineColor");
    const lineStyle = this.get("lineStyle");
    const lineWidth = this.get("lineWidth");
    const symbolXOffset = this.get("symbolXOffset");
    const symbolYOffset = this.get("symbolYOffset");
    const rotation = 0.0
    const align = this.get("labelAlign") || "center";
    const baseline = this.get("labelBaseline") || "alphabetic";
    const size = this.get("labelSize") || "12px";
    const offsetX = this.get("labelOffsetX") || 0;
    const offsetY = this.get("labelOffsetY") || 0;
    const weight = this.get("labelWeight") || "normal";
    const font = weight + ' ' + size + ' ' + (this.get('labelFont') || "Arial");
    const labelFillColor = this.get("labelFillColor") || "#000000";
    const outlineColor = this.get("labelOutlineColor") || "#FFFFFF";
    const outlineWidth = this.get("labelOutlineWidth") || 3;
    const labelAttribute = this.get("labelAttribute") || "Name";
    const showLabels = this.get('showLabels');

    function getLineDash() {
        var scale = (a, f) => a.map(b => f * b)
        ,   width = lineWidth
        ,   style = lineStyle
        ,   dash  = [12, 7]
        ,   dot   = [2, 7]
        ;
        switch (style) {
          case "dash":
            return width > 3 ? scale(dash, 2) : dash;
          case "dot":
            return width > 3 ? scale(dot, 2) : dot;
          default :
            return undefined;
        }
    }

    function getFill() {
      return new ol.style.Fill({
        color: fillColor
      });
    }    

    function getText() {
      return new ol.style.Text({
        textAlign: align,
        textBaseline: baseline,
        font: font,
        text: feature.get(labelAttribute),
        fill: new ol.style.Fill({
          color: labelFillColor
        }),
        stroke: new ol.style.Stroke({
          color: outlineColor, 
          width: outlineWidth
        }),
        offsetX: offsetX,
        offsetY: offsetY,
        rotation: rotation
      });
    }

    function getImage() {
      return icon === ""
      ? getPoint()
      : getIcon();
    }

    function getIcon() {
      return new ol.style.Icon({
        src: icon,
        scale: 1,
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        anchor: [
          symbolXOffset,
          symbolYOffset
        ]
      });
    }

    function getPoint() {
      return new ol.style.Circle({
        fill: getFill(),
        stroke: getStroke(),
        radius: 4
      });
    }

    function getStroke() {
      return new ol.style.Stroke({
        color: lineColor,
        width: lineWidth,
        lineDash: getLineDash()
      })
    }

    function getStyleObj() {
      return {
        fill: getFill(),
        image: getImage(),
        stroke: getStroke(),
        text: showLabels ? getText() : undefined
      };      
    }

    return [new ol.style.Style(getStyleObj())];
  },

  initialize: function () {

    var source
    ,   layer;

    source = new ol.source.Vector({
      loader: (extent) => {
        if (this.get('dataFormat') === "GeoJSON") {
          this.loadAJAX(this.get('url'), this.get('dataFormat').toLowerCase());
        } else {
          if (this.get('loadType') === 'jsonp') {
            this.loadJSON(this.createUrl(extent));
          }
          if (this.get('loadType') === 'ajax') {
            this.loadAJAX(this.createUrl(extent, true));
          }
        }
      },
      strategy: ol.loadingstrategy.all
    });

    layer = new ol.layer.Image({
      information: this.get('information'),
      caption: this.get('caption'),
      name: this.get('name'),
      visible: this.get("visible"),
      opacity: this.get("opacity"),
      queryable: this.get('queryable'),
      source: new ol.source.ImageVector({
        source: source,
        style: this.getStyle.bind(this)
      })
    });

    if (this.get('loadType') === "jsonp") {
      global.window[this.get('callbackFunction')] = (response) => {
        this.addFeatures(response, "geojson");
      };
    }

    //this.set("queryable", true);
    this.set("source", source);
    this.set("layer", layer);
    this.set("type", "wfs");

    LayerModel.prototype.initialize.call(this);
  },

  createUrl: function (extent, ll) {
    var props = Object.keys(this.get("params"))
    ,   url = this.get("url") + "?"
    ,   version = this.get('params')['version'];

    for (let i = 0; i < props.length; i++) {
      let key   = props[i];
      let value = "";

      if (key !== "bbox") {
        value = this.get("params")[key];
        url += key + '=' + value;
      } else {
        // value = extent.join(',');
        // if (version !== "1.0.0") {
        //    value += "," + this.get("params")['srsname'];
        // }
      }

      if (i !== props.length - 1) {
        url += "&";
      }
    }

    return url;
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"layers/layer":"layers/layer"}],"layers/wmslayer":[function(require,module,exports){
(function (global){
var LayerModel = require('layers/layer');

/**
 * @typedef {Object} WmsLayer~WmsLayerProperties
 * @property {string} url
 * @property {string} projection - Default: EPSG:3007
 * @property {string} serverType - argis | geoserver. Default: geoserver
 * @property {number} opacity - Default: 1
 * @property {string} status - Load status for layer. Default: ok
 * @property {object} params
 */
var WmsLayerProperties = {
  url: "",
  projection: "EPSG:3007",
  serverType: 'geoserver',
  opacity: 1,
  status: "ok",
  params: {}
};

/**
 * @description
 *
 * Layer to be used as a display layer wich loads its content WMS-service.
 * This layer type is supported for both geoserver and ArcGIS for Server.
 *
 * @class WmsLayer
 * @param {WmsLayer~WmsLayerProperties} options
 * @param {string} type
 */
var WmsLayer = {

  /**
   * @property {WmsLayer~WmsLayerProperties} defaults - Default properties
   * @instance
   */
  defaults: WmsLayerProperties,

  /**
   * @property {bool} validInfo - Default: true
   * @instance
   */
  validInfo: true,

  initialize: function () {
    LayerModel.prototype.initialize.call(this);

    var source = {
      url: this.get('url'),
      params: this.get('params'),
      projection: this.get('projection'),
      serverType: this.get('serverType'),
      imageFormat: this.get('imageFormat'),
      attributions: this.getAttributions(),
    };

    if (this.get('resolutions') &&
      this.get('resolutions').length > 0 &&
      this.get('origin') &&
      this.get('origin').length > 0) {
        source.tileGrid = new ol.tilegrid.TileGrid({
          resolutions: this.get('resolutions'),
          origin: this.get('origin')
        });
        source.extent = this.get('extent')
    }

    if (this.get('singleTile')) {
      this.layer = new ol.layer.Image({
        name: this.get('name'),
        visible: this.get('visible'),
        queryable: this.get('queryable'),
        caption: this.get('caption'),
        opacity: this.get("opacity"),
        source: new ol.source.ImageWMS(source)
      });
    } else {
      this.layer = new ol.layer.Tile({
        name: this.get('name'),
        visible: this.get('visible'),
        queryable: this.get('queryable'),
        caption: this.get('caption'),
        opacity: this.get("opacity"),
        source: new ol.source.TileWMS(source)
      });
    }

    this.set("wmsCallbackName", "wmscallback" + Math.floor(Math.random() * 1000) + 1);
    global.window[this.get("wmsCallbackName")] = _.bind(this.getFeatureInformationReponse, this);

    this.layer.getSource().on('tileloaderror', e => {
      this.tileLoadError();
    });

    this.layer.getSource().on('tileloadend', e => {
      this.tileLoadOk();
    });

    this.layer.on('change:visible', (e) => {
      if (!this.get('visible')) {
        this.tileLoadOk();
      }
    });

    this.layer.getSource().set('url', this.get('url'));
    this.set("type", "wms");
  },

  removeProxyFromURLIfPresent : function(url){

    var http = url.lastIndexOf("http://");
    var https = url.lastIndexOf("https://");

    if(http > https){
      index = http;
    }
    else {
      index = https;
    }
   
    if(index != -1){
      return url.substr(index);
    }
    else {
      return url;
    }
  },

  /**
   * Load feature information.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {external:"ol.style"} style
   */
  getFeatureInformation: function (params) {
    var url;
    try {

      this.validInfo = true;
      this.featureInformationCallback = params.success;

      url = this.getLayer()
      .getSource()
      .getGetFeatureInfoUrl(
        params.coordinate,
        params.resolution,
        params.projection,
        {
          'INFO_FORMAT': this.get('serverType') === "arcgis" ? 'application/geojson' : 'application/json',
          'feature_count': 100
        }
      );

      if (url) {
        url = this.removeProxyFromURLIfPresent(url);

        if (HAJK2.searchProxy) {
          url = encodeURIComponent(url);
        }

        var request = $.ajax({
          url: HAJK2.searchProxy + url,
          success: (data) => {
            var features = new ol.format.GeoJSON().readFeatures(data);
            this.featureInformationCallback(features, this.getLayer());
          }
        });
        
        request.fail(params.error);
      }
    } catch (e) {
      params.error(e);
    }
  },

  /**
   * Triggers when a tile fails to load.
   * @instance
   */
  tileLoadError: function () {
    this.set("status", "loaderror");
  },

  /**
   * Triggers when a tile loads.
   * @instance
   */
  tileLoadOk: function () {
    this.set("status", "ok");
  },

  /**
   * Parse response and trigger registred feature information callback.
   * @param {XMLDocument} respose
   * @instance
   */
  getFeatureInformationReponse: function (response) {
    try {
        var features = new ol.format.GeoJSON().readFeatures(response);
      this.featureInformationCallback(features, this.getLayer());
      } catch (e) {
        console.error(e);
    }
  }
};

/**
 * WmsLayer module.<br>
 * Use <code>require('layer/wmslayer')</code> for instantiation.
 * @module WmsLayer-module
 * @returns {WmsLayer}
 */
module.exports = LayerModel.extend(WmsLayer);

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"layers/layer":"layers/layer"}],"layers/wmtslayer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var LayerModel = require('layers/layer');

/**
 * @typedef {Object} WmtsLayer~WmtsLayerProperties
 * @property {string} url
 * @property {string} projection - Default: EPSG:3007
 * @property {string} layer
 * @property {number} opacity - Default: 1
 * @property {string} matrixSet - Default: '3006'
 * @property {string} style - Default: 'default'
 * @property {string} axisMode - Used by export engine: natural | crs | geographic. Default: natural (X and Y are flipped in comparison with cartesian).
 * @property {array} origin - Origin of tileset. Default: [-1200000, 8500000]
 */
var WmtsLayerProperties = {
  url: '',
  projection: 'EPSG:3006',
  layer: '',
  opacity: 1,
  matrixSet: '3006',
  style: 'default',
  axisMode: 'natural',
  origin: [-1200000, 8500000],
  resolutions: [4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5],
  matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  attribution: ""
};

/**
 * @description
 *
 *   Layer to be used as a background layer wich loads its content form a WMTS tile source.
 *
 * @class WmtsLayer
 * @param {WmsLayer~WmsLayerProperties} options
 * @param {string} type
 */
var WmtsLayer = {

  /**
   * @property {WmtsLayer~WmtsLayerProperties} defaults - Default properties
   * @instance
   */
  defaults: WmtsLayerProperties,

  /**
   * Update the map view when the shell changes.
   * @instance
   */
  updateMapViewResolutions: function () {
    var map  = this.get('shell').getMap().getMap()
    ,   view = map.getView();
    map.setView(new ol.View({
      zoom: view.getZoom(),
      center: view.getCenter(),
      resolutions: this.get('resolutions'),
      projection: ol.proj.get(this.get('projection'))
    }));
  },

  initialize: function () {
    LayerModel.prototype.initialize.call(this);
    this.set('resolutions', this.get('resolutions').map(r => Number(r)));
    this.set('origin', this.get('origin').map(o => Number(o)));
    this.layer = new ol.layer.Tile({
      name: this.get('name'),
      caption: this.get('caption'),
      visible: this.get('visible'),
      queryable: this.get('queryable'),
      opacity: this.get('opacity'),
      source: new ol.source.WMTS({
        attributions: this.getAttributions(),
        format: 'image/png',
        wrapX: false,
        url: this.get('url'),
        axisMode: this.get('axisMode'),
        layer: this.get('layer'),
        matrixSet: this.get('matrixSet'),
        style: this.get('style'),
        projection: this.get('projection'),
        tileGrid: new ol.tilegrid.WMTS({
          origin: this.get('origin'),
          resolutions: this.get('resolutions'),
          matrixIds: this.get('matrixIds'),
          extent: this.get('extent')
        })
      })
    });

    this.layer.getSource().set('url', this.get('url'));
    this.layer.getSource().set('axisMode', this.get('axisMode'));

    this.on('change:shell', function (sender, shell) {
      this.updateMapViewResolutions();
    }, this);

    this.set("type", "wmts");
  },
};

/**
 * WmtsLayer module.<br>
 * Use <code>require('layer/wmtslayer')</code> for instantiation.
 * @module WmtsLayer-module
 * @returns {WmtsLayer}
 */
module.exports = LayerModel.extend(WmtsLayer);

},{"layers/layer":"layers/layer"}],"models/drag":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

ol.interaction.Drag = function() {

  ol.interaction.Pointer.call(this, {
    handleDownEvent: ol.interaction.Drag.prototype.handleDownEvent,
    handleDragEvent: ol.interaction.Drag.prototype.handleDragEvent,
    handleMoveEvent: ol.interaction.Drag.prototype.handleMoveEvent,
    handleUpEvent: ol.interaction.Drag.prototype.handleUpEvent
  });

  this.coordinate_ = null;

  this.cursor_ = 'pointer';

  this.feature_ = null;

  this.layer_ = null;

  this.previousCursor_ = undefined;
};

ol.inherits(ol.interaction.Drag, ol.interaction.Pointer);

ol.interaction.Drag.prototype.pause = function() {
  this.paused = true;
};

ol.interaction.Drag.prototype.resume = function () {
  this.paused = false;
};

var acceptedLayers = {
  'preview-layer': true
};

ol.interaction.Drag.prototype.addAcceptedLayer = function (layerName) {
  acceptedLayers[layerName] = layerName;
}

ol.interaction.Drag.prototype.removeAcceptedLayer = function (layerName) {
  delete acceptedLayers[layerName];
}

ol.interaction.Drag.prototype.isDraggable = function (layer) {
  return layer ? acceptedLayers.hasOwnProperty(layer.getProperties().name) || layer.dragLocked === false : true;
};

ol.interaction.Drag.prototype.handleDownEvent = function (evt) {
  var map = evt.map
  ,   feature;

  this.layer_ = undefined;

  feature = map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
    this.layer_ = layer;
    return feature;
  });

  if (feature && this.isDraggable(this.layer_)) {
    this.coordinate_ = evt.coordinate;
    this.feature_ = feature;
  } else {
    if (this.layer_)
      this.layer_.dragLocked = true;
    feature = false;
    this.feature_ = false;
  }

  return !!feature;

};

ol.interaction.Drag.prototype.handleDragEvent = function(evt) {
  var map = evt.map
  ,   deltaX = 0
  ,   deltaY = 0
  ,   geometry;

  if (this.paused) {
    return;
  }

  deltaX = evt.coordinate[0] - this.coordinate_[0];
  deltaY = evt.coordinate[1] - this.coordinate_[1];

  this.coordinate_[0] = evt.coordinate[0];
  this.coordinate_[1] = evt.coordinate[1];

  if (this.layer_ &&  this.layer_.getProperties().name !== 'highlight-wms') {
    this.feature_.getGeometry().translate(deltaX, deltaY);
  }
};

ol.interaction.Drag.prototype.handleMoveEvent = function(evt) {

  if (this.cursor_) {
    var featureLayer = ""
    ,   map = evt.map
    ,   feature = map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                    featureLayer = layer;
                    return feature;
                  })
    ,   element = evt.map.getTargetElement();

    if (feature && feature.getProperties().user === true) {
      if (element.style.cursor != this.cursor_) {
        this.previousCursor_ = element.style.cursor;
        element.style.cursor = this.cursor_;
      }
    } else if (this.previousCursor_ !== undefined) {
      element.style.cursor = this.previousCursor_;
      this.previousCursor_ = undefined;
    }
  }
};

ol.interaction.Drag.prototype.handleUpEvent = function(evt) {
  this.coordinate_ = null;
  this.feature_ = null;
  return false;
};

module.exports = ol.interaction.Drag;

},{}],"models/map":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Drag = require("models/drag");

/**
 * @typedef {Object} MapModel~MapModelProperties
 * @property {Array<{number}>} center - Center of map. Default: [0, 0]
 * @property {number} zoom - Default: 1
 * @property {number} minZoom - Default: 15
 * @property {string} target - Default: map
 * @property {string} projectionCode - Default: EPSG:3006
 * @property {object} ol
 * @property {bool} clicked
 */
var MapModelProperties = {
  center: [0, 0],
  zoom: 1,
  maxZoom: 15,
  minZoom: 1,
  target: "map",
  projection: "EPSG:3007",
  ol: undefined,
  clicked: undefined,
  extent: undefined
};

/**
 * Prototype for creating a map.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {MapModel~MapModelProperties} options - Default options
 */
var MapModel = {
  /**
   * These are the default properties, can me augmentet, has default values.
   * @instance
   * @property {MapModel~MapModelProperties} defaults - Default settings
   */
  defaults: MapModelProperties,

  initialize: function (options) {

    this.initialState =  _.clone(this.attributes);
    if (typeof options.mobile !== 'undefined'){
      mobilAnpassningEnabled = options.mobile;
    } else {
      mobilAnpassningEnabled = false;
    }

    if(typeof options.maxMobileWidth !== 'undefined') {
      isMobile = document.body.clientWidth <= options.maxMobileWidth;
    } else {
      isMobile = document.body.clientWidth <= 600;
    }

    infologo = null;
    if(typeof options.infologo !== 'undefined') {
      infologo = options.infologo;
    }

    var app = window.app;
    var map = new ol.Map({
      interactions: ol.interaction.defaults().extend([new Drag()]),
      target: this.get("target"),
      layers: [],
      logo: false,
      pil: false,
      controls: [
        new ol.control.Zoom({ zoomInTipLabel: 'Zooma in', zoomOutTipLabel: 'Zooma ut' }),
        new ol.control.Attribution({ collapsible: false }),
        new ol.control.Rotate({tipLabel: 'Återställ rotation'}),
        //new app.PositioningControl()
      ],
      pixelRatio: 1,
      overlays: [],
      view: new ol.View({
        zoom: this.get("zoom"),
        units: 'm',
        resolutions: this.get('resolutions'),
        center: this.get("center"),
        projection: ol.proj.get(this.get('projection')),
        extent: this.get('extent').length != 0 ? this.get('extent') : undefined
      })
    });
    this.set("ol", map);
    setTimeout(() => {
      var scaleLine = new ol.control.ScaleLine({
        target: 'map-scale-bar'
      })
      map.addControl(scaleLine);
    map.addOverlay(this.createPopupOverlay());
    $('.ol-popup').show();
  }, 100);
  },

  createPopupOverlay: function () {
    var container = document.getElementById('popup')
      ,   closer = document.getElementById('popup-closer');

    overlay = new ol.Overlay({
      element: container,
      autoPan: false,
      id: "popup-0"
    });

    closer.onclick = function() {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    return overlay;
  },

  update: function(config) {
    var map = this.get('ol');
    map.getView().setCenter(config.center);
    map.getView().setZoom(config.zoom);
  },

  /**
   * Get openlayers map instance.
   * @instance
   * @return {object} map
   */
  getMap: function () {
    return this.get("ol");
  },
  /**
   * Get current zoom level
   * @instance
   * @return {number} zoom level
   */
  getZoom: function () {
    return this.getMap().getView().getZoom();
  },
  /**
   * Get current map sclae
   * @instance
   * @return {number} map scale
   */
  getScale: function () {

    var dpi = 25.4 / 0.28
      ,   mpu = ol.proj.METERS_PER_UNIT["m"]
      ,   inchesPerMeter = 39.37
      ,   res = this.getMap().getView().getResolution()
    ;

    return res * mpu * inchesPerMeter * dpi;
  },
  /**
   * Get EPSG code.
   * @instance
   * @return {number} EPSG-code
   */
  getCRS: function () {
    return this.getMap().getView().getProjection().getCode();
  },
  /**
   * Get JSON representation.
   * @instance
   * @return {string} JSON-representation
   */
  toJSON: function () {
    var json = this.initialState;
    json.zoom = this.getMap().getView().getZoom();
    json.center = this.getMap().getView().getCenter();
    return json;
  }
};

/**
 * Map model module.<br>
 * Use <code>require('models/map')</code> for instantiation.
 * @module MapModel-module
 * @returns {MapModel}
 */
module.exports = Backbone.Model.extend(MapModel);

},{"models/drag":"models/drag"}],"models/navigation":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @typedef {Object} NavigationModel~NavigationModelProperties
 * @property {Array<{object}>} panels
 * @property {boolean} visible
 * @property {booelan} toggled
 * @property {string} activePanel
 */
var NavigationModelProperties =  {
  panels: [],
  visible: false,
  toggled: false,
  activePanel: undefined
};

/**
 * @desription
 *
 *  Prototype for creating a navigation model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {NavigationModel~NavigationModelProperties} options - Default options
 */
var NavigationModel = {
  /**
   * @instance
   * @property {NavigationModel~NavigationModelProperties} defaults - Default settings
   */
  defaults: NavigationModelProperties,

  initialize: function (options) {
    options.panels.forEach(panel => {
      panel.model.on("change:r", () => {
        this.set('r', panel.model.get('r'));
      });
      panel.model.on("change:visible", this.onPanelVisibleChanged, this);
      panel.model.on("change:toggled", () => {
        if (this.get('lastPanel') && this.get('lastPanel').type === "InfoPanel") {
          this.set("lastPanel", panel);
        } else {
          if (this.get('lastPanel') && this.get('lastPanel').type !== panel.type) {
            this.set("lastPanel", panel);
            this.set('toggled', false);
          } else {
            this.set("lastPanel", panel);
            this.set('toggled', !this.get('toggled'));
          }
        }
      });
    });

    this.on('change:visible', (s, visible) => {
      if (this.get('activePanel') && !visible) {
        this.get('activePanel').model.set('visible', visible);
      }
    });
  },

  /**
   * Change active panel
   * @instance
   * @property {object} panelRef
   * @property {string} type
   */
  navigate: function(panelRef, type) {
    this.set('lastPanel', this.get("activePanel"));
    if (panelRef) {
      this.set("activePanelType", type);
      this.set("activePanel", panelRef);
      if (!this.get("visible")) {
        this.set("visible", true);
      }
    } else {
      this.set("visible", false);
    }
  },

  /**
   * Handler for toggle events of panels.
   * @instance
   * @param {object} panel
   * @param {boolean} visible
   */
  onPanelVisibleChanged: function (panel, visible) {
    var type = (panel.get('panel') || '').toLowerCase()
    ,   panelRef = _.find(this.get("panels"), panel => (panel.type || '').toLowerCase() === type)
    ,   activePanel = this.get("activePanel", panelRef);

    if (visible) {
      if (activePanel) {
        let a = activePanel.model.get('panel')
        ,   b = panel.get('panel').toLowerCase();

        activePanel.model.set("visible", false);

        if (activePanel.model.filty && a !== b) {
          this.set('alert', true);
          this.ok = () => {
            this.navigate(panelRef, type);
          };
          this.deny = () => {
            if (panelRef) {
              panelRef.model.set('visible', false);
            }
          }
        }
      }
      if (!this.get('alert')) {
        this.navigate(panelRef, type);
      }
    }
  }
};

/**
 * Navigation model module.<br>
 * Use <code>require('models/navigation')</code> for instantiation.
 * @module NavigationModel-module
 * @returns {NavigationModel}
 */
module.exports = Backbone.Model.extend(NavigationModel);

},{}],"models/selection":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var HighlightLayer = require('layers/highlightlayer');

/**
 * @typedef {Object} SelectionModel~SelectionModelProperties
 * @property {string} type -Default: anchor
 * @property {string} panel -Default: anchorpanel
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-link icon fa-flip-horizontal
 * @property {string} title -Default: Länk
 * @property {boolean} visible - Default: false
 * @property {ShellModel} shell
 * @property {string} anchor - Default: ''
 */
var SelectionModelProperties = {
  activeTool: '',
  markerImg: "assets/icons/marker.png",
  anchor: [
    8,
    8
  ],
  imgSize: [
    16,
    16
  ]
};

/**
 * @description
 *
 *  Prototype for creating an anchor model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {SelectionModel~SelectionModelProperties} options - Default options
 */
var SelectionModel = {
  /**
   * @instance
   * @property {SelectionModel~SelectionModelProperties} defaults - Default settings
   */
  defaults: SelectionModelProperties,

  features: {},

  isDrawActive: false,

  initialize: function (options) {

    this.set('olMap', options.map);
    this.set('layerCollection', options.layerCollection);
    this.set('source', new ol.source.Vector({ wrapX: false }));

    this.set('drawLayer', new ol.layer.Vector({
      source: this.get('source'),
      queryable: false,
      name: 'search-selection-layer',
      style: (feature) => this.getScetchStyle(feature)
    }));

    this.set('highlightLayer', new HighlightLayer({
      id: 'selection-highligt',
      anchor: this.get('anchor'),
      imgSize: this.get('imgSize'),
      markerImg: this.get('markerImg'),
      style: this.getScetchStyle()
    }));

    this.get('olMap').addLayer(this.get('drawLayer'));
    this.get('olMap').addLayer(this.get('highlightLayer').layer);

    this.set('drawTool', new ol.interaction.Draw({
      source: this.get('source'),
      style: this.getScetchStyle(),
      type: 'Polygon'
    }));

    this.get('drawTool').on('drawend', () => {
      this.get('source').clear();
      this.get('highlightLayer').clearHighlight();
      this.clear();
    });
  },

  isQueryable: function (layer) {
    return (
      (
        layer.get("type") === "wms" ||
        layer.get("type") === "arcgis"
      ) &&
      layer.get("queryable") &&
      layer.getVisible()
    )
  },

  clearSelection: function () {
    this.get('source').clear();
    this.get('highlightLayer').clearHighlight();
    this.features = {};
  },

  clear: function () {
    this.features = {};
  },

  putHighlightLayerOnTop: function() {
    let layers = this.get('olMap').getLayers();
    const topIndex = layers.getLength() - 1;
    var h = layers.getArray().find(layer => layer.get("id") === "selection-highligt");
    if (h) {
      layers.remove(h);
      layers.push(h);
    }
  },

  addFeature: function (f) {
    const id = f.getId();

    let clone = f.clone();
    clone.setId(f.getId());

    this.get('source').clear();
    this.putHighlightLayerOnTop();

    if (this.features.hasOwnProperty(id)) {
      delete this.features[id];
      this.get('highlightLayer').removeHighlight(clone);
    } else {
      this.features[id] = f;
      f.operation = "Within";
      this.get('highlightLayer').addHighlight(clone, false);
    }
  },

  onMapSingleClick: function (event) {

    if (this.get('activeTool') !== "multiSelect") {
      return;
    }

    var wmsLayers = this.get('layerCollection').filter(layer => this.isQueryable(layer))
    ,   projection = this.get('olMap').getView().getProjection().getCode()
    ,   resolution = this.get('olMap').getView().getResolution()
    ,   promises = []
    ;

    this.get('olMap').forEachFeatureAtPixel(event.pixel, (feature, layer) => {
      if (layer && layer.get('name')) {
        if (
          layer.get('name') !== 'preview-layer' &&
          layer.get('name') !== 'highlight-wms'
        ) {
          promises.push(new Promise((resolve, reject) => {
            this.addFeature(feature);
            resolve();
          }));
        }
      }
    });

    wmsLayers.forEach((wmsLayer, index) => {
      wmsLayer.index = index;
      promises.push(new Promise((resolve, reject) => {
        wmsLayer.getFeatureInformation({
          coordinate: event.coordinate,
          resolution: resolution,
          projection: projection,
          error: message => {
            resolve();
          },
          success: features => {
            if (Array.isArray(features) && features.length > 0) {
              features.forEach(feature => {
                this.addFeature(feature);
              });
            }
            resolve();
          }
        });
      }));
    });

    Promise.all(promises).then(() => {
      // Selection complete
    });

  },

  getScetchStyle: function () {
    const color = 'rgba(0, 0, 0, 0.6)';
    return [
      new ol.style.Style({
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0)'
        }),
        stroke: new ol.style.Stroke({
          color: color,
          width: 4
        }),
        image: new ol.style.Circle({
          radius: 6,
          fill: new ol.style.Fill({
            color: 'rgba(255, 255, 255, 0)'
          }),
          stroke: new ol.style.Stroke({
            color: color,
            width: 2
          })
        })
      })
    ];
  },

  hasFeatures: function () {
    return (
      this.get('source').getFeatures().length > 0 ||
      Object.keys(this.features).length > 0
    );
  },

  setActiveTool: function (tool) {
    this.get('olMap').removeInteraction(this.get('drawTool'));
    this.set('activeTool', tool);

    if (tool === 'drawSelection') {
      this.get('olMap').addInteraction(this.get('drawTool'));
      this.get('olMap').set('clickLock', true);
    }

    if (tool === 'multiSelect') {
      this.get('olMap').on('singleclick', this.onMapSingleClick, this);
      this.get('olMap').set('clickLock', true);
    }

    if (!tool) {
      this.get('olMap').set('clickLock', false);
      this.get('olMap').un('singleclick', this.onMapSingleClick, this);
    }
  },

  getFeatures: function () {
    return this.get('highlightLayer').getFeatures().concat(
      this.get('source').getFeatures()
    );
  },

  abort: function () {
    this.setActiveTool('');
    this.get('source').clear();
    this.get('olMap').set('clickLock', false);
    this.get('highlightLayer').clearHighlight();
    this.clear();
  }
};

/**
 * Selection model module.<br>
 * Use <code>require('models/selectionmodel')</code> for instantiation.
 * @module SelectionModel-module
 * @returns {SelectionModel}
 */
module.exports = Backbone.Model.extend(SelectionModel);

},{"layers/highlightlayer":"layers/highlightlayer"}],"models/shell":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var MapModel = require('models/map');
var LayerCollection = require('collections/layers');
var ToolCollection = require('collections/tools');
var NavigationPanelModel = require("models/navigation");

/**
 * @description
 *
 *  Prototype for creating a shell model.
 *  The shell is used as a container for the application environment.
 *  This intermediate class holds references to other modules. (Map, Tools, Layers, Navigation)
 *  Any communication between models will be occur through this model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {object} options - Default options
 */
var ShellModel = {

  initialize: function (config) {
    this.initialConfig = config;
    this.cid += '_map';
    if (config) {
      config.map.target = this.cid;
      _.each(config.projections || [], function (proj) {
        proj4.defs(proj.code, proj.definition);
        ol.proj.addProjection(new ol.proj.Projection({
          code: proj.code,
          extent: proj.extent,
          units: proj.units
        }));
      });
      this.set('canStart', true);
    } else {
      this.set('canStart', false);
    }
  },

  configure: function () {
    var config = this.initialConfig;
    if (this.get('canStart')) {
      config.tools.sort((a, b) => a === b ? 0 : a.index > b.index ? 1 : -1);
      this.set('map', new MapModel(config.map));
      this.set('layerCollection', new LayerCollection(config.layers, { shell: this, mapConfig: config.map, tools: config.tools }));
      this.set('toolCollection', new ToolCollection(config.tools, { shell: this }));

      let tools = this.get('toolCollection').toArray();
      let panels = tools.filter(tool => tool.get('panel'))
        .map(panel => {
          return {
            type: panel.get('panel'),
            model: panel
          }
        });

      this.set('navigation', new NavigationPanelModel({ panels: panels }));
    }
  },

  /**
   * Get map property value
   * @instance
   * @return {MapModel} map model
   */
  getMap: function () {
    return this.get('map');
  },

  /**
   * Get layer collection property value
   * @instance
   * @return {LayerCollection} layer collection
   */
  getLayerCollection: function () {
    return this.get('layerCollection');
  },

  /**
   * Get tool collection property value
   * @instance
   * @return {ToolCollection} tool collection
   */
  getToolCollection: function () {
    return this.get('toolCollection');
  },

  /**
   * Get navigation property value
   * @instance
   * @return {NavigationModel} navigation model
   */
  getNavigation: function () {
    return this.get('navigation');
  },

  /**
   * Convert model to JSON-string
   * @instance
   * @return {string} JSON-string
   */
  toJSON: function () {
    var json = _.clone(this.initialConfig);
    json.layers = this.getLayerCollection().toJSON();
    json.map = this.getMap().toJSON();
    json.toolCollection = this.getToolCollection().toJSON();
    return JSON.stringify(json);
  },

  /**
   * Set bookmark property value
   * @instance
   * @param {Array<{object}>} bookmars
   */
  setBookmarks: function (bookmarks) {
    this.set('bookmarks', bookmarks);
  },

  /**
   * Get bookmarks property value
   * @instance
   * @return {object} bookmars
   */
  getBookmarks: function () {
    return this.get('bookmarks');
  },

  /**
   * Get configuration property value
   * @instance
   * @return {object} configuration
   */
  getConfig: function () {
    return this.get('config');
  },

  /**
   * Set configuration property value
   * @instance
   * @param {object} configuration
   */
  setConfig: function (config) {
    this.set('config', config);
    this.set('configUpdated', new Date().getTime());
  },

  /**
   * Set configuration property value
   * @instance
   * @param {object} configuration
   */
  updateConfig: function () {
  }
};

/**
 * Shell model module.<br>
 * Use <code>require('models/shell')</code> for instantiation.
 * @module ShellModel-module
 * @returns {ShellModel}
 */
module.exports = Backbone.Model.extend(ShellModel);

},{"collections/layers":"collections/layers","collections/tools":"collections/tools","models/map":"models/map","models/navigation":"models/navigation"}],"models/transform":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

// https://raw.githubusercontent.com/Viglino/ol3-ext

/** Interaction rotate
 * @constructor
 * @extends {ol.interaction.Pointer}
 * @fires select | rotatestart | rotating | rotateend | translatestart | translating | translateend | scalestart | scaling | scaleend
 * @param {olx.interaction.TransformOptions}
 *  - layers {Array<ol.Layer>} array of layers to transform,
 *  - features {ol.Collection<ol.Feature>} collection of feature to transform,
 *	- translateFeature {bool} Translate when click on feature
 *	- translate {bool} Can translate the feature
 *	- stretch {bool} can stretch the feature
 *	- scale {bool} can scale the feature
 *	- rotate {bool} can rotate the feature
 *	- keepAspectRatio { ol.events.ConditionType | undefined } A function that takes an ol.MapBrowserEvent and returns a boolean to keep aspect ratio, default ol.events.condition.shiftKeyOnly.
 *	- style {} list of ol.style for handles
 *
 */
ol.interaction.Transform = function(options)
{	if (!options) options={};
	var self = this;

	// Create a new overlay layer for the sketch
	this.handles_ = new ol.Collection();
	this.overlayLayer_ = new ol.layer.Vector(
		{	source: new ol.source.Vector({
				features: this.handles_,
				useSpatialIndex: false
			}),
			name:'Transform overlay',
			displayInLayerSwitcher: false,
			// Return the style according to the handle type
			style: function (feature)
				{	return (self.style[(feature.get('handle')||'default')+(feature.get('constraint')||'')+(feature.get('option')||'')]);
				}
		});

	// Extend pointer
	ol.interaction.Pointer.call(this,
	{	handleDownEvent: this.handleDownEvent_,
		handleDragEvent: this.handleDragEvent_,
		handleMoveEvent: this.handleMoveEvent_,
		handleUpEvent: this.handleUpEvent_
	});

	/** Collection of feature to transform */
	this.features_ = options.features;
	/** List of layers to transform */
	this.layers_ = options.layers ? (options.layers instanceof Array) ? options.layers:[options.layers] : null;

	/** Translate when click on feature */
	this.set('translateFeature', (options.translateFeature!==false));
	/** Can translate the feature */
	this.set('translate', (options.translate!==false));
	/** Can stretch the feature */
	this.set('stretch', (options.stretch!==false));
	/** Can scale the feature */
	this.set('scale', (options.scale!==false));
	/** Can rotate the feature */
	this.set('rotate', (options.rotate!==false));
	/** Keep aspect ratio */
	this.set('keepAspectRatio', (options.keepAspectRatio || function(e){ return e.originalEvent.shiftKey }));

	// Force redraw when changed
	this.on ('propertychange', function()
	{	this.drawSketch_();
	});

	// setstyle
	this.setDefaultStyle();

};
ol.inherits(ol.interaction.Transform, ol.interaction.Pointer);

/** Cursors for transform
*/
ol.interaction.Transform.prototype.Cursors =
{	'default':	'auto',
	'select':	'pointer',
	'translate':'move',
	'rotate':	'move',
	'scale':	'ne-resize',
	'scale1':	'nw-resize',
	'scale2':	'ne-resize',
	'scale3':	'nw-resize',
	'scalev':	'e-resize',
	'scaleh1':	'n-resize',
	'scalev2':	'e-resize',
	'scaleh3':	'n-resize'
};

/**
 * Remove the interaction from its current map, if any,  and attach it to a new
 * map, if any. Pass `null` to just remove the interaction from the current map.
 * @param {ol.Map} map Map.
 * @api stable
 */
ol.interaction.Transform.prototype.setMap = function(map)
{	if (this.getMap()) this.getMap().removeLayer(this.overlayLayer_);
	ol.interaction.Pointer.prototype.setMap.call (this, map);
	this.overlayLayer_.setMap(map);
 	if (map !== null) {
		this.isTouch = /touch/.test(map.getViewport().className);
		this.setDefaultStyle();
	}
};

/**
 * Activate/deactivate interaction
 * @param {bool}
 * @api stable
 */
ol.interaction.Transform.prototype.setActive = function(b)
{	ol.interaction.Pointer.prototype.setActive.call (this, b);
	if (b) this.select(null);
};


/** Set efault sketch style
*/
ol.interaction.Transform.prototype.setDefaultStyle = function()
{	// Style
	var stroke = new ol.style.Stroke({ color: [0, 0, 0, 1], width: 1 });
	var strokedash = new ol.style.Stroke({ color: [0, 0, 0, 1], width: 1, lineDash:[4,4] });
	var fill0 = new ol.style.Fill({ color:[0, 0, 0, 0.01] });
	var fill = new ol.style.Fill({ color:[255, 255, 255, 0.8] })
	var circle = new ol.style.RegularShape({
					fill: fill,
					stroke: stroke,
					radius: this.isTouch ? 12 : 6,
					points: 15
				});
	circle.getAnchor()[0] = this.isTouch ? -10 : -5;
	var bigpt = new ol.style.RegularShape({
					fill: fill,
					stroke: stroke,
					radius: this.isTouch ? 16 : 8,
					points: 4,
					angle: Math.PI/4
				});
	var smallpt = new ol.style.RegularShape({
					fill: fill,
					stroke: stroke,
					radius: this.isTouch ? 12 : 6,
					points: 4,
					angle: Math.PI/4
				});
	function createStyle (img, stroke, fill)
	{	return [ new ol.style.Style({image:img, stroke:stroke, fill:fill}) ];
	}
	/** Style for handles */
	this.style =
	{	'default': createStyle (bigpt, strokedash, fill0),
		'translate': createStyle (bigpt, stroke, fill),
		'rotate': createStyle (circle, stroke, fill),
		'rotate0': createStyle (bigpt, stroke, fill),
		'scale': createStyle (bigpt, stroke, fill),
		'scale1': createStyle (bigpt, stroke, fill),
		'scale2': createStyle (bigpt, stroke, fill),
		'scale3': createStyle (bigpt, stroke, fill),
		'scalev': createStyle (smallpt, stroke, fill),
		'scaleh1': createStyle (smallpt, stroke, fill),
		'scalev2': createStyle (smallpt, stroke, fill),
		'scaleh3': createStyle (smallpt, stroke, fill),
	};
	this.drawSketch_();
}

/**
 * Set sketch style.
 * @param {ol.Map} map Map.
 * @api stable
 */
ol.interaction.Transform.prototype.setStyle = function(style, olstyle)
{	if (!olstyle) return;
	if (olstyle instanceof Array) this.style[style] = olstyle;
	else this.style[style] = [ olstyle ];
	for (var i=0; i<this.style[style].length; i++)
	{	var im = this.style[style][i].getImage();
		if (im)
		{	if (style == 'rotate') im.getAnchor()[0] = -5;
			if (this.isTouch) im.setScale(1.8);
		}
		var tx = this.style[style][i].getText();
		if (tx)
		{	if (style == 'rotate') tx.setOffsetX(this.isTouch ? 14 : 7);
			if (this.isTouch) tx.setScale(1.8);
		}
	}
	this.drawSketch_();
};

/** Get Feature at pixel
 * @param {ol.Pixel}
 * @return {ol.feature}
 * @private
 */
ol.interaction.Transform.prototype.getFeatureAtPixel_ = function(pixel)
{	var self = this;
	return this.getMap().forEachFeatureAtPixel(pixel,
		function(feature, layer)
		{	var found = false;
			// Overlay ?
			if (!layer)
			{	if (feature===self.bbox_) return false;
				self.handles_.forEach (function(f) { if (f===feature) found=true; });
				if (found) return { feature: feature, handle:feature.get('handle'), constraint:feature.get('constraint'), option:feature.get('option') };
			}
			// feature belong to a layer
			if (self.layers_)
			{	for (var i=0; i<self.layers_.length; i++)
				{	if (self.layers_[i]===layer) return { feature: feature };
				}
				return null;
			}
			// feature in the collection
			else if (self.features_)
			{	self.features_.forEach (function(f) { if (f===feature) found=true; });
				if (found) return { feature: feature };
				else return null;
			}
			// Others
			else return { feature: feature };
		}) || {};
}

/** Draw transform sketch
* @param {boolean} draw only the center
*/
ol.interaction.Transform.prototype.drawSketch_ = function(center)
{
	this.overlayLayer_.getSource().clear();
	if (!this.feature_) return;
	if (center===true)
	{	if (!this.ispt_)
		{	this.overlayLayer_.getSource().addFeature(new ol.Feature( { geometry: new ol.geom.Point(this.center_), handle:'rotate0' }) );
			var ext = this.feature_.getGeometry().getExtent();
			var geom = ol.geom.Polygon.fromExtent(ext);
			var f = this.bbox_ = new ol.Feature(geom);
			this.overlayLayer_.getSource().addFeature (f);
		}
	}
	else
	{	var ext = this.feature_.getGeometry().getExtent();
		if (this.ispt_)
		{	var p = this.getMap().getPixelFromCoordinate([ext[0], ext[1]]);
			ext = ol.extent.boundingExtent(
				[	this.getMap().getCoordinateFromPixel([p[0]-10, p[1]-10]),
					this.getMap().getCoordinateFromPixel([p[0]+10, p[1]+10])
				]);
		}
		var geom = ol.geom.Polygon.fromExtent(ext);
		var f = this.bbox_ = new ol.Feature(geom);
		var features = [];
		var g = geom.getCoordinates()[0];
		if (!this.ispt_)
		{	features.push(f);
			// Middle
			if (this.get('stretch') && this.get('scale')) for (var i=0; i<g.length-1; i++)
			{	f = new ol.Feature( { geometry: new ol.geom.Point([(g[i][0]+g[i+1][0])/2,(g[i][1]+g[i+1][1])/2]), handle:'scale', constraint:i%2?"h":"v", option:i });
				features.push(f);
			}
			// Handles
			if (this.get('scale')) for (var i=0; i<g.length-1; i++)
			{	f = new ol.Feature( { geometry: new ol.geom.Point(g[i]), handle:'scale', option:i });
				features.push(f);
			}
			// Center
			if (this.get('translate') && !this.get('translateFeature'))
			{	f = new ol.Feature( { geometry: new ol.geom.Point([(g[0][0]+g[2][0])/2, (g[0][1]+g[2][1])/2]), handle:'translate' });
				features.push(f);
			}
		}
		// Rotate
		if (this.get('rotate'))
		{	f = new ol.Feature( { geometry: new ol.geom.Point(g[3]), handle:'rotate' });
			features.push(f);
		}
		// Add sketch
		this.overlayLayer_.getSource().addFeatures(features);
	}

};

/** Select a feature to transform
* @param {ol.Feature} the feature to transform
*/
ol.interaction.Transform.prototype.select = function(feature)
{	this.feature_ = feature;
	this.ispt_ = this.feature_ ? (this.feature_.getGeometry().getType() == "Point") : false;
	this.drawSketch_();
	this.dispatchEvent({ type:'select', feature: this.feature_ });
}

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @return {boolean} `true` to start the drag sequence.
 */
ol.interaction.Transform.prototype.handleDownEvent_ = function(evt)
{
	var sel = this.getFeatureAtPixel_(evt.pixel);
	var feature = sel.feature;
	if (this.feature_ && this.feature_==feature && ((this.ispt_ && this.get('translate')) || this.get('translateFeature')))
	{	sel.handle = 'translate';
	}
	if (sel.handle)
	{	this.mode_ = sel.handle;
		this.opt_ = sel.option;
		this.constraint_ = sel.constraint;
		// Save info
		this.coordinate_ = evt.coordinate;
		this.pixel_ = evt.pixel;
		this.geom_ = this.feature_.getGeometry().clone();
		this.extent_ = (ol.geom.Polygon.fromExtent(this.geom_.getExtent())).getCoordinates()[0];
		this.center_ = ol.extent.getCenter(this.geom_.getExtent());
		this.angle_ = Math.atan2(this.center_[1]-evt.coordinate[1], this.center_[0]-evt.coordinate[0]);

		this.dispatchEvent({ type:this.mode_+'start', feature: this.feature_, pixel: evt.pixel, coordinate: evt.coordinate });
		return true;
	}
	else
	{	this.feature_ = feature;
		this.ispt_ = this.feature_ ? (this.feature_.getGeometry().getType() == "Point") : false;
		this.drawSketch_();
		this.dispatchEvent({ type:'select', feature: this.feature_, pixel: evt.pixel, coordinate: evt.coordinate });
    let drag = this.getMap().getInteractions().getArray().find(interaction => interaction instanceof ol.interaction.Drag);
    if (drag) {
      drag.pause();
    }
		return false;
	}

};

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 */
ol.interaction.Transform.prototype.handleDragEvent_ = function(evt)
{
	switch (this.mode_)
	{	case 'rotate':
		{	var a = Math.atan2(this.center_[1]-evt.coordinate[1], this.center_[0]-evt.coordinate[0]);
			if (!this.ispt)
			{	var geometry = this.geom_.clone();
				geometry.rotate(a-this.angle_, this.center_);

				this.feature_.setGeometry(geometry);
			}
			this.drawSketch_(true);
			this.dispatchEvent({ type:'rotating', feature: this.feature_, angle: a-this.angle_, pixel: evt.pixel, coordinate: evt.coordinate });
			break;
		}
		case 'translate':
		{
      var deltaX = evt.coordinate[0] - this.coordinate_[0];
			var deltaY = evt.coordinate[1] - this.coordinate_[1];

			this.feature_.getGeometry().translate(deltaX, deltaY);
			this.handles_.forEach(function(f)
			{	f.getGeometry().translate(deltaX, deltaY);
			});

			this.coordinate_ = evt.coordinate;
			this.dispatchEvent({ type:'translating', feature: this.feature_, delta:[deltaX,deltaY], pixel: evt.pixel, coordinate: evt.coordinate });
			break;
		}
		case 'scale':
		{	var center = this.center_;
			if (evt.originalEvent.metaKey || evt.originalEvent.ctrlKey)
			{	center = this.extent_[(Number(this.opt_)+2)%4];
			}

			var scx = (evt.coordinate[0] - center[0]) / (this.coordinate_[0] - center[0]);
			var scy = (evt.coordinate[1] - center[1]) / (this.coordinate_[1] - center[1]);

			if (this.constraint_)
			{	if (this.constraint_=="h") scx=1;
				else scy=1;
			}
			else
			{	if (this.get('keepAspectRatio')(evt)) //evt.originalEvent.shiftKey)
				{	scx = scy = Math.min(scx,scy);
				}
			}

			var geometry = this.geom_.clone();
			geometry.applyTransform(function(g1, g2, dim)
			{	if (dim<2) return g2;

				for (i=0; i<g1.length; i+=dim)
				{	if (scx!=1) g2[i] = center[0] + (g1[i]-center[0])*scx;
					if (scy!=1) g2[i+1] = center[1] + (g1[i+1]-center[1])*scy;
				}
				return g2;
			});
			this.feature_.setGeometry(geometry);
			this.drawSketch_();
			this.dispatchEvent({ type:'scaling', feature: this.feature_, scale:[scx,scy], pixel: evt.pixel, coordinate: evt.coordinate });
		}
		default: break;
	}
};

/**
 * @param {ol.MapBrowserEvent} evt Event.
 */
ol.interaction.Transform.prototype.handleMoveEvent_ = function(evt)
{	
	if (!this.mode_)
	{	var map = evt.map;
		var sel = this.getFeatureAtPixel_(evt.pixel);
		var element = evt.map.getTargetElement();
		if (sel.feature)
		{	var c = sel.handle ? this.Cursors[(sel.handle||'default')+(sel.constraint||'')+(sel.option||'')] : this.Cursors.select;

			if (this.previousCursor_===undefined)
			{	this.previousCursor_ = element.style.cursor;
			}
			element.style.cursor = c;
		}
		else
		{	if (this.previousCursor_!==undefined) element.style.cursor = this.previousCursor_;
			this.previousCursor_ = undefined;
		}
	}
};


/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @return {boolean} `false` to stop the drag sequence.
 */
ol.interaction.Transform.prototype.handleUpEvent_ = function(evt)
{	//dispatchEvent
	this.dispatchEvent({ type:this.mode_+'end', feature: this.feature_, oldgeom: this.geom_ });

	this.drawSketch_();
	this.mode_ = null;
	return false;
};

ol.interaction.Transform.prototype.clear = function() {
  this.overlayLayer_.getSource().clear();
  let drag = this.getMap().getInteractions().getArray().find(interaction => interaction instanceof ol.interaction.Drag);
  if (drag) {
    drag.resume();
  }
};

},{}],"oloverrides/wmsurl":[function(require,module,exports){
/**
 * Dessa funktioner är överlagrade från openlayers eftersom det finns en bugg i openlayers implementation
 * som ej tar hänsyn till den förändrade ordningen av koordinater för BBOX i wms-lager av version 1.3.0, vilket skapade ett fel i
 * SWEREF-koordinatsystem.
 **/

function getRequestUrl(tileCoord, tileSize, tileExtent, pixelRatio, projection, params) {
	var urls = this.urls;
	if (!urls) {
		return undefined;
	}
	const isV13 = params.VERSION == '1.3.0';
	params['WIDTH'] = tileSize[0];
	params['HEIGHT'] = tileSize[1];

	params[isV13 ? 'CRS' : 'SRS'] = projection.getCode();

	if (!params.hasOwnProperty('STYLES')) {
		params['STYLES'] = '';
	}
	
	if (pixelRatio != 1) {
		switch (this.get('serverType').toLowerCase()) {
			case 'geoserver':
				var dpi = (90 * pixelRatio + 0.5) | 0;
				if (params.hasOwnProperty('FORMAT_OPTIONS')) {
					params['FORMAT_OPTIONS'] += ';dpi:' + dpi;
				} else {
					params['FORMAT_OPTIONS'] = 'dpi:' + dpi;
				}
				break;
			case 'arcgis':
				params['MAP_RESOLUTION'] = 90 * pixelRatio;
				break;
				// case ol.source.WMSServerType.CARMENTA_SERVER:
				// case ol.source.WMSServerType.QGIS:
				// 	params['DPI'] = 90 * pixelRatio;
				// 	break;
			default:
				// ol.asserts.assert(false, 52); // Unknown `serverType` configured
				break;
		}
	}

	//	var axisOrientation = projection.getAxisOrientation();
	var bbox = tileExtent;
	if (this.v13_ && axisOrientation.substr(0, 2) == 'ne' || isSweref(projection)) {
		var tmp;
		tmp = tileExtent[0];
		bbox[0] = tileExtent[1];
		bbox[1] = tmp;
		tmp = tileExtent[2];
		bbox[2] = tileExtent[3];
		bbox[3] = tmp;
	}
	params['BBOX'] = bbox.join(',');
	var url = urls[0];
	return url + '?' + $.param(params);
}

module.exports = {
	/**
	 * 
	 source: this.layer.getSource(),
      layers: this.queryableLayerNames,
      coordinate: params.coordinate,
      resolution:  params.resolution,
      projection: params.projection,
      params: {
        'INFO_FORMAT': this.get('serverType') === "arcgis" ? 'application/geojson' : 'application/json',
        'feature_count': 100
      } 
	 * 
	 */
	customGetFeatureInformationUrl: function (options) {
		let source = options.source;
		let version = source.getParams().VERSION;
		let projection = ol.proj.get(options.projection);
		let layerNames = options.layers;
		let resolution = options.resolution;
		let coordinate = options.coordinate;
		let params = options.params;
		
		if (options.isSingleTile || version != "1.3.0") {
			return source.getGetFeatureInfoUrl(coordinate,
				resolution,
				projection,
				params);
		}

		let tileGrid = source.getTileGrid();
		if (!tileGrid) return undefined;

		// if (!tileGrid) {
		// 	tileGrid = source.getTileGridForProjection(projection);
		// }
		var tileCoord = tileGrid.getTileCoordForCoordAndResolution(
			coordinate, resolution);

		if (tileGrid.getResolutions().length <= tileCoord[0]) {
			return undefined;
		}
		var tileResolution = tileGrid.getResolution(tileCoord[0]);
		var tileExtent = tileGrid.getTileCoordExtent(tileCoord, this.tmpExtent_);
		var tileSize = ol.size.toSize(
			tileGrid.getTileSize(tileCoord[0]), this.tmpSize);
		let x = Math.floor((coordinate[0] - tileExtent[0]) / tileResolution);
		let y = Math.floor((tileExtent[3] - coordinate[1]) / tileResolution)

		let baseParams = {
			'SERVICE': 'WMS',
			'VERSION': version,
			'REQUEST': 'GetFeatureInfo',
			'FORMAT': 'image/png',
			'TRANSPARENT': true,
			'LAYERS': layerNames,
			'QUERY_LAYERS': layerNames
		};
		Object.assign(baseParams, params);

		baseParams[version == "1.3.0" ? 'I' : 'X'] = x;
		baseParams[version == "1.3.0" ? 'J' : 'Y'] = y;

		// //No gutter support
		return getRequestUrl.call(source, tileCoord, tileSize, tileExtent,
			1, projection, baseParams);


	},

	customGetTileUrl: function (tileCoord, pixelRatio, projection) {
		let tileGrid = this.getTileGrid();
		if (!tileGrid) {
			tileGrid = this.getTileGridForProjection(projection);
		}

		if (tileGrid.getResolutions().length <= tileCoord[0]) {
			return undefined;
		}

		if (pixelRatio != 1 && (!this.hidpi_ || this.serverType_ === undefined)) {
			pixelRatio = 1;
		}

		let tileResolution = tileGrid.getResolution(tileCoord[0]);
		let tileExtent = tileGrid.getTileCoordExtent(tileCoord, this.tmpExtent_);
		let tileSize = ol.size.toSize(
			tileGrid.getTileSize(tileCoord[0]), this.tmpSize);

		let gutter = this.gutter_ || 0; //Vi stödjer ej gutter just nu
		if (gutter !== 0) {
			tileSize = ol.size.buffer(tileSize, gutter, this.tmpSize);
			tileExtent = ol.extent.buffer(tileExtent,
				tileResolution * gutter, tileExtent);
		}

		if (pixelRatio != 1) {
			tileSize = ol.size.scale(tileSize, pixelRatio, this.tmpSize);
		}

		let baseParams = Object.assign({
			'SERVICE': 'WMS',
			'VERSION': '1.1.0',
			'REQUEST': 'GetMap',
			'FORMAT': 'image/png',
			'TRANSPARENT': true
		}, this.getParams());

		//{ SRS: undefined, CRS: undefined}
		if (baseParams.hasOwnProperty("SRS"))
			delete baseParams.SRS;

		if (baseParams.hasOwnProperty("CRS"))
			delete baseParams.CRS;

		return getRequestUrl.call(this, tileCoord, tileSize, tileExtent,
			pixelRatio, projection, baseParams);
	}
};

const isSweref = (projection) => {
	let code = projection.getCode().split(':');
	code = parseInt(code[code.length - 1]);
	let isSweref = isNaN(code) ? false : code >= 3006 && code <= 3018;
	return isSweref;
};
},{}],"tools/anchor":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} AnchorModel~AnchorModelProperties
 * @property {string} type -Default: anchor
 * @property {string} panel -Default: anchorpanel
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-link icon fa-flip-horizontal
 * @property {string} title -Default: Länk
 * @property {boolean} visible - Default: false
 * @property {ShellModel} shell
 * @property {string} anchor - Default: ''
 */
var AnchorModelProperties = {
  type: 'anchor',
  panel: 'anchorpanel',
  toolbar: 'bottom',
  icon: 'fa fa-link icon fa-flip-horizontal',
  title: 'Länk till kartan',
  visible: false,
  shell: undefined,
  anchor: "",
  instruction: ''
}

/**
 * @description
 *
 *  Prototype for creating an anchor model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {AnchorModel~AnchorModelProperties} options - Default options
 */
var AnchorModel = {
  /**
   * @instance
   * @property {AnchorModel~AnchorModelProperties} defaults - Default settings
   */
  defaults: AnchorModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {
    this.set('map', shell.getMap());
    this.set('layers', shell.getLayerCollection());
    this.set(
      'layerswitcher',
      shell.getToolCollection()
           .find(tool =>
              tool.get('type') === 'layerswitcher'
            )
    );
  },

  /**
   * Generate an anchor string which represents the current state of the map.
   * @instance
   * @return {string} anchor
   */
  generate: function () {

    var a = document.location.protocol + "//" + document.location.host + document.location.pathname
    ,   map = this.get("map")
    ,   olMap = map.getMap()
    ,   layers = this.get("layers")

    ,   c = olMap.getView().getCenter()
    ,   z = olMap.getView().getZoom()
    ,   x = c[0]
    ,   y = c[1]
    ,   l = layers.filter(layer => layer.getVisible() === true)
                  .map(layer => encodeURIComponent(layer.getName())).join(',');

    a += `?m=${HAJK2.configFile}&x=${x}&y=${y}&z=${z}&l=${l}`;
    this.set("anchor", a);

    return a;
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);    
    this.set('toggled', !this.get('toggled'));

  }
};

/**
 * Anchor model module.<br>
 * Use <code>require('models/anchor')</code> for instantiation.
 * @module AnchorModel-module
 * @returns {AnchorModel}
 */
module.exports = ToolModel.extend(AnchorModel);

},{"tools/tool":"tools/tool"}],"tools/bookmark":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} SaveStateModel~SaveStateModelProperties
 * @property {string} type - Default: export
 * @property {string} panel - Default: exportpanel
 * @property {string} title - Default: Skriv ut
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-bookmark icon
 * @property {string} title - Default: Kartlager
 * @property {Shell} shell
 * @property {string} settingsUrl
 * @property {object[]} bookmarks
 */
var BookmarkProperties = {
  type: 'bookmark',
  panel: 'bookmarkpanel',
  toolbar: 'bottom',
  icon: 'fa fa-bookmark icon',
  title: 'Bokmärken',
  visible: false,
  shell: undefined,
  settingsUrl: "",
  bookmarks: [],
  instruction: ''
};

/**
 * Prototype for creating a layerswitcher model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {SaveStateModel~SaveStateModelProperties} options - Default options
 */
var BookmarkModel = {
  /**
   * @instance
   * @property {SaveStateModel~SaveStateModelProperties} defaults - Default settings
   */
  defaults: BookmarkProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {

    var url = this.get('settingsUrl')
    ,   bookmarks;

    this.set('shell', shell);

    if (!shell.getBookmarks()) {
      bookmarks = localStorage.getItem('bookmarks');
      if (bookmarks) {
        try {
          bookmarks = JSON.parse(bookmarks);
        } catch (e) {
          bookmarks = [];
          localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
        }
      } else {
        bookmarks = [];
        localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      }
      shell.setBookmarks(bookmarks);
    }
  },

  /**
   * Reload the application with given bookmark.
   * @instance
   * @param {object} bookmark - Bookmark with settings to be loaded.
   */
  updateApplication: function (bookmark) {
    var json = atob(bookmark.settings);
    var settings = JSON.parse(json);
    this.get('shell').setConfig(settings);
  },

  /**
   * Add and save a new bookmark.
   * @instance
   * @param {string} name - Name of the bookmark.
   * @param {function} callback - Fn to be called when the save is complete.
   */
  addBookmark: function (name, callback) {
    var numBookmarks = this.getBookmarks() &&
        this.getBookmarks().length ?
        this.getBookmarks().length : 0;
    var model = this.get('shell').toJSON();
    var b64 = btoa(model);
    var data = {
      name: name,
      settings: b64,
      favourite: numBookmarks === 0 ? true : false
    };

    if (!localStorage.getItem('bookmarks')) {
      localStorage.setItem('bookmarks', JSON.stringify([]));
    }

    var bookmarks = localStorage.getItem('bookmarks');

    try {
      bookmarks = JSON.parse(bookmarks);
      if (!Array.isArray(bookmarks)) {
        bookmarks = [];
      }
    } catch (e) {
      bookmarks = [];
    }

    bookmarks.push(data);
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    this.get('shell').setBookmarks(bookmarks);
    callback();
  },

  /**
  * Update an existing bookmark.
  * @instance
  * @param {object} bookmark - Bookmark to be updated.
  * @param {function} callback - Fn to be called when the update is complete.
  */
  updateBookmark: function(bookmark, callback) {
    /*var visibleLayers = []
    this.get('layerCollection').forEach(layer => {
      // if visible add to list
      if(layer.getVisible()){
        visibleLayers.push(layer.name);
    }

    });

    // store list and location in bookmark
    bookmark.layers = visibleLayers

  });
    //
    //TODO:
    // Hämta från local storage och uppdatera istället.
    //
    // $.ajax({
    //   url: this.get('settingsUrl'),
    //   type: 'put',
    //   contentType: 'application/json',
    //   data: JSON.stringify(bookmark),
    //   dataType: 'json',
    //   success:(bookmarks) => {
    //     this.get('shell').setBookmarks(bookmarks);
    //     callback();
    //   }
    // });
*/
  },

  /**
   * Update an existing bookmark.
   * @instance
   * @param {number} id - ID of bookmark to be removed.
   * @param {function} callback - Fn to be called when the removal is complete.
   */
  removeBookmark: function(name, callback) {
      var bookmarks = this.getBookmarks();

      bookmarks = bookmarks.filter(bookmark => bookmark.name !== name);
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    callback();
  },

  /**
   * Get bookmarks from shell.
   * @instance
   * @return {object[]} bookmarks
   */
  getBookmarks: function () {
    var json = localStorage.getItem('bookmarks');
    if (json !== undefined) {
      return JSON.parse(json);
    }
    return [];
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Bookmark model module.<br>
 * Use <code>require('models/bookmark')</code> for instantiation.
 * @module BookmarkModel-module
 * @returns {BookmarkModel}
 */
module.exports = ToolModel.extend(BookmarkModel);

},{"tools/tool":"tools/tool"}],"tools/buffer":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

const ToolModel = require('tools/tool');
const SelectionModel = require('models/selection');


/**
 * @typedef {Object} BufferModel~BufferModelProperties
 * @property {string} type -Default: Buffer
 * @property {string} panel -Default: Bufferpanel
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-link icon fa-flip-horizontal
 * @property {string} title -Default: Länk
 * @property {boolean} visible - Default: false
 * @property {ShellModel} shell
 */
var BufferModelProperties = {
  type: 'buffer',
  panel: 'bufferpanel',
  toolbar: 'bottom',
  icon: 'fa fa-bullseye icon',
  title: 'Skapa buffertzon',
  visible: false,
  shell: undefined,
  bufferDist: 1000,
  marker: undefined,
  markerPos: undefined,
  popupHighlight: undefined,
  instruction: '',
  varbergVer: false,
  geoserverUrl: '',
  notFeatureLayers: [],
  geoserverNameToCategoryName: {}
}

/**
 * @description
 *
 *  Prototype for creating an Buffer model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {BufferModel~BufferModelProperties} options - Default options
 */
var BufferModel = {
  /**
   * @instance
   * @property {BufferModel~BufferModelProperties} defaults - Default settings
   */
  defaults: BufferModelProperties,

  initialize: function () {
    ToolModel.prototype.initialize.call(this);
  },

  getDefaultStyle: function () {
    const color = 'rgba(90, 100, 115, 1.5)';
    const fill = 'rgba(255, 255, 255, 0.5)';
    return [
      new ol.style.Style({
        fill: new ol.style.Fill({
          color: fill
        }),
        stroke: new ol.style.Stroke({
          color: color,
          width: 4
        }),
        image: new ol.style.Circle({
          radius: 6,
          fill: new ol.style.Fill({
            color: fill
          }),
          stroke: new ol.style.Stroke({
            color: color,
            width: 2
          })
        })
      })
    ];
  },

  configure: function (shell) {

    this.set('map', shell.getMap());
    this.set('olMap', shell.getMap().getMap());

    if(!this.get('varbergVer')){
      this.set('layers', shell.getLayerCollection());

      this.set('bufferLayer', new ol.layer.Vector({
        source: new ol.source.Vector(),
        name: 'buffer-layer',
        style: this.getDefaultStyle()
      }));

      this.get('olMap').addLayer(this.get('bufferLayer'));
    }else {
      this.set('layersWithNames', shell.attributes.layers);
      this.set('layersCollection', shell.getLayerCollection());

      this.set('bufferLayer', new ol.layer.Vector({
        source: new ol.source.Vector(),
        name: 'buffer-layer',
        queryable: false,
        style: this.getDefaultStyle()
      }));

      this.set('style_marker', new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          opacity: .8,
          src: 'assets/icons/dot_marker_blue.png',
          scale: (0.5)
        })
      }));

      this.set('markerlayer', new ol.layer.Vector({
        source: new ol.source.Vector(),
        name: 'bufferMarkerLayer',
        queryable: false,
        style: this.get('style_marker')
      }));

      // popupHighlight style
      this.set('style_popup', new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          opacity: 1.0,
          src: 'assets/icons/Ikon_på_buffert.png',
          scale: (1.5)
        })
      }));

      this.set("layer_popup", new ol.layer.Vector({
          source: new ol.source.Vector(),
          name: "popupHighlight",
          queryable: false,
          style: this.get('style_popup')
        })
      );

      this.get('olMap').addLayer(this.get('bufferLayer'));
      this.get('olMap').addLayer(this.get('markerlayer'));
      this.get('olMap').addLayer(this.get('layer_popup'));
    }

    this.set('selectionModel', new SelectionModel({
      map: this.get('olMap'),
      layerCollection: shell.getLayerCollection()
    }));

    if(typeof this.get("geoserverNameToCategoryName") === "string"){
      try {
        this.set("geoserverNameToCategoryName", JSON.parse(this.get("geoserverNameToCategoryName")));
      } catch (e){
        this.set("geoserverNameToCategoryName", {});
      }
    }
  },

  activateBufferMarker: function(){
    if (this.get('bufferMarkKey') == undefined) {
      this.set('bufferMarkKey', this.get('olMap').on('singleclick', this.placeMarker.bind(this)));
    }
  },

  deActivateBufferMarker: function(){
    if(this.get('bufferMarkKey') !== undefined) {
      ol.Observable.unByKey(this.get('bufferMarkKey'));
      this.set('bufferMarkKey', undefined);
    }
  },

  /**
   * @instance
   */
  getActiveTool: function () {
    return this.get('selectionModel').get('activeTool');
  },
  /**
   * @instance
   * @property {string} name
   */
  setActiveTool: function (name) {
    if (this.get('selectionModel').get('activeTool') === name) {
      this.get('selectionModel').setActiveTool(undefined);
    } else {
      this.get('selectionModel').setActiveTool(name);
    }
  },

  placeMarker: function(event){

    if (this.get('marker') === undefined){
      var ft =  new ol.Feature();
      this.set('marker', ft);
      this.get('markerlayer').getSource().addFeature(this.get('marker'));
      ft.setStyle(this.get('style_marker'));
    }

    this.get('marker').setGeometry(new ol.geom.Point(event.coordinate));

    var lonlat = ol.proj.transform(this.get('marker').getGeometry().getCoordinates(), 'EPSG:3007', 'EPSG:4326');
  },

  isNumber: function (obj) {

    if (typeof obj === "number") {
      return true;
    }

    if (typeof obj !== "string") {
      return false;
    }

    if (obj.trim() === "") {
      return false;
    }

    if (!isNaN(Number(obj))) {
      return true;
    }

    return false;
  },

  /**
   * @instance
   */
  buffer: function() {
    if (!this.get('varbergVer')) {
      const parser = new jsts.io.OL3Parser();
      const features = this.get('selectionModel').features;
      const dist = this.get('bufferDist');

      if (!this.isNumber(dist)) {
        return false;
      }

      var buffered = Object.keys(features).map(key => {

        var feature = features[key]
        , olf = new ol.Feature()
        , olGeom = feature.getGeometry()
        , jstsGeom
        , buff
      ;

      if (olGeom instanceof ol.geom.Circle) {
        olGeom = ol.geom.Polygon.fromCircle(olGeom, 0b10000000);
      }

      jstsGeom = parser.read(olGeom);
      buff = jstsGeom.buffer(dist);
      olf.setGeometry(parser.write(buff));
      olf.setStyle(this.getDefaultStyle());
      olf.setId(Math.random() * 1E20);

      return olf;
    })
      ;

      if (buffered) {
        this.get('bufferLayer').getSource().addFeatures(buffered);
        return true;
      } else {
        return false;
      }
    } else {
      if (this.get('marker') === undefined) {
        return false;
      }

      this.deActivateBufferMarker();
// JSON?
      //var notFeatureLayers = ['150', '160', '170', '410', '420', '430', '440', '260', '310', '350', '360', '250', '230', '340', '330', '270', '280', '320', '325', '140', '220', '210'];
      var activeLayers = [];
      for (var i = 0; i < this.get('layersCollection').length; i++) {
        if (this.get('layersCollection').models[i].getVisible() && this.get("notFeatureLayers").indexOf(this.get('layersCollection').models[i].id) != -1) {
          activeLayers.push(this.get('layersCollection').models[i]);
        }
      }

      var activeNames = [];
      for (var i = 0; i < activeLayers.length; i++) {
        for (var j = 0; j < this.get('layersWithNames').length; j++) {
          if (activeLayers[i].id == this.get('layersWithNames')[j].id) {
            activeNames.push(this.get('layersWithNames')[j].layers[0]);
          }
        }
      }


      var lonlat = ol.proj.transform(this.get('marker').getGeometry().getCoordinates(), 'EPSG:3007', 'EPSG:4326');
      var lon = lonlat[0];
      var lat = lonlat[1];

      var circle = new ol.geom.Circle(this.get('marker').getGeometry().getCoordinates(), Number(this.get('bufferDist')));

      var circleFeature = new ol.Feature(circle);

      this.get('bufferLayer').getSource().clear();
      this.get('bufferLayer').getSource().addFeature(circleFeature);
      circleFeature.setStyle(this.getDefaultStyle());


      document.getElementById('visibleLayerList').innerHTML = '';
      if (activeNames.length == 0) {
        return true;
      }


      this.getFeaturesWithinRadius(activeNames);

      return true;
    }
  },

  createWFSQuery: function(typeName, radius, coordStr){
    var query = '<wfs:Query typeName=\'feature:' + typeName + '\' srsName=\'EPSG:3007\'>\n' +
      '          <ogc:Filter>\n' +
      '        \n' +
      '        \n' +
      '\t<ogc:DWithin>\n' +
      '                <ogc:PropertyName>geom</ogc:PropertyName>\n' +
      '                <gml:Point srsName="http://www.opengis.net/gml/srs/epsg.xml#3007"\n' +
      '                    xmlns:gml="http://www.opengis.net/gml">\n' +
      '                            <gml:coordinates decimal="." cs="," ts=" "> ' + coordStr + '</gml:coordinates>\n' +
      '                </gml:Point>\n' +
      '                <ogc:Distance units="meter">' + radius + '</ogc:Distance>\n' +
      '            </ogc:DWithin>\n' +
      '\n' +
      '      \n' +
      '          </ogc:Filter>\n' +
      '         </wfs:Query>';
    return query;
  },

  getFeaturesWithinRadius: function(layers){
    var requestPrefix = '<wfs:GetFeature\n' +
      '         service = \'WFS\'\n' +
      '         version = \'1.1.0\'\n' +
      '         xmlns:wfs = \'http://www.opengis.net/wfs\'\n' +
      '         xmlns:ogc = \'http://www.opengis.net/ogc\'\n' +
      '         xmlns:gml = \'http://www.opengis.net/gml\'\n' +
      '         xmlns:esri = \'http://www.esri.com\'\n' +
      '         xmlns:xsi = \'http://www.w3.org/2001/XMLSchema-instance\'\n' +
      '         xsi:schemaLocation=\'http://www.opengis.net/wfs ../wfs/1.1.0/WFS.xsd\'\n' +
      '         outputFormat="GML2"\n' +
      '         maxFeatures="1000">\n';


    var requestSuffix = '\n      </wfs:GetFeature>'

    var queries = '';

    // One query per layer
    for (var i = 0; i < layers.length; i++){
      queries += this.createWFSQuery(layers[i], this.get('bufferDist'), this.get('marker').getGeometry().getCoordinates());
    }

    var wfsRequset = requestPrefix + queries + requestSuffix;

    // Do Ajax call
    $.ajax({
      url: this.get("geoserverUrl"),
      contentType: 'text/xml',
      crossDomain: true,
      type: 'post',
      data: wfsRequset,
      success: result => {
      this.putFeaturesInResult(result);
  },
    error: result => {
      alert('Något gick fel');
    }
  });
  },

  putFeaturesInResult: function(res){

    var featureMembers = res.getElementsByTagName('gml:featureMember');

    var foundFeatures = {};
    var str = '';
    for(var i = 0; i < featureMembers.length; i++){
      var nameTag = featureMembers[i].getElementsByTagName('varberg:namn')[0];
      var name = nameTag.innerHTML;
      var categoryName = nameTag.parentElement.localName;

      var coordinate = featureMembers[i].getElementsByTagName('gml:coordinates')[0].innerHTML;
      if (!(categoryName in foundFeatures)){
        foundFeatures[categoryName] = [];
      }
      foundFeatures[categoryName].push([name, coordinate]);
    }
    var categories = Object.keys(foundFeatures);
    categories.sort();

    var categoryPrefix = '<div class="panel panel-default layer-item"><div class="panel-heading unselectable"><label class="layer-item-header-text">';
    var endCategoryToStartLayers = '</label></div><div class="panel-body"><div class="legend"><div>';
    var categorySuffix = '</div></div></div></div>';

    /*
      var geoserverNameToCategoryName = {
      'forskolor': 'Förskola',
      'grundskolor': 'Grundskola',
      'gymnasieskolor': 'Gymnasieskolor',
      'vardcentral': 'Vårdcentral',
      'vardcentral_privat': 'Privat vårdcentral',
      'sjukhus': 'Sjukhus',
      'folktandvard': 'Folktandvård',
      'badplatser': 'Badplatser',
      'bibliotek': 'Bibliotek',
      'hallplatser_for_bokbussen': 'Hållplatser bokbussen',
      'kultur_utf_8': 'Kultur och teater',
      'lekplatser': 'Lekplatser',
      'offentliga_toaletter': 'Offentliga toaletter',
      'off_konst': 'Offentliga konstverk',
      'turistbyran': 'Turistbyrå',
      'atervinningsstationer': 'Återvinningsstationer',
      'atervinningscentraler': 'Återvinningscentraler',
      'detaljplaner': 'Detaljplaner',
      'fornybar_energi': 'Förnybar energi',
      'cykelservicestallen': 'Cykelservice',
      'laddplatser': 'Laddplatser',
      'parkering_punkt': 'Parkeringsplatser',
      'polisstationer': 'Polisstation'
    };
    */


    var div = document.createElement('div');

    for(var i = 0; i < categories.length; i++){
      var outerDiv = document.createElement('div');
      outerDiv.className = 'panel panel-default layer-item';
      var headingDiv = document.createElement('div');
      headingDiv.className = 'panel-heading unselectable';
      outerDiv.appendChild(headingDiv);
      var label = document.createElement('label');
      label.className = 'layer-item-header-text';
      headingDiv.appendChild(label);

      label.innerHTML = this.get("geoserverNameToCategoryName")[categories[i]];

      var bodyDiv = document.createElement('div');
      bodyDiv.className = 'panel-body';
      var legendDiv = document.createElement('div');
      legendDiv.className = 'legend';
      var tomten = document.createElement('div');
      outerDiv.appendChild(bodyDiv);
      bodyDiv.appendChild(legendDiv);
      legendDiv.appendChild(tomten);

      var features = foundFeatures[categories[i]];
      features.sort();
      var layer = this.get('layer_popup');
      for(var j = 0; j < features.length; j++){
        var tag = document.createElement('p'); // remember to convert coordinate to list of float
        tag.setAttribute('coord', features[j][1]);
        tag.onclick = this.popupIcons.bind(this);
        tag.innerHTML = features[j][0];
        tomten.appendChild(tag);
      }

      div.appendChild(outerDiv);
    }

    document.getElementById('visibleLayerList').appendChild(div);
    this.set('foundFeatures', foundFeatures);
  },

  popupIcons: function(event) {
    var coord = event.target.attributes.coord.value.split(',');
    coord = [parseFloat(coord[1]), parseFloat(coord[0])];
    var point = new ol.geom.Point([
      coord[1],
      coord[0]
    ]);
    // clear layer
    this.get('layer_popup').getSource().clear();

    // create feature
    var ft = new ol.Feature({geometry: point});
    this.get('layer_popup').getSource().addFeature(ft);
    ft.setStyle(this.get('style_popup'));

  },

  clearSelection: function() {
    this.get('selectionModel').clearSelection();
  },

  clearBuffer: function() {
    this.get('bufferLayer').getSource().clear();

    if(this.get('varbergVer')) {
      this.deActivateBufferMarker();
      this.get('markerlayer').getSource().clear();
      this.get('layer_popup').getSource().clear();
      this.set('marker', undefined);
      this.set('popupHighlight', undefined);
      document.getElementById('visibleLayerList').innerHTML = '';
    }
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Buffer model module.<br>
 * Use <code>require('models/Buffer')</code> for instantiation.
 * @module BufferModel-module
 * @returns {BufferModel}
 */
module.exports = ToolModel.extend(BufferModel);

},{"models/selection":"models/selection","tools/tool":"tools/tool"}],"tools/coordinates":[function(require,module,exports){
var ToolModel = require('tools/tool');

/**
 * @typedef {Object} CoordinatesModel~CoordinatesModelPropertiesPosition
 * @property {number} x
 * @property {number} y
 */
var position = {
  x: 0,
  y: 0
};

/**
 * @typedef {Object} CoordinatesModel~CoordinatesModelProperties
 * @property {string} type -Default: coordinates
 * @property {string} panel -Default: CoordinatesPanel
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-crosshairs icon
 * @property {string} title -Default: Visa koordinater
 * @property {boolean} visible - Default: false
 * @property {object} map
 * @property {object} features
 * @property {object} interactionLayer
 * @property {array} interactions
 * @property {CoordinatesModel~CoordinatesModelPropertiesPosition} position
 */
var CoordinatesModelProperties = {
  type: 'coordinates',
  panel: 'CoordinatesPanel',
  toolbar: 'bottom',
  icon: 'fa fa-crosshairs icon',
  title: 'Visa koordinater',
  visible: false,
  map: undefined,
  features: undefined,
  interactionLayer: undefined,
  interactions: [],
  instruction: '',
  position: {
    x: undefined,
    y: undefined
  }
};

/**
 * Prototype for creating an coordinates model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {CoordinatesModel~CoordinatesModelProperties} options - Default options
 */
var CoordinatesModel = {

  /**
   * @instance
   * @property {CoordinatesModel~CoordinatesModelProperties} defaults - Default settings
   */
  defaults: CoordinatesModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {
    this.set('map', shell.getMap().getMap());
    this.set('interactionLayer', new ol.layer.Vector({
      source: new ol.source.Vector({}),
      name: 'coordinatesToolInteractionLayer'
    }));
    this.get('map').addLayer(this.get('interactionLayer'));
    proj4.defs("EPSG:3021","+proj=tmerc +lat_0=0 +lon_0=15.80827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs");
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function(arg) {
    this.set('visible', !this.get('visible'));
    this.set('toggled', !this.get('toggled'));
  },

  /**
   * Create and add marker interaction to map.
   * @instance
   */
  createInteractions: function () {
    var center = this.get('map').getView().getCenter();
    var source = this.get('interactionLayer').getSource();
    var feature = new ol.Feature({geometry: new ol.geom.Point(center)});
    var iconStyle =
      new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 32],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 0.75,
          src: 'assets/icons/crosshairs-64x64.png'
        })
      });
    var selectInteraction =
      new ol.interaction.Select({
        layers: [this.get('interactionLayer')],
      });
    var selectedFeatures = selectInteraction.getFeatures();
    var modifyInteraction =
      new ol.interaction.Modify({
        features: selectedFeatures,
        style: iconStyle,
        pixelTolerance: 32,
      });

    this.get('map').addInteraction(selectInteraction);
    this.get('map').addInteraction(modifyInteraction);
    this.set('interactions', [selectInteraction, modifyInteraction]);
    this.setCoordinates(feature.getGeometry().getCoordinates());

    feature.setStyle(iconStyle);
    var timer = null;
    feature.on('change', event => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {this.updateCoordinates(event)}, 50)
    });

    selectedFeatures.push(feature);
    selectInteraction.on('select', event => {
      if (event.deselected.length > 0) {
        selectedFeatures.push(feature);
      }
    });
  },

  /**
   * Remove the marker interaction from the map.
   * @instance
   */
  removeInteractions: function () {
    var interactions = this.get('interactions');
    var i;

    for (i = 0;i < interactions.length; i++){
      this.get('map').removeInteraction(interactions[i]);
    }

    this.set('interactions', []);
  },

  /**
   * Set position property value
   * @instance
   * @param {array} xy
   */
  setCoordinates: function (xy) {
    this.set('position', {
      x: xy[0],
      y: xy[1]
    });
  },

  /**
   * Update coordinates
   * @instance
   * @param {object} event
   */
  updateCoordinates: function (e) {
    var coordinates = e.target.getGeometry().getCoordinates();
    this.setCoordinates(coordinates);
  },

  /**
   * @desription
   *
   *  Transform coordinates with proj4.
   *  The coordinate system used must be present in proj4.defs.
   *
   * @instance
   * @param {array<{number}>} coordinates
   * @param {string} to
   * @return {array<{number}>} coordinates
   *
   */
  transform: function (coordinates, to) {
    var from = this.get('map').getView().getProjection();
    return ol.proj.transform(coordinates, from, to);
  },

  /**
   * Create a coordinate presentation object with configured out srs.
   * @instance
   * @return {object} coordinatePresentation
   */
  presentCoordinates: function () {
    var presentedCoordinates = {
      raw: this.get('position'),
    };
    var transformedCoordinates = {};
    var transformations = this.get('transformations');
    var coordinates = this.extractXYArray(presentedCoordinates['raw']);

    _.each(transformations, (transformation) => {
      transformedCoordinates[transformation.title] = this.transform(coordinates, transformation.code);
      transformedCoordinates[transformation.title] = this.extractXYObject(transformedCoordinates[transformation.title]);

      transformedCoordinates[transformation.title].xtitle = transformation.xtitle || 'X';
      transformedCoordinates[transformation.title].ytitle = transformation.ytitle || 'Y';
      transformedCoordinates[transformation.title].inverseAxis = transformation.inverseAxis === undefined ? false : transformation.inverseAxis;

      if (transformation.hasOwnProperty('default')) {
        transformedCoordinates[transformation.title].default = transformation.default;
        transformedCoordinates[transformation.title].hint = transformation.hint || "";
      }
    });

    presentedCoordinates['transformed'] = transformedCoordinates;

    return presentedCoordinates;
  },

  /**
   * Convert XY-array to XY-object
   * @instance
   * @param {object} xyObject
   * @return {array} coordinates
   */
  extractXYArray: function (xyObject) {
    return Object.keys(xyObject).map((key, value) => {
      if (key === 'x' || key === 'y') {
        return xyObject[key];
      }
    });
  },

  /**
   * Convert XY-object to XY-array
   * @instance
   * @param {array} coordinates
   * @return {object} xyObject
   */
  extractXYObject: function (xy) {
    var coordinates = {};
    xy.forEach((element, index) => {
      if (index === 0) {
        coordinates['x'] = element;
      } else if (index === 1) {
        coordinates['y'] = element;
      } else {
        throw 'Array index out of bounds while parsing coordinates.'
      }
    });
    return coordinates;
  }

};

/**
 * Coordinates model module.<br>
 * Use <code>require('models/coordinates')</code> for instantiation.
 * @module CoordinatesModel-module
 * @returns {CoordinatesModel}
 */
module.exports = ToolModel.extend(CoordinatesModel);

},{"tools/tool":"tools/tool"}],"tools/draw":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');
var kmlWriter = require('utils/kmlwriter');
var source;
var olMap;

/**
 * @typedef {Object} DrawModel~DrawModelProperties
 * @property {string} type - Default: 'draw'
 * @property {string} panel - Default: 'DrawPanel'
 * @property {string} title - Default: 'Ritverktyg'
 * @property {string} toolbar - Default: 'bottom'
 * @property {string} visible - Default: false
 * @property {string} icon - Default: 'fa fa-pencil icon'
 * @property {string} drawLayerName - Default: 'draw-layer'
 * @property {external:"ol.layer"} drawLayer - Default: undefined
 * @property {object} drawTool - Default: undefined
 * @property {object} removeTool - Default: undefined
 * @property {external:"ol.map"} olMap - Default: undefined
 * @property {external:"ol.source"} source - Default: undefined
 * @property {boolean} showLabels - Default: false
 * @property {boolean} dialog - Default: false
 * @property {boolean} kmlImport - Default: false
 * @property {boolean} kmlExportUrl - Default: false
 * @property {string} fontSize - Default: 10px
 * @property {string} fontColor - Default: "rgb(255, 255, 255)"
 * @property {string} fontBackColor - Default: "rgb(0, 0, 0)"
 * @property {string} pointText - Default: "Text"
 * @property {string} pointColor - Default: "rgb(15, 175, 255)"
 * @property {number} pointRadius - Default: 7
 * @property {boolean} pointSymbol - Default: false
 * @property {string} markerImg - Default: "http://localhost/gbg/assets/icons/marker.png"
 * @property {string} lineColor - Default: "rgb(15, 175, 255)"
 * @property {number} lineWidth - Default: 3
 * @property {string} lineStyle - Default: "solid"
 * @property {string} circleFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} circleFillOpacity - Default: 0.5
 * @property {string} circleLineColor - Default: "rgb(15, 175, 255)"
 * @property {string} polygonLineColor - Default: "rgb(15, 175, 255)"
 * @property {number} polygonLineWidth - Default: 3
 * @property {string} polygonLineStyle - Default: "solid"
 * @property {string} polygonFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} polygonFillOpacity - Default: 0.5
 * @property {Array<{external:"ol.Style"}>} scetchStyle
 * @property {string} boxLineColor - Default: "rgb(15, 175, 255)"
 * @property {number} boxLineWidth - Default: 3
 * @property {string} boxLineStyle - Default: "solid"
 * @property {string} boxFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} boxFillOpacity - Default: 0.5
 */
var DrawModelProperties = {
  type: 'draw',
  panel: 'DrawPanel',
  title: 'Rita och mäta',
  toolbar: 'bottom',
  visible: false,
  icon: 'fa fa-pencil icon',
  drawLayerName: 'draw-layer',
  drawLayer: undefined,
  drawTool: undefined,
  removeTool: undefined,
  olMap: undefined,
  source: undefined,
  showLabels: false,
  dialog: false,
  kmlImport: false,
  kmlExportUrl: false,
  fontSize: "10",
  fontColor: "rgb(255, 255, 255)",
  fontBackColor: "rgb(0, 0, 0)",
  pointText: "Text",
  pointColor: "rgb(15, 175, 255)",
  pointSettings: "point",
  pointRadius: 7,
  pointSymbol: false,
  icons: "",
  instruction: "",
  markerImg: window.location.href + "assets/icons/marker.png",
  lineColor: "rgb(15, 175, 255)",
  lineWidth: 3,
  lineStyle: "solid",
  circleFillColor: "rgb(255, 255, 255)",
  circleLineColor: "rgb(15, 175, 255)",
  circleFillOpacity: 0.5,
  circleLineStyle: "solid",
  circleLineWidth: 3,
  polygonLineColor: "rgb(15, 175, 255)",
  polygonLineWidth: 3,
  polygonLineStyle: "solid",
  polygonFillColor: "rgb(255, 255, 255)",
  polygonFillOpacity: 0.5,
  base64Encode: false,
  boxFillColor: "rgb(255, 255, 255)",
  boxLineColor: "rgb(15, 175, 255)",
  boxFillOpacity: 0.5,
  boxLineStyle: "solid",
  boxLineWidth: 3,
  scetchStyle: [
    new ol.style.Style({
    fill: new ol.style.Fill({
      color: 'rgba(255, 255, 255, 0.5)'
    }),
    stroke: new ol.style.Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 4
    }),
    image: new ol.style.Circle({
      radius: 6,
      fill: new ol.style.Fill({
        color: 'rgba(0, 0, 0, 0.5)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(255, 255, 255, 0.5)',
        width: 2
      })
    })
  })]
}

/**
 * Prototype for creating an draw model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {DrawModel~DrawModelProperties} options - Default options
 */
var DrawModel = {
  /**
   * @instance
   * @property {DrawModel~DrawModelProperties} defaults - Default settings
   */
  defaults: DrawModelProperties,

  /**
   * @instance
   * @property {object} measureTooltipElement
   */
  measureTooltipElement: undefined,

  /**
   * @instance
   * @property {object} measureTooltip
   */
  measureTooltip: undefined,

  /**
   * @instance
   * @property {number} exportHitsFormId
   */
  exportHitsFormId: 12345,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);

    this.set('editOpenDialogBinded', null);
  },

  configure: function (shell) {
    source = new ol.source.Vector({ wrapX: false });
    olMap = shell.getMap().getMap();
    this.set('source', source);

    this.set('drawLayer', new ol.layer.Vector({
      source: this.get('source'),
      queryable: false,
      name: this.get('drawLayerName'),
      style: (feature) => this.getStyle(feature)
    }));

    this.set('olMap', olMap);
    this.get('olMap').addLayer(this.get('drawLayer'));
    this.set('drawLayer', this.get('drawLayer'));
    if (this.get('icons') !== "") {
      let icon = this.get('icons').split(',')[0];
      this.set('markerImg', window.location.href + "assets/icons/" + icon + ".png");
    }
    this.createMeasureTooltip();
  },


  editOpenDialog: function(event){
    this.get('olMap').forEachFeatureAtPixel(event.pixel, (feature) => {
      if (typeof feature.getProperties().description !== 'undefined'){
      feature.setStyle(this.get('scetchStyle'));
      this.set('dialog', true);
      this.set('drawFeature', feature);
      this.set('editing', true);
    }
  });
  },

  /**
   * Removes the selected feature from source.
   * @instance
   * @params {external:"ol.event.Event"} event
   */
  removeSelected: function (event) {
    var first = true;
    olMap.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (feature.getProperties().user === true && first) {
        source.removeFeature(feature);
      }
      first = false;
    });
  },

  /**
   * Activate tool for feature removal.
   * @instance
   */
  activateRemovalTool: function () {
    var dragInteraction = this.getDragInteraction();
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', true);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').on('singleclick', this.removeSelected);
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('draw-layer');
    }
  },


  /**
   * Activate tool for feature edit.
   * @instance
   */
  activateEditTool: function () {

    var dragInteraction = this.getDragInteraction()
    ,   revision = 1
    ,   features = new ol.Collection();

    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', true);
    this.set('drawToolActive', true);

    this.set('editOpenDialogBinded', this.editOpenDialog.bind(this));

    this.get('olMap').on('singleclick', this.get('editOpenDialogBinded'));

    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('draw-layer');
    }
    this.get('source').getFeatures().forEach(f => {
      features.push(f);
    });

    this.set("editTool", new ol.interaction.Modify({
      features: features
    }));

    this.get('olMap').addInteraction(this.get("editTool"));

    this.get("editTool").on('modifyend', e => {

      this.measureTooltip.setPosition(undefined);
    e.features.forEach(this.updateFeatureText.bind(this));
  });
  },

  /**
   * Update features text.
   * @instance
   */
  updateFeatureText: function (feature) {
    var labelText
    ,   style;
    this.setFeaturePropertiesFromGeometry(feature);

    labelText = this.getLabelText(feature);
    style = feature.getStyle()[1] || feature.getStyle()[0];

    if (style && style.getText() !== null) {
      style.getText().setText(labelText);
    }
  },

  /**
   * Get map´s first drag interaction, if any.
   * @instance
   */
  getDragInteraction: function () {
    return this.get('olMap')
      .getInteractions()
      .getArray()
      .filter(interaction =>
        interaction instanceof ol.interaction.Drag
      )[0];
  },

  /**
   * Activate drag intecation for draw layer.
   * @instance
   */
  activateMoveTool: function () {
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.set('drawToolActive', false);
    var dragInteraction = this.getDragInteraction();
    if (dragInteraction) {
      dragInteraction.addAcceptedLayer('draw-layer');
    }
  },

  /**
   * Remove the last edited feature from soruce.
   * @instance
   */
  removeEditFeature: function() {
    if (!this.get('editing') && this.get("drawFeature") && (typeof this.get("drawFeature").getProperties().description === "undefined"
    || this.get("drawFeature").getProperties().description === "")) {
      this.get('source').removeFeature(this.get('drawFeature'));
    } else if(this.get('editing')){
      var feature = this.get("drawFeature");
      this.set('pointText', feature.getProperties().description);
      this.setFeaturePropertiesFromText(feature, feature.getProperties().description || "");
      feature.setStyle(this.getStyle(feature));
    }
  },

  /**
   * Event handler to excecute after features are drawn.
   * @params: {external:"ol.feature"} type
   * @params: {string} type
   * @instance
   */
  handleDrawEnd: function (feature, type) {
    if (type === undefined)
      return;
    if (type === "Text") {
      feature.setStyle(this.get('scetchStyle'));
      this.set('dialog', true);
      this.set('editing', false);
      this.set('drawFeature', feature);
    } else {
      this.setFeaturePropertiesFromGeometry(feature);
      feature.setStyle(this.getStyle(feature));
    }
    this.measureTooltip.setPosition(undefined);
  },

  /**
   * Event handler to excecute when the users starts to draw.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  handleDrawStart: function(e, geometryType) {

    var circleRadius = parseFloat(this.get('circleRadius'));

    if (!isNaN(circleRadius) && geometryType === "Circle") {
      this.get("drawTool").finishDrawing();
      e.feature.getGeometry().setRadius(circleRadius);
    }

    e.feature.getGeometry().on('change', e => {
      var toolTip = ""
      ,   coord = undefined
      ,   pointerCoord;

      if (this.get("drawToolActive")) {

        if (this.get('pointerPosition')) {
          pointerCoord = this.get('pointerPosition').coordinate;
        }

        if (e.target instanceof ol.geom.LineString) {
          toolTip = this.formatLabel("length", e.target.getLength());
          coord = e.target.getLastCoordinate()
        }

        if (e.target instanceof ol.geom.Polygon) {
          toolTip = this.formatLabel("area", e.target.getArea());
          coord = pointerCoord || e.target.getFirstCoordinate();
        }

        if (e.target instanceof ol.geom.Circle) {
          toolTip = this.formatLabel("length", e.target.getRadius());
          coord = pointerCoord;
        }

        this.measureTooltipElement.innerHTML = toolTip;
        if (this.get('showLabels') && coord) {
          this.measureTooltip.setPosition(coord);
        }
      }
    });

  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  createMeasureTooltip: function() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip-draw tooltip-measure';
    this.measureTooltip = new ol.Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });
    this.get('olMap').addOverlay(this.measureTooltip);
  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  formatLabel: function(type, value) {

    if (type === "point") {
      label ="Nord: " + value[0] + " Öst: " + value[1];
    }

    if (typeof value === "number") {
      value = Math.round(value);
    }

    if (type === "circle") {
      let prefix = " m";
      let prefixSq = " m²";
      if (value >= 1E3) {
        prefix = " km";
        value = value / 1E3;
      }
      label = (
        "R = " + value + prefix +
        " \nA = " + (Math.round((value * value * Math.PI) * 1E3) / 1E3) + prefixSq
      );
    }

    if (type === "area") {
      let prefix = " m²";
      if (value >= 1E6) {
        prefix = " km²";
        value = Math.round((value / 1E6) * 1E3) / 1E3;
      }
      label = value + prefix;
    }

    if (type === "length") {
      let prefix = " m";
      if (value >= 1E3) {
        prefix = " km";
        value = value / 1E3;
      }
      label = value + prefix;
    }

    return label;
  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  activateDrawTool: function (type) {
    var style = undefined
    ,   drawTool = undefined
    ,   geometryType = undefined
    ,   dragInteraction = this.getDragInteraction()
    ,   olMap = this.get('olMap')
    ,   geometryFunction = undefined
    ,   geometryName = undefined;
        olMap.un('singleclick', this.removeSelected);
    olMap.un('singleclick', this.get('editOpenDialogBinded'));
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('draw-layer');
    }
    olMap.removeInteraction(this.get("drawTool"));
    olMap.removeInteraction(this.get("editTool"));
    this.measureTooltip.setPosition(undefined);

    if (type === "Box") {
      type = "Circle";
      geometryName = "Box";
      geometryFunction = ol.interaction.Draw.createBox();
      this.set('circleRadius', undefined);
    } else {
      geometryName = type;
    }

    geometryType = type !== "Text" ? type : "Point";

    drawTool = new ol.interaction.Draw({
      source: this.get('source'),
      style: this.get('scetchStyle'),
      type: geometryType,
      geometryFunction: geometryFunction,
      geometryName: geometryName
    });

    olMap.on('pointermove', this.setPointerPosition.bind(this));

    drawTool.on('drawstart', e => {
      this.handleDrawStart(e, geometryType);
    });

    drawTool.on('drawend', (event) => {
      this.handleDrawEnd(event.feature, type)
    });

    this.set('drawTool', drawTool);
    olMap.addInteraction(this.get('drawTool'));
    olMap.set('clickLock', true);
    this.set('drawToolActive', true);
  },

  /**
   * Remove all interactions from the map.
   * @instance
   */
  abort: function () {
    var dragInteraction = this.getDragInteraction();
    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').un('pointermove', this.setPointerPosition);
    this.get('olMap').removeInteraction(this.get('drawTool'));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', false);
    this.set('drawToolActive', false);
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('draw-layer');
    }
  },

  /**
   * Clear the source from features.
   * @instance
   */
  clear: function () {
    this.get('source').clear();
  },

  /**
   * Extract style info from ol Style object.
   * @instance
   * @param {external:"ol.style.Style"} style
   * @return {object} style
   */
  extractStyle: function (style) {

    var obj = {
      text: "",
      image: "",
      pointRadius: 0,
      pointColor: "",
      fillColor: "",
      strokeColor: "",
      strokeWidth: "",
      strokeDash: ""
    };

    obj.text = style.getText() ? style.getText().getText() : "";
    obj.image = style.getImage() instanceof ol.style.Icon ? style.getImage().getSrc() : "";
    obj.pointRadius = style.getImage() instanceof ol.style.Circle ? style.getImage().getRadius() : "";
    obj.pointColor = style.getImage() instanceof ol.style.Circle ? style.getImage().getFill().getColor() : "";
    obj.fillColor = style.getFill().getColor();
    obj.strokeColor = style.getStroke().getColor();
    obj.strokeWidth = style.getStroke().getWidth();
    obj.strokeDash = style.getStroke().getLineDash();

    return obj;
  },

  /**
   * Checks if a proxy url is set.
   * @instance
   * @param {string} url
   * @return {string} url
   */
  validateProxyUrl: function (url) {
    if (this.get('proxyUrl')) {
      return this.get('proxyUrl') + url.substr(url.indexOf("/Temp/"));
    } else {
      return url;
    }
  },

  /**
   * Export draw layer.
   * @instance
   */
  export: function () {

    var features = this.get('drawLayer').getSource().getFeatures()
    ,   transformed = []
    ,   postData
    ,   form = document.createElement('form')
    ,   input = document.createElement('input')
    ,   curr = document.getElementById(this.exportHitsFormId);

    if (features.length === 0) {
      this.set({
        'kmlExportUrl': "NO_FEATURES"
      });
      return false;
    }

    features.forEach((feature) => {
      var c = feature.clone();
      if (c.getGeometry() instanceof ol.geom.Circle) {
        let geom = ol.geom.Polygon.fromCircle(feature.getGeometry(), 96);
        c.setGeometry(geom);
      }
      c.getGeometry().transform(this.get('olMap').getView().getProjection(), "EPSG:4326");

      if (c.getStyle()[1]) {
        c.setProperties({
          style: JSON.stringify(this.extractStyle(c.getStyle()[1] || c.getStyle()[0]))
        });
      }

      transformed.push(c);
    });

    postData = kmlWriter.createXML(transformed, "ritobjekt");
    if(this.get('base64Encode')){
      postData = btoa(postData);
    }

    this.set("downloadingDrawKml", true);
    $.ajax({
      url: this.get('exportUrl'),
      method: "post",
      data: {
        json: postData
      },
      format: "json",
      success: (url) => {
        this.set("downloadingDrawKml", false);
        this.set("kmlExportUrl", this.validateProxyUrl(url));
      },
      error: (err) => {
        this.set("downloadingDrawKml", false);
        alert("Något gick fel. Försök igen");
      }
    });
  },

  /**
   * Set the features style from based upon its properties.
   * @param {external:"ol.feature"}
   * @instance
   */
  setStyleFromProperties: function (feature) {
    if (feature.getProperties().style) {
      try {
        let style = JSON.parse(feature.getProperties().style);
        if (style.text) {
          this.setFeaturePropertiesFromText(feature);
          if (style.pointRadius > 0) {
            this.setFeaturePropertiesFromGeometry(feature);
          }
        } else {
          this.setFeaturePropertiesFromGeometry(feature);
        }
        feature.setStyle(this.getStyle(feature, style));
      } catch (ex) {
        console.error("Style attribute could not be parsed.", ex)
      }
    } else {
      //https://github.com/openlayers/openlayers/issues/3262
      let func = feature.getStyleFunction();
      if (func) {
        let style = func.call(feature, this.get('olMap').getView().getResolution());
        if (style[0] && style[0].getFill && style[0].getFill() === null) {
          style[0].setFill(new ol.style.Fill({
            color: [0, 0, 0, 0]
          }));
        }
        feature.setStyle(style);
      }
    }
  },

  /**
   * Calculate extent of given features
   * @instance
   * @param {array} features
   * @return {external:ol.Extent} extent
   */
  calculateExtent(features) {
    var x = [];
    features.forEach((feature, i) => {
      var e = feature.getGeometry().getExtent(); // l b r t
      if (i === 0) {
        x = e;
      } else {
        let t = 0;
        for (;t < 4; t++) {
          if (t < 2) {
            if (x[t] > e[t]) {
              x[t] = e[t];
            }
          } else {
            if (x[t] < e[t]) {
              x[t] = e[t];
            }
          }
        }
      }
    });
    return x.every(c => c) ? x : false;
  },

  /**
   * Import draw layer and add features to the map.
   * @instance
   * @param {XMLDocument} xmlDocument
   */
  importDrawLayer: function (xmlDoc) {

    var clonedNode = xmlDoc.childNodes[0].cloneNode(true)
    ,   serializer = new XMLSerializer()
    ,   kml_string = serializer.serializeToString(clonedNode)
    ,   parser = new ol.format.KML()
    ,   features = parser.readFeatures(kml_string)
    ,   extent = false;

    features.forEach((feature) => {
      coordinates = feature.getGeometry().getCoordinates();
      type = feature.getGeometry().getType()
      newCoordinates = [];
      feature.setProperties({
        user: true
      });
      if (type == 'LineString') {
        coordinates.forEach((c, i) => {
          pairs = [];
          c.forEach((digit) => {
            if (digit!=0)
              pairs.push(digit)
          });
         newCoordinates.push(pairs)
        });
        feature.getGeometry().setCoordinates(newCoordinates);
      } else if (type == 'Polygon') {
        newCoordinates[0] = [];
        coordinates.forEach((polygon, i) => {
          polygon.forEach((vertex, j) => {
            pairs = []
            vertex.forEach((digit) => {
            if (digit!=0)
              pairs.push(digit)
            });
            newCoordinates[0].push(pairs);
          });
        });
        feature.getGeometry().setCoordinates(newCoordinates);
      }

      feature.getGeometry().transform(
        "EPSG:4326",
        this.get('olMap').getView().getProjection()
      );
      this.setStyleFromProperties(feature);
    });

    this.get('drawLayer').getSource().addFeatures(features);
    extent = this.calculateExtent(features);

    if (extent) {
      let size = this.get('olMap').getSize();
      this.get('olMap').getView().fit(extent, size);
    }

  },

  /**
   * Trigger kml import
   * @instance
   */
  import: function () {
    this.set("kmlImport", true);
  },

  /**
   * Get styles array.
   * @instance
   * @param {external:"ol.feature"} feature
   * @param {boolean} forcedProperties - Force certain properties to be taken directly from the feature.
   * @return {Array<{external:"ol.style"}>} style
   *
   */
  getStyle: function(feature, forcedProperties) {
    var geometryName = feature.getGeometryName();
    function getLineDash() {
        var scale = (a, f) => a.map(b => f * b)
        ,   width = lookupWidth.call(this)
        ,   style = lookupStyle.call(this)
        ,   dash  = [12, 7]
        ,   dot   = [2, 7]
        ;
        switch (style) {
          case "dash":
            return width > 3 ? scale(dash, 2) : dash;
          case "dot":
            return width > 3 ? scale(dot, 2) : dot;
          default :
            return undefined;
        }
    }

    function getFill() {
      function rgba() {
        switch(geometryName) {
          case "Circle":
            return this.get('circleFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('circleFillOpacity')})`)

          case "Polygon":
            return this.get('polygonFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('polygonFillOpacity')})`)

          case "Box":
            return this.get('boxFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('boxFillOpacity')})`);
        }
      }

      var color = forcedProperties ? forcedProperties.fillColor : rgba.call(this);
      var fill = new ol.style.Fill({
        color: color
      });

      return fill;
    }

    function lookupStyle() {
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineStyle');
        case "Circle":
          return this.get('circleLineStyle');
        case "Box":
          return this.get('boxLineStyle');
        default:
          return this.get('lineStyle');
      }
    }

    function lookupWidth() {
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineWidth');
        case "Circle":
          return this.get('circleLineWidth');
        case "Box":
          return this.get('boxLineWidth');
        default:
          return this.get('lineWidth');
      }
    }

    function lookupColor() {
      if (forcedProperties) {
        return forcedProperties.strokeColor;
      }
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineColor');
        case "Circle":
          return this.get('circleLineColor');
        case "Box":
          return this.get('boxLineColor');
        default:
          return this.get('lineColor');
      }
    }

    function getStroke() {

      var color = forcedProperties ?
                  forcedProperties.strokeColor :
                  lookupColor.call(this);

      var width = forcedProperties ?
                  forcedProperties.strokeWidth :
                  lookupWidth.call(this);

      var lineDash = forcedProperties ?
                     forcedProperties.strokeDash :
                     getLineDash.call(this);

      var stroke =  new ol.style.Stroke({
        color: color,
        width: width,
        lineDash: lineDash
      })

      return stroke;
    }

    function getImage() {

      var radius = type === "Text" ? 0 : forcedProperties ? forcedProperties.pointRadius : this.get('pointRadius');
      var iconSrc = forcedProperties ? (forcedProperties.image || this.get('markerImg')) : this.get('markerImg');

      var icon = new ol.style.Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: iconSrc
      });

      var dot = new ol.style.Circle({
        radius: radius,
        fill: new ol.style.Fill({
          color: forcedProperties ? forcedProperties.pointColor : this.get('pointColor')
        }),
        stroke: new ol.style.Stroke({
          color: 'rgb(255, 255, 255)',
          width: 2
        })
      });

      if (forcedProperties) {
        if (forcedProperties.image) {
          return icon;
        } else {
          return dot;
        }
      }

      if (this.get('pointSymbol') && type !== 'Text') {
        return icon;
      } else {
        return dot;
      }
    }

    function getText() {

      var offsetY = () => {
        var offset = -15;

        if (this.get('pointSymbol'))
          offset = -40;

        if (type === "Text")
          offset = 0;

        return offset;
      }

      return new ol.style.Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: `${this.get('fontSize')}px sans-serif`,
        text: forcedProperties ? forcedProperties.text : this.getLabelText(feature),
        fill: new ol.style.Fill({color: this.get('fontColor')}),
        stroke: new ol.style.Stroke({color: this.get('fontBackColor'), width: 3}),
        offsetX: type === "Text" ? 0 : 10,
        offsetY: offsetY(),
        rotation: 0,
        scale: 1.4
      });
    }

    var type = feature.getProperties().type;

    return [
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'rgba(255, 255, 255, 0.5)',
          width: type === 'Polygon' ?
                   this.get('polygonLineWidth') + 2 :
                   this.get('lineWidth') + 2
        })
      }),
      new ol.style.Style({
        fill: getFill.call(this),
        stroke: getStroke.call(this),
        image: getImage.call(this),
        text: getText.call(this)
      })
    ]
  },

  /**
   * Generate feature label text from properties.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {string} label
   *
   */
  getLabelText: function (feature) {
    var show  = this.get('showLabels')
    ,   props = feature.getProperties()
    ,   type  = feature.getProperties().type;

    if (typeof props.description !== 'undefined'){
      type = "Text";
    }

    switch (type) {
      case "Point": return show ? this.formatLabel("point", [props.position.n, props.position.e]) : "";
      case "LineString": return show ? this.formatLabel("length", props.length): "";
      case "Polygon": return show ? this.formatLabel("area", props.area) : "";
      case "Circle": return show ? this.formatLabel("circle", props.radius): "";
      case "Text": return props.description;
      case "Box": return show ? this.formatLabel("area", props.area) : "";
      default: return "";
    }
  },

  /**
   * Set the property wich will show/hide labels and update the source.
   * @instance
   * @return {boolean} showLabels
   */
  toggleLabels: function () {

    this.set('showLabels', !this.get('showLabels'));
    this.get('source').changed();

    source.forEachFeature(feature => {
      if (feature.getProperties().type !== "Text" && typeof feature.getProperties().description === "undefined" && feature.getStyle()) {
        let style = feature.getStyle();
        if (this.get('showLabels')) {
          if (style[1]) {
            style[1].getText().setText(this.getLabelText(feature));
          } else if (style[0]) {
            style[0].getText().setText(this.getLabelText(feature));
          }
        } else {
          if (style[1]) {
            style[1].getText().setText("");
          } else if (style[0]) {
            style[0].getText().setText("");
          }
        }
      } else if (feature.getProperties().type === "Text" || typeof feature.getProperties().description !== "undefined"){
        let style = feature.getStyle();
        if (style[1]) {
          style[1].getText().setText(this.getLabelText(feature));
        } else if (style[0]) {
          style[0].getText().setText(this.getLabelText(feature));
        }
      }
    });

    return this.get('showLabels');
  },

  /**
   * Update any feature with property to identify feature as text feature.
   * @instance
   * @params {external:"ol.feature"} feature
   * @params {string} text
   */
  setFeaturePropertiesFromText: function (feature, text) {
    if (!feature) return;
    feature.setProperties({
      type: "Text",
      user: true,
      description: text
    });
  },

  /**
   * Update any feature with properties from its own geometry.
   * @instance
   * @params {external:"ol.feature"} feature
   */
  setFeaturePropertiesFromGeometry: function (feature) {
    if (!feature) return;
    var geom
    ,   type = ""
    ,   lenght = 0
    ,   radius = 0
    ,   area = 0
    ,   position = {
          n: 0,
          e: 0
        }
    ;
    geom = feature.getGeometry();
    type = geom.getType();
    switch (type) {
      case "Point":
        position = {
          n: Math.round(geom.getCoordinates()[1]),
          e: Math.round(geom.getCoordinates()[0])
        };
        break;
      case "LineString" :
        length = Math.round(geom.getLength());
        break;
      case "Polygon":
        area = Math.round(geom.getArea());
        break;
      case "Circle":
        radius = Math.round(geom.getRadius());
        break;
      default:
        break;
    }
    feature.setProperties({
      type: type,
      user: true,
      length: length,
      area: area,
      radius: radius,
      position: position
    });
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  },

  /**
   * Set the property pointColor
   * @param {string} color
   * @instance
   */
  setCircleRadius: function (radius) {
    this.set("circleRadius", radius);
  },

  /**
   * Set the property pointSettings
   * @param {string} color
   * @instance
   */
  setPointSettings: function (value) {
    this.set("pointSettings", value);
  },

  /**
   * Set the property pointColor
   * @param {string} color
   * @instance
   */
  setPointColor: function (color) {
    this.set("pointColor", color);
  },
  /**
   * Set the property pointRadius
   * @param {number} radius
   * @instance
   */
  setPointRadius: function (radius) {
    this.set("pointRadius", radius);
  },

  /**
   * Set the property lineWidth
   * @param {number} width
   * @instance
   */
  setLineWidth: function (width) {
    this.set("lineWidth", width);
  },

  /**
   * Set the property lineColor
   * @param {string} color
   * @instance
   */
  setLineColor: function (color) {
    this.set("lineColor", color);
  },

  /**
   * Set the property lineStyle
   * @param {string} style
   * @instance
   */
  setLineStyle: function (style) {
    this.set("lineStyle", style);
  },

  /**
   * Set the property polygonLineStyle
   * @param {string} style
   * @instance
   */
  setPolygonLineStyle: function (style) {
    this.set("polygonLineStyle", style);
  },

  /**
   * Set the property polygonFillOpacity
   * @param {number} opacity
   * @instance
   */
  setPolygonFillOpacity: function (opacity) {
    this.set("polygonFillOpacity", opacity);
  },

  /**
   * Set the property polygonLineWidth
   * @param {number} width
   * @instance
   */
  setPolygonLineWidth: function (width) {
    this.set("polygonLineWidth", width);
  },

  /**
   * Set the property polygonLineColor
   * @param {string} color
   * @instance
   */
  setPolygonLineColor: function (color) {
    this.set("polygonLineColor", color);
  },

  /**
   * Set the property polygonFillColor
   * @param {string} color
   * @instance
   */
  setPolygonFillColor: function (color) {
    this.set("polygonFillColor", color);
  },

  /**
   * Set the property circleFillColor
   * @param {string} color
   * @instance
   */
  setCircleFillColor: function (color) {
    this.set("circleFillColor", color);
  },

  /**
   * Set the property circleFillOpacity
   * @param {number} opacity
   * @instance
   */
  setCircleFillOpacity: function (opacity) {
    this.set("circleFillOpacity", opacity);
  },

  /**
   * Set the property circleLineColor
   * @param {string} color
   * @instance
   */
  setCircleLineColor: function (color) {
    this.set("circleLineColor", color);
  },

  /**
   * Set the property circleLineStyle
   * @param {string} style
   * @instance
   */
  setCircleLineStyle: function (style) {
    this.set("circleLineStyle", style);
  },

  /**
   * Set the property circleLineWidth
   * @param {number} width
   * @instance
   */
  setCircleLineWidth: function (width) {
    this.set("circleLineWidth", width);
  },

  /**
   * Set the property boxFillColor
   * @param {string} color
   * @instance
   */
  setBoxFillColor: function (color) {
    this.set("boxFillColor", color);
  },

  /**
   * Set the property boxFillOpacity
   * @param {number} opacity
   * @instance
   */
  setBoxFillOpacity: function (opacity) {
    this.set("boxFillOpacity", opacity);
  },

  /**
   * Set the property boxLineColor
   * @param {string} color
   * @instance
   */
  setBoxLineColor: function (color) {
    this.set("boxLineColor", color);
  },

  /**
   * Set the property boxLineStyle
   * @param {string} style
   * @instance
   */
  setBoxLineStyle: function (style) {
    this.set("boxLineStyle", style);
  },

  /**
   * Set the property boxLineWidth
   * @param {number} width
   * @instance
   */
  setBoxLineWidth: function (width) {
    this.set("boxLineWidth", width);
  },

  /**
   * Set the property pointSymbol
   * @param {string} value
   * @instance
   */
  setPointSymbol: function(value) {
    this.set('pointSymbol', value);
  },

  /**
   * Set the property pointSymbol
   * @param {string} value
   * @instance
   */
  setFontSize: function(value) {
    this.set('fontSize', value);
  },

  /**
   * Set the property fontColor
   * @param {string} value
   * @instance
   */
  setFontColor: function(value) {
    this.set('fontColor', value);
  },

  /**
   * Set the property fontBackColor
   * @param {string} value
   * @instance
   */
  setFontBackColor: function(value) {
    this.set('fontBackColor', value);
  },

  /**
   * Set the point text
   * @param {string} text
   * @instance
   */
  setPointText: function(text) {
    var feature = this.get('drawFeature');
    this.set('pointText', text);
    this.setFeaturePropertiesFromText(feature, text || "");
    feature.setStyle(this.getStyle(feature));
  },

  /**
   * Set pointer position
   * @param {object} event
   * @instance
   */
  setPointerPosition: function(e) {
    this.set('pointerPosition', e);
  }
};

/**
 * Draw model module.<br>
 * Use <code>require('models/draw')</code> for instantiation.
 * @module DrawModel-module
 * @returns {DrawModel}
 */
module.exports = ToolModel.extend(DrawModel);

},{"tools/tool":"tools/tool","utils/kmlwriter":"utils/kmlwriter"}],"tools/edit":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} EditModel~EditModelProperties
 * @property {string} type - Default: edit
 * @property {string} panel - Default: editpanel
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-pencil-square-o icon
 * @property {string} title - Default: Editera
 * @property {boolean} visible - Default: false
 * @property {external:"ol.source"} vectorSource - Default: undefined
 * @property {external:"ol.source"} imageSource - Default: undefined
 * @property {external:"ol.layer"} layer - Default: undefined
 * @property {external:"ol.interaction.Select"} select - Default: undefined
 * @property {external:"ol.interaction.Modify"} modify - Default: undefined
 * @property {string} key - Default: undefined
 * @property {external:"ol.feature"} editFeature - Default: undefined
 * @property {external:"ol.source"} editSource - Default: undefined
 * @property {external:"ol.feature"} removeFeature - Default: undefined
 * @property {ShellModel} shell - Default: undefined
 */
var EditModelProperties = {
  type: 'edit',
  panel: 'editpanel',
  toolbar: 'bottom',
  icon: 'fa fa-pencil-square-o icon',
  title: 'Editera',
  visible: false,
  vectorSource: undefined,
  imageSource: undefined,
  layer: undefined,
  select: undefined,
  modify: undefined,
  key: undefined,
  editFeature: undefined,
  editSource: undefined,
  removeFeature: undefined,
  shell: undefined,
  instruction: ""
};

/**
 * Prototype for creating an edit model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {EditModel~EditModelProperties} options - Default options
 */
var EditModel = {
  /*
   * @instance
   * @property defaults - Default settings
   * @{EditModel~EditModelProperties} defaults
   */
  defaults: EditModelProperties,

  /**
   * @instance
   * @property {boolean} filty - Default: false
   */
  filty: false,

  configure: function (shell) {
    this.set('map', shell.getMap().getMap());

    this.set('layerCollection', shell.getLayerCollection());
    var navigation = shell.getNavigation();
    navigation.on('change:activePanel', (e) => {
      this.deactivate();
    });
  },

  /**
   * Generate transaction XML-string to post
   * @instance
   * @param {Array<{external:"ol.feature"}>} features
   * @return {string} XML-string to post
   */
  write: function (features) {

    var format = new ol.format.WFS()
    ,   lr = this.get('editSource').layers[0].split(':')
    ,   ns = lr.length === 2 ? lr[0] : ""
    ,   ft = lr.length === 2 ? lr[1] : lr[0]
    ,   options = {
          featureNS: ns,
          featureType: ft,
          srsName: this.get('editSource').projection
        }
    ,   gml = new ol.format.GML(options);

    return format.writeTransaction(features.inserts, features.updates, features.deletes, gml);
  },

  /**
   * Try to find the corresponding WMS-layer in the map.
   * If found, refresh that layer.
   * @instance
   * @param {string} layerName
   */
  refreshLayer: function (layerName) {
    var source
    ,   foundLayer = this.get('layerCollection').find(layer => {
          if (layer.getLayer().getSource().getParams) {
            let p = layer.getLayer().getSource().getParams();
            if (typeof p === 'object') {

              let paramName = p['LAYERS'].split(':');
              let layerSplit = layerName.split(':');

              if (paramName.length === 2 && layerSplit.length === 2) {
                return layerName === p['LAYERS'];
              }
              if (paramName.length === 1) {
                return layerSplit[1] === p['LAYERS'];
              }

            }
          }
        });

    if (foundLayer) {
      source = foundLayer.getLayer().getSource();
      source.changed();
      source.updateParams({"time": Date.now()});
      this.get('map').updateSize();
    }
  },
  /**
   * @instance
   * @param {XMLDocument} response
   * @return {object} js-xml-translation-object
   */
  parseWFSTresponse: function (response) {
    var str = (new XMLSerializer()).serializeToString(response);
    return (new X2JS()).xml2js(str);
  },
  /**
   * Make transaction
   * @instance
   * @param {Array<{external:"ol.feature"}>} features
   * @param {function} done - Callback to invoke when the transaction is complete.
   */
  transact: function (features, done) {

    var node = this.write(features)
    ,   serializer = new XMLSerializer()
    ,   src = this.get('editSource')
    ,   payload = node ? serializer.serializeToString(node) : undefined;

    if (payload) {
      $.ajax(HAJK2.searchProxy + src.url, {
        type: 'POST',
        dataType: 'xml',
        processData: false,
        contentType: 'text/xml',
        data: payload
      }).done((data) => {

        this.refreshLayer(src.layers[0]);
        var data = this.parseWFSTresponse(data);

        this.get('vectorSource')
          .getFeatures()
          .filter(f => f.modification !== undefined)
          .forEach(f => f.modification = undefined);
        done(data);
      }).fail((data) => {
        var data = this.parseWFSTresponse(data);
        done(data);
      });
    }
  },

  /**
   * Trigger transaction
   * @instance
   * @param {function} done - Callback to invoke when the transaction is complete.
   */
  save: function (done) {

    var find = mode =>
      this.get('vectorSource').getFeatures().filter(feature =>
        feature.modification === mode);

    var features = {
      updates: find('updated'),
      inserts: find('added'),
      deletes: find('removed')
    };

    if (features.updates.length === 0 &&
        features.inserts.length === 0 &&
        features.deletes.length === 0) {
      return done();
    }

    this.transact(features, done);
  },

  /**
   * Generate the select style.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {Array<{external:"ol.style"}>}
   */
  getSelectStyle: function (feature) {
    return [new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 255, 255, 1)',
        width: 3
      }),
      fill: new ol.style.Fill({
        color: 'rgba(0, 0, 0, 0.5)'
      }),
      image: new ol.style.Circle({
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0.5)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 255, 255, 1)',
          width: 2
        }),
        radius: 3
      })
    }), new ol.style.Style({
      image: new ol.style.RegularShape({
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0.2)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 1)',
          width: 2
        }),
        points: 4,
        radius: 8,
        angle: Math.PI / 4
      }),
      geometry: function(feature) {
        coordinates = feature.getGeometry() instanceof ol.geom.Polygon ?
                      feature.getGeometry().getCoordinates()[0] :
                      feature.getGeometry().getCoordinates();
        return new ol.geom.MultiPoint(coordinates);
      }
    })];
  },

  /**
   * Generate the default display style.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {Array<{external:"ol.style"}>}
   */
  getStyle: function (feature) {
    return [new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 0, 0, 1)',
        width: 3
      }),
      fill: new ol.style.Fill({
        color: 'rgba(0, 0, 0, 0.5)'
      }),
      image: new ol.style.Circle({
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0.5)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 1)',
          width: 3
        }),
        radius: 4
      })
    })];
  },

  /**
   * Generate the hidden style.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {Array<{external:"ol.style"}>}
   */
  getHiddenStyle : function (feature) {
    return [new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 0
      }),
      fill: new ol.style.Fill({
        color: 'rgba(1, 2, 3, 0)'
      }),
      image: new ol.style.Circle({
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 0)',
          width: 0
        }),
        radius: 0
      })
    })];
  },

  /**
   * Generate the scetch style.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {Array<{external:"ol.style"}>}
   */
  getScetchStyle: function () {
    return [
      new ol.style.Style({
        fill: new ol.style.Fill({
          color: 'rgba(255, 255, 255, 0.5)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          width: 4
        }),
        image: new ol.style.Circle({
          radius: 6,
          fill: new ol.style.Fill({
            color: 'rgba(0, 0, 0, 0.5)'
          }),
          stroke: new ol.style.Stroke({
            color: 'rgba(255, 255, 255, 0.5)',
            width: 2
          })
        })
      })
    ]
  },

  /**
   * Filter features by hidden default value.
   * If the the dataset has a hidden field with a default value this will be set as filter.
   * @instance
   * @param {external:ol.Feature[]} features
   */
  filterByDefaultValue: function(features) {
    return features.filter(feature => {
      return this.get('editSource').editableFields.some(field => {
        var value = feature.getProperties()[field.name];
        if (field.hidden && value === field.defaultValue) {
          return true;
        }
      });
    });
  },

  /**
   * Load data from WFS-service and att to the source.
   * @instance
   * @param {object} config
   * @param {number[]} extent
   * @param {function} done - Callback
   */
  loadData: function (config, extent, done) {
    var format = new ol.format.WFS();
    $.ajax(HAJK2.wfsProxy + config.url, {
        type: 'GET',
        data: {
          service: 'WFS',
          version: '1.1.0',
          request: 'GetFeature',
          typename: config.layers[0],
          srsname: config.projection
        }
    }).done(rsp => {
      var features
      try {
        features = format.readFeatures(rsp);
      } catch (e) {
        alert("Fel: data kan inte läsas in. Kontrollera koordinatsystem.");
      }
      if (features.length > 0) {
        this.set("geometryName", features[0].getGeometryName());
      }

      if (this.get('editSource').editableFields.some(field => field.hidden)) {
        features = this.filterByDefaultValue(features);
      }

      this.get('vectorSource').addFeatures(features);
      this.get('vectorSource').getFeatures().forEach(feature => {
        //Property changed
        feature.on('propertychange', (e) => {
          if (feature.modification === 'removed')
            return;
          if (feature.modification === 'added')
            return;
          feature.modification = 'updated';
        });
        //Geometry changed.
        feature.on('change', (e) => {
          if (feature.modification === 'removed')
            return;
          if (feature.modification === 'added')
            return;
          feature.modification = 'updated';
        });
      });
      if (done) done();
    }).fail(rsp => {
      alert("Fel: data kan inte hämtas. Försök igen senare.");
      if (done) done();
    });
  },

  /**
   * Trigger edit attribute session.
   * @instance
   * @param {external:"ol.feature"} feature
   */
  editAttributes: function(feature) {
    this.set({editFeature: feature});
  },

  /**
   * Event handler for feature selection.
   * @instance
   * @param {object} event
   */
  featureSelected: function (event) {
    if (this.get('removalToolMode') === 'on') {
      event.selected.forEach(feature => {
        this.set({removeFeature: feature});
      });
      return;
    }

    if (event.selected.length === 0) {
      this.editAttributes(null, null);
    }

    event.selected.forEach(feature => {
      if (!feature.getId() && feature.getProperties().user) {
        this.get('select').getFeatures().remove(feature);
      }
      event.mapBrowserEvent.filty = true;
      this.editAttributes(feature);
    });
  },

  /**
   * Set this models avtive layer.
   * @instance
   * @param {external:"ol.source"} source
   * @param {function} done - Callback
   */
  setLayer: function (source, done) {
    this.filty = true;

    this.get('map').set('clickLock', true);

    if (this.get('layer')) {
      this.get('map').removeLayer(this.get('layer'));
    }

    this.set('vectorSource', new ol.source.Vector({
      loader: (extent) => this.loadData(source, extent, done),
      strategy: ol.loadingstrategy.all,
      projection: source.projection
    }));

    this.set('imageSource', new ol.source.ImageVector({
       source: this.get('vectorSource'),
       style: this.getStyle()
    }));

    this.set('layer', new ol.layer.Image({
      source: this.get('imageSource'),
      name: "edit-layer"    
    }));
    this.get('map').addLayer(this.get('layer'));

    if (!this.get('select')) {
      this.set('select', new ol.interaction.Select({
        style: this.getSelectStyle(),
        toggleCondition: ol.events.condition.never
      }));
      this.get('map').addInteraction(this.get('select'));
    } else {
      this.get('select').getFeatures().clear();
      this.get('select').unset(this.get('key'));
    }

    this.set('key', this.get('select').on('select', (event) => { this.featureSelected(event, source) }));

    if (!this.get('modify')) {
      this.set('modify', new ol.interaction.Modify({ features: this.get('select').getFeatures() }));
      this.get('map').addInteraction(this.get('modify'));
    }

    this.set({editSource: source});
    this.set({editFeature: null});
    this.get('select').setActive(true);
    this.get('modify').setActive(true);
    this.get('layer').dragLocked = true;
  },

  /**
   * Event handler for draw end.
   * @instance
   * @param {external:"ol.feature"} - Drawn feature
   * @param {string} geometryType - Geometry type of feature
   */
  handleDrawEnd: function(feature, geometryType) {
    feature.modification = 'added';
    this.editAttributes(feature);
  },

  /**
   * Set the mode of the removal tool.
   * @instance
   * @param {string} mode
   */
  setRemovalToolMode: function (mode) {
    this.set('removalToolMode', mode);
  },

  /**
   * Activate the draw tool.
   * @instance
   * @param {string} geometryType
   */
  activateDrawTool: function(geometryType) {

    var add = () => {
      this.set('drawTool', new ol.interaction.Draw({
        source: this.get('vectorSource'),
        style: this.getScetchStyle(),
        type: geometryType,
        geometryName: this.get('geometryName')
      }));
      this.get("drawTool").on('drawend', (event) => {
        this.handleDrawEnd(event.feature, geometryType)
      });
      this.get('map').addInteraction(this.get('drawTool'));
    };

    var remove = () => {
      this.get('map').removeInteraction(this.get('drawTool'));
      this.set('drawTool', undefined);
    };

    this.get('map').set('clickLock', true);

    if (this.get('select')) {
      this.get('select').setActive(false);
    }

    if (this.get("drawTool")) {
      this.get('drawTool').setActive(true);
      if (this.set('geometryType', geometryType) !== geometryType) {
        remove();
        add();
      }
    } else {
      add();
    }
  },

  /**
   * Activate the draw tool.
   * @instance
   * @param {boolean} keepClickLock - Whether to keep the maps' clicklock or not.
   */
  deactivateDrawTool: function(keepClickLock) {
    if (!keepClickLock)
      this.get('map').set('clickLock', false);

    if (this.get('select')) {
      this.get('select').setActive(true);
    }

    if (this.get('drawTool')) {
      this.get('drawTool').setActive(false);
    }
  },

  /**
   * Deactivate all edit interactions.
   * @instance
   */
  deactivateTools: function() {
    if (this.get('select')) {
      this.get('select').setActive(false);
      this.get('select').getFeatures().clear();
    }

    if (this.get('modify')) {
      this.get('modify').setActive(false);
    }

    if (this.get('drawTool')) {
      this.get('drawTool').setActive(false);
    }
  },

  /**
   * Deactivate the edit tool
   * @instance
   */
  deactivate: function () {
    if (this.get('select')) {
      this.get('select').setActive(false);
      this.get('select').getFeatures().clear();
    }

    if (this.get('modify')) {
      this.get('modify').setActive(false);
    }

    if (this.get('drawTool')) {
      this.get('drawTool').setActive(false);
    }

    if (this.get('layer')) {
      this.get('map').removeLayer(this.get('layer'));
      this.set('layer', undefined);
    }

    this.set({
      editSource: undefined,
      editFeature: undefined,
      removeFeature: undefined,
      removalToolMode: undefined
    });

    this.filty = false;
    this.get('map').set('clickLock', false);
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function () {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Edit model module.<br>
 * Use <code>require('models/edit')</code> for instantiation.
 * @module EditModel-module
 * @returns {EditModel}
 */
module.exports = ToolModel.extend(EditModel);

},{"tools/tool":"tools/tool"}],"tools/export":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

"use strict";

var ToolModel = require('tools/tool');
var transform = require('models/transform');

String.prototype.toHex = function() {
  if (/^#/.test(this)) return this;
  var hex = (
  "#" +
  this.match(/\d+(\.\d+)?/g)
    .splice(0, 3)
    .map(i => {
    var v =  parseInt(i, 10).toString(16);
  if (parseInt(i) < 16) {
    v = "0" + v;
  }
  return v;
})
  .join("")
  );
  return hex;
}

String.prototype.toOpacity = function() {
  return parseFloat(this.match(/\d+(\.\d+)?/g).splice(3, 1)[0]);
}

/**
 * @typedef {Object} ExportModel~ExportModelProperties
 * @property {string} type - Default: export
 * @property {string} panel - Default: exportpanel
 * @property {string} title - Default: Skriv ut
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-print icon
 * @property {string} exportUrl - Default: /mapservice/export/pdf
 * @property {string} exportTiffUrl - Default: /mapservice/export/tiff
 * @property {string} copyright - Default: © Lantmäteriverket i2009/00858
 */
var ExportModelProperties = {
  type: 'export',
  panel: 'exportpanel',
  title: 'Skriv ut',
  toolbar: 'bottom',
  icon: 'fa fa-print icon',
  exportUrl: '/mapservice/export/pdf',
  exportTiffUrl: '/mapservice/export/tiff',
  pdfActive: true,
  tiffActive: true,
  copyright: "© Lantmäteriverket i2009/00858",
  activeTool: '',
  base64Encode: false,
  autoScale: false,
  instruction: "",
  scales: [250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000]
};

/**
 * Prototype for creating an draw model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {ExportModel~ExportModelProperties} options - Default options
 */
var ExportModel = {
  /**
   * @instance
   * @property {ExportModel~ExportModelProperties} defaults - Default settings
   */
  defaults: ExportModelProperties,

  configure: function (shell) {

    const formats = [];

    if (this.get('pdfActive')) {
      formats.push('pdf');
    }
    if (this.get('tiffActive')) {
      formats.push('tiff');
    }
    if (formats.length > 0) {
      this.setActiveTool(formats[0]);
    }

    // change scale on the map here?
    this.set('olMap', shell.getMap().getMap());
    this.addPreviewLayer();
  },

  setActiveTool: function (tool) {
    this.set('activeTool', tool);
  },

  /**
   * Add preview layer to map.
   * @instance
   */
  addPreviewLayer: function () {
    this.previewLayer = new ol.layer.Vector({
      source: new ol.source.Vector(),
      name: "preview-layer",
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 0.7)',
          width: 2
        }),
        fill: new ol.style.Fill({
          color: 'rgba(255, 145, 20, 0.4)'
        })
      })
    });
    this.get('olMap').addLayer(this.previewLayer);
  },

  /**
   * Remove preview layer from map.
   * @instance
   */
  removePreview: function () {
    this.set('previewFeature', undefined);
    this.previewLayer.getSource().clear();
  },

  removeTiffPreview: function() {
    this.get('transform').clear();
    this.get('olMap').removeInteraction(this.get('transform'));
    this.previewLayer.getSource().clear();
    this.set('previewFeature', undefined);
    this.get('olMap').set('clickLock', false);
  },

  /**
   * Get the preview feature.
   * @instance
   * @return {external:"ol.feature"} preview feature
   */
  getPreviewFeature: function () {
    return this.get('previewFeature')
  },

  /**
   * Get center coordinate of the preview feature.
   * @return {external:"ol.coordinate"} center coordinate
   */
  getPreviewCenter: function () {
    var extent = this.getPreviewFeature().getGeometry().getExtent();
    return ol.extent.getCenter(extent);
  },

  addTiffPreview: function (center) {

    var dpi = 25.4 / 0.28
      ,   ipu = 39.37
      ,   sf  = 1
      ,   w   = (210 / dpi / ipu * 10000 / 2) * sf
      ,   y   = (297 / dpi  / ipu * 10000 / 2) * sf
      ,   coords = [
        [
          [center[0] - w, center[1] - y],
          [center[0] - w, center[1] + y],
          [center[0] + w, center[1] + y],
          [center[0] + w, center[1] - y],
          [center[0] - w, center[1] - y]
        ]
      ]
      ,   feature = new ol.Feature({
        geometry: new ol.geom.Polygon(coords)
      })
    ;

    this.removePreview();
    this.set('previewFeature', feature);
    this.previewLayer.getSource().addFeature(feature);

    var features = new ol.Collection();
    features.push(feature);


    this.set('transform', new ol.interaction.Transform({
      translateFeature: true,
      scale: true,
      rotate: false,
      keepAspectRatio: false,
      translate: true,
      stretch: false,
      features: features
    }));
    this.get('olMap').addInteraction(this.get('transform'));
    this.get('olMap').set('clickLock', true);
  },

  /**
   * Add the preview feature to the export layer source.
   * @instance
   * @param {number} scale
   * @param {object} paper
   * @param {number[]} center
   */
  addPreview: function (scale, paper, center) {

    var dpi = 25.4 / 0.28
      ,   ipu = 39.37
      ,   sf  = 1
      ,   w   = (paper.width / dpi / ipu * scale / 2) * sf
      ,   y   = (paper.height / dpi  / ipu * scale / 2) * sf
      ,   coords = [
        [
          [center[0] - w, center[1] - y],
          [center[0] - w, center[1] + y],
          [center[0] + w, center[1] + y],
          [center[0] + w, center[1] - y],
          [center[0] - w, center[1] - y]
        ]
      ]
      ,   feature = new ol.Feature({
        geometry: new ol.geom.Polygon(coords)
      })
    ;

    this.removePreview();
    this.set('previewFeature', feature);
    this.previewLayer.getSource().addFeature(feature);
  },

  /**
   * Clone map draw canvas.
   * @instance
   * @param {HTMLElement} old canvas
   * @param {number} size
   */
  cloneCanvas: function (oldCanvas, size) {
    var newCanvas = document.createElement('canvas')
      ,   context = newCanvas.getContext('2d');

    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
  },

  /**
   * Generate scale bar
   * @instance
   * @return {string} svg image as string
   */
  generateScaleBar: function() {

    var elem  = document.querySelector('.ol-scale-line').outerHTML
      ,   clone = $(elem)
      ,   html  = ''
      ,   data;

    clone.css({
      "width": $('.ol-scale-line-inner').width() + 4,
      "border-radius": "0px",
      "padding": "4px",
      "background": "white"
    });

    clone.find('.ol-scale-line-inner').css({
      "border-right-width": "1px",
      "border-bottom-width": "1px",
      "border-left-width": "1px",
      "border-style": "none solid solid",
      "border-right-color": "rgb(0, 0, 0)",
      "border-bottom-color": "rgb(0, 0, 0)",
      "border-left-color": "rgb(0, 0, 0)",
      "color": "rgb(0, 0, 0)",
      "font-size": "10px",
      "text-align": "center",
      "margin": "1px"
    });

    elem = clone.get(0).outerHTML;

    html = `<div xmlns='http://www.w3.org/1999/xhtml'>
          ${elem}
        </div>`;

    data = `data:image/svg+xml,
        <svg xmlns='http://www.w3.org/2000/svg' width='200' height='50'>
          <foreignObject width='100%' height='100%'>
            ${html}
          </foreignObject>
        </svg>`;

    return data;
  },

  /**
   * Find WMS layer to export in the map.
   * @instance
   * @return {object[]} wms layers
   */
  findWMS: function () {

    var exportable = layer =>
    (layer instanceof ol.layer.Tile || layer instanceof ol.layer.Image) && (
    layer.getSource() instanceof ol.source.TileWMS ||
    layer.getSource() instanceof ol.source.ImageWMS) &&
    layer.getVisible();

    var formatUrl = url =>
    /^\//.test(url) ?
    (window.location.protocol + "//" + window.location.host + url) :
    url;

    return this.get('olMap')
        .getLayers()
        .getArray()
        .filter(exportable)
        .map((layer, i) => {
        return {
          url: layer.getSource().get('url'),
          layers: layer.getSource().getParams()["LAYERS"].split(','),
          zIndex: i,
          workspacePrefix: null,
          coordinateSystemId: this.get('olMap').getView().getProjection().getCode().split(':')[1]
        }
      });
  },

  /**
   * Find vector layer to export in the map.
   * @instance
   * @return {object[]} vector layers
   */
  findVector: function () {

    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(rgbString) {
      const matches = /rgb(a)?\((\d+), (\d+), (\d+)(, [\d\.]+)?\)/.exec(rgbString);
      if (matches !== null) {
        let r = parseInt(matches[2]);
        let g = parseInt(matches[3]);
        let b = parseInt(matches[4]);
        let a = parseInt(matches[5]);
        return a
        ? null
        : ("#" + componentToHex(r) + componentToHex(g) + componentToHex(b));
      } else {
        return null;
      }
    }

    function asObject(style) {

      function olColorToHex(olColor) {
        var colorString = olColor.join(', ');
        var hex = rgbToHex(`rgba(${colorString})`);
        return hex;
      }

      if (!style) return null;

      if (Array.isArray(style)) {
        if (style.length === 2) {
          style = style[1];
        }
        if (style.length === 1) {
          style = style[0];
        }
      }

      var fillColor = "#FC345C"
      ,   fillOpacity = 0.5
      ,   strokeColor = "#FC345C"
      ,   strokeOpacity = 1
      ,   strokeWidth = 3
      ,   strokeLinecap = "round"
      ,   strokeDashstyle = "solid"
      ,   pointRadius = 10
      ,   pointFillColor = "#FC345C"
      ,   pointSrc = ""
      ,   labelAlign = "cm"
      ,   labelOutlineColor = "white"
      ,   labelOutlineWidth = 3
      ,   fontSize = "16"
      ,   fontColor = "#FFFFFF"
      ,   fontBackColor = "#000000";

      if (style.getText && style.getText() && style.getText().getFont && style.getText().getFont()) {
        fontSize = style.getText().getFont().match(/\d+/)[0];
      }

      if (style.getText && style.getText() && style.getText().getFill && style.getText().getFill()) {
        if (typeof style.getText().getFill().getColor() === "string") {
          fontColor = style.getText().getFill().getColor();
        } else if (Array.isArray(style.getText().getFill().getColor())) {
          fontColor = olColorToHex(style.getText().getFill().getColor());
        }
      }

      if (style.getText && style.getText() && style.getText().getStroke && style.getText().getStroke()) {
        if (typeof style.getText().getFill().getColor() === "string") {
          fontBackColor = style.getText().getStroke().getColor();
        } else if (Array.isArray(style.getText().getStroke().getColor())) {
          fontBackColor = olColorToHex(style.getText().getStroke().getColor());
        }
      }

      if (fontColor && /^rgb/.test(fontColor)) {
        fontColor = rgbToHex(fontColor);
      }

      if (fontBackColor) {
        if (/^rgb\(/.test(fontBackColor)) {
          fontBackColor = rgbToHex(fontBackColor);
        } else {
          fontBackColor = null;
        }
      }

      if (style.getFill && style.getFill() && style.getFill().getColor()) {

        if (style.getFill().getColor().toHex) {
          fillColor = style.getFill().getColor().toHex();
          fillOpacity = style.getFill().getColor().toOpacity();
        } else if (Array.isArray(style.getFill().getColor())) {
          fillColor = olColorToHex(style.getFill().getColor());
          fillOpacity = style.getFill().getColor()[style.getFill().getColor().length - 1];
        }
      }

      if (style.getFill && style.getStroke()) {

        if (style.getStroke().getColor().toHex) {
          strokeColor = style.getStroke().getColor().toHex();
        } else if (Array.isArray(style.getStroke().getColor())) {
          strokeColor = olColorToHex(style.getStroke().getColor())
        }

        strokeWidth = style.getStroke().getWidth() || 3;
        strokeLinecap = style.getStroke().getLineCap() || "round";
        strokeDashstyle = style.getStroke().getLineDash() ?
          style.getStroke().getLineDash()[0] === 12 ?
            "dash" : "dot": "solid";
      }

      if (style.getImage && style.getImage()) {
        if (style.getImage() instanceof ol.style.Icon) {
          pointSrc = style.getImage().getSrc();
        }
        if (style.getImage() instanceof ol.style.Circle) {
          pointRadius = style.getImage().getRadius();
          pointFillColor = style.getImage().getFill().getColor().toHex();
        }
      }

      return {
        fillColor: fillColor,
        fillOpacity: fillOpacity,
        strokeColor: strokeColor,
        strokeOpacity: strokeOpacity,
        strokeWidth: strokeWidth,
        strokeLinecap: strokeLinecap,
        strokeDashstyle: strokeDashstyle,
        pointRadius: pointRadius,
        pointFillColor: pointFillColor,
        pointSrc: pointSrc,
        labelAlign: labelAlign,
        labelOutlineColor: labelOutlineColor,
        labelOutlineWidth: labelOutlineWidth,
        fontSize: fontSize,
        fontColor: fontColor,
        fontBackColor: fontBackColor
      }
    }

    function as2DPairs(coordinates, type) {
      switch (type) {
        case "Point":
          return [coordinates];
        case "LineString":
          return coordinates;
        case "Polygon":
          return coordinates[0];
        case "MultiPolygon":
          return coordinates[0][0];
        case "Circle":
          return [coordinates[0], coordinates[1]];
      }
    }

    function translateVector(features, layer) {

      function getText(feature) {

        var text = "";

        if (feature.getProperties() &&
          feature.getProperties().type === "Text") {
          if (feature.getProperties().description)
            text = feature.getProperties().description
          else if (feature.getProperties().name)
            text = feature.getProperties().name
          else
            text = ''
          return text
        }

        if (feature.getStyle &&
          Array.isArray(feature.getStyle()) &&
          feature.getStyle()[1] &&
          feature.getStyle()[1].getText() &&
          feature.getStyle()[1].getText().getText()) {
          text = feature.getStyle()[1].getText().getText();
        }

        if (feature.getStyle &&
            feature.getStyle() &&
            feature.getStyle().getText &&
            feature.getStyle().getText()) {
          text = feature.getStyle().getText().getText();
        }

        return text;
      }

      return {
        features: features.map(feature => {

          var type = feature.getGeometry().getType()
          ,   geom = feature.getGeometry()
          ,   holes = null
          ,   coords;

          if (!feature.getStyle() && layer) {
            let sourceStyle = layer.getSource().getStyle()(feature)[0];
            feature.setStyle(sourceStyle)
          }

          coords = type === "Circle"
          ? as2DPairs([geom.getCenter(), [geom.getRadius(), 0]], "Circle")
          : as2DPairs(geom.getCoordinates(), type);

          if (type === "MultiPolygon") {
            holes = geom.getCoordinates()[0].slice(1, geom.getCoordinates()[0].length);
          }

      return {
        type: type,
        attributes: {
          text: getText(feature),
          style: asObject(feature.getStyle())
        },
        coordinates: coords,
        holes: holes
      }
    })
    }
    }

    var layers
      ,   vectorLayers
      ,   imageVectorLayers
      ,   extent = this.previewLayer.getSource().getFeatures()[0].getGeometry().getExtent()
    ;

    layers = this.get('olMap').getLayers().getArray();

    vectorLayers = layers.filter(layer =>
      layer instanceof ol.layer.Vector &&
      layer.getVisible() &&
      layer.get('name') !== 'preview-layer' &&
      layer.get('name') !== 'search-selection-layer'
    );

    imageVectorLayers = layers.filter(layer =>
      layer instanceof ol.layer.Image &&
      layer.getSource() instanceof ol.source.ImageVector &&
      layer.getVisible()
    );

    vectorLayers = vectorLayers.map(layer =>
      translateVector(layer.getSource().getFeaturesInExtent(extent))
    ).filter(layer => layer.features.length > 0);

    imageVectorLayers = imageVectorLayers.map(layer => {

      return translateVector(layer.getSource().getSource().getFeaturesInExtent(extent), layer)

    }).filter(layer => layer.features.length > 0);

    return vectorLayers.concat(imageVectorLayers);
  },

  /**
   * Find WMTS layer to export in the map.
   * @instance
   * @return {object[]} wmts layers
   */
  findWMTS: function() {
    var layers = this.get('olMap').getLayers().getArray();
    return layers
        .filter(layer =>
      layer.getSource() instanceof ol.source.WMTS && layer.getVisible()
    )
    .map(layer => {
      var s = layer.getSource();
    return {
      url: s.get("url"),
      axisMode: s.get('axisMode')
    }
  });
  },

  /**
   * Find ArcGIS layer to export in the map.
   * @instance
   * @return {object[]} wmts layers
   */
  findArcGIS: function() {

    function getArcGISLayerContract(layer) {

      var url = layer.getSource().get('url')
        ,   extent = layer.get('extent') || []
        ,   layers = []
        ,   projection = layer.get('projection');

      if (typeof layer.getSource().getParams('params')['LAYERS'] === 'string') {
        layers = layer.getSource().getParams('params')['LAYERS'].replace('show:', '').split(',');
      }

      if (typeof projection === 'string') {
        projection = projection.replace('EPSG:', '');
      }

      return {
        url: url,
        layers: layers,
        spatialReference: projection,
        extent: {
          left: extent[0],
          bottom: extent[1],
          right: extent[2],
          top: extent[3]
        }
      }
    }

    function visibleArcGISLayer(layer) {
      return layer.getSource() instanceof ol.source.TileArcGISRest && layer.getVisible()
    }

    return this.get('olMap').getLayers().getArray()
      .filter(visibleArcGISLayer)
      .map(getArcGISLayerContract);
  },

  /**
   * Export the map
   * @instance
   * @param {function} callback
   * @param {object} size
   */
  exportMap: function(callback, size) {
    var map = this.get('olMap');
    map.once('postcompose', (event) => {
      var href
      ,   anchor
      ,   canvas
      ,   context
      ,   exportImage
    ;
    canvas = this.cloneCanvas(event.context.canvas, size);
    context = canvas.getContext('2d');
    context.textBaseline = 'bottom';
    context.font = '12px sans-serif';
    if (!size.x) {
      context.fillText(this.get('copyright'), 10, 25);
    }
    var img = new Image();
    img.src = this.generateScaleBar();
    img.onload = function() {
      context.drawImage(img, (size.x + 10) || 10, (size.y + size.height - 30) || (canvas.height - 30));
      href = canvas.toDataURL('image/png');
      href = href.split(';')[1].replace('base64,','');
      callback(href);
    }
  });
    map.renderSync();
  },

  /**
   * Export the map
   * @instance
   * @param {function} callback
   */
  exportImage: function(callback) {
    this.exportMap((href) => {
      $.ajax({
        url: this.get('url'),
        type: 'post',
        contentType: 'text/plain',
        data: 'image;' + encodeURIComponent(href),
        success: response => {
        var anchor = $('<a>Hämta</a>').attr({
          href: response,
          target: '_blank',
          download: 'karta.png'
        });
    callback(anchor);
  }
  });
  }, {});
  },

  exportHitsFormId: 13245,

  /**
   * Export the map as a PDF-file
   * @instance
   * @param {object} options
   * @param {function} callback
   */
  exportPDF: function(options, callback) {

    var extent = this.previewLayer.getSource().getFeatures()[0].getGeometry().getExtent()
      ,   left   = extent[0]
      ,   right  = extent[2]
      ,   bottom = extent[1]
      ,   top    = extent[3]
      ,   scale  = options.scale
      ,   dpi    = options.resolution
      ,   form   = document.createElement('form')
      ,   input  = document.createElement('input')
      ,   curr   = document.getElementById(this.exportHitsFormId)
      ,   url    = this.get('exportUrl')
      ,   data   = {
      wmsLayers: [],
      vectorLayers: [],
      size: null,
      resolution: options.resolution,
      bbox: null
    };

    data.vectorLayers = this.findVector() || [];
    data.wmsLayers = this.findWMS() || [];
    data.wmtsLayers = this.findWMTS() || [];
    data.arcgisLayers = this.findArcGIS() || [];

    dx = Math.abs(left - right);
    dy = Math.abs(bottom - top);

    data.size = [
      parseInt(options.size.width * dpi),
      parseInt(options.size.height * dpi)
    ];

    data.bbox = [left, right, bottom, top];
    data.orientation = options.orientation;
    data.format = options.format;
    data.scale = options.scale;
    data.proxyUrl = this.get('proxyUrl');

    this.set("downloadingPdf", true);
    var dataString = '';
    if (this.get('base64Encode')){ // base64 here
      dataString = btoa(JSON.stringify(data));
    } else {
      dataString = JSON.stringify(data);
    }
    $.ajax({
        url: url,
        method: "post",
        data: {
          json: dataString
        },
        format: "json",
        success: (url) => {
        this.set("downloadingPdf", false);
        this.set("urlPdf", url);
      },
      error: (err) => {
        this.set("downloadingPdf", false);
        alert("Ett eller flera av lagren du försöker skriva ut klarar inte de angivna inställningarna. Prova med en mindre pappersstorlek eller lägre upplösning.");
      }
    });

    callback();
  },

  resolutionToScale: function(dpi, resolution) {
    var inchesPerMeter = 39.37;
    return resolution * dpi * inchesPerMeter;
  },

  exportTIFF: function() {
    var extent = this.previewLayer.getSource().getFeatures()[0].getGeometry().getExtent()
      ,   left   = extent[0]
      ,   right  = extent[2]
      ,   bottom = extent[1]
      ,   top    = extent[3]
      ,   dpi    = (25.4 / 0.28)
      ,   scale  = this.resolutionToScale(dpi, this.get('olMap').getView().getResolution())
      ,   form   = document.createElement('form')
      ,   input  = document.createElement('input')
      ,   curr   = document.getElementById(this.exportHitsFormId)
      ,   url    = this.get('exportTiffUrl')
      ,   data   = {
      wmsLayers: [],
      vectorLayers: [],
      size: null,
      bbox: null
    };

    data.vectorLayers = this.findVector() || [];
    data.wmsLayers = this.findWMS() || [];
    data.wmtsLayers = this.findWMTS() || [];
    data.arcgisLayers = this.findArcGIS() || [];

    dx = Math.abs(left - right);
    dy = Math.abs(bottom - top);

    data.size = [
      parseInt(49.65 * (dx / scale) * dpi),
      parseInt(49.65 * (dy / scale) * dpi)
    ];

    data.resolution = 96;
    data.bbox = [left, right, bottom, top];
    data.orientation = "";
    data.format = "";
    data.scale = scale;
    data.proxyUrl = this.get('proxyUrl');
    this.set("downloadingTIFF", true);
    var dataString = '';
    if (this.get('base64Encode')){ // base64 here
      dataString = btoa(JSON.stringify(data));
    } else {
      dataString = JSON.stringify(data);
    }
    $.ajax({
        url: url,
        method: "post",
        data: {
          json: dataString
        },
        format: "json",
        success: (url) => {
        this.set("downloadingTIFF", false);
        this.set("urlTIFF", url);
      },
      error: (err) => {
        this.set("downloadingTIFF", false);
        alert("Ett eller flera av lagren du försöker skriva ut klarar inte de angivna inställningarna. Prova med en mindre pappersstorlek eller lägre upplösning.");
      }
    });
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }

};

/**
 * Eport model module.<br>
 * Use <code>require('models/export')</code> for instantiation.
 * @module ExportModel-module
 * @returns {ExportModel}
 */
module.exports = ToolModel.extend(ExportModel);
},{"models/transform":"models/transform","tools/tool":"tools/tool"}],"tools/infoclick":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');
var HighlightLayer = require('layers/highlightlayer');

var FeatureModel = Backbone.Model.extend({
  defaults:{
    feature: undefined,
    information: undefined,
    layer: undefined
  },

  initialize: function () {
    this.id = this.cid;
  }
});

var FeatureCollection = Backbone.Collection.extend({
  model: FeatureModel
});

/**
 * @typedef {Object} InfoClickModel~InfoClickModelProperties
 * @property {string} type - Default: infoclick
 * @property {string} panel - Default: InfoPanel
 * @property {boolean} visible - Default: false
 * @property {external:"ol.map"} map
 * @property {string} wmsCallbackName - Default: LoadWmsFeatureInfo
 * @property {external:"ol.feature"[]} features
 * @property {external:"ol.feature"} selectedFeature
 * @property {external:"ol.layer"} highlightLayer
 * @property {string} markerImg - Default: "assets/icons/marker.png"
 */
var InfoClickModelProperties = {
  type: 'infoclick',
  panel: 'InfoPanel',
  visible: false,
  map: undefined,
  wmsCallbackName: "LoadWmsFeatureInfo",
  features: undefined,
  selectedFeature: undefined,
  highlightLayer: undefined,
  markerImg: "assets/ico" +
  "ns/marker.png",
  anchor: [
    16,
    16
  ],
  imgSize: [
    32,
    32
  ],
  displayPopup: true,
  popupOffsetY: 0
};

/**
 * Prototype for creating an infoclick model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {InfoClickModel~InfoClickModelProperties} options - Default options
 */
var InfoClickModel = {
  /**
   * @instance
   * @property {InfoClickModel~InfoClickModelProperties} defaults - Default settings
   */
  defaults: InfoClickModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
    this.initialState = options;

    this.set('highlightLayer', new HighlightLayer({
      anchor: this.get('anchor'),
      imgSize: this.get('imgSize'),
      markerImg: this.get('markerImg')
    }));
    this.set("features", new FeatureCollection());
    this.get("features").on("add", (feature, collection) => {
      if (collection.length === 1) {
        this.set('selectedFeature', feature);
      }
    });
    this.on("change:selectedFeature", (sender, feature) => {
      setTimeout(() => {
        if (this.get('visible')) {
          this.highlightFeature(feature);
        }
      }, 0);
    });
  },

  configure: function (shell) {
    var map = shell.getMap().getMap();

    this.layerCollection = shell.getLayerCollection();
    this.map = map;
    this.map.on('singleclick', (event) => {
      try {
        setTimeout(a => {
          if (!map.get('clickLock') && !event.filty) {
            this.onMapPointer(event);
          }
        }, 0);
      } catch (e) {}
    });
    this.set('map', this.map);
    this.map.addLayer(this.get('highlightLayer').layer);
    $('#popup-closer').click(() => {
      this.clearHighlight();
    });
  },

  /**
   * Handle when users clicks anywhere in the map.
   * Support for WMS layers and vector layers.
   * @instance
   * @param {object} event - Mouse event
   */
  onMapPointer: function (event) {
    var wmsLayers = this.layerCollection.filter((layer) => {
          return (layer.get("type") === "wms" || layer.get("type") === "arcgis") &&
                 layer.get("queryable") &&
                 layer.getVisible();
        })
    ,   projection = this.map.getView().getProjection().getCode()
    ,   resolution = this.map.getView().getResolution()
    ,   infos = []
    ,   promises = []
    ;

    this.layerOrder = {};
    this.get("features").reset();

    this.map.getLayers().forEach((layer, i) => {
      this.layerOrder[layer.get('name')] = i;
    });

    this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
      if (layer && layer.get('name') && (layer.get('queryable') !== false)) {
        if (
          layer.get('name') !== 'preview-layer' &&
          layer.get('name') !== 'highlight-wms'
        ) {
          promises.push(new Promise((resolve, reject) => {
              features = [feature];
              _.each(features, (feature) => {
                  this.addInformation(feature, layer, (featureInfo) => {
                    if (featureInfo) {
                      infos.push(featureInfo);
                    }
                    resolve();
                  });
              });
          }));
        }
      }
    });

    wmsLayers.forEach((wmsLayer, index) => {
      wmsLayer.index = index;
      promises.push(new Promise((resolve, reject) => {
        wmsLayer.getFeatureInformation({
          coordinate: event.coordinate,
          resolution: resolution,
          projection: projection,
          error: message => {
            resolve();
          },
          success: (features, layer) => {
            if (Array.isArray(features) && features.length > 0) {
              features.forEach(feature => {
                this.addInformation(feature, wmsLayer, (featureInfo) => {
                  if (featureInfo) {
                    infos.push(featureInfo);
                  }
                  resolve();
                });
              });
            } else {
              resolve();
            }
          }
        });
      }));
    });

    this.set('loadFinished', false);

    Promise.all(promises).then(() => {
      infos.sort((a, b) => {
        var s1 = a.information.layerindex
        ,   s2 = b.information.layerindex
        ;
        return s1 === s2 ? 0 : s1 < s2 ? 1 : -1;
      });

      infos.forEach(info => {
        this.get('features').add(info);
      });

      this.set('loadFinished', true);
      if (this.get('displayPopup')) {
        this.togglePopup(infos, event.coordinate);
      } else {
        this.togglePanel()
      }

      if (infos.length === 0) {
        this.set('selectedFeature', undefined);
        this.get('map').getOverlayById('popup-0').setPosition(undefined);
        this.clearHighlight();
      }

    });
  },

  /**
   * Convert object to markdown
   * @instance
   * @param {object} object to transform
   * @return {string} markdown
   */
  objectAsMarkdown: function (o) {
    return Object
      .keys(o)
      .reduce((str, next, index, arr) =>
        /^geom$|^geometry$|^the_geom$/.test(arr[index]) ?
        str : str + `**${arr[index]}**: ${o[arr[index]]}\r`
      , "");
  },

  /**
   * Check if this device supports touch.
   * @instance
   */
  isTouchDevice: function () {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch(e) {
      return false;
    }
  },

  /**
   * Enable scroll on infowindow
   * @instance
   * @param {DOMelement} elm
   */
  enableScroll: function (elm) {
    if (this.isTouchDevice()){
      var scrollStartPos = 0;
      elm.addEventListener("touchstart", function(event) {
        scrollStartPos = this.scrollTop + event.touches[0].pageY;
      }, false);
      elm.addEventListener("touchmove", function(event) {
        this.scrollTop = scrollStartPos - event.touches[0].pageY;
      }, false);
    }
  },

  /**
   * Toogle popup
   * @instance
   * @param {array} infos
   * @param {array} coordinate
   */
  togglePopup: function(infos, coordinate) {

    const ovl = this.get('map').getOverlayById('popup-0');

    function isPoint (coord) {
      if (coord.length === 1) {
        coord = coord[0];
      }
      return (
        (coord.length === 2 ||  coord.length === 3) &&
        typeof coord[0] === "number" &&
        typeof coord[1] === "number"
      )
      ? [coord[0], coord[1]]
      : false;
    }

    infos.forEach((info, i) => {
        function display(index, inf) {

          var coords    = inf.feature.getGeometry() ? inf.feature.getGeometry().getCoordinates() : false
          ,   position  = coordinate
          ,   feature   = new Backbone.Model()
          ,   infobox   = $('<div></div>')
          ,   caption   = $(`<div class="popup-navigation"> ${index + 1} av ${infos.length} </div>`)
          ,   next      = $('<span class="fa fa-btn fa-arrow-circle-o-right"></span>')
          ,   prev      = $('<span class="fa fa-btn fa-arrow-circle-o-left"></span>')
          ,   title     = $(`<div class="popup-title">${inf.information.caption}</div>`)
          ,   content   = $(`<div id="popup-content-text"></div>`)
          ,   markdown  = ""
          ,   offsetY   = 0
          ,   html      = "";

          inf.layer.once('change:visible', () => {
            ovl.setPosition(undefined);
            this.clearHighlight();
          });

          if (typeof inf.information.information === "object") {
            markdown = this.objectAsMarkdown(inf.information.information);
          } else {
            markdown = inf.information.information;
          }
          html = marked(markdown, { sanitize: false, gfm: true, breaks: true });
          content.html(html);

          if (coords = isPoint(coords)) {
            position = coords;
          }

          caption.prepend(prev);
          caption.append(next);
          if (infos.length > 1) {
            infobox.append(caption);
          }

          infobox.append(title, content);
          $('#popup-content').show().html(infobox);

          if (this.isTouchDevice()) {
            this.enableScroll($('#popup-content-text')[0]);
            $('#popup-content-text').scrollTop(0);
          }

          if (isPoint(coords)) {
            offsetY = this.get('popupOffsetY');
          }

          ovl.setPosition(position);
          ovl.setOffset([0, offsetY]);

          $(ovl.getElement()).hide().fadeIn(0);

          Object.keys(inf).forEach(key => {
            feature.set(key, inf[key]);
          });
          this.highlightFeature(feature);

          prev.click(() => {
            if (infos[index - 1]) {
              display.call(this, index - 1, infos[index - 1]);
            }
          });
          next.click(() => {
            if (infos[index + 1]) {
              display.call(this, index + 1, infos[index + 1]);
            }
          });
        }
        if (i === 0) {
          display.call(this, i, info);
        }
    });
  },

  /**
   * Add feature to hit list.
   * @instance
   * @param {external:"ol.feature"} feature
   * @param {external:"ol.layer"} layer
   * @param {function} callback to invoke when information is added
   */
  addInformation: function (feature, layer, callback) {

    if (layer.get('name') === 'draw-layer') {
      callback(false);
      return;
    }

    var layerModel = this.layerCollection.findWhere({ name: layer.get("name") })
    ,   layerindex = -1
    ,   properties
    ,   information
    ,   iconUrl = feature.get('iconUrl') || ''
    ;
   
    properties = feature.getProperties();
    information = layerModel && layerModel.get("information") || "";

    if (feature.infobox) {
      information = feature.infobox;
      information = information.replace(/export:/g, '');
    }

    if (information && typeof information === "string") {
      (information.match(/\{.*?\}\s?/g) || []).forEach(property => {
          function lookup(o, s) {
            s = s.replace('{', '')
                 .replace('}', '')
                 .trim()
                 .split('.');

            switch (s.length) {
              case 1: return o[s[0]] || "";
              case 2: return o[s[0]][s[1]] || "";
              case 3: return o[s[0]][s[1]][s[2]] || "";
            }
          }
          information = information.replace(property, lookup(properties, property));
      });
    }

    if (!layerModel) {
      layerIndex = 999;
    } else {
      layerindex = this.layerOrder.hasOwnProperty(layerModel.getName())
        ? this.layerOrder[layerModel.getName()]
        : 999;
    }

    callback({
      feature: feature,
      layer: layer,
      information: {
          caption: layerModel && layerModel.getCaption() || "Sökträff",
          layerindex: layerindex,
          information: information || properties,
          iconUrl: iconUrl,
      }
    });
  },

  /**
   * Toggle the panel
   * @instance
   */
  togglePanel: function () {
    if (this.get("features").length > 0) {
      this.set('r', Math.round(Math.random() * 1E12));
      this.set('toggled', true);
      this.set('visible', true);
    } else if (this.get("navigation").get("activePanelType") === this.get("panel")) {
      this.set('visible', false);
    }
  },

  /**
   * Create and add feature to highlight layer.
   * @instance
   * @param {external:"ol.feature"} feature
   */
  createHighlightFeature: function (feature) {
    var layer = this.get('highlightLayer');
    layer.clearHighlight();
    this.reorderLayers(feature);
    layer.addHighlight(feature.get('feature').clone());
    layer.setSelectedLayer(feature.get('layer'));
  },

  /**
   * Adds the highlight layer at correct draw order in the map.
   * @instance
   * @param {external:"ol.feature"}
   */
  reorderLayers: function (feature) {

    var layerCollection = this.get('map').getLayers()
    ,   featureInfo = feature.get('information')
    ,   selectedLayer = feature.get('layer')
    ,   insertIndex;

    if (selectedLayer && this.layerOrder.hasOwnProperty(selectedLayer.get('name'))) {
      insertIndex = this.layerOrder[selectedLayer.get('name')];
      insertIndex += 1;
    }

    if (insertIndex) {
      layerCollection.remove(this.get('highlightLayer').getLayer());
      layerCollection.insertAt(insertIndex, this.get('highlightLayer').getLayer());
      insertIndex = undefined;
    }

  },

  /**
   * Highlight feature.
   * @instance
   * @param {external:"ol.feature"} feature
   */
  highlightFeature: function (feature) {
    if (feature) {
      this.createHighlightFeature(feature);
    } else {
      this.get('highlightLayer').clearHighlight();
    }
  },

  /**
   * Highlight feature.
   * @instance
   * @param {external:"ol.feature"} feature
   */
  clearHighlight: function () {
     this.get('highlightLayer').clearHighlight();
  }

};

/**
 * InfoClick model module.<br>s
 * Use <code>require('models/infoclick')</code> for instantiation.
 * @module InfoClickModel-module
 * @returns {InfoClickModel}
 */
module.exports = ToolModel.extend(InfoClickModel);

},{"layers/highlightlayer":"layers/highlightlayer","tools/tool":"tools/tool"}],"tools/information":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');
var InformationView = require('components/information');

/**
 * @typedef {Object} SearchModel~SearchModelProperties
 * @property {string} type - Default: search
 * @property {string} panel - Default: searchpanel
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-search icon
 * @property {string} title - Default: Sök i kartan
 * @property {string} visible - Default: false
 * @property {string} headerText - Default: 'Information om kartan.'
 * @property {string} text - Default: false
 */
var InformationModelProperties = {
  type: 'information',
  panel: '',
  toolbar: 'bottom',
  icon: 'fa fa-info-circle icon',
  title: 'Om kartan',
  display: false,
  headerText: 'Om kartan',
  text: 'Information om kartan.'
};

/**
 * Prototype for creating a search model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {SearchModel~InformationModelProperties} options - Default options
 */
var InformationModel = {
  /**
   * @instance
   * @property {SearchModel~InformationModelProperties} defaults - Default settings
   */
  defaults: InformationModelProperties,

  initialize: function (options) {
    var cookies = document.cookie;
    if(cookies.length == 0 || !options.showInfoOnce){
      // TODO: Titta efter om vi ska använda cookie för att visa informationsrutan endast en gång
      // OBS! json.showInfoOnce kan vara undefined, då ska det fungera som innan cookie användes
      if(options.showInfoOnce) {
        document.cookie = "seen=true";
      }
    } else {
      this.set({'display': false});
        this.set({'visibleAtStart': false});
    }
      ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {
    this.set({'display': this.get('visibleAtStart')});
    const element = React.createElement(InformationView, {model: this});
    ReactDOM.render(
      element,
      document.getElementById('information')
    );

  },
  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   which in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function () {    
    this.set({
      'display': !this.get('display')
    });
  }
};

/**
 * Search model module.<br>
 * Use <code>require('models/search')</code> for instantiation.
 * @module SearchModel-module
 * @returns {InformationModel}
 */
module.exports = ToolModel.extend(InformationModel);

},{"components/information":"components/information","tools/tool":"tools/tool"}],"tools/layerswitcher":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} LayerSwitcherModel~LayerSwitcherModelProperties
 * @property {string} type - Default: export
 * @property {string} panel - Default: exportpanel
 * @property {string} title - Default: Skriv ut
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-bars icon
 * @property {string} title - Default: Kartlager
 * @property {boolean} visible - Default: false
 * @property {LayerCollection} layerCollection - Default: undefined
 * @property {boolean} backgroundSwitcherMode - Default: hidden
 * @property {boolean} active - Default: false
 * @property {boolean} visibleAtStart - Default: true
 * @property {boolean} backgroundSwitcherBlack - Default: true
 * @property {boolean} backgroundSwitcherWhite - Default: true
 * @property {boolean} toggleAllButton - Default: false
 */
var LayerSwitcherModelProperties = {
  type: 'layerswitcher',
  panel: 'LayerPanel',
  toolbar: 'bottom',
  icon: 'fa fa-bars icon',
  title: 'Lagerhanterare',
  visible: false,
  layerCollection: undefined,
  backgroundSwitcherMode: 'hidden',
  active: true,
  visibleAtStart: true,
  backgroundSwitcherBlack: true,
  backgroundSwitcherWhite: true,
  toggleAllButton: true,
  dropdownThemeMaps : false,
  themeMapHeaderCaption : 'Temakarta'
};

/**
 * Prototype for creating a layerswitcher model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {LayerSwitcherModel~LayerSwitcherModelProperties} options - Default options
 */
var LayerSwitcherModel = {
  /**
   * @instance
   * @property {LayerSwitcherModel~LayerSwitcherModelProperties} defaults - Default settings
   */
  defaults: LayerSwitcherModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {
    this.set('layerCollection', shell.getLayerCollection());
    if (this.get('visibleAtStart') && document.body.scrollWidth >= 600) {
      this.set('visible', true);
    }
  },

  /**
   * Set checked group toggles property based on the layers visibility.
   * @instance
   * @param {object[]} groups
   */
  setExpanded: function recursive(groups) {
    groups.forEach(group => {
      if (!this.get("group_" + group.id)) {
        this.set("group_" + group.id, group.expanded ? "visible" : "hidden");
        if (group.hasOwnProperty('groups')) {
          recursive.call(this, group.groups);
        }
      }
    });
  },

  setThemeMap : function (configurationName, configurationTitle) {
    HAJK2.configFile = configurationName;
    HAJK2.configTitle = configurationTitle;
    HAJK2.start({
      configPath: HAJK2.configPath + configurationName,
      layersPath: HAJK2.layersPath
    }, function (status, message) {
      if (!status) {
        document.write(message);
      }
    });
  },


  loadThemeMaps: function (callback) {      
    $.ajax({
      url: HAJK2.configPath + "/userspecificmaps",
      method: 'GET',
      contentType: 'application/json',
      success: (data) => {        
        callback(data);
      },
      error: (message) => {
        callback(message);
      }
    });
  },

  /**
   * Set visibility for all layers to false.
   * @instance
   */
   toggleAllOff() {
     var baseLayers = this.getBaseLayers();
     this.get('layerCollection').forEach(layer => {
       var isBaseLayer = baseLayers.find(l => l.id === layer.id);
       if (!isBaseLayer) {
         layer.setVisible(false);
       }
     });
   },

  /**
   * Get base layers.
   * @instance
   * @return {Layer[]} base layers
   */
  getBaseLayers: function () {
    var baseLayers = [];
    this.get('baselayers').forEach(baseLayer => {
      var layer = this.get('layerCollection').find(layer => layer.id === baseLayer.id);
      if (layer) {
        baseLayers.push(layer);
      }
    });
    return baseLayers;
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Layer switcher model module.<br>
 * Use <code>require('models/layerswitcher')</code> for instantiation.
 * @module LayerSwitcherModel-module
 * @returns {LayerSwitcher}
 */
module.exports = ToolModel.extend(LayerSwitcherModel);

},{"tools/tool":"tools/tool"}],"tools/location":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} LocationModel~LocationModelProperties
 * @property {string} type - Default: search
 * @property {string} panel - Default: searchpanel
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-search icon
 * @property {string} title - Default: Sök i kartan
 * @property {string} visible - Default: false
 */
var LocationModelProperties = {
  type: 'location',
  Id: 'locationBtn',
  panel: '',
  toolbar: 'top-right',
  icon: 'fa fa-location-arrow icon',
  title: 'Visa min position',
  active: false,
  visible: false,
  location: {
    lat: undefined,
    lng: undefined
  }
};

/* Global variable */
positioning = undefined;
latitude = undefined;
longitude = undefined;
accuracy = undefined;


/**
 * Prototype for creating a search model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {LocationModel~LocationModelProperties} options - Default options
 */
var LocationModel = {
  /**
   * @instance
   * @property {LocationModel~LocationModelProperties} defaults - Default settings
   */
  defaults: LocationModelProperties,

  watchId: undefined,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);

    var style = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 0.8,
        src: 'assets/icons/gps.png',
        scale: (1/2)
      })
    });

    // this.set('accuracyFeature', new ol.Feature());
    var source = new ol.source.Vector({});
    // source.addFeature(this.get('accuracyFeature'));

    this.set("layer", new ol.layer.Vector({
      source: source,
      name: "location",
      style: style
    }));
  },

  getOptions: function () {
    return {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0
    };
  },

  configure: function (shell) {
    this.set('olMap', shell.getMap().getMap());
    this.get('olMap').addLayer(this.get('layer'));

    this.on('change:active', (e) => {
      if (!this.get('active') || this.get('watchId') !== undefined) {
      this.reset();
      } else {
        navigator.geolocation.getCurrentPosition(this.onLocationSucess.bind(this), this.onLocationError.bind(this));
      }
  });

    this.on('change:location', () => { this.setLocation() });

  },

  setLocation: function (coord) {
    this.get('layer').getSource().clear();
    if (this.get('location').lng && this.get('location').lat) {
      let point = new ol.geom.Point([
        this.get('location').lng,
        this.get('location').lat
      ]);
      let transformed = ol.proj.transform(point.getCoordinates(), "EPSG:4326", this.get('olMap').getView().getProjection());
      point.setCoordinates(transformed);
      this.get('layer').getSource().addFeature(
        new ol.Feature({
          geometry: point
        })
      );
      this.get('olMap').getView().setCenter(point.getCoordinates());
    }
  },

  reset: function() {
    this.set({
      location: {
        lat: undefined,
        lng: undefined
      }
    });
  },

  onLocationSucess: function(e) {
    this.set({
      location: {
        lat: e.coords.latitude,
        lng: e.coords.longitude,
        acc: e.coords.accuracy
      }
    });
    latitude = e.coords.latitude;
    longitude = e.coords.longitude;
    accuracy = e.coords.accuracy;
    positioning = true;
    /*
    var acc = window.navigator.geolocation.getAccuracyGeometry();
    if(acc != null) {
        this.get('accuracyFeature').setGeometry(acc);
    }
    */
  },

  onLocationError: function(e) {
    this.get('layer').getSource().clear();
    if(typeof this.get('location').lat == 'undefined') { // quick fix for the reoccuring errors in Firefox
      alert("Din position kan inte fastställas.");
      console.error(e);
      console.warn(e);
      console.info(e);
      this.reset();
    }
    positioning = false;
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function () {
    this.set({
      'visible': !this.get('visible'),
      'active': !this.get('active')
    });
  }
};

/**
 * Location model module.<br>
 * Use <code>require('models/information')</code> for instantiation.
 * @module LocationModel-module
 * @returns {LocationModel}
 */
module.exports = ToolModel.extend(LocationModel);


},{"tools/tool":"tools/tool"}],"tools/measure":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var ToolModel = require('tools/tool');
var source;
var olMap;

/**
 * @typedef {Object} MeasureModel~MeasureModelProperties
 * @property {string} type - Default: 'draw'
 * @property {string} panel - Default: 'DrawPanel'
 * @property {string} title - Default: 'Ritverktyg'
 * @property {string} toolbar - Default: 'bottom'
 * @property {string} visible - Default: false
 * @property {string} icon - Default: 'fa fa-pencil icon'
 * @property {string} drawLayerName - Default: 'measure-layer'
 * @property {external:"ol.layer"} drawLayer - Default: undefined
 * @property {object} drawTool - Default: undefined
 * @property {object} removeTool - Default: undefined
 * @property {external:"ol.map"} olMap - Default: undefined
 * @property {external:"ol.source"} source - Default: undefined
 * @property {boolean} showLabels - Default: false
 * @property {boolean} dialog - Default: false
 * @property {boolean} kmlImport - Default: false
 * @property {boolean} kmlExportUrl - Default: false
 * @property {string} fontSize - Default: 10px
 * @property {string} fontColor - Default: "rgb(255, 255, 255)"
 * @property {string} fontBackColor - Default: "rgb(0, 0, 0)"
 * @property {string} pointText - Default: "Text"
 * @property {string} pointColor - Default: "rgb(15, 175, 255)"
 * @property {number} pointRadius - Default: 7
 * @property {boolean} pointSymbol - Default: false
 * @property {string} markerImg - Default: "http://localhost/gbg/assets/icons/marker.png"
 * @property {string} lineColor - Default: "rgb(15, 175, 255)"
 * @property {number} lineWidth - Default: 3
 * @property {string} lineStyle - Default: "solid"
 * @property {string} circleFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} circleFillOpacity - Default: 0.5
 * @property {string} circleLineColor - Default: "rgb(15, 175, 255)"
 * @property {string} polygonLineColor - Default: "rgb(15, 175, 255)"
 * @property {number} polygonLineWidth - Default: 3
 * @property {string} polygonLineStyle - Default: "solid"
 * @property {string} polygonFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} polygonFillOpacity - Default: 0.5
 * @property {Array<{external:"ol.Style"}>} scetchStyle
 * @property {string} boxLineColor - Default: "rgb(15, 175, 255)"
 * @property {number} boxLineWidth - Default: 3
 * @property {string} boxLineStyle - Default: "solid"
 * @property {string} boxFillColor - Default: "rgb(255, 255, 255)"
 * @property {number} boxFillOpacity - Default: 0.5
 */
var MeasureModelProperties = {
  type: 'measure',
  panel: 'MeasurePanel',
  title: 'Mät',
  toolbar: 'bottom',
  visible: false,
  icon: 'custom-ruler icon',
  drawLayerName: 'measure-layer',
  drawLayer: undefined,
  drawTool: undefined,
  removeTool: undefined,
  olMap: undefined,
  source: undefined,
  showLabels: true,
  dialog: false,
  fontSize: "10",
  fontColor: "rgb(255, 255, 255)",
  fontBackColor: "rgb(0, 0, 0)",
  pointText: "Text",
  pointColor: "rgb(15, 175, 255)",
  pointSettings: "point",
  pointRadius: 7,
  pointSymbol: false,
  icons: "",
  instruction: "",
  markerImg: window.location.href + "assets/icons/marker.png",
  lineColor: "rgb(15, 175, 255)",
  lineWidth: 3,
  lineStyle: "solid",
  circleFillColor: "rgb(255, 255, 255)",
  circleLineColor: "rgb(15, 175, 255)",
  circleFillOpacity: 0.5,
  circleLineStyle: "solid",
  circleLineWidth: 3,
  polygonLineColor: "rgb(15, 175, 255)",
  polygonLineWidth: 3,
  polygonLineStyle: "solid",
  polygonFillColor: "rgb(255, 255, 255)",
  polygonFillOpacity: 0.5,
  base64Encode: false,
  boxFillColor: "rgb(255, 255, 255)",
  boxLineColor: "rgb(15, 175, 255)",
  boxFillOpacity: 0.5,
  boxLineStyle: "solid",
  boxLineWidth: 3,
  scetchStyle: [
    new ol.style.Style({
    fill: new ol.style.Fill({
      color: 'rgba(255, 255, 255, 0.5)'
    }),
    stroke: new ol.style.Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 4
    }),
    image: new ol.style.Circle({
      radius: 6,
      fill: new ol.style.Fill({
        color: 'rgba(0, 0, 0, 0.5)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(255, 255, 255, 0.5)',
        width: 2
      })
    })
  })]
}

/**
 * Prototype for creating an draw model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {MeasureModel~MeasureModelProperties} options - Default options
 */
var MeasureModel = {
  /**
   * @instance
   * @property {MeasureModel~MeasureModelProperties} defaults - Default settings
   */
  defaults: MeasureModelProperties,

  /**
   * @instance
   * @property {object} measureTooltipElement
   */
  measureTooltipElement: undefined,

  /**
   * @instance
   * @property {object} measureTooltip
   */
  measureTooltip: undefined,

  /**
   * @instance
   * @property {number} exportHitsFormId
   */
  exportHitsFormId: 12345,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);

    this.set('editOpenDialogBinded', null);
  },

  configure: function (shell) {
    source = new ol.source.Vector({ wrapX: false });
    olMap = shell.getMap().getMap();
    this.set('source', source);

    this.set('drawLayer', new ol.layer.Vector({
      source: this.get('source'),
      queryable: false,
      name: this.get('drawLayerName'),
      style: (feature) => this.getStyle(feature)
    }));

    this.set('olMap', olMap);
    this.get('olMap').addLayer(this.get('drawLayer'));
    this.set('drawLayer', this.get('drawLayer'));
    if (this.get('icons') !== "") {
      let icon = this.get('icons').split(',')[0];
      this.set('markerImg', window.location.href + "assets/icons/" + icon + ".png");
    }
    this.createMeasureTooltip();
  },


  editOpenDialog: function(event){
    this.get('olMap').forEachFeatureAtPixel(event.pixel, (feature) => {
      if (typeof feature.getProperties().description !== 'undefined'){
      feature.setStyle(this.get('scetchStyle'));
      this.set('dialog', true);
      this.set('drawFeature', feature);
      this.set('editing', true);
    }
  });
  },

  /**
   * Removes the selected feature from source.
   * @instance
   * @params {external:"ol.event.Event"} event
   */
  removeSelected: function (event) {
    var first = true;
    olMap.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (feature.getProperties().user === true && first) {
        source.removeFeature(feature);
      }
      first = false;
    });
  },

  /**
   * Activate tool for feature removal.
   * @instance
   */
  activateRemovalTool: function () {
    var dragInteraction = this.getDragInteraction();
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', true);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').on('singleclick', this.removeSelected);
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('measure-layer');
    }
  },


  /**
   * Activate tool for feature edit.
   * @instance
   */
  activateEditTool: function () {

    var dragInteraction = this.getDragInteraction()
    ,   revision = 1
    ,   features = new ol.Collection();

    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', true);
    this.set('drawToolActive', true);

    this.set('editOpenDialogBinded', this.editOpenDialog.bind(this));

    this.get('olMap').on('singleclick', this.get('editOpenDialogBinded'));

    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('measure-layer');
    }
    this.get('source').getFeatures().forEach(f => {
      features.push(f);
    });

    this.set("editTool", new ol.interaction.Modify({
      features: features
    }));

    this.get('olMap').addInteraction(this.get("editTool"));

    this.get("editTool").on('modifyend', e => {

      this.measureTooltip.setPosition(undefined);
    e.features.forEach(this.updateFeatureText.bind(this));
  });
  },

  /**
   * Update features text.
   * @instance
   */
  updateFeatureText: function (feature) {
    var labelText
    ,   style;
    this.setFeaturePropertiesFromGeometry(feature);

    labelText = this.getLabelText(feature);
    style = feature.getStyle()[1] || feature.getStyle()[0];

    if (style && style.getText() !== null) {
      style.getText().setText(labelText);
    }
  },

  /**
   * Get map´s first drag interaction, if any.
   * @instance
   */
  getDragInteraction: function () {
    return this.get('olMap')
      .getInteractions()
      .getArray()
      .filter(interaction =>
        interaction instanceof ol.interaction.Drag
      )[0];
  },

  /**
   * Activate drag intecation for draw layer.
   * @instance
   */
  activateMoveTool: function () {
    this.get('olMap').removeInteraction(this.get("drawTool"));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.set('drawToolActive', false);
    var dragInteraction = this.getDragInteraction();
    if (dragInteraction) {
      dragInteraction.addAcceptedLayer('measure-layer');
    }
  },

  /**
   * Remove the last edited feature from soruce.
   * @instance
   */
  removeEditFeature: function() {
    if (!this.get('editing') && this.get("drawFeature") && (typeof this.get("drawFeature").getProperties().description === "undefined"
    || this.get("drawFeature").getProperties().description === "")) {
      this.get('source').removeFeature(this.get('drawFeature'));
    } else if(this.get('editing')){
      var feature = this.get("drawFeature");
      this.set('pointText', feature.getProperties().description);
      this.setFeaturePropertiesFromText(feature, feature.getProperties().description || "");
      feature.setStyle(this.getStyle(feature));
    }
  },

  controlDoubleClickZoom: function(active){
    var interactions = this.get('olMap').getInteractions().getArray();
    var dblClickZoom = interactions.find(interaction => interaction instanceof ol.interaction.DoubleClickZoom);
    dblClickZoom.setActive(active);
  },

  /**
   * Event handler to excecute after features are drawn.
   * @params: {external:"ol.feature"} type
   * @params: {string} type
   * @instance
   */
  handleDrawEnd: function (feature, type) {
    if (type === "Text") {
      feature.setStyle(this.get('scetchStyle'));
      this.set('dialog', true);
      this.set('editing', false);
      this.set('drawFeature', feature);
    } else {
      this.setFeaturePropertiesFromGeometry(feature);
      feature.setStyle(this.getStyle(feature));
    }
    this.controlDoubleClickZoom(false);

    this.abort();
    this.measureTooltip.setPosition(undefined);

    setTimeout(() => this.controlDoubleClickZoom(true),251); 
  },

  /**
   * Event handler to excecute when the users starts to draw.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  handleDrawStart: function(e, geometryType) {

    var circleRadius = parseFloat(this.get('circleRadius'));

    if (!isNaN(circleRadius) && geometryType === "Circle") {
      this.get("drawTool").finishDrawing();
      let f = new ol.Feature({
        geometry: new ol.geom.Circle(e.feature.getGeometry().getCenter(), circleRadius)
      });
      this.get('source').removeFeature(e.feature);
      this.get('source').addFeature(f);
      this.handleDrawEnd(f);
    }

    e.feature.getGeometry().on('change', e => {
      var toolTip = ""
      ,   coord = undefined
      ,   pointerCoord;

      if (this.get("drawToolActive")) {

        if (this.get('pointerPosition')) {
          pointerCoord = this.get('pointerPosition').coordinate;
        }

        if (e.target instanceof ol.geom.LineString) {
          toolTip = this.formatLabel("length", e.target.getLength());
          coord = e.target.getLastCoordinate()
        }

        if (e.target instanceof ol.geom.Polygon) {
          toolTip = this.formatLabel("area", e.target.getArea());
          coord = pointerCoord || e.target.getFirstCoordinate();
        }

        if (e.target instanceof ol.geom.Circle) {
          toolTip = this.formatLabel("length", e.target.getRadius());
          coord = pointerCoord;
        }

        this.measureTooltipElement.innerHTML = toolTip;
        if (this.get('showLabels') && coord) {
          this.measureTooltip.setPosition(coord);
        }
      }
    });

  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  createMeasureTooltip: function() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip-draw tooltip-measure';
    this.measureTooltip = new ol.Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });
    this.get('olMap').addOverlay(this.measureTooltip);
  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  formatLabel: function(type, value) {

    if (type === "point") {
      label ="Nord: " + value[0] + " Öst: " + value[1];
    }

    if (typeof value === "number") {
      value = Math.round(value);
    }

    if (type === "circle") {
      let prefix = " m";
      let prefixSq = " m²";
      if (value >= 1E3) {
        prefix = " km";
        value = value / 1E3;
      }
      label = (
        "R = " + value + prefix +
        " \nA = " + (Math.round((value * value * Math.PI) * 1E3) / 1E3) + prefixSq
      );
    }

    if (type === "area") {
      let prefix = " m²";
      if (value >= 1E6) {
        prefix = " km²";
        value = Math.round((value / 1E6) * 1E3) / 1E3;
      }
      label = value + prefix;
    }

    if (type === "length") {
      let prefix = " m";
      if (value >= 1E3) {
        prefix = " km";
        value = value / 1E3;
      }
      label = value + prefix;
    }

    return label;
  },

  /**
   * Create draw interaction and add to map.
   * @param {extern:"ol.geom.GeometryType"} type
   * @instance
   */
  activateDrawTool: function (type) {
    var style = undefined
    ,   drawTool = undefined
    ,   geometryType = undefined
    ,   dragInteraction = this.getDragInteraction()
    ,   olMap = this.get('olMap')
    ,   geometryFunction = undefined
    ,   geometryName = undefined;

    olMap.un('singleclick', this.removeSelected);
    olMap.un('singleclick', this.get('editOpenDialogBinded'));
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('measure-layer');
    }
    olMap.removeInteraction(this.get("drawTool"));
    olMap.removeInteraction(this.get("editTool"));
    this.measureTooltip.setPosition(undefined);

    if (type === "Box") {
      type = "Circle";
      geometryName = "Box";
      geometryFunction = ol.interaction.Draw.createBox();
    } else {
      geometryName = type;
    }

    geometryType = type !== "Text" ? type : "Point";

    drawTool = new ol.interaction.Draw({
      source: this.get('source'),
      style: this.get('scetchStyle'),
      type: geometryType,
      geometryFunction: geometryFunction,
      geometryName: geometryName
    });

    olMap.on('pointermove', this.setPointerPosition.bind(this));

    drawTool.on('drawstart', e => {
      this.handleDrawStart(e, geometryType);
    });

    drawTool.on('drawend', (event) => {
      this.handleDrawEnd(event.feature, type)
    });

    this.set('drawTool', drawTool);
    olMap.addInteraction(this.get('drawTool'));
    olMap.set('clickLock', true);
    this.set('drawToolActive', true);
  },

  /**
   * Remove all interactions from the map.
   * @instance
   */
  abort: function () {
    var dragInteraction = this.getDragInteraction();
    this.get('olMap').un('singleclick', this.removeSelected);
    this.get('olMap').un('singleclick', this.get('editOpenDialogBinded'));
    this.get('olMap').un('pointermove', this.setPointerPosition);
    this.get('olMap').removeInteraction(this.get('drawTool'));
    this.get('olMap').removeInteraction(this.get("editTool"));
    this.get('olMap').set('clickLock', false);
    this.set('drawToolActive', false);
    if (dragInteraction) {
      dragInteraction.removeAcceptedLayer('measure-layer');
    }
    $('.measure-tool-item').removeClass('selected');
  },

  /**
   * Clear the source from features.
   * @instance
   */
  clear: function () {
    this.get('source').clear();
  },

  /**
   * Extract style info from ol Style object.
   * @instance
   * @param {external:"ol.style.Style"} style
   * @return {object} style
   */
  extractStyle: function (style) {

    var obj = {
      text: "",
      image: "",
      pointRadius: 0,
      pointColor: "",
      fillColor: "",
      strokeColor: "",
      strokeWidth: "",
      strokeDash: ""
    };

    obj.text = style.getText() ? style.getText().getText() : "";
    obj.image = style.getImage() instanceof ol.style.Icon ? style.getImage().getSrc() : "";
    obj.pointRadius = style.getImage() instanceof ol.style.Circle ? style.getImage().getRadius() : "";
    obj.pointColor = style.getImage() instanceof ol.style.Circle ? style.getImage().getFill().getColor() : "";
    obj.fillColor = style.getFill().getColor();
    obj.strokeColor = style.getStroke().getColor();
    obj.strokeWidth = style.getStroke().getWidth();
    obj.strokeDash = style.getStroke().getLineDash();

    return obj;
  },

  /**
   * Checks if a proxy url is set.
   * @instance
   * @param {string} url
   * @return {string} url
   */
  validateProxyUrl: function (url) {
    if (this.get('proxyUrl')) {
      return this.get('proxyUrl') + url.substr(url.indexOf("/Temp/"));
    } else {
      return url;
    }
  },

  /**
   * Set the features style from based upon its properties.
   * @param {external:"ol.feature"}
   * @instance
   */
  setStyleFromProperties: function (feature) {
    if (feature.getProperties().style) {
      try {
        let style = JSON.parse(feature.getProperties().style);
        if (style.text) {
          this.setFeaturePropertiesFromText(feature);
          if (style.pointRadius > 0) {
            this.setFeaturePropertiesFromGeometry(feature);
          }
        } else {
          this.setFeaturePropertiesFromGeometry(feature);
        }
        feature.setStyle(this.getStyle(feature, style));
      } catch (ex) {
        console.error("Style attribute could not be parsed.", ex)
      }
    } else {
      //https://github.com/openlayers/openlayers/issues/3262
      let func = feature.getStyleFunction();
      if (func) {
        let style = func.call(feature, this.get('olMap').getView().getResolution());
        if (style[0] && style[0].getFill && style[0].getFill() === null) {
          style[0].setFill(new ol.style.Fill({
            color: [0, 0, 0, 0]
          }));
        }
        feature.setStyle(style);
      }
    }
  },

  /**
   * Calculate extent of given features
   * @instance
   * @param {array} features
   * @return {external:ol.Extent} extent
   */
  calculateExtent(features) {
    var x = [];
    features.forEach((feature, i) => {
      var e = feature.getGeometry().getExtent(); // l b r t
      if (i === 0) {
        x = e;
      } else {
        let t = 0;
        for (;t < 4; t++) {
          if (t < 2) {
            if (x[t] > e[t]) {
              x[t] = e[t];
            }
          } else {
            if (x[t] < e[t]) {
              x[t] = e[t];
            }
          }
        }
      }
    });
    return x.every(c => c) ? x : false;
  },
  
  /**
   * Get styles array.
   * @instance
   * @param {external:"ol.feature"} feature
   * @param {boolean} forcedProperties - Force certain properties to be taken directly from the feature.
   * @return {Array<{external:"ol.style"}>} style
   *
   */
  getStyle: function(feature, forcedProperties) {
    var geometryName = feature.getGeometryName();

    function getLineDash() {
        var scale = (a, f) => a.map(b => f * b)
        ,   width = lookupWidth.call(this)
        ,   style = lookupStyle.call(this)
        ,   dash  = [12, 7]
        ,   dot   = [2, 7]
        ;
        switch (style) {
          case "dash":
            return width > 3 ? scale(dash, 2) : dash;
          case "dot":
            return width > 3 ? scale(dot, 2) : dot;
          default :
            return undefined;
        }
    }

    function getFill() {

      function rgba() {
        switch(geometryName) {
          case "Circle":
            return this.get('circleFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('circleFillOpacity')})`)

          case "Polygon":
            return this.get('polygonFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('polygonFillOpacity')})`)

          case "Box":
            return this.get('boxFillColor')
                   .replace('rgb', 'rgba')
                   .replace(')', `, ${this.get('boxFillOpacity')})`);
        }
      }

      var color = forcedProperties ? forcedProperties.fillColor : rgba.call(this);
      var fill = new ol.style.Fill({
        color: color
      });

      return fill;
    }

    function lookupStyle() {
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineStyle');
        case "Circle":
          return this.get('circleLineStyle');
        case "Box":
          return this.get('boxLineStyle');
        default:
          return this.get('lineStyle');
      }
    }

    function lookupWidth() {
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineWidth');
        case "Circle":
          return this.get('circleLineWidth');
        case "Box":
          return this.get('boxLineWidth');
        default:
          return this.get('lineWidth');
      }
    }

    function lookupColor() {
      if (forcedProperties) {
        return forcedProperties.strokeColor;
      }
      switch (geometryName) {
        case "Polygon":
          return this.get('polygonLineColor');
        case "Circle":
          return this.get('circleLineColor');
        case "Box":
          return this.get('boxLineColor');
        default:
          return this.get('lineColor');
      }
    }

    function getStroke() {

      var color = forcedProperties ?
                  forcedProperties.strokeColor :
                  lookupColor.call(this);

      var width = forcedProperties ?
                  forcedProperties.strokeWidth :
                  lookupWidth.call(this);

      var lineDash = forcedProperties ?
                     forcedProperties.strokeDash :
                     getLineDash.call(this);

      var stroke =  new ol.style.Stroke({
        color: color,
        width: width,
        lineDash: lineDash
      })

      return stroke;
    }

    function getImage() {

      var radius = type === "Text" ? 0 : forcedProperties ? forcedProperties.pointRadius : this.get('pointRadius');
      var iconSrc = forcedProperties ? (forcedProperties.image || this.get('markerImg')) : this.get('markerImg');

      var icon = new ol.style.Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: iconSrc
      });

      var dot = new ol.style.Circle({
        radius: radius,
        fill: new ol.style.Fill({
          color: forcedProperties ? forcedProperties.pointColor : this.get('pointColor')
        }),
        stroke: new ol.style.Stroke({
          color: 'rgb(255, 255, 255)',
          width: 2
        })
      });

      if (forcedProperties) {
        if (forcedProperties.image) {
          return icon;
        } else {
          return dot;
        }
      }

      if (this.get('pointSymbol') && type !== 'Text') {
        return icon;
      } else {
        return dot;
      }
    }

    function getText() {

      var offsetY = () => {
        var offset = -15;

        if (this.get('pointSymbol'))
          offset = -40;

        if (type === "Text")
          offset = 0;

        return offset;
      }

      return new ol.style.Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: `${this.get('fontSize')}px sans-serif`,
        text: forcedProperties ? forcedProperties.text : this.getLabelText(feature),
        fill: new ol.style.Fill({color: this.get('fontColor')}),
        stroke: new ol.style.Stroke({color: this.get('fontBackColor'), width: 3}),
        offsetX: type === "Text" ? 0 : 10,
        offsetY: offsetY(),
        rotation: 0,
        scale: 1.4
      });
    }

    var type = feature.getProperties().type;

    return [
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'rgba(255, 255, 255, 0.5)',
          width: type === 'Polygon' ?
                   this.get('polygonLineWidth') + 2 :
                   this.get('lineWidth') + 2
        })
      }),
      new ol.style.Style({
        fill: getFill.call(this),
        stroke: getStroke.call(this),
        image: getImage.call(this),
        text: getText.call(this)
      })
    ]
  },

  /**
   * Generate feature label text from properties.
   * @instance
   * @param {external:"ol.feature"} feature
   * @return {string} label
   *
   */
  getLabelText: function (feature) {
    var show  = this.get('showLabels')
    ,   props = feature.getProperties()
    ,   type  = feature.getProperties().type;

    if (typeof props.description !== 'undefined'){
      type = "Text";
    }

    switch (type) {
      case "Point": return show ? this.formatLabel("point", [props.position.n, props.position.e]) : "";
      case "LineString": return show ? this.formatLabel("length", props.length): "";
      case "Polygon": return show ? this.formatLabel("area", props.area) : "";
      case "Circle": return show ? this.formatLabel("circle", props.radius): "";
      case "Text": return props.description;
      case "Box": return show ? this.formatLabel("area", props.area) : "";
      default: return "";
    }
  },

  /**
   * Set the property wich will show/hide labels and update the source.
   * @instance
   * @return {boolean} showLabels
   */
  toggleLabels: function () {

    this.set('showLabels', !this.get('showLabels'));
    this.get('source').changed();

    source.forEachFeature(feature => {
      if (feature.getProperties().type !== "Text" && typeof feature.getProperties().description === "undefined" && feature.getStyle()) {
        let style = feature.getStyle();
        if (this.get('showLabels')) {
          if (style[1]) {
            style[1].getText().setText(this.getLabelText(feature));
          } else if (style[0]) {
            style[0].getText().setText(this.getLabelText(feature));
          }
        } else {
          if (style[1]) {
            style[1].getText().setText("");
          } else if (style[0]) {
            style[0].getText().setText("");
          }
        }
      } else if (feature.getProperties().type === "Text" || typeof feature.getProperties().description !== "undefined"){
        let style = feature.getStyle();
        if (style[1]) {
          style[1].getText().setText(this.getLabelText(feature));
        } else if (style[0]) {
          style[0].getText().setText(this.getLabelText(feature));
        }
      }
    });

    return this.get('showLabels');
  },

  /**
   * Update any feature with property to identify feature as text feature.
   * @instance
   * @params {external:"ol.feature"} feature
   * @params {string} text
   */
  setFeaturePropertiesFromText: function (feature, text) {
    if (!feature) return;
    feature.setProperties({
      type: "Text",
      user: true,
      description: text
    });
  },

  /**
   * Update any feature with properties from its own geometry.
   * @instance
   * @params {external:"ol.feature"} feature
   */
  setFeaturePropertiesFromGeometry: function (feature) {
    if (!feature) return;
    var geom
    ,   type = ""
    ,   lenght = 0
    ,   radius = 0
    ,   area = 0
    ,   position = {
          n: 0,
          e: 0
        }
    ;
    geom = feature.getGeometry();
    type = geom.getType();
    switch (type) {
      case "Point":
        position = {
          n: Math.round(geom.getCoordinates()[1]),
          e: Math.round(geom.getCoordinates()[0])
        };
        break;
      case "LineString" :
        length = Math.round(geom.getLength());
        break;
      case "Polygon":
        area = Math.round(geom.getArea());
        break;
      case "Circle":
        radius = Math.round(geom.getRadius());
        break;
      default:
        break;
    }
    feature.setProperties({
      type: type,
      user: true,
      length: length,
      area: area,
      radius: radius,
      position: position
    });
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  },

  /**
   * Set the property pointColor
   * @param {string} color
   * @instance
   */
  setCircleRadius: function (radius) {
    this.set("circleRadius", radius);
  },

  /**
   * Set the property pointSettings
   * @param {string} color
   * @instance
   */
  setPointSettings: function (value) {
    this.set("pointSettings", value);
  },

  /**
   * Set the property pointColor
   * @param {string} color
   * @instance
   */
  setPointColor: function (color) {
    this.set("pointColor", color);
  },
  /**
   * Set the property pointRadius
   * @param {number} radius
   * @instance
   */
  setPointRadius: function (radius) {
    this.set("pointRadius", radius);
  },

  /**
   * Set the property lineWidth
   * @param {number} width
   * @instance
   */
  setLineWidth: function (width) {
    this.set("lineWidth", width);
  },

  /**
   * Set the property lineColor
   * @param {string} color
   * @instance
   */
  setLineColor: function (color) {
    this.set("lineColor", color);
  },

  /**
   * Set the property lineStyle
   * @param {string} style
   * @instance
   */
  setLineStyle: function (style) {
    this.set("lineStyle", style);
  },

  /**
   * Set the property polygonLineStyle
   * @param {string} style
   * @instance
   */
  setPolygonLineStyle: function (style) {
    this.set("polygonLineStyle", style);
  },

  /**
   * Set the property polygonFillOpacity
   * @param {number} opacity
   * @instance
   */
  setPolygonFillOpacity: function (opacity) {
    this.set("polygonFillOpacity", opacity);
  },

  /**
   * Set the property polygonLineWidth
   * @param {number} width
   * @instance
   */
  setPolygonLineWidth: function (width) {
    this.set("polygonLineWidth", width);
  },

  /**
   * Set the property polygonLineColor
   * @param {string} color
   * @instance
   */
  setPolygonLineColor: function (color) {
    this.set("polygonLineColor", color);
  },

  /**
   * Set the property polygonFillColor
   * @param {string} color
   * @instance
   */
  setPolygonFillColor: function (color) {
    this.set("polygonFillColor", color);
  },

  /**
   * Set the property circleFillColor
   * @param {string} color
   * @instance
   */
  setCircleFillColor: function (color) {
    this.set("circleFillColor", color);
  },

  /**
   * Set the property circleFillOpacity
   * @param {number} opacity
   * @instance
   */
  setCircleFillOpacity: function (opacity) {
    this.set("circleFillOpacity", opacity);
  },

  /**
   * Set the property circleLineColor
   * @param {string} color
   * @instance
   */
  setCircleLineColor: function (color) {
    this.set("circleLineColor", color);
  },

  /**
   * Set the property circleLineStyle
   * @param {string} style
   * @instance
   */
  setCircleLineStyle: function (style) {
    this.set("circleLineStyle", style);
  },

  /**
   * Set the property circleLineWidth
   * @param {number} width
   * @instance
   */
  setCircleLineWidth: function (width) {
    this.set("circleLineWidth", width);
  },

  /**
   * Set the property boxFillColor
   * @param {string} color
   * @instance
   */
  setBoxFillColor: function (color) {
    this.set("boxFillColor", color);
  },

  /**
   * Set the property boxFillOpacity
   * @param {number} opacity
   * @instance
   */
  setBoxFillOpacity: function (opacity) {
    this.set("boxFillOpacity", opacity);
  },

  /**
   * Set the property boxLineColor
   * @param {string} color
   * @instance
   */
  setBoxLineColor: function (color) {
    this.set("boxLineColor", color);
  },

  /**
   * Set the property boxLineStyle
   * @param {string} style
   * @instance
   */
  setBoxLineStyle: function (style) {
    this.set("boxLineStyle", style);
  },

  /**
   * Set the property boxLineWidth
   * @param {number} width
   * @instance
   */
  setBoxLineWidth: function (width) {
    this.set("boxLineWidth", width);
  },

  /**
   * Set the property pointSymbol
   * @param {string} value
   * @instance
   */
  setPointSymbol: function(value) {
    this.set('pointSymbol', value);
  },

  /**
   * Set the property pointSymbol
   * @param {string} value
   * @instance
   */
  setFontSize: function(value) {
    this.set('fontSize', value);
  },

  /**
   * Set the property fontColor
   * @param {string} value
   * @instance
   */
  setFontColor: function(value) {
    this.set('fontColor', value);
  },

  /**
   * Set the property fontBackColor
   * @param {string} value
   * @instance
   */
  setFontBackColor: function(value) {
    this.set('fontBackColor', value);
  },

  /**
   * Set the point text
   * @param {string} text
   * @instance
   */
  setPointText: function(text) {
    var feature = this.get('drawFeature');
    this.set('pointText', text);
    this.setFeaturePropertiesFromText(feature, text || "");
    feature.setStyle(this.getStyle(feature));
  },

  /**
   * Set pointer position
   * @param {object} event
   * @instance
   */
  setPointerPosition: function(e) {
    this.set('pointerPosition', e);
  }
};

/**
 * Draw model module.<br>
 * Use <code>require('models/draw')</code> for instantiation.
 * @module MeasureModel-module
 * @returns {MeasureModel}
 */
module.exports = ToolModel.extend(MeasureModel);

},{"tools/tool":"tools/tool"}],"tools/preset":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/Johkar/Hajk2

var ToolModel = require('tools/tool');

/**
 * @typedef {Object} PresetModel~PresetModelProperties
 * @property {string} type -Default: preset
 * @property {string} panel -Default: presetpanel
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-link icon fa-flip-horizontal
 * @property {string} title -Default: Länk
 * @property {boolean} visible - Default: false
 * @property {ShellModel} shell
 * @property {string} anchor - Default: ''
 * @property {string} preset - Default: ''
 */
var PresetModelProperties = {
  type: 'preset',
  panel: 'presetpanel',
  toolbar: 'bottom',
  icon: 'fa fa-bookmark icon',
  title: 'Snabbval',
  visible: false,
  shell: undefined,
  anchor: "",
  presetValue: "",
  instruction: ""
}

/**
 * @description
 *
 *  Prototype for creating an preset model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {PresetModel~PresetModelProperties} options - Default options
 */
var PresetModel = {
  /**
   * @instance
   * @property {PresetModel~PresetModelProperties} defaults - Default settings
   */
  defaults: PresetModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {

    this.set('map', shell.getMap());
    this.set('layers', shell.getLayerCollection());
    this.set(
      'layerswitcher',
      shell.getToolCollection()
           .find(tool =>
              tool.get('type') === 'layerswitcher'
            )
    );
  },

  /**
   * Generate an anchor string which represents the current state of the map.
   * @instance
   * @return {string} anchor
   */
  generate: function () {

    var a = document.location.protocol + "//" + document.location.host + document.location.pathname
    ,   map = this.get("map")
    ,   olMap = map.getMap()
    ,   layers = this.get("layers")

    ,   c = olMap.getView().getCenter()
    ,   z = olMap.getView().getZoom()
    ,   x = c[0]
    ,   y = c[1]
    ,   l = layers.filter(layer => layer.getVisible() === true)
                  .map(layer => encodeURIComponent(layer.getName())).join(',');

    a += `?m=${HAJK2.configFile}&x=${x}&y=${y}&z=${z}&l=${l}`;
    this.set("anchor", a);
    this.set("presetName", this.get('presetList')[0].name);
    this.set("presetUrl", this.get('presetList')[0].presetUrl);

    return a;
  },

  /**
   * Add and save a new preset.
   * @instance
   * @param {string} name - Name of the preset.
   * @param {function} callback - Fn to be called when the save is complete.
   */
  addPreset: function (name, callback) {

    var preset = this.generate();

    this.set("presetValue", preset);

    this.updatePreset(preset);
  },

  /**
   * Update preset.
   *
   *
   *
   */
  updatePreset: function(preset, callback) {
    $.ajax({
      url: `${this.get('config').url_layermenu_settings}?mapFile=${this.get('mapFile')}.json`,
      method: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(preset),
      success: () => {
        callback(true);
      },
      error: () => {
        callback(false);
      }
    });
  },

  /**
   * Get URL.
   *
   *
   *
   */
  getUrl: function () {
    var a = document.location.protocol + "//" + document.location.host + document.location.pathname;
    
    return a;
  },

  /**
   * Get presets from config.
   * @instance
   * @return {object[]} presets
   */
  getPresets: function () {
    return this.get('presetList');
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function () {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Preset model module.<br>
 * Use <code>require('models/preset')</code> for instantiation.
 * @module PresetModel-module
 * @returns {PresetModel}
 */
module.exports = ToolModel.extend(PresetModel);

},{"tools/tool":"tools/tool"}],"tools/routing":[function(require,module,exports){
/**
 * Created by hiwe001 on 2017-07-04.
 */
/**
 * Created by hiwe001 on 2017-05-24.
 */
var ToolModel = require('tools/tool');

/**
 * @typedef {Object} LocationModel~LocationModelProperties
 * @property {string} type - Default: search
 * @property {string} panel - Default: searchpanel
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-search icon
 * @property {string} title - Default: Sök i kartan
 * @property {string} visible - Default: false
 */
var RoutingModelProperties = {
  type: 'routing',
  panel: 'routingpanel',
  toolbar: 'bottom',
  icon: 'fa fa-level-up icon',
  title: 'Navigation',
  visible: false,
  Id: 'LocationB',
  state: 'choose_start', // will change to choose_end and choose_mode
  onStartKey: undefined,
  onEndKey: undefined,
  onRoutingKey: undefined,
  routingFinished: undefined,
  apiKey: '',
  travelMode: 'walking',
  travelModeSwe: 'Gå',
  position: {
    latitude: undefined,
    longitude: undefined,
    latitudeEnd: undefined,
    longitudeEnd: undefined
  },
  instruction: ''
};

/**
 * Prototype for creating a search model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {LocationModel~LocationModelProperties} options - Default options
 */
startPoint = undefined;

var RoutingModel = {

  defaults: RoutingModelProperties,
  /**
   * @instance
   * @property {RoutingModel~RoutingModelProperties} defaults - Default settings
   */

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  /* Starting Point */
  /* Get a current position from GPS(button right top)*/
  turnOnGPSClicked: function() {
      this.getLocation();
  },

  getLocation: function(){
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(this.setPositionEvent.bind(this));
    }else{
      alert("kan inte få position. Skriv startposition i rutan eller tryck position på kartan.");
    }

  },


  positionError: function(error){
    /* reset this location setting */
    this.set({
      position: {
        latitude: undefined,
        longitude: undefined
      }
    });
  },

  /* Choose a starting location on the map manually. and drop a pin */
  startPointSelection: function(event){
    var startPoint = new ol.Feature(); /* startPoint and point(below) must be the same l.134*/
    startPoint.setGeometry(new ol.geom.Point(event.coordinate));
  /* Convert Geometry to Coordinate */

    var lonlat = ol.proj.transform(startPoint.getGeometry().getCoordinates(), 'EPSG:3007', 'EPSG:4326');
    var lon = lonlat[0];
    var lat = lonlat[1];

    this.get('layer_start').getSource().clear();
    this.get('layer_start').getSource().addFeature(startPoint);
    startPoint.setStyle(style_start);

    var pos = this.get('position');
    pos.latitude = lat;
    pos.longitude = lon;
    this.set('position', pos);

   },

  setTravelMode: function(travelmode){
    switch(travelmode){
      case "walking":
        travelModeSwe = "Gå";
        break;
      case "driving":
        travelModeSwe = "Köra";
        break;
      case "bicycling":
        travelModeSwe = "Cykla";
        break;
      case "transit":
        travelModeSwe = "Åka kollektivt";
        break;
    }

    this.set('travelMode', travelmode);

  },

  endPointSelection: function(event){
    var endPoint = new ol.Feature();
    endPoint.setGeometry(new ol.geom.Point(event.coordinate));

    var lonlat = ol.proj.transform(endPoint.getGeometry().getCoordinates(), 'EPSG:3007', 'EPSG:4326');
    var lon = lonlat[0];
    var lat = lonlat[1];

    this.get('layer_end').getSource().clear();
    this.get('layer_end').getSource().addFeature(endPoint);
    endPoint.setStyle(style_end);

    var pos = this.get('position');
    pos.latitudeEnd = lat;
    pos.longitudeEnd = lon;
    this.set('position', pos);
  },


  activateStartMode: function(){
    this.set('state', 'choose_start');
    if(this.get('onEndKey') !== undefined) {
      ol.Observable.unByKey(this.get('onEndKey'));
      this.set('onEndKey', undefined);
    }
    if(this.get('onRoutingKey') !== undefined) {
      ol.Observable.unByKey(this.get('onRoutingKey'));
      this.set('onRoutingKey', undefined);
    }
    if(this.get('onStartKey') === undefined) {
      this.set('onStartKey', this.get('map').on('singleclick', this.startPointSelection.bind(this)));
    }

    if (isMobile) {
      this.props.navigationPanel.minimize();
    }
  },

  activateEndMode: function(){
    this.set('state', 'choose_end');
    if(this.get('onStartKey') !== undefined) {
      ol.Observable.unByKey(this.get('onStartKey'));
      this.set('onStartKey', undefined);
    }
    if(this.get('onRoutingKey') !== undefined) {
      ol.Observable.unByKey(this.get('onRoutingKey'));
      this.set('onRoutingKey', undefined);
    }
    if(this.get('onEndKey') === undefined) {
      this.set('onEndKey', this.get('map').on('singleclick', this.endPointSelection.bind(this)));
    }
    if(this.get('onEndKey') !== undefined && this.get('routingFinished')) {
      this.set('onEndKey', this.get('map').on('singleclick', this.endPointSelection.bind(this)));
      // TODO modify if and clear route
      this.set('routeFinished', false);
    }

    if (isMobile) {
      this.props.navigationPanel.minimize();
    }
  },

  activateRoutingMode: function(){
    this.set('state', 'show_route');
    if(this.get('onStartKey') !== undefined) {
      ol.Observable.unByKey(this.get('onStartKey'));
      this.set('onStartKey', undefined);
    }
    if(this.get('onEndKey') !== undefined) {
      ol.Observable.unByKey(this.get('onEndKey'));
      this.set('onEndKey', undefined);
    }

    if(this.get('onRoutingKey') === undefined) {
      //this.set('onRoutingKey', this.get('map').on('singleclick', this.showRoutingInfoPopup.bind(this)));
    }
    this.searchTrip();
  },


  // Executed once when the panel is loaded
  initStartPoint: function() {
    style_start = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1.0,
        src: 'assets/icons/startRouting_40.png',
        scale: (1)
      })
    });

    style_end = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1.0,
        src: 'assets/icons/malRouting_40.png',
        scale: (1)
      })
    });

    style_route = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1.0,
        src: 'assets/icons/markering_A_liten.png',
        scale: (1)
      })
    });

    this.set('style_route_normal', style_route);

    style_route_highlight = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1.0,
        src: 'assets/icons/Markering_A_stor.png',
        scale: (1.5)
      })
    });

    this.set('style_route_highlight', style_route_highlight);

    layer_drawing_style =  new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.5)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 0, 255, 0.5)',
        width: 4
      }),
      image: new ol.style.Circle({
        radius: 6,
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 0, 0.5)'
        }),
        stroke: new ol.style.Stroke({
          color: 'rgba(255, 255, 255, 0.5)',
          width: 2
        })
      })});

    var source_start = new ol.source.Vector({});
    var source_end = new ol.source.Vector({});
    var source_route = new ol.source.Vector({});
    var source_drawing = new ol.source.Vector({});

    if (this.get('layer_start') === undefined) {
      this.set("layer_start", new ol.layer.Vector({
        source: source_start,
        name: "routing",
        content: "Punkt",
        queryable: false,
        style: style_start
      }));

      this.set("layer_end", new ol.layer.Vector({
        source: source_end,
        name: "routing",
        content: "Punkt",
        queryable: false,
        style: style_end
      }));

      this.set("layer_route", new ol.layer.Vector({
        source: source_route,
        name: "routing",
        content: "Punkt",
        queryable: true,
        style: style_route
      }));

      this.set("layer_drawing", new ol.layer.Vector({
        source: source_drawing,
        name: "routing",
        content: "linje",
        queryable: false,
        style: layer_drawing_style
      }));

      this.get('map').addLayer(this.get('layer_start'));
      this.get('map').addLayer(this.get('layer_end'));
      this.get('map').addLayer(this.get('layer_route'));
      this.get('map').addLayer(this.get('layer_drawing'));
    }
  },

  setPositionEvent: function(event){
    var pos = this.get('position');
    pos.latitude = event.coords.latitude;
    pos.longitude = event.coords.longitude;
    this.set('position', pos);
    this.setPosition();
  },

  setPosition: function(){
    this.get('layer_start').getSource().clear();
    if (this.get('position').longitude && this.get('position').latitude) {
      var point = new ol.geom.Point([
        this.get('position').longitude,
        this.get('position').latitude
      ]);
      var transformed = ol.proj.transform(point.getCoordinates(), "EPSG:4326", this.get('map').getView().getProjection());
      point.setCoordinates(transformed);
      var ft = new ol.Feature({geometry: point});
      ft.setStyle(style_start);
      this.get('layer_start').getSource().addFeature(ft);
      this.get('map').getView().setCenter(point.getCoordinates());
    }
  },

  configure: function (shell) {
    this.set('map', shell.getMap().getMap());
  },

  searchTrip: function(){
    this.set({'state': undefined});
    var pos = this.get('position');
    if(pos.latitude === undefined || pos.longitude === undefined ||
  pos.latitudeEnd === undefined || pos.longitudeEnd === undefined){
      alert('Välj start och slut');
    } else {
      ol.Observable.unByKey(this.get('onEndKey'));
      var mode = this.get('travelMode');
      var url = 'https://' + document.location.hostname + '/maps/api/directions/json?mode=' + mode + '&origin=' + pos.latitude + ',' + pos.longitude + '&destination=' + pos.latitudeEnd + ',' + pos.longitudeEnd +'&key=' + this.get('apiKey');
    var request =$.ajax({
        url: url,
        type: "post",
        contentType: 'text/plain',
        xhrFields: {
          withCredentials: false
        },
        cache: false,
        success: (res) => { this.plotRoute(res, this.get('map'), this.get('layer_route'), this.get('layer_drawing'))},
        error: (err) => {
          alert("Det gick inte att navigera dig. Försök igen senare");
        },
    });
  }
  },

  plotRoute: function(res, map, layer, layer_drawing) {

    var routeResult = "";
    layer.getSource().clear();
    var steps = res.routes[0].legs[0].steps;
    var routeDiv = document.createElement('div');
    var p = document.createElement('p');
    p.innerHTML = '<b>Färdsätt:</b>' + travelModeSwe + '<br>' + '<b>Avstånd:</b> ' + res.routes[0].legs[0].distance.text +'('+res.routes[0].legs[0].distance.value+'m)' + '<br>' + '<b>Tid:</b> ' + res.routes[0].legs[0].duration.text + '<br>' + '<b>Startadress:</b> ' + res.routes[0].legs[0].start_address + '<br>' + '<b>Slutadress:</b> ' + res.routes[0].legs[0].end_address;
    routeDiv.appendChild(p);
    for(var i = 0; i < steps.length; i++){
      var lat = steps[i].start_location.lat;
      var lng = steps[i].start_location.lng;

      var point = new ol.geom.Point([
        lng,
        lat
      ]);
      var transformed = ol.proj.transform(point.getCoordinates(), "EPSG:4326", "EPSG:3007");
      point.setCoordinates(transformed);


      var n = i + 1;
      var tmpFeature = new ol.Feature({geometry: point, information: steps[i].html_instructions});
      tmpFeature.number = "" + n;
      tmpFeature.setStyle(style_route);
      layer.getSource().addFeature(tmpFeature);
      // route features
      var tmpLi = document.createElement('li');
      tmpLi.onclick = this.highlightFeature.bind(this);
      tmpLi.id = 'step_number' + n;
      tmpLi.innerHTML = n + "," + steps[i].html_instructions;
      var tmpI = document.createElement('n');
      tmpI.class = 'fa fa-arrow-down';
      var tmpBr = document.createElement('br');
      routeDiv.appendChild(tmpLi);
      routeDiv.appendChild(tmpI);
      routeDiv.appendChild(tmpBr);
    }


    var resList = document.getElementById('resultList');
    while (resList.firstChild) {
      resList.removeChild(resList.firstChild);
    }

    // put result into the table
    document.getElementById('resultList').appendChild(routeDiv);

    var routePath = new ol.format.Polyline({
    }).readGeometry(res.routes[0].overview_polyline.points);

    routePath = (new ol.format.Polyline({
    }).readGeometry(res.routes[0].overview_polyline.points, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3007'
    }));

    layer_drawing.getSource().clear();
    var ft = new ol.Feature({
      type: 'routing',
      geometry: routePath
    });
    ft.setStyle(layer_drawing_style);

    layer_drawing.getSource().addFeature(ft);
    var centerLat = (this.get('position').latitude + this.get('position').latitudeEnd) / 2;
    var centerLon = (this.get('position').longitude + this.get('position').longitudeEnd) / 2;
    map.getView().setCenter(ol.proj.transform([centerLon, centerLat], 'EPSG:4326', 'EPSG:3007'));
    map.getView().fit(layer_drawing.getSource().getExtent(), map.getSize());

  },

  highlightFeature: function(event){
    var feature_number = -1;
    if (event.target.nodeName === 'B'){
      feature_number = event.target.parentNode.id.substring('step_number'.length);
    } else {
      feature_number = event.target.id.substring('step_number'.length);
    }

    //feature_number = feature_number - 1;
    var layer = this.get('layer_route');

    var features = layer.getSource().getFeatures();
    var featuresLength = features.length + 1;

    for (var i= 0; i < features.length ; i++){
      if(features[i].number === feature_number){
        features[i].setStyle(this.get('style_route_highlight'));
      } else {
        features[i].setStyle(this.get('style_route_normal'));
      }
    }
  },

  drawRoute: function(steps){

    var routePath = new ol.format.Polyline({
    }).readGeometry(steps);

    var ft = new ol.Feature({type: 'routing', geometry: routePath});
    ft.setStyle(style_route);
    this.get('layer_drawing').getSource().addFeature(ft);


  },

  getOptions: function () {
  },

  deleteLayers: function() {
    this.get('layer_start').getSource().clear();
    this.get('layer_end').getSource().clear();
    this.get('layer_route').getSource().clear();
    this.get('layer_drawing').getSource().clear();

    this.set({
      position:{
        latitude: undefined,
        longitude: undefined,
        latitudeEnd: undefined,
        longitudeEnd: undefined
      }
    });

    if(this.get('onStartKey') !== undefined) {
      ol.Observable.unByKey(this.get('onStartKey'));
      this.set('onStartKey', undefined);
    }
    if(this.get('onRoutingKey') !== undefined) {
      ol.Observable.unByKey(this.get('onRoutingKey'));
      this.set('onRoutingKey', undefined);
    }
    if(this.get('onEndKey') !== undefined) {
      ol.Observable.unByKey(this.get('onEndKey'));
      this.set('onEndKey', undefined);
    }
  },

  ConvertAddressToCoord: function(){
    /* need to create a box with suggestion */
    /* var searchStringStart = "<wfs:GetFeature\
     service = 'WFS'\
     version = '1.1.0'\
     xmlns:wfs = 'http://www.opengis.net/wfs'\
     xmlns:ogc = 'http://www.opengis.net/ogc'\
     xmlns:gml = 'http://www.opengis.net/gml'\
     xmlns:esri = 'http://www.esri.com'\
     xmlns:xsi = 'http://www.w3.org/2001/XMLSchema-instance'\
     xsi:schemaLocation='http://www.opengis.net/wfs ../wfs/1.1.0/WFS.xsd'\
     outputFormat="GML2"\
     maxFeatures="1000">\
     <wfs:Query typeName='feature:fastighetsytor' srsName='EPSG:3007'>\
     <ogc:Filter>\
     \
     <ogc:PropertyIsLike matchCase="false" wildCard="*" singleChar="." escapeChar="!">\
     <ogc:PropertyName>text</ogc:PropertyName>\
     <ogc:Literal>";
     var searchStringEnd = "</ogc:Literal>\
     </ogc:PropertyIsLike>\
     </ogc:Filter>\
     </wfs:Query>\
     </wfs:GetFeature>";

     var value = ''; // TODO get value from box
     var forAjax = searchStringStart + value + '*' + searchStringEnd;
     */


  },



  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */

  onCloseTab: function() {
    this.get('layer_start').getSource().clear();
    this.get('layer_end').getSource().clear();
    this.get('layer_route').getSource().clear();
    this.get('layer_drawing').getSource().clear();

    this.set({
      position:{
        latitude: undefined,
        longitude: undefined,
        latitudeEnd: undefined,
        longitudeEnd: undefined
      }
    });

    if(this.get('onStartKey') !== undefined) {
      ol.Observable.unByKey(this.get('onStartKey'));
      this.set('onStartKey', undefined);
    }
    if(this.get('onRoutingKey') !== undefined) {
      ol.Observable.unByKey(this.get('onRoutingKey'));
      this.set('onRoutingKey', undefined);
    }
    if(this.get('onEndKey') !== undefined) {
      ol.Observable.unByKey(this.get('onEndKey'));
      this.set('onEndKey', undefined);
    }

  },

  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  },
};

/**
 * Location model module.<br>
 * Use <code>require('models/information')</code> for instantiation.
 * @module LocationModel-module
 * @returns {LocationModel}
 */
module.exports = ToolModel.extend(RoutingModel);

},{"tools/tool":"tools/tool"}],"tools/search":[function(require,module,exports){
var ToolModel = require('tools/tool');
var SelectionModel = require('models/selection');
var arraySort = require('utils/arraysort');
var kmlWriter = require('utils/kmlwriter');

/**
 * @typedef {Object} SearchModel~SearchModelProperties
 * @property {string} type - Default: search
 * @property {string} panel - Default: searchpanel
 * @property {string} toolbar - Default: bottom
 * @property {string} icon - Default: fa fa-search icon
 * @property {string} title - Default: Sök i kartan
 * @property {string} visible - Default: false
 * @property {string} value
 * @property {boolean} force
 * @property {string} filter - Default: "*"
 * @property {string} filterVisibleActive - Default: false
 * @property {string} markerImg - Default: "assets/icons/marker.png"
 * @property {number} maxZoom - Default: 14
 * @property {string} exportUrl
 */
var SearchModelProperties = {
  type: 'search',
  Id: 'searchPanelB',
  panel: 'searchpanel',
  toolbar: 'bottom',
  icon: 'fa fa-search icon',
  title: 'Sök i kartan',
  visible: false,
  value: "",
  valueBar: "",
  filter: "*",
  filterVisibleActive: false,
  markerImg: "assets/icons/marker.png",
  base64Encode: false,
  instruction: "",
  anchor: [
    16,
    32
  ],
  imgSize: [
    32,
    32
  ],
  maxZoom: 14,
  exportUrl: "",
  displayPopup: false,
  displayPopupBar: false,
  hits: [],
  popupOffsetY: 0,
  aliasDict: {}
};

/**
 * Prototype for creating a search model.
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {SearchModel~SearchModelProperties} options - Default options
 */
var SearchModel = {
  /**
   * @instance
   * @property {SearchModel~SearchModelProperties} defaults - Default settings
   */
  defaults: SearchModelProperties,

  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  configure: function (shell) {
    this.set('displayPopupBar', this.get('displayPopup'));
    this.set('layerCollection', shell.getLayerCollection());
    this.set('map', shell.getMap().getMap());
    this.featureLayer = new ol.layer.Vector({
      caption: 'Sökträff',
      name: 'search-vector-layer',
      source: new ol.source.Vector(),
      queryable: true,
      visible: true,
      style: this.getStyle()
    });

    this.featureLayer.getSource().on('addfeature', evt => {
      evt.feature.setStyle(this.featureLayer.getStyle());
    });

    this.get('map').addLayer(this.featureLayer);

    if (this.get('selectionTools')) {
      this.set('selectionModel', new SelectionModel({
        map: shell.getMap().getMap(),
        layerCollection: shell.getLayerCollection()
      }));
    }
  },

  /**
   * @instance
   * @property {XMLHttpRequest[]} requests
   */
  requests: [],

  /**
   * @instance
   * @property {external:"ol.layer"} featureLayer
   */
  featureLayer: undefined,

  /**
   * @instance
   * @property {number} exportHitsFormId
   */
  exportHitsFormId: 1234,

  /**
   * Create a property filter
   * @instance
   * @param {object} props
   * @return {string} wfs-filter
   */
  getPropertyFilter: function (props) {
    var multipleAttributes = props.propertyName.split(',').length > 1;
    var conditions = props.propertyName.split(',').reduce((condition, property) => {
    /*  if (props.value == null){
        return condition;
    }*/
      props.value.indexOf("\\") >= 0 ? props.value = props.value.replace(/\\/g, "\\\\") : props.value;
      
      if (props.value) {
        return condition += `
          <ogc:PropertyIsLike matchCase="false" wildCard="*" singleChar="." escapeChar="!">
            <ogc:PropertyName>${property}</ogc:PropertyName>
            <ogc:Literal>${props.value}*</ogc:Literal>
          </ogc:PropertyIsLike>`
     } else {
       return condition;
     }
   }, "");

   if (multipleAttributes && props.value) {
     return `<ogc:Or>${conditions}</ogc:Or>`;
   } else {
     return conditions;
   }
  },

  isCoordinate: function (c) {
    return typeof c[0] === "number" && typeof c[1] === "number";
  },

  /**
   * Create a feature filter
   * @instance
   * @param {object} props
   * @return {string} wfs-filter
   */
  getFeatureFilter: function (features, props) {
    if (Array.isArray(features) && features.length > 0) {

      return features.reduce((str, feature) => {

        var posList = ""
        ,   operation = "Intersects"
        ,   coords = [];

        if (feature.getGeometry() instanceof ol.geom.Circle) {
          coords = ol.geom.Polygon.fromCircle(feature.getGeometry(), 96).getCoordinates();
        } else {
          coords = feature.getGeometry().getCoordinates();
        }

        if (this.isCoordinate(coords[0])) {
          posList = coords.map(c => c[0] + " " + c[1]).join(" ");
        }

        if (this.isCoordinate(coords[0][0])) {
          posList = coords[0].map(c => c[0] + " " + c[1]).join(" ");
        }

        if (this.isCoordinate(coords[0][0][0])) {
          posList = coords[0][0].map(c => c[0] + " " + c[1]).join(" ");
        }

        if (feature.operation === "Within") {
          operation = feature.operation;
        }

        str += `
            <ogc:${operation}>
              <ogc:PropertyName>${props.geometryField}</ogc:PropertyName>
              <gml:Polygon srsName="${props.srsName}">
              <gml:exterior>
                <gml:LinearRing>
                  <gml:posList>${posList}</gml:posList>
                </gml:LinearRing>
                </gml:exterior>
              </gml:Polygon>
            </ogc:${operation}>
        `;

        if (features.length > 1) {
          str = `<ogc:Or>${str}</ogc:Or>`;
        }

        return str;

      }, "");
    } else {
      return "";
    }
  },

  /**
   * Perform a WFS-search.
   * @instance
   * @param {object} props
   */
  doWFSSearch: function (props) {
    var filters = ""
    ,   str = ""
    ,   featureFilter = ""
    ,   propertyFilter = ""
    ,   read = (result) => {

      var format
      ,   features = []
      ,   outputFormat = props.outputFormat;

      if (outputFormat === 'GML2')
        format = new ol.format.GML2({});
      else
        format = new ol.format.WFS({});

      if (!(result instanceof XMLDocument)) {
        if (result.responseText) {
          result = result.responseText;
        }
      }          
      try {
        features = format.readFeatures(result);        
        features = features.reduce((r, f) => {
          if (this.get('selectionTools')) {
            let found = this.get('features').find(feature =>
              f.getId() === feature.getId()
            );
            if (!found) {
              r.push(f);
            }
          } else {
            r.push(f);
          }
          return r;
        }, []);

      } catch (e) {
        console.error("Parsningsfel. Koordinatsystem kanske saknas i definitionsfilen? Mer information: ", e);
      }

      if (features.length === 0) {
        features = [];
      }
      props.done(features);
    };

    outputFormat = props.outputFormat;

    if (!outputFormat || outputFormat === '') {
      outputFormat = 'GML3'
    }

    propertyFilter = this.getPropertyFilter(props);
    featureFilter = this.getFeatureFilter(this.get('features'), props);

    if (featureFilter && propertyFilter) {
      filters = `
        <ogc:And>
          ${propertyFilter}
          ${featureFilter}
        </ogc:And>
      `;
    } else if (propertyFilter) {
      filters = propertyFilter;
    } else if (featureFilter) {
      filters = featureFilter;
    } else {
      filters = "";
    }

    var typeName = `'${props.featureType}'`;
    if(!typeName.includes(':')) { // If no namespace, add "feature:"
      typeName = `'feature:${props.featureType}'`;
    } 
  
    str = `
     <wfs:GetFeature
         service = 'WFS'
         version = '1.1.0'
         xmlns:wfs = 'http://www.opengis.net/wfs'
         xmlns:ogc = 'http://www.opengis.net/ogc'
         xmlns:gml = 'http://www.opengis.net/gml'
         xmlns:esri = 'http://www.esri.com'
         xmlns:xsi = 'http://www.w3.org/2001/XMLSchema-instance'
         xsi:schemaLocation='http://www.opengis.net/wfs ../wfs/1.1.0/WFS.xsd'
         outputFormat="${outputFormat}"
         maxFeatures="1000">
         <wfs:Query typeName=` + typeName + ` srsName='${props.srsName}'>
          <ogc:Filter>
            ${filters}
          </ogc:Filter>
         </wfs:Query>
      </wfs:GetFeature>`;

    var contentType = "text/xml"
    ,   data = str;

    this.requests.push(
      $.ajax({
        url: props.url,
        contentType: contentType,
        crossDomain: true,
        type: 'post',
        data: str,
        success: result => {
          read(result);
        },
        error: result => {
          if (result.status === 200) {
            read(result);
          } else {
            props.done([]);
          }
        }
      })
    );

  },

  /**
   * Abort current requests.
   * @instance
   */
  abort: function () {
    this.requests.forEach((request) => {
      request.abort();
    });
    this.requests = [];
  },

  /**
   * Clear result layer.
   * @instance
   *
   */
  clear: function() {
    var ovl = this.get('map').getOverlayById('popup-0');
    if (this.get('selectionModel')) {
      this.get('selectionModel').abort();
    }
    this.featureLayer.getSource().clear();
    this.set('items', []);
    this.set('barItems', []);
    if (ovl) {
      ovl.setPosition(undefined);
    }
  },

  /**
   * Translate infobox template
   * @instance
   * @param {string} information template
   * @param {object} object to translate
   * @return {string} markdown
   */
  translateInfoboxTemplate: function(information, properties) {
    (information.match(/\{.*?\}\s?/g) || []).forEach(property => {
        function lookup(o, s) {
          s = s.replace('{', '')
               .replace('}', '')
               .replace('export:', '')
               .replace(/ as .*/, '')
               .trim()
               .split('.');

          switch (s.length) {
            case 1: return o[s[0]] || "";
            case 2: return o[s[0]][s[1]] || "";
            case 3: return o[s[0]][s[1]][s[2]] || "";
          }
        }
        information = information.replace(property, lookup(properties, property));
    });
    return information;
  },

  /**
   * Convert object to markdown
   * @instance
   * @param {object} object to transform
   * @return {string} markdown
   */
  objectAsMarkdown: function (o) {
    return Object
      .keys(o)
      .reduce((str, next, index, arr) =>
        /^geom$|^geometry$|^the_geom$/.test(arr[index]) ?
        str : str + `**${arr[index]}**: ${o[arr[index]]}\r`
      , "");
  },

  /**
   * Get information
   * @instance
   * @param {extern:ol.feature} feature
   * @return {string} information
   */
  getInformation: function(feature) {

    var info = feature.infobox || feature.getProperties();
    var content = "";

    if (typeof info === 'object')
      content = this.objectAsMarkdown(info);

    if (typeof info === 'string')
      content = this.translateInfoboxTemplate(info, feature.getProperties());

    return marked(content, { sanitize: false, gfm: true, breaks: true });
  },

  /**
   * Check if this device supports touch.
   * @instance
   */
  isTouchDevice: function () {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch(e) {
      return false;
    }
  },

  /**
   * Enable scroll on infowindow
   * @instance
   * @param {DOMelement} elm
   */
  enableScroll: function (elm) {
    if (this.isTouchDevice()){
      var scrollStartPos = 0;
      elm.addEventListener("touchstart", function(event) {
        scrollStartPos = this.scrollTop + event.touches[0].pageY;
      }, false);
      elm.addEventListener("touchmove", function(event) {
        this.scrollTop = scrollStartPos - event.touches[0].pageY;
      }, false);
    }
  },

  /**
   * Focus map on feature.
   * @instance
   * @param {object} spec
   *
   */
  focus: function (spec, isBar) {

    function isPoint (coord) {
      if (coord.length === 1) {
        coord = coord[0];
      }
      return (
        (coord.length === 2 ||  coord.length === 3) &&
        typeof coord[0] === "number" &&
        typeof coord[1] === "number"
      )
      ? [coord[0], coord[1]]
      : false;
    }

    var map     = this.get('map')
    ,   exist   = this.get('selectedIndices').find(item => item.group === spec.id)
    ,   extent  = spec.hit.getGeometry().getExtent()
    ,   size    = map.getSize()
    ,   ovl     = map.getOverlayById('popup-0')
    ,   offsetY = 0

    this.set('hits', [spec.hit]);
    map.getView().fit(extent, {
      size: size,
      maxZoom: this.get('maxZoom')
    });

    this.featureLayer.getSource().clear();
    this.featureLayer.getSource().addFeature(spec.hit);
    var displayPopup = isBar ? this.get("displayPopupBar"): this.get("displayPopup");
    if (ovl && displayPopup) {

      let title = $(`<div class="popup-title">${spec.hit.caption}</div>`);
      let textContent = $('<div id="popup-content-text"></div>');

      textContent.html(this.getInformation(spec.hit));
      $('#popup-content').empty().append(title, textContent);

      if (isPoint(spec.hit.getGeometry().getCoordinates())) {
        offsetY = this.get('popupOffsetY');
      }
      ovl.setOffset([0, offsetY]);
      ovl.setPosition(map.getView().getCenter());
      if (this.isTouchDevice()) {
        this.enableScroll($('#popup-content')[0]);
      }
    }

    if (ovl && !this.get('displayPopup')) {
      ovl.setPosition(undefined);
    }

    if (!this.get('selectedIndices') instanceof Array) {
      this.set('selectedIndices', []);
    }

    if (exist) {
      exist.index = spec.index;
    } else {
      this.get('selectedIndices').push({
        index: spec.index,
        group: spec.id
      });
    }

  },

  append: function (spec) {

    var map    = this.get('map')
    ,   exist  = this.get('selectedIndices').find(item => item.group === spec.id && spec.index === item.index)
    ,   extent = spec.hit.getGeometry().getExtent()
    ,   size   = map.getSize()
    ,   ovl    = map.getOverlayById('popup-0');

    this.get('hits').push(spec.hit);

    map.getView().fit(extent, size, { maxZoom: this.get('maxZoom') });

    this.featureLayer.getSource().addFeature(spec.hit);

    if (ovl) {
      ovl.setPosition(undefined);
    }

    if (!this.get('selectedIndices') instanceof Array) {
      this.set('selectedIndices', []);
    }

    if (exist) {
      exist.index = spec.index;
    } else {
      this.get('selectedIndices').push({
        index: spec.index,
        group: spec.id
      });
    }
  },

  detach: function (spec) {

    var map    = this.get('map')
    ,   ovl    = map.getOverlayById('popup-0')
    ,   exist  = this.get('selectedIndices').find(item =>
                   item.group === spec.id &&
                   spec.index === item.index
                 );

    this.set('hits', this.get('hits').filter(hit =>
      hit.getId() !== spec.hit.getId()
    ));

    this.featureLayer.getSource().removeFeature(spec.hit);

    if (ovl) {
      ovl.setPosition(undefined);
    }

    if (this.get('selectedIndices') instanceof Array) {
      this.set('selectedIndices', this.get('selectedIndices').filter(f =>
        f.index !== spec.index &&
        f.id !== spec.id
      ));
    }

  },

  /**
   * Get searchable layers.
   * @isntance
   * @return {Layer[]} layers
   */
  getLayers: function () {
    
    var filter = (layer) => {
      var criteria = this.get('filter');
      var visible  = this.get('filterVisibleActive');
      var searchable = layer.get('searchUrl');
      return (searchable && (visible ? layer.get('visible') : false) || layer.get('id') === criteria);
    };

    return this.get('layerCollection').filter(filter);
  },

  /**
   * Get searchable sources.
   * @instance
   * @return {Array<{external:"ol.source"}>} searchable/choosen sources
   */
  getSources: function () {
    var filter = (source) => {
      var criteria = this.get('filter');
      return criteria === '*' ? true : criteria === source.caption;
    }
    return this.get('sources').filter(filter);
  },

  /**
   * Get searchable layers. By design, visible layers and layers set with the property search set.
   * @isntance
   * @return {Layer[]} layers
   */
  getHitsFromItems: function () {
    var hits = [];
    if (this.get('hits').length === 0) {
      this.get('items').map(item => {
        item.hits.forEach((hit, i) => {
          hit.setStyle(this.featureLayer.getStyle());
          hits.push(hit);
        });
      });
    }
    return hits;
  },

/**
   * Get kml data-object for export.
   * @isntance
   * @return {string} xml-string
   */
  getKmlData: function () {

    var exportItems = this.get('hits').length > 0
        ? this.get('hits')
        : this.getHitsFromItems();

    var transformed = kmlWriter.transform(
      exportItems,
      this.get('map').getView().getProjection().getCode(),
      "EPSG:4326"
    );
    return kmlWriter.createXML(transformed, "Export");
  },

  /**
   * Get excel data-object for export.
   * @isntance
   * @return {Object} excelData
   */
  getExcelData: function () {

    var groups = {}
    ,   exportItems = this.get('hits').length > 0
        ? this.get('hits')
        : this.getHitsFromItems();

    if (exportItems.length>1 && _.isEqual(exportItems[0], exportItems[1])) {
      // Ensure we don't have duplicate first items (happens when user selects items to export manually)
      exportItems.shift();
    }

    exportItems.forEach(hit => {
      if (!groups.hasOwnProperty(hit.caption)) {
        groups[hit.caption] = [];
      }
      groups[hit.caption].push(hit);
    });

    return Object.keys(groups).map(group => {

      var columns = []
      ,   aliases = []
      ,   values = [];

     var getAliasWithDict = (column, aliasDict) => {
       var keys = Object.keys(aliasDict);
       if (keys.indexOf(column) >= 0){
         return aliasDict[column];
       } else {
         return column;
       }
      }

      var getAlias = (column, infobox) => {
        var regExp = new RegExp(`{export:${column}( as .*)?}`)
          ,   result = regExp.exec(infobox);

        if (result && result[1]) {
          result[1] = result[1].replace(" as ", "");
        }

        return result && result[1] ? result[1] : column;
      }


      values = groups[group].map((hit) => {


        if(typeof hit.aliasDict !== "undefined" && hit.aliasDict !== null){
        var attributes = hit.getProperties()
        ,   names = Object.keys(attributes),
            aliasKeys = Object.keys(hit.aliasDict);
        names = names.filter(name => {
          return aliasKeys.indexOf(name) >= 0;
        });
        } else {
          var attributes = hit.getProperties()
            ,   names = Object.keys(attributes);
          names = names.filter(name => {
            if (!hit.infobox) {
            return typeof attributes[name] === "string"  ||
              typeof attributes[name] === "boolean" ||
              typeof attributes[name] === "number";
          } else {
            let regExp = new RegExp(`{export:${name}( as .*)?}`);
            return (
              regExp.test(hit.infobox)
            );
          }
        });
        }

        if (names.length > columns.length) {
          columns = names;
          aliases = columns.slice();
        }

        columns.forEach((column, i) => {
          if(typeof hit.aliasDict !== "undefined" && hit.aliasDict !== null){
            aliases[i] = getAliasWithDict(column, hit.aliasDict);
          } else {
            aliases[i] = getAlias(column, hit.infobox);
          }
        });

        return columns.map(column => attributes[column] || null);

      });

      return {
        TabName: group,
        Cols: aliases,
        Rows: values
      };

    });
  },

  export: function (type) {
    var url = ""
    ,   data = {}
    ,   postData = "";

    switch (type) {
      case 'kml':
        url = this.get('kmlExportUrl');
        data = this.getKmlData();
        postData = data;
        break;
      case 'excel':
        url = this.get('excelExportUrl');
        data = this.getExcelData();
        postData = JSON.stringify(data);
        break;
    }

    this.set("downloading", true);

    if (this.get('base64Encode')){
      postData = btoa(postData);
    }

    $.ajax({
      url: url,
      method: "post",
      data: {
        json: postData
      },
      format: "json",
      success: (url) => {
        this.set("downloading", false);
        this.set("url", url);
      },
      error: (err) => {
        this.set("downloading", false);
        alert("Datamängden är för stor. Det går inte att exportera så många träffar. Begränsa ditt sökresultat.");
      }
    });
  },

  /**
   * Lookup searchable layers in loaded LayerCollection.
   * Stacks requests as promises and resolves when all requests are done.
   * @instance
   * @param {string} value
   * @param {function} done
   */
  search: function (done, isBar) {
    var value = isBar ? this.get('valueBar') : this.get('value')
    ,   items = []
    ,   promises = []
    ,   layers
    ,   sources
    ,   features = []
    ;

    function addRequest(searchProps) {
      promises.push(new Promise((resolve, reject) => {
        this.doWFSSearch({
          value: value,
          url: searchProps.url,
          featureType: searchProps.featureType,
          propertyName: searchProps.propertyName,
          srsName: searchProps.srsName,
          outputFormat: searchProps.outputFormat,
          geometryField: searchProps.geometryField,
          done: features => {
            if (features.length > 0) {
              features.forEach(feature => {
                feature.caption = searchProps.caption;
                feature.infobox = searchProps.infobox;
                try{
                  feature.aliasDict = JSON.parse(searchProps.aliasDict);
                } catch(e){
                  feature.aliasDict = undefined;
                }
              });
              items.push({
                layer: searchProps.caption,
                displayName: searchProps.displayName,
                propertyName: searchProps.propertyName,
                hits: features
              });
            }
            resolve();
          }
        });
      }));
    }

    if (this.get('selectionTools')) {
      features = this.get('selectionModel').getFeatures();
      this.set('features', features);
    }

    if (value === "" && features.length === 0) return;

    sources = this.getSources();
    layers  = this.getLayers();

    this.set('hits', []);
    this.set('selectedIndices', []);
    this.featureLayer.getSource().clear();

    layers.forEach(layer => {

      layer.get('params').LAYERS.split(',').forEach(featureType => {
        var searchProps = {
          url: (HAJK2.searchProxy || "") + layer.get('searchUrl'),
          caption: layer.get('caption'),
          infobox: layer.get('infobox'),
          aliasDict: layer.get("aliasDict"),
          featureType: featureType,
          propertyName: layer.get('searchPropertyName'),
          displayName: layer.get('searchDisplayName'),
          srsName: this.get('map').getView().getProjection().getCode(),
          outputFormat: layer.get('searchOutputFormat'),
          geometryField: layer.get('searchGeometryField')
        };

        addRequest.call(this, searchProps);
      });

    });

    sources.forEach(source => {
      var searchProps = {
        url: (HAJK2.searchProxy || "") + source.url,
        caption: source.caption,
        infobox: source.infobox,
        aliasDict: source.aliasDict,
        featureType: source.layers[0].split(':')[1],
        propertyName: source.searchFields.join(','),
        displayName: source.displayFields ? source.displayFields : (source.searchFields[0] || "Sökträff"),
        srsName: this.get('map').getView().getProjection().getCode(),
        outputFormat: source.outputFormat,
        geometryField: source.geometryField
      };
      addRequest.call(this, searchProps);
    });

    Promise.all(promises).then(() => {

        items.forEach(function (item) {
        item.hits = arraySort({
          array: item.hits,
          index: item.displayName
        });
      });
    items = items.sort((a, b) => a.layer > b.layer ? 1 : -1
  )
    ;
    if(isBar){
      this.set('barItems', items);
    } else {
      this.set('items', items);
    }
    if (done) {
      done({
        status: "success",
        items: items
      });
    }

    });
  },

  shouldRenderResult: function (isBar) {
    return !!(
      (isBar ? this.get('valueBar') : this.get('value')) ||
      (
        !isBar &&
        this.get('selectionModel') &&
        this.get('selectionModel').hasFeatures() &&
        this.get('searchTriggered')
      )
    );
  },

  /**
   * Get style for search hit layer.
   * @instance
   * @return {external:"ol.style"} style
   *
   */
  getStyle: function () {
    return new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 0, 0, 0.6)',
        width: 4
      }),
      image: new ol.style.Icon({
        anchor: this.get('anchor'),
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: this.get('markerImg'),
        imgSize: this.get('imgSize')
      })
    })
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', true);
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Search model module.<br>
 * Use <code>require('models/search')</code> for instantiation.
 * @module SearchModel-module
 * @returns {SearchModel}
 */
module.exports = ToolModel.extend(SearchModel);

},{"models/selection":"models/selection","tools/tool":"tools/tool","utils/arraysort":"utils/arraysort","utils/kmlwriter":"utils/kmlwriter"}],"tools/streetview":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

const googleMapsLoader = require('google-maps');
const ToolModel = require('tools/tool');
var streetViewService;
var panorama;

/**
 * @typedef {Object} StreetViewModel~StreetViewModelProperties
 * @property {string} type -Default: streetview
 * @property {string} panel -Default: ''
 * @property {string} toolbar -Default: bottom
 * @property {string} icon -Default: fa fa-street-view icon
 * @property {string} title -Default: Google Street View
 * @property {boolean} visible - Default: false
 * @property {ShellModel} shell
 * @property {string} imageDate
 * @property {string} apiKey
 */
var StreetViewModelProperties = {
  type: 'streetview',
  panel: 'streetviewpanel',
  toolbar: 'bottom',
  icon: 'fa fa-street-view icon',
  title: 'Google Street View',
  visible: false,
  shell: undefined,
  google: undefined,
  imageDate: '',
  apiKey: '',
  instruction: ''
}

/**
 * @description
 *
 *  Prototype for creating an streetview model.
 *
 * @class
 * @augments {external:"Backbone.Model"}
 * @param {StreetViewModel~StreetViewModellProperties} options - Default options
 */
var StreetViewModel = {
  /**
   * @instance
   * @property {StreetViewModel~StreetViewModelProperties} defaults - Default settings
   */
  defaults: StreetViewModelProperties,

  /**
   * Create and add marker interaction to map.
   * @instance
   */
  initialize: function (options) {
    ToolModel.prototype.initialize.call(this);
  },

  /**
   * Set properties of panorama object witch controlls the street view window.
   * @instance
   * @param {object} data
   * @param {google.maps.StreetViewStatus} status
   */
  displayPanorama: function (data, status) {
    if (status === google.maps.StreetViewStatus.OK) {
      this.set('imageDate', `Bild tagen: ${data.imageDate}`);
      panorama.setPano(data.location.pano);
      panorama.setPov({ heading: 270, pitch: 0 });
      panorama.setVisible(true);
    } else {
      this.set('imageDate', 'Bild saknas för vald position.');
    }
  },

  /**
   * Create a street view window and show given location.
   * If the window in not yet initialized the bearing will be 0.
   * @instance
   * @param {array} coordinate
   */
  showLocation: function (e) {

    if (!this.get('activated')) return;

    var coord  = ol.proj.transform(
      e.coordinate,
      this.get('olMap').getView().getProjection(),
      "EPSG:4326"
    )
      ,	location = new google.maps.LatLng(coord[1], coord[0]);

    this.addMarker(e.coordinate, (panorama && panorama.getPov().heading) || 0);
    streetViewService = new google.maps.StreetViewService();
    panorama = new google.maps.StreetViewPanorama(document.getElementById('street-view-window'));
    streetViewService.getPanoramaByLocation(location, 50, this.displayPanorama.bind(this));
    google.maps.event.addListener(panorama, 'position_changed', () => { this.onPositionChanged() });
    google.maps.event.addListener(panorama, 'pov_changed', () => { this.onPositionChanged() });
    this.set('location', location);
    this.set('location', location);
  },

  /**
   * Create icon style based on rotation.
   * @instance
   */
  getIconStyle: function(rotation) {
    //
    // Find location in sprite of given rotation.
    //
    function position(r) {
      const w = 49;
      var i = 1;
      var n = 1;
      for (;i <= 16; i++) {
        let min = 22.5 * (i - 1);
        let max = 22.5 * i;
        if (r >= min && r <= max) {
          n = i;
        }
      }
      return (n * w) - w;
    }

    const p = position(rotation);
    const w = 48;
    const h = 55;

    return new ol.style.Style({
      image: new ol.style.Icon({
        offset: [p, 0],
        anchor: [(w / 2), (h / 2)],
        size: [w, h],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        opacity: 1,
        src: 'assets/icons/google_man.png'
      })
    });
  },

  /**
   * Create and add marker interaction to map.
   * @instance
   */
  addMarker: function (coordinate, rotation) {
    var feature = new ol.Feature({
      geometry: new ol.geom.Point(coordinate)
    });
    feature.setStyle(this.getIconStyle(rotation));
    this.set('marker', feature);
    this.get('streetViewMarkerLayer').getSource().clear();
    this.get('streetViewMarkerLayer').getSource().addFeature(this.get('marker'));
  },

  /**
   * Moves the marker in the map based on the state of the panorama object.
   * @instance
   */
  onPositionChanged: function () {

    if (!panorama.getPosition() || this.get('activated') === false)
      return;

    var x = panorama.getPosition().lng()
    ,	  y = panorama.getPosition().lat()
    ,	  b = panorama.getPov().heading
    ,	  l = [x, y]
    ,   p = this.get('olMap').getView().getProjection()
    ,   c = ol.proj.transform(l, "EPSG:4326", p);

    this.addMarker(c, b);
  },

  /**
   * Initialize the model.
   * @instance
   */
  configure: function (shell) {
    this.set('map', shell.getMap());
    this.set('olMap', shell.getMap().getMap());

    googleMapsLoader.KEY = this.get('apiKey');
    googleMapsLoader.load(google => {
      this.set('google', google);
  });
    this.set('streetViewMarkerLayer', new ol.layer.Vector({
      source: new ol.source.Vector({}),
      name: 'streetViewMarkerLayer'
    }));
    this.get('olMap').addLayer(this.get('streetViewMarkerLayer'));
  },

  activate: function () {
    this.get('olMap').set('clickLock', true);
    this.eventKey = this.get('olMap').on('click', this.showLocation, this);
    this.set('activated', true);
  },

  deactivate: function () {
    this.get('olMap').set('clickLock', false);
    this.get('olMap').un('click', this.showLocation);
    this.set('activated', false);
    this.get('streetViewMarkerLayer').getSource().clear();
    this.set('location', false);
  },

  /**
   * @description
   *
   *   Handle click event on toolbar button.
   *   This handler sets the property visible,
   *   wich in turn will trigger the change event of navigation model.
   *   In pracice this will activate corresponding panel as
   *   "active panel" in the navigation panel.
   *
   * @instance
   */
  clicked: function (arg) {
    this.set('visible', !this.get('visible'));
    this.set('toggled', !this.get('toggled'));
  }
};

/**
 * Street View model module.<br>
 * Use <code>require('models/streetview')</code> for instantiation.
 * @module StreetViewModel-module
 * @returns {StreetViewModel}
 */
module.exports = ToolModel.extend(StreetViewModel);
},{"google-maps":1,"tools/tool":"tools/tool"}],"tools/tool":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @typedef {Object} ToolModel~ToolModelProperties
 * @property {string} type
 * @property {string} toolbar
 * @property {string} panel
 * @property {string} title
 * @property {string} icon
 * @property {ToolCollection} collection
 * @property {NavigationModel} navigation
 */
var ToolModelProperties = {
  type: 'tool',
  toolbar: undefined,
  panel: undefined,
  Id: '',
  title: '',
  icon: '',
  collection: undefined,
  navigation: undefined,
  instruction: ""
};

/**
 * Base prototype for creating a tool
 * @class
 * @augments {Backbone.Model}
 */
var ToolModel = {
  /**
   * @instance
   * @property {ToolModel~ToolModelProperties} defaults - Default settings
   */
  defaults: ToolModelProperties,

  initialize: function () {
    this.initialState =  _.clone(this.attributes);
    this.on("change:shell", (sender, shell) => {
      this.set('navigation', shell.get('navigation'));
      this.configure(shell);
    });
  },

  configure: function (shell) {
  },

  /**
   * Not implemented by base prototype.
   * @instance
   */
  clicked: function (arg) { },
  /**
   * Get JSON-representation of this instance.
   * @instance
   */
  toJSON: function () {
    var json = this.initialState;
    return json;
  }
};

/**
 * Search model module.<br>
 * Use <code>require('models/tool')</code> for instantiation.
 * @module ToolModel-module
 * @returns {ToolModel}
 */
module.exports = Backbone.Model.extend(ToolModel);

},{}],"utils/arraysort":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

function arraySort(options) {

    function getTitle(hit, property) {
      if (Array.isArray(property)) {
        return property.map(item => hit.getProperties()[item]).join(', ');
      } else {
        return hit.getProperties()[property] || property
      }
    }
    // Sortera på nummer i sträng.
    // Tex Storgatan 9 < Storgatan 10
    function num(str) {
      var re = /\d+/
      ,   n  = re.exec(str)
      ;
      return n !== null ? parseInt(n) : -1
    }
    // Sortera på sträng
    // Tex Storgatan < Störgatan
    function str(str) {
      var re = /^[a-zA-ZåäöÅÄÖ\-:_ ]+/
      ,   s  = re.exec(str)
      ;
      return s != null ? s[0] : -1;
    }
    // Sortera på siffra efter nummer, eller siffra efter kolon.
    // Tex Storgatan 3A < Storgatan 3B
    // Tex Almlunden 1:42 < Almlunden 1:43
    function strnum(str) {
      var re = /(\d+)(:)?([a-zA-ZåäöÅÄÖ])?(\d+)?/
      ,   s  = re.exec(str)
      ;
      var r = s === null ? -1 : s[2] ? parseInt(s[4]) : s[3];
      return r;
    }
    // Jämför två strängar.
    function comparer(a, b) {
      var a_s = str(getTitle(a, options.index)) // Strängjämförare.
      ,   b_s = str(getTitle(b, options.index)) // Strängutmanare.
      ,   a_n = NaN // Nummerjämförare.
      ,   b_n = NaN // Nummerutmanare.
      ,   ans = NaN // Suffixutmanare.
      ,   bns = NaN // Suffixjämförare.
      ;

      // Hela strängen är samma.
      if (getTitle(a, options.index) === getTitle(b, options.index)) return 0;
      if (a_s > b_s) return  1;
      if (a_s < b_s) return -1;
      // Strängdelen är samma, jämför nummer.
      a_n = num(getTitle(a, options.index));
      b_n = num(getTitle(b, options.index));

      if (a_n > b_n) return 1;
      if (a_n < b_n) return -1;
      // Strängdelen och textdelen är samma,
      // jämför suffix.
      ans = strnum(getTitle(a, options.index));
      bns = strnum(getTitle(b, options.index));

      if (ans > bns) return 1;
      if (ans < bns) return -1;
      // Övriga matchningar sorteras alfabetiskt.
      return getTitle(a, options.index) > getTitle(b, options.index) ? 1 : -1;
    }

    return options.array.sort(comparer);
}

module.exports = arraySort;

},{}],"utils/cssmodifier":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk


function getCSSRule(ruleName) {
    ruleName = ruleName.toLowerCase();
    var rule = undefined;
    if (!document || !document.styleSheets) return;
    Array.prototype.forEach.call(document.styleSheets, styleSheet => {
      try {
        if (!styleSheet.cssRules) return;
        var mathces = Array.prototype.filter.call(styleSheet.cssRules, cssRule =>
          cssRule instanceof CSSStyleRule &&
          cssRule.selectorText.toLowerCase() === ruleName
        );
        if (mathces[mathces.length - 1]) {
          rule = mathces[mathces.length - 1];
        }
      } catch (e) {
        // Firefox throws if the css is loaded from external source.
      }
    });
    return rule;
}

module.exports = {
    configure: function (config) {
      if (!config.secondaryColor || !config.primaryColor) {
        return;
      }
      var panelHeader = getCSSRule('.navigation-panel-inner > .panel-heading')

      var panelHeaderItalic = getCSSRule('.navigation-panel-inner > .panel-heading i');

      var navigationPanelInner = getCSSRule('.navigation-panel-inner');

      var dl = getCSSRule('dl');
      var dt = getCSSRule('dt');

      var btnPrimary = getCSSRule('.btn-primary');
      var btnPrimaryFocus = getCSSRule('.btn-primary:focus');
      var btnPrimaryHover = getCSSRule('.btn-primary:hover');
      var btnPrimaryActive = getCSSRule('.btn-primary:active');
      var btnPrimaryActiveHover = getCSSRule('.btn-primary:active:hover');
      var btnPrimaryActiveHoverIE = getCSSRule('.btn-primary:hover:active');

      var btnMain = getCSSRule('.btn-main');
      var btnMainFocus = getCSSRule('.btn-main:focus');
      var btnMainHover = getCSSRule('.btn-main:hover');
      var btnMainActive = getCSSRule('.btn-main:active');
      var btnMainActiveHover = getCSSRule('.btn-main:active:hover');
      var btnMainActiveHoverIE = getCSSRule('.btn-main:hover:active');

      var drawToolsSelected = getCSSRule('.draw-tools li.selected');
      var informationBlanketHeader = getCSSRule('.information #blanket #container #header');
      var informationBlanketContainer = getCSSRule('.information #blanket #container');

      var olControlButton = getCSSRule('.ol-control button');
      var olControlButtonHover = getCSSRule('.ol-control button:hover');
      var olControlButtonFocus = getCSSRule('.ol-control button:focus');
      var olControlButtonActive = getCSSRule('.ol-control button:active');

      var mapScaleBar = getCSSRule('#map-scale-bar .ol-scale-line');
      var mapScaleBarInner = getCSSRule('#map-scale-bar .ol-scale-line-inner');
      var mapScaleText = getCSSRule('.map-scale .map-scale-text');

      var searchToolGroup = getCSSRule('.search-tools .group');
      var searchbarInputField = getCSSRule('#searchbar-input-field');
      var searchbarSearchButton = getCSSRule('#searchbar-search-button');

      if (panelHeader) {
        panelHeader.style.backgroundColor = config.primaryColor;
        panelHeader.style.borderColor = config.primaryColor;
        panelHeader.style.color = config.secondaryColor;
      } else {
        console.error("Wat");
      }


      if (panelHeaderItalic) {
        panelHeaderItalic.style.color = config.secondaryColor;
      }
      if (navigationPanelInner) {
        navigationPanelInner.style.borderRight = "1px solid " + config.primaryColor;
      }
      if (dl) {
        dl.style.border = "1px solid " + config.primaryColor;
      }
      if (dt) {
        dt.style.backgroundColor = config.primaryColor;
        dt.style.color = config.secondaryColor;
      }
      if (btnPrimary) {
        btnPrimary.style.backgroundColor = config.primaryColor;
        btnPrimary.style.zIndex = 2;
        btnPrimary.style.color = config.secondaryColor;
      }
      if (btnPrimaryFocus) {
        btnPrimaryFocus.style.backgroundColor = config.primaryColor;
        btnPrimaryFocus.style.color = config.secondaryColor;
      }
      if (btnPrimaryHover) {
        btnPrimaryHover.style.backgroundColor = config.primaryColor;
        btnPrimaryHover.style.color = config.secondaryColor;
      }
      if (btnPrimaryActive) {
        btnPrimaryActive.style.backgroundColor = config.primaryColor;
        btnPrimaryActive.style.color = config.secondaryColor;
      }
      if (btnPrimaryActiveHover) {
        btnPrimaryActiveHover.style.backgroundColor = config.primaryColor;
        btnPrimaryActiveHover.style.color = config.secondaryColor;
      }

      if (btnPrimaryActiveHoverIE) {
        btnPrimaryActiveHoverIE.style.backgroundColor = config.primaryColor;
        btnPrimaryActiveHoverIE.style.color = config.secondaryColor;
      }

      if (btnMain) {
        btnMain.style.backgroundColor = config.primaryColor;
        btnMain.style.zIndex = 2;
        btnMain.style.color = config.secondaryColor;
      }
      if (btnMainFocus) {
        btnMainFocus.style.backgroundColor = config.primaryColor;
        btnMainFocus.style.color = config.secondaryColor;
      }
      if (btnMainHover) {
        btnMainHover.style.backgroundColor = config.primaryColor;
        btnMainHover.style.color = config.secondaryColor;
      }
      if (btnMainActive) {
        btnMainActive.style.backgroundColor = config.primaryColor;
        btnMainActive.style.color = config.secondaryColor;
      }
      if (btnMainActiveHover) {
        btnMainActiveHover.style.backgroundColor = config.primaryColor;
        btnMainActiveHover.style.color = config.secondaryColor;
      }

      if (btnMainActiveHoverIE) {
        btnMainActiveHoverIE.style.backgroundColor = config.primaryColor;
        btnMainActiveHoverIE.style.color = config.secondaryColor;
      }

      if (drawToolsSelected) {
        drawToolsSelected.style.backgroundColor = config.primaryColor;
      }

      if (informationBlanketHeader) {
        informationBlanketHeader.style.backgroundColor = config.primaryColor;
        informationBlanketHeader.style.color = config.secondaryColor;
      }
      if (informationBlanketContainer) {
        informationBlanketContainer.style.borderColor = config.primaryColor;
      }
      if (olControlButton) {
        olControlButton.style.backgroundColor = config.primaryColor;
        olControlButton.style.color = config.secondaryColor;
      }
      if (olControlButtonHover) {
        olControlButtonHover.style.backgroundColor = config.primaryColor;
        olControlButtonHover.style.color = config.secondaryColor;
      }
      if (olControlButtonFocus) {
        olControlButtonFocus.style.backgroundColor = config.primaryColor;
        olControlButtonFocus.style.color = config.secondaryColor;
      }
      if (olControlButtonActive) {
        olControlButtonActive.style.backgroundColor = config.primaryColor;
        olControlButtonActive.style.color = config.secondaryColor;
      }
      if (mapScaleBar) {
        mapScaleBar.style.backgroundColor = config.primaryColor;
      }
      if (mapScaleBarInner) {
        mapScaleBarInner.style.color = config.secondaryColor;
      }
      if (mapScaleText) {
        mapScaleText.style.backgroundColor = config.primaryColor;
        mapScaleText.style.color = config.secondaryColor;
      }
      if (searchToolGroup) {
        searchToolGroup.style.backgroundColor = config.primaryColor;
        searchToolGroup.style.color = config.secondaryColor;
      }
      if (searchbarInputField) {
        searchbarInputField.style.borderColor = config.primaryColor;
      }
      if (searchbarSearchButton) {
         searchbarSearchButton.style.background = config.primaryColor;
      }
    }
};

},{}],"utils/kmlwriter":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function colorToArray(color, type) {

  var res = []
  ,   reg = type === "rgb"
  ? /rgb\((.+)\)/
  : /rgba\((.+)\)/;

  if (Array.isArray(color)) {
    res = color;
  } else {
    res = reg.exec(color)[1].split(',').map(a => parseFloat(a));
    if (type === "rgb") {
      res.push(1);
    }
  }

  return res;
}

function toKmlColor(color) {
  var s, r, g, b, o, res;
  if (color) {
    res = /^rgba/.test(color) ? colorToArray(color, 'rgba') : colorToArray(color, 'rgb');
    s = rgbToHex(res[0], res[1], res[2]);
    r = s.substr(0, 2);
    g = s.substr(2, 2);
    b = s.substr(4, 2);
    o = (Math.floor(res[3] * 255)).toString(16);
    return o + b + g + r;
  }
}

function toKmlString(str, type) {

  var strs = []
  ,   a
  ,   b;

  switch (type) {
      case 'point':
        str = str.replace(/^POINT\(/, '').replace(/\)$/, '');
        str = str.replace(/^POINT Z\(/, '').replace(/\)$/, '');
        str = str.replace(/^MULTIPOINT\(/, '').replace(/\)$/, '');
        str = str.replace(/^MULTIPOINT Z\(/, '').replace(/\)$/, '');
        break;
      case 'line':
        str = str.replace(/^LINESTRING\(/, '').replace(/\)$/, '');
        str = str.replace(/^LINESTRING Z\(/, '').replace(/\)$/, '');
        str = str.replace(/^MULTILINESTRING\(/, '').replace(/\)$/, '');
        str = str.replace(/^MULTILINESTRING Z\(/, '').replace(/\)$/, '');
        break;
      case 'polygon':
        strs = str.split('),(');
        str = "";
        _.each(strs, function (coords, i) {
          if (i === 0) {
            coords = coords.replace(/^POLYGON\(\(/, '').replace(/\)$/, '');
            coords = coords.replace(/^POLYGON Z\(\(/, '').replace(/\)$/, '');
            str +=   '<outerBoundaryIs>';
            str +=     '<LinearRing>';
            str +=       '<coordinates>' + coords + '</coordinates>';
            str +=     '</LinearRing>';
            str +=   '</outerBoundaryIs>';
          } else {
            coords = coords.replace(/\)/g, '');
            str +=   '<innerBoundaryIs>';
            str +=     '<LinearRing>';
            str +=       '<coordinates>' + coords + '</coordinates>';
            str +=     '</LinearRing>';
            str +=   '</innerBoundaryIs>';
          }
        });
        break;

      case 'multiPolygon':
        a = str.split(')),((');
        str = "";
        _.each(a, function (coords, t) {

          if (t === 0) {
            coords = coords.replace(/^MULTIPOLYGON\(\(/, '').replace(/\)$/, '');
            coords = coords.replace(/^MULTIPOLYGON Z\(\(\(/, '').replace(/\)$/, '');
          }

          b = coords.split('),(');

          str += '<Polygon>';
          _.each(b, function (coordinates, i) {
            coordinates = coordinates
              .replace(/\)/g, '')
              .split(',')
              .map(coordString => {
                var coords = coordString.split(' ');
                return (coords[0] + " " + coords[1]);
              });

            if (i === 0) {
              str +=   '<outerBoundaryIs>';
              str +=     '<LinearRing>';
              str +=       '<coordinates>' + coordinates + '</coordinates>';
              str +=     '</LinearRing>';
              str +=   '</outerBoundaryIs>';
            } else {
              str +=   '<innerBoundaryIs>';
              str +=     '<LinearRing>';
              str +=       '<coordinates>' + coordinates + '</coordinates>';
              str +=     '</LinearRing>';
              str +=   '</innerBoundaryIs>';
            }
          });
          str += '</Polygon>';
        });

        break;
  }

  return str.replace(/ /g, '_')
            .replace(/,/g, ' ')
            .replace(/_/g, ',')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
}

function point(f) {
  var str = "";
  str += '<Point>';
  str +=   '<coordinates>' + toKmlString(f, "point") + '</coordinates>';
  str += '</Point>';
  return str;
}

function line(f) {
  var str = "";
  str += '<LineString>';
  str +=    '<coordinates>' + toKmlString(f, "line") + '</coordinates>';
  str += '</LineString>';
  return str;
}

function polygon(f) {
  var str = "";
  str += '<Polygon>';
      str += toKmlString(f, "polygon");
  str += '</Polygon>';
  return str;
}

function multiPolygon(f) {
  var str = "";
  str += '<MultiGeometry>';
  str += toKmlString(f, "multiPolygon");
  str += '</MultiGeometry>';
  return str;
}

function safeInject(string) {
  string = string.toString();
  return string.replace(/<\/?[^>]+(>|$)|&/g, "");
}

function extractStyle(style) {

  var obj = {
    text: "",
    image: "",
    pointRadius: 0,
    pointColor: "",
    fillColor: "",
    strokeColor: "",
    strokeWidth: "",
    strokeDash: ""
  };

  obj.text = style.getText() ? style.getText().getText() : "";
  obj.image = style.getImage() instanceof ol.style.Icon ? style.getImage().getSrc() : "";
  obj.pointRadius = style.getImage() instanceof ol.style.Circle ? style.getImage().getRadius() : "";
  obj.pointColor = style.getImage() instanceof ol.style.Circle ? style.getImage().getFill().getColor() : "";
  obj.fillColor = style.getFill().getColor();
  obj.strokeColor = style.getStroke().getColor();
  obj.strokeWidth = style.getStroke().getWidth();
  obj.strokeDash = style.getStroke().getLineDash();

  return obj;
}

function filterProperties (template, properties) {
  var props = {};
  Object.keys(properties).filter(property => {
    var regExp = new RegExp(`{export:${property}}`);
    if (regExp.test(template)) {
      props[property] = properties[property];
    }
  });
  return props;
}

var kmlWriter = {};

kmlWriter.transform = (features, from, to) => {
  return features.map(feature => {
    var c = feature.clone()
    ,   style = Array.isArray(feature.getStyle())
        ? feature.getStyle()[1]
        : feature.getStyle();
    c.getGeometry().transform(from, to);
    c.setStyle(style);
    c.caption = feature.caption;
    c.infobox = feature.infobox;
    return c;
  });
};

kmlWriter.createXML = (features, name) => {

  var header = ''
  ,   parser = new ol.format.WKT()
  ,   doc = ''
  ;

  header += '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">';
  doc += '<Document>';
  doc += '<name>' + name + '</name>';

  doc += "<Folder>";
  doc += "<name>" + name + "</name>";
  doc += "<open>0</open>";

  features.forEach((feature, i) => {

    var style = Array.isArray(feature.getStyle())
    ? (feature.getStyle()[1] || feature.getStyle()[0])
    : feature.getStyle();

    doc += '<Style id="' + i + '">';
        if (style.getImage() instanceof ol.style.Icon) {
            doc += '<IconStyle>';
            doc +=   '<scale>' + (style.getImage().getSize()[0] / 32) + '</scale>';
            doc +=     '<Icon>';
            doc +=       '<href>' + style.getImage().getSrc() + '</href>';
            doc +=     '</Icon>';
            doc += '</IconStyle>';
        }

        if (style.getStroke() instanceof ol.style.Stroke) {
            doc += '<LineStyle>';
            doc +=   '<color>' + toKmlColor(style.getStroke().getColor()) + '</color>';
            doc +=   '<width>' + style.getStroke().getWidth() + '</width>';
            doc += '</LineStyle>';
        }

        if (style.getFill() instanceof ol.style.Fill) {
            doc += '<PolyStyle>';
            doc +=    '<color>' + toKmlColor(style.getFill().getColor()) + '</color>';
            doc += '</PolyStyle>';
        }

    doc += '</Style>';
  });

  features.forEach((feature, i) => {

    var style = Array.isArray(feature.getStyle())
    ? (feature.getStyle()[1] || feature.getStyle()[0])
    : feature.getStyle();

    var text = style.getText()
    ? style.getText().getText()
    : "";

    var description = feature.getProperties().description || ""
    ,   name = feature.getProperties().namn || feature.getProperties().name || feature.caption || text
    ;

    if (!description && feature.getProperties()) {
        description = "<table>";
        let properties = feature.getProperties();

        if (feature.infobox) {
          properties = filterProperties(feature.infobox, properties);
        }

        _.each(properties, function (value, attribute) {
            if (typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean") {
                description += "<tr>";
                    description += "<td>" + attribute + "</td>";
                    description += "<td>" + safeInject(value) + "</td>";
                description += "</tr>";
            }
        });

        description += "</table>";
    }

    doc += '<Placemark>';
    doc += '<name>' + (name || ('Ritobjekt ' + (i + 1))) + '</name>';
    doc += '<description>' + (description || ('Ritobjekt ' + (i + 1))) + '</description>';
    doc += '<styleUrl>#' + i + '</styleUrl>';

    if (feature.getGeometry() instanceof ol.geom.Point) {
        doc += point(parser.writeFeature(feature));
    }
    if (feature.getGeometry() instanceof ol.geom.MultiPoint) {
        doc += point(parser.writeFeature(feature));
    }
    if (feature.getGeometry() instanceof ol.geom.LineString) {
        doc += line(parser.writeFeature(feature));
    }
    if (feature.getGeometry() instanceof ol.geom.MultiLineString) {
        doc += line(parser.writeFeature(feature));
    }
    if (feature.getGeometry() instanceof ol.geom.Polygon) {
        doc += polygon(parser.writeFeature(feature));
    }
    if (feature.getGeometry() instanceof ol.geom.MultiPolygon) {
        doc += multiPolygon(parser.writeFeature(feature));
    }

    if (feature.getProperties().style) {
        doc += '<ExtendedData>';
        doc +=    '<Data name="style">';
        doc +=       '<value>' + feature.getProperties().style + '</value>';
        doc +=    '</Data>';
        doc += '</ExtendedData>';
    }
    doc += '</Placemark>';
  });

  doc += "</Folder>";
  doc += '</Document>';
  header += doc;
  header += '</kml>';

  return header
};

module.exports = kmlWriter;

},{}],"views/anchorpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
/**
 * @class
 */
var AnchorPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      anchor: ""
    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.generate();
  },

  /**
   * Generete anchor text.
   * @instance
   */
  generate: function () {
    this.setState({
      anchor: this.props.model.generate()
    });
  },

  openInstruction: function (){
    var element = $("#instructionText");
    element.toggle();
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var anchor = this.props.model.get('anchor');
    return (
      React.createElement(Panel, {title: "Länk till karta", onUnmountClicked: this.props.onUnmountClicked, onCloseClicked: this.props.onCloseClicked, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "panel-content"}, 
          React.createElement("button", {onClick: this.generate, className: "btn btn-main"}, "Skapa länk"), React.createElement("br", null), React.createElement("br", null), 
          React.createElement("p", null, 
            "När du har skapat en länk kan du kopiera länkadressen genom att högerklicka på \"Länk\" nedan.", React.createElement("br", null)
          ), 
          React.createElement("div", {className: "alert alert-success"}, 
            React.createElement("a", {target: "_blank", href: anchor}, "Länk")
          )
        )
      )
    );
  }
};

/**
 * AnchorPanelView module.<br>
 * Use <code>require('views/anchorpanel')</code> for instantiation.
 * @module AnchorPanelView-module
 * @returns {AnchorPanelView}
 */
module.exports = React.createClass(AnchorPanelView);

},{"views/panel":"views/panel"}],"views/application":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Shell = require('views/shell');
var ShellModel = require('models/shell');

/**
 * Application view
 * @class
 * @augments {external:"Backbone.View"}
 */
var ApplicationView = {
  /**
   * @property {string} el - DOM element to render this app into.
   * @instance
   */
  /**
   * Load the application.
   * @instance
   * @param {object} config
   */
  load: function (config, bookmarks) {
    this.shell = new ShellModel(config);
    this.shell.setBookmarks(bookmarks);
    this.shell.on('change:configUpdated', () => {
      this.shell.updateConfig();
      this.shell.setBookmarks(bookmarks);
    });
  },

  initialize: function (config, bookmarks) {
    this.load(config, bookmarks);
  },
  /**
   * Render the view
   * @instance
   * @param {boolean} force - Force update
   */
  render: function (force) {
    // TODO: get ID from config instead
    var el = document.getElementById('map');
    
    var errorStyle = { 'margin-top': '50px', 'text-align': 'center' };

    if (!el) {
      return alert("Applikationen har stannat. Försök igen senare.");
    }

    if (force) {
      ReactDOM.unmountComponentAtNode(el);
    }

    if (this.shell.get('canStart')) {
      ReactDOM.render(React.createElement(Shell, {model: this.shell}), el);
    } else {
      ReactDOM.render(
        React.createElement("div", {className: "container"}, 
          React.createElement("div", {className: "alert alert-danger", style: errorStyle}, 
            React.createElement("h2", null, "Kartan kunde inte startas upp."), 
            React.createElement("p", null, "Var god kontakta systemadminstratören.")
          )
        ),
        el
      );
    }
  }
};

/**
 * ApplicationView module.<br>
 * Use <code>require('views/application')</code> for instantiation.
 * @module ApplicationView-module
 * @returns {ApplicationView}
 */
module.exports = Backbone.View.extend(ApplicationView);

},{"models/shell":"models/shell","views/shell":"views/shell"}],"views/bookmarkpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
/**
 * @class
 */
var BookmarkPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
    this.bind();
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.bind();
  },

  /**
   * Bind DOM events.
   * @instance
   */
  bind: function () {
    var node = $(ReactDOM.findDOMNode(this));
    node.find('li').mousedown(() => false);
  },

  /**
   * Delegate to handle insertion of bookmarks.
   * @instance
   * @param {object} e - Syntetic DOM event.
   */
  onSubmitForm: function (e) {
    e.preventDefault();
    var name = ReactDOM.findDOMNode(this.refs.name).value;
    if(name.length > 0) {
      this.props.model.addBookmark(name, () => this.forceUpdate());
    }
  },

  /**
   * Remove a bookmark from the view.
   * @instance
   * @param {number} id - ID of bookmark.
   * @param {object} e - Syntetic DOM event.
   */
  removeBookmark: function (id, e) {
    this.props.model.removeBookmark(id, () => this.forceUpdate());
    e.stopPropagation();
  },

  /**
   * Update a bookmark in the view.
   * @instance
   * @param {number} id - ID of bookmark.
   * @param {object} e - Syntetic DOM event.
   */
  updateBookmark: function (id, e) {
    var bookmarks = this.props.model.getBookmarks();
    var bookmark = bookmarks.filter(bookmark => bookmark.id === id)[0];
    bookmarks.forEach((bookmark) => { bookmark.favourite = false });

    if (bookmark) {
      bookmark.favourite = true;
      this.props.model.updateBookmark(bookmark, () => this.forceUpdate());
    }

    e.stopPropagation();
  },

  /**
   * Load a bookmark through the model.
   * @instance
   * @param {object} bookmark
   */
  loadBookmark: function (bookmark) {
    this.props.model.updateApplication(bookmark);
  },

  openInstruction: function (){
    var element = $("#instructionText");
    element.toggle();
  },

  /**
   * Render the view.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    var bookmarks = this.props.model.getBookmarks();
    var items = null;

    if (bookmarks) {
      items = bookmarks.map((bookmark, i) => {
        var iconClass = bookmark.favourite ?
          'favourite fa icon fa-check-circle' :
          'favourite fa icon fa-circle';
        return (
          React.createElement("li", {key: i, onClick: this.loadBookmark.bind(this, bookmark)}, 
            React.createElement("i", {className: iconClass, onClick: this.updateBookmark.bind(this, bookmark.id)}), 
            bookmark.name, 
            React.createElement("i", {className: "delete fa icon fa-remove", onClick: this.removeBookmark.bind(this, bookmark.name)})
          )
        );
      });
    }

    return (
      React.createElement(Panel, {title: "Bokmärken", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "bookmark-panel panel-content"}, 
          React.createElement("form", {onSubmit: this.onSubmitForm}, 
            React.createElement("div", {className: "form-group"}, 
              React.createElement("label", null, "Lägg till bokmärke"), 
              React.createElement("div", {className: "input-group"}, 
                React.createElement("div", {className: "input-group-addon"}, 
                  React.createElement("i", {className: "fa fa-bookmark"})
                ), 
                React.createElement("input", {type: "text", 
                  ref: "name", 
                  className: "form-control", 
                  placeholder: "Ange namn"})
              )
            )
          ), 
          React.createElement("ul", null, items)
        )
      )
    );
  }
};

/**
 * BookmarkPanelView module.<br>
 * Use <code>require('views/bookmarkpanel')</code> for instantiation.
 * @module BookmarkPanelView-module
 * @returns {BookmarkPanelView}
 */
module.exports = React.createClass(BookmarkPanelView);

},{"views/panel":"views/panel"}],"views/bufferpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

const Panel = require('views/panel');

/**
 * @class
 */
var BufferPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      activeTool: this.props.model.get('activeTool'),
      validBufferDist: this.props.model.isNumber(this.props.model.get('bufferDist'))
    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
  },

  componentWillUnmount: function () {
    this.props.model.setActiveTool(undefined);
  },

  activateTool: function (name) {
    if (this.props.model.getActiveTool() === name) {
      this.props.model.setActiveTool(undefined);
    } else {
      this.props.model.setActiveTool(name);
    }
    this.setState({
      activeTool: this.props.model.getActiveTool()
    });
  },

  getClassNames: function (type) {
    return this.state.activeTool === type
      ? "btn btn-primary"
      : "btn btn-default";
  },

  openInstruction: function (){
    var element = $("#instructionText");
    element.toggle();
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    if(this.props.model.get('varbergVer')){
      return (
        React.createElement(Panel, {title: "Urvalsområde", onUnmountClicked: this.props.onUnmountClicked, onCloseClicked: this.props.onCloseClicked, instruction: atob(this.props.model.get('instruction'))}, 
          React.createElement("div", {className: "panel-content"}, 
            React.createElement("br", null), React.createElement("br", null), 
            React.createElement("div", {className: "panel panel-default-transparent"}, 
              React.createElement("button", {onClick: () => this.props.model.activateBufferMarker(), type: "button", className: "btn btn-main", 
                      title: "Markera flera objekt"}, 
                "Sätt ut mittpunkt"
              )
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Ange avstånd från mittpunkt"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("input", {
                  id: "buff-dist", 
                  type: "text", 
                  name: "buff-dist", 
                  style: {
                    background: this.state.validBufferDist ? '#FFF' : '#F33'
                  }, 
                  value: this.props.model.get('bufferDist'), 
                  onBlur: (e) => {
                    this.setState({
                      validBufferDist: this.props.model.isNumber(e.target.value)
                    });
                  }, 
                  onChange: (e) => {
                    this.props.model.set('bufferDist', e.target.value);
                    this.setState({
                      bufferDist: e.target.value
                    });
                  }}
                ), " m"
              )
            ), 
            React.createElement("div", {className: "panel panel-default-transparent"}, 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("button", {
                  onClick: () => {
                    var status = this.props.model.buffer();
                    if (!status) {
                      this.setState({
                        validBufferDist: false
                      });
                    }
                  }, 
                  className: "btn btn-main"}, 
                  "Skapa buffertzon"
                ), 
                " ", 
                React.createElement("button", {
                  onClick: () => this.props.model.clearBuffer(), 
                  className: "btn btn-main"}, 
                  "Rensa"
                )
              )
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Resultat: Objekt inom buffertzon"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("div", {id: "visibleLayerList"})
              )
            )
          )
        )
      );
    }else{
      return (
        React.createElement(Panel, {title: "Buffertverktyg", onUnmountClicked: this.props.onUnmountClicked, onCloseClicked: this.props.onCloseClicked, instruction: atob(this.props.model.get('instruction'))}, 
          React.createElement("div", {className: "panel-content"}, 
            React.createElement("p", null, 
              "Detta verktyg skapar en zon med angivet avstånd runt valda objekt i kartan."
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Välj objekt"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("button", {onClick: () => this.activateTool('multiSelect'), type: "button", className: this.getClassNames('multiSelect'), title: "Markera flera objekt"}, 
                  React.createElement("i", {className: "fa fa-plus icon"})
                ), 
                " ", 
                React.createElement("span", {
                  onClick: () => {
                    this.props.model.clearSelection();
                  }, 
                  className: "btn btn-link"}, 
                "Rensa selektering"
              )
              )
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Ange buffertavstånd"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("input", {
                  id: "buff-dist", 
                  type: "text", 
                  name: "buff-dist", 
                  style: {
                    background: this.state.validBufferDist ? '#FFF' : '#F33'
                  }, 
                  value: this.props.model.get('bufferDist'), 
                  onBlur: (e) => {
                    this.setState({
                      validBufferDist: this.props.model.isNumber(e.target.value)
                    });
                  }, 
                  onChange: (e) => {
                    this.props.model.set('bufferDist', e.target.value);
                    this.setState({
                      bufferDist: e.target.value
                    });
                  }}
                ), " m"
              )
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Skapa buffert"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("button", {
                  onClick: () => {
                    var status = this.props.model.buffer();
                    if (!status) {
                      this.setState({
                        validBufferDist: false
                      });
                    }
                  }, 
                  className: "btn btn-primary"}, 
                  "Buffra"
                )
              )
            ), 
            React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, "Rensa kartan från buffrade objekt"), 
              React.createElement("div", {className: "panel-body"}, 
                React.createElement("button", {
                  onClick: () => this.props.model.clearBuffer(), 
                  className: "btn btn-primary"}, 
                  "Rensa"
                )
              )
            )
          )
        )
      );

    }

  }
};

/**
 * bufferPanelView module.<br>
 * Use <code>require('views/bufferpanel')</code> for instantiation.
 * @module BufferPanelView-module
 * @returns {BufferPanelView}
 */
module.exports = React.createClass(BufferPanelView);

},{"views/panel":"views/panel"}],"views/coordinatespanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');

var CoordinatesList = React.createClass({displayName: "CoordinatesList",

  isPlanar: function(epsgString) {
    return (
      ['WGS 84'].indexOf(epsgString) === -1
    );
  },

  convertDDToDMS: function(D, lng){
    return {
      dir : D < 0 ? lng? 'W' : 'S' :lng ? 'E' : 'N',
      deg : Math.floor((D < 0 ? D =- D : D)),
      min : Math.floor((D * 60) % 60),
      sec : ((D * 3600) % 60).toFixed(5)
    };
  },

  formatDMS: function(dms) {
    return (
      [dms.deg, '°', dms.min, '′', dms.sec, '″', dms.dir].join('')
    )
  },

  getLon: function (xyObject) {
    return (
      React.createElement("span", null, 
        React.createElement("strong", null,  xyObject.xtitle, ": "), " ",  this.formatDMS(this.convertDDToDMS(xyObject.x, true)) 
      )
    )
  },

  getLat: function (xyObject) {
    return (
      React.createElement("span", null, 
        React.createElement("strong", null,  xyObject.ytitle, ": "), " ",  this.formatDMS(this.convertDDToDMS(xyObject.y, false)) 
      )
    )
  },

  getX: function (xyObject) {
    return (
      React.createElement("span", null, 
        React.createElement("strong", null,  xyObject.xtitle, ": "), " ",  xyObject.x.toFixed(2), " m"
      )
    )
  },

  getY: function (xyObject) {
    return (
      React.createElement("span", null, 
        React.createElement("strong", null,  xyObject.ytitle, ": "), " ",  xyObject.y.toFixed(2), " m"
      )
    )
  },

  processSphericalXY: function(xyObject) {
    return (
      React.createElement("div", null, 
        React.createElement("dd", null, 
          xyObject.inverseAxis ? this.getLat(xyObject) : this.getLon(xyObject)
        ), 
        React.createElement("dd", null, 
          xyObject.inverseAxis ? this.getLon(xyObject) : this.getLat(xyObject)
        )
      )
    )
  },

  processPlanarXY: function(xyObject) {
    return (
      React.createElement("div", null, 
        React.createElement("dd", null, 
          xyObject.inverseAxis ? this.getX(xyObject) : this.getY(xyObject)
        ), 
        React.createElement("dd", null, 
          xyObject.inverseAxis ? this.getY(xyObject) : this.getX(xyObject)
        )
      )
    )
  },

  processTitle: function(title, object) {
    if (object.hasOwnProperty('default') && object['default'] === true){
      return (
        React.createElement("dt", null, 
          React.createElement("strong", {style: {fontWeight: 'bold'}}, title), " ", object.hint
        )
      )
    } else {
      return (
        React.createElement("dt", null, title)
      )
    }
  },

  processRow: function(object, title) {
    if (this.isPlanar(title)) {
      return (
        [this.processTitle(title, object), this.processPlanarXY(object)]
      )
    } else {
      return (
        [this.processTitle(title, object), this.processSphericalXY(object)]
      )
    }
  },

  render: function() {
    var coordinates = this.props.coordinates;
    return (
      React.createElement("dl", null, 
         Object.keys(coordinates).map((key) => this.processRow(coordinates[key], key)) 
      )
    )
  }
});

/**
 * @class
 */
var CoordinatesPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false,
      interactionVisible: true
    };
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.off('change:position', this.writeCoordinates);
    this.props.model.removeInteractions();
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.model.on('change:position', this.writeCoordinates);
    this.setState({
      coordinates: this.props.model.presentCoordinates()
    });
  },

  /**
   * Write coordinates, will trigger set state.
   * @instance
   */
  writeCoordinates: function () {
    this.setState({
      coordinates: this.props.model.presentCoordinates()
    });
  },

  /**
   * Reset the application, will trigger set state.
   * @instance
   */
  reset: function () {
    this.setState({
      interactionVisible: !this.state.interactionVisible
    });
    this.state.interactionVisible ? this.props.model.removeInteractions() :
                                    this.props.model.createInteractions();
  },

  /**
   * Render the view.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var coordinates;
    if (this.props.model.get('interactions').length === 0) {
      this.props.model.createInteractions();
    }
    coordinates = this.state.coordinates ? this.state.coordinates.transformed : {};
    return (
      React.createElement(Panel, {title: "Koordinater", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "coordinate-display"}, 
          React.createElement("p", null, 
            "Välj en plats i kartan genom att dra i siktet. ", React.createElement("br", null)
          ), 
          React.createElement(CoordinatesList, {coordinates: coordinates})
        )
      )
    );
  }
};

/**
 * CoordinatesPanelView module.<br>
 * Use <code>require('views/coordinatespanel')</code> for instantiation.
 * @module CoordinatesPanelView-module
 * @returns {CoordinatesPanelView}
 */
module.exports = React.createClass(CoordinatesPanelView);

},{"views/panel":"views/panel"}],"views/drawpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var Alert = require('alert');
var ColorPicker = require('components/colorpicker');

/**
 * @class
 */
var DrawPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false,
      pointSettings: this.props.model.get('pointSettings'),
      pointRadius: this.props.model.get('pointRadius'),
      pointSymbol: this.props.model.get('pointSymbol'),
      fontSize: this.props.model.get('fontSize'),
      lineWidth: this.props.model.get('lineWidth'),
      lineStyle: this.props.model.get('lineStyle'),
      circleLineColor: this.props.model.get('circleLineColor'),
      circleFillColor: this.props.model.get('circleFillColor'),
      circleLineStyle: this.props.model.get('circleLineStyle'),
      circleLineWidth: this.props.model.get('circleLineWidth'),
      polygonLineWidth: this.props.model.get('polygonLineWidth'),
      polygonLineStyle: this.props.model.get('polygonLineStyle'),
      polygonFillOpacity: this.props.model.get('polygonFillOpacity'),
      exportUrl: false,
      kmlImport: false,
      boxLineColor: this.props.model.get('boxLineColor'),
      boxFillColor: this.props.model.get('boxFillColor'),
      boxLineStyle: this.props.model.get('boxLineStyle'),
      boxLineWidth: this.props.model.get('boxLineWidth')
    };
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.abort();
    this.props.model.off('change:dialog');
    this.props.model.off('change:kmlExportUrl');
    this.props.model.off('change:kmlImport');
    this.props.model.off('change:downloadDrawKml');
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
    this.setState({
      showLabels: this.props.model.get('showLabels')
    });
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.model.on('change:dialog', () => {
      this.setState({
        dialog: this.props.model.get('dialog')
      });
      this.refs.textInput.focus();
    });
    this.props.model.on("change:kmlExportUrl", () => {
      this.setState({
        exportUrl: this.props.model.get('kmlExportUrl')
      });
    });
    this.props.model.on("change:kmlImport", () => {
      this.setState({
        kmlImport: this.props.model.get('kmlImport')
      });
    });
    this.props.model.on("change:downloadDrawKml")
  },

  /**
   * Render alert component
   * @instance
   * @return {AlertView}
   */
  renderAlert: function () {
    var options = {
      visible: this.state.alert,
      message: this.state.alertMessage,
      confirm: this.state.confirm,
      confirmAction: () => {
        this.state.confirmAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      denyAction: () => {
        this.state.denyAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      onClick: () => {
        this.setState({
          alert: false,
          alertMessage: ""
        })
      }
    };

    if (this.state.alert) {
      return React.createElement(Alert, {options: options})
    } else {
      return null;
    }
  },

  /**
   * Remove all drawings from map.
   * @instance
   */
  clear: function () {
    this.props.model.clear();
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
    this.props.model.set("circleRadius", "");
    this.setState({
      circleRadius: ""
    });
  },

  /**
   * Confirm before clear.
   * @instance
   */
  alertClear: function(){
    this.setState({
      alert: true,
      alertMessage: ` Vill du verkligen rensa allt?`,
      confirm: true,
      confirmAction: () => {
        this.clear();
      },
      denyAction: () => {
        this.setState({ alert: false });
      }
    });
  },

  /**
   * Abort any operation and deselect any tool.
   * @instance
   */
  abort: function () {
    this.props.model.abort();
    $('#Point, #Circle, #Text, #Polygon, #LineString, #move, #edit, #delete, #Box').removeClass('selected');
    $('#abort').hide();
    this.setState({
      symbology: ""
    })
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
    this.props.model.measureTooltip.setPosition(null);
  },

  /**
   * Import kml.
   * @instance
   */
  import: function () {
    this.abort();
    this.props.model.set("kmlExportUrl", false);
    this.props.model.import();
  },

  /**
   * Export kml.
   * @instance
   */
  export: function () {
    this.abort();
    this.props.model.set("kmlImport", false);
    this.props.model.export();
  },

  /**
   * Handle change event of the show labels checkbox.
   * @instance
   */
  toggleLabels: function () {
    this.setState({
      showLabels: this.props.model.toggleLabels()
    });
  },

  /**
   * Activate the removal tool and update visuals.
   * @instance
   */
  activateRemovalTool: function () {
    this.props.model.activateRemovalTool();
    $('#Point, #Text, #Polygon, #LineString, #Circle, #move, #edit, #delete, #Box').removeClass('selected');
    $('#delete').addClass('selected');
    $('#abort').show();
    this.setState({
      symbology: ""
    })
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
  },

  /**
   * Activate move tool and update visuals.
   * @instance
   */
  activateMoveTool: function () {
    this.props.model.activateMoveTool();
    $('#Point, #Text, #Polygon, #LineString, #Circle, #move, #edit, #delete, #Box').removeClass('selected');
    $('#move').addClass('selected');
    $('#abort').show();
    this.setState({
      symbology: ""
    })
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
  },

  /**
   * Activate move tool and update visuals.
   * @instance
   */
  activateEditTool: function () {
    this.props.model.activateEditTool();
    $('#Point, #Text, #Polygon, #LineString, #Circle, #move, #edit, #delete, #Box').removeClass('selected');
    $('#edit').addClass('selected');
    $('#abort').show();
    this.setState({
      symbology: ""
    })
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
  },

  /*
   * Activate given draw tool and update visuals.
   * @instance
   * @param {string} type
   */
  activateDrawTool: function (type) {
    this.props.model.activateDrawTool(type);
    $('#Circle, #Point, #Text, #Polygon, #LineString, #move, #edit, #delete, #Box').removeClass('selected');
    $('#' + type).addClass('selected');
    $('#abort').show();
    this.setState({
      symbology: type
    });
    this.props.model.set("kmlExportUrl", false);
    this.props.model.set("kmlImport", false);
  },

  /**
   * Set marker image.
   * @instance
   * @param {object} e
   */
  setMarkerImg: function(e) {
    this.props.model.set('markerImg', e.target.src);
    this.forceUpdate();
  },

  /**
   * Render the symbology component.
   * @instance
   * @param {string} type
   * @return {external:ReactElement} component
   */
  renderSymbology: function (type) {

    function update(func, state_prop, e) {
      var value = e.target.value
      ,   state = {};

      if (e.target.type === "checkbox") {
        value = e.target.checked;
      }

      if (typeof value === "string") {
        value = !isNaN(parseFloat(value)) ?
                 parseFloat(value) :
                !isNaN(parseInt(value)) ?
                 parseInt(value) : value;
      }
      state[state_prop] = value;
      this.setState(state);
      this.props.model[func].call(this.props.model, value);
    }

    function hasClass(icon) {
      return this.props.model.get('markerImg') === window.location.href + `assets/icons/${icon}.png`
        ? "selected"
        : "" ;
    }

    function renderIcons() {

      var icons = this.props.model.get('icons').split(',');

      return (
        icons.map((icon, i) => {
          icon = icon.trim();
          if (icon === "br") {
            return (React.createElement("br", {key: i}));
          } else {
            var iconSrc = `assets/icons/${icon}.png`;
            return (
              React.createElement("div", {key: i, className: hasClass.call(this, icon)}, 
                React.createElement("img", {onClick: this.setMarkerImg, src: iconSrc})
              )
            )
          }
        })
      );
    }

    function renderPointSettings() {
      switch (this.state.pointSettings) {
        case "point":
          return (
            React.createElement("div", null, 
              React.createElement("div", null, "Färg"), 
              React.createElement(ColorPicker, {
                model: this.props.model, 
                property: "pointColor", 
                onChange: this.props.model.setPointColor.bind(this.props.model)}
              ), 
              React.createElement("div", null, "Storlek"), 
              React.createElement("select", {value: this.state.pointRadius, onChange: update.bind(this, 'setPointRadius', 'pointRadius')}, 
                React.createElement("option", {value: "4"}, "Liten"), 
                React.createElement("option", {value: "7"}, "Normal"), 
                React.createElement("option", {value: "14"}, "Stor"), 
                React.createElement("option", {value: "20"}, "Större")
              )
            )
          );
        case "symbol":
          return (
            React.createElement("div", {className: "point-marker-img"}, 
                renderIcons.call(this)
            )
          );
      }
    }

    switch (type) {
      case "Text":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér text"), 
            React.createElement("div", null, "Textstorlek"), 
            React.createElement("select", {value: this.state.fontSize, onChange: update.bind(this, 'setFontSize', 'fontSize')}, 
              React.createElement("option", {value: "8"}, "8"), 
              React.createElement("option", {value: "10"}, "10"), 
              React.createElement("option", {value: "12"}, "12"), 
              React.createElement("option", {value: "14"}, "14"), 
              React.createElement("option", {value: "16"}, "16"), 
              React.createElement("option", {value: "18"}, "18"), 
              React.createElement("option", {value: "20"}, "20"), 
              React.createElement("option", {value: "30"}, "30"), 
              React.createElement("option", {value: "40"}, "40"), 
              React.createElement("option", {value: "60"}, "60"), 
              React.createElement("option", {value: "80"}, "100")
            ), 
            React.createElement("div", null, "Textfärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "fontColor", 
              onChange: this.props.model.setFontColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Bakgrundsfärg text"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              noColor: "true", 
              property: "fontBackColor", 
              onChange: this.props.model.setFontBackColor.bind(this.props.model)}
            )
          )
        );
      case "Point":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér punkt"), 
            React.createElement("label", null, "Välj typ"), 
            React.createElement("div", null, 
              React.createElement("select", {value: this.state.pointSettings, onChange: e => {
                  var value = e.target.value === "symbol" ? true : false;
                  update.call(this, 'setPointSettings', 'pointSettings', e);
                  update.call(this, 'setPointSymbol', 'pointSymbol', {
                    target: {
                      type: "checkbox",
                      checked: value
                    }
                  });
                }}, 
                React.createElement("option", {key: "point", value: "point"}, "Punkt"), 
                React.createElement("option", {key: "symbol", value: "symbol"}, "Symbol")
              )
            ), 
             renderPointSettings.call(this)
          )
        );
      case "LineString":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér linje"), 
            React.createElement("div", null, "Färg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "lineColor", 
              onChange: this.props.model.setLineColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Tjocklek"), 
            React.createElement("select", {value: this.state.lineWidth, onChange: update.bind(this, 'setLineWidth', 'lineWidth')}, 
              React.createElement("option", {value: "1"}, "Tunn"), 
              React.createElement("option", {value: "3"}, "Normal"), 
              React.createElement("option", {value: "5"}, "Tjock"), 
              React.createElement("option", {value: "8"}, "Tjockare")
            ), 
            React.createElement("div", null, "Stil"), 
            React.createElement("select", {value: this.state.lineStyle, onChange: update.bind(this, 'setLineStyle', 'lineStyle')}, 
              React.createElement("option", {value: "solid"}, "Heldragen"), 
              React.createElement("option", {value: "dash"}, "Streckad"), 
              React.createElement("option", {value: "dot"}, "Punktad")
            )
          )
        );
      case "Circle":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér cirkel"), 
            React.createElement("label", null, "Ange radie: "), " ", 
            React.createElement("input", {type: "text", name: "circle-radius", value: this.state.circleRadius, onChange: update.bind(this, 'setCircleRadius', 'circleRadius')}), 
            React.createElement("div", null, "Linjefärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "circleLineColor", 
              onChange: this.props.model.setCircleLineColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Fyllnadsfärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "circleFillColor", 
              onChange: this.props.model.setCircleFillColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Opacitet"), 
            React.createElement("select", {value: this.state.circleFillOpacity, onChange: update.bind(this, 'setCircleFillOpacity', 'circleFillOpacity')}, 
              React.createElement("option", {value: "0"}, "0% (genomskinlig)"), 
              React.createElement("option", {value: "0.25"}, "25%"), 
              React.createElement("option", {value: "0.5"}, "50%"), 
              React.createElement("option", {value: "0.75"}, "75%"), 
              React.createElement("option", {value: "1"}, "100% (fylld)")
            ), 
            React.createElement("div", null, "Linjetjocklek"), 
            React.createElement("select", {value: this.state.circleLineWidth, onChange: update.bind(this, 'setCircleLineWidth', 'circleLineWidth')}, 
              React.createElement("option", {value: "1"}, "Tunn"), 
              React.createElement("option", {value: "3"}, "Normal"), 
              React.createElement("option", {value: "5"}, "Tjock"), 
              React.createElement("option", {value: "8"}, "Tjockare")
            ), 
            React.createElement("div", null, "Linjestil"), 
            React.createElement("select", {value: this.state.circleLineStyle, onChange: update.bind(this, 'setCircleLineStyle', 'circleLineStyle')}, 
              React.createElement("option", {value: "solid"}, "Heldragen"), 
              React.createElement("option", {value: "dash"}, "Streckad"), 
              React.createElement("option", {value: "dot"}, "Punktad")
            )
          )
        );
      case "Polygon":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér yta"), 
            React.createElement("div", null, "Linjefärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "polygonLineColor", 
              onChange: this.props.model.setPolygonLineColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Fyllnadsfärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "polygonFillColor", 
              onChange: this.props.model.setPolygonFillColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Opacitet"), 
            React.createElement("select", {value: this.state.polygonFillOpacity, onChange: update.bind(this, 'setPolygonFillOpacity', 'polygonFillOpacity')}, 
              React.createElement("option", {value: "0"}, "0% (genomskinlig)"), 
              React.createElement("option", {value: "0.25"}, "25%"), 
              React.createElement("option", {value: "0.5"}, "50%"), 
              React.createElement("option", {value: "0.75"}, "75%"), 
              React.createElement("option", {value: "1"}, "100% (fylld)")
            ), 
            React.createElement("div", null, "Linjetjocklek"), 
            React.createElement("select", {value: this.state.polygonLineWidth, onChange: update.bind(this, 'setPolygonLineWidth', 'polygonLineWidth')}, 
              React.createElement("option", {value: "1"}, "Tunn"), 
              React.createElement("option", {value: "3"}, "Normal"), 
              React.createElement("option", {value: "5"}, "Tjock"), 
              React.createElement("option", {value: "8"}, "Tjockare")
            ), 
            React.createElement("div", null, "Linjestil"), 
            React.createElement("select", {value: this.state.polygonLineStyle, onChange: update.bind(this, 'setPolygonLineStyle', 'polygonLineStyle')}, 
              React.createElement("option", {value: "solid"}, "Heldragen"), 
              React.createElement("option", {value: "dash"}, "Streckad"), 
              React.createElement("option", {value: "dot"}, "Punktad")
            )
          )
        );
      case "Box":
        return (
          React.createElement("div", null, 
            React.createElement("h2", null, "Ritmanér yta"), 
            React.createElement("div", null, "Linjefärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "boxLineColor", 
              onChange: this.props.model.setBoxLineColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Fyllnadsfärg"), 
            React.createElement(ColorPicker, {
              model: this.props.model, 
              property: "boxFillColor", 
              onChange: this.props.model.setBoxFillColor.bind(this.props.model)}
            ), 
            React.createElement("div", null, "Opacitet"), 
            React.createElement("select", {value: this.state.boxFillOpacity, onChange: update.bind(this, 'setBoxFillOpacity', 'boxFillOpacity')}, 
              React.createElement("option", {value: "0"}, "0% (genomskinlig)"), 
              React.createElement("option", {value: "0.25"}, "25%"), 
              React.createElement("option", {value: "0.5"}, "50%"), 
              React.createElement("option", {value: "0.75"}, "75%"), 
              React.createElement("option", {value: "1"}, "100% (fylld)")
            ), 
            React.createElement("div", null, "Linjetjocklek"), 
            React.createElement("select", {value: this.state.boxLineWidth, onChange: update.bind(this, 'setBoxLineWidth', 'boxLineWidth')}, 
              React.createElement("option", {value: "1"}, "Tunn"), 
              React.createElement("option", {value: "3"}, "Normal"), 
              React.createElement("option", {value: "5"}, "Tjock"), 
              React.createElement("option", {value: "8"}, "Tjockare")
            ), 
            React.createElement("div", null, "Linjestil"), 
            React.createElement("select", {value: this.state.boxLineStyle, onChange: update.bind(this, 'setBoxLineStyle', 'boxLineStyle')}, 
              React.createElement("option", {value: "solid"}, "Heldragen"), 
              React.createElement("option", {value: "dash"}, "Streckad"), 
              React.createElement("option", {value: "dot"}, "Punktad")
            )
          )
        );
      default:
        return React.createElement("div", null);
    }
  },

  /**
   * Render the import result component.
   * @instance
   * @param {string} url
   * @return {external:ReactElement} component
   */
  renderImport: function (visible) {

    function upload() {
      this.refs.uploadIframe.addEventListener("load", () => {
        this.props.model.importDrawLayer(this.refs.uploadIframe.contentDocument);
      });
    }

    if (!visible) return null;
    var url = this.props.model.get('importUrl');
    var style = {display: "none"};
    return (
      React.createElement("div", null, 
        React.createElement("h4", null, "Importera"), 
        React.createElement("p", null, "Välj KML-fil att importera"), 
        React.createElement("form", {id: "upload-form", method: "post", action: url, target: "upload-iframe", encType: "multipart/form-data"}, 
          React.createElement("input", {onChange: upload.bind(this), type: "file", name: "files[]", accept: ".kml", multiple: "false", className: "btn btn-default"}), React.createElement("br", null), 
          React.createElement("input", {type: "submit", value: "Ladda upp", name: "upload-file-form", className: "btn btn-default"}), React.createElement("br", null), 
          React.createElement("iframe", {id: "upload-iframe", name: "upload-iframe", ref: "uploadIframe", style: style})
        )
      )
    )
  },

  /**
   * Render the export result component.
   * @instance
   * @param {string} url
   * @return {external:ReactElement} component
   */
  renderExportResult: function (url) {
    if (!url) return null;
    if (url === "NO_FEATURES") {
      return (
        React.createElement("div", null, 
          React.createElement("h4", null, "Export"), 
          React.createElement("p", null, "Denna funktionen exporterar inritade objekt."), 
          React.createElement("p", null, "Kartan innehåller inte något att exportera.")
        )
      )
    } else {
      return (
        React.createElement("div", null, 
          React.createElement("h4", null, "Export"), 
          React.createElement("p", null, "Din export är klar, hämta den genom att klicka på länken nedan."), 
          React.createElement("p", null, 
            "Exportfilen är av typen .kml och kan vid ett senare tillfälle importeras i kartan." + ' ' +
            "Detta kan vara användbart om du vill dela med dig av det du ritat eller vill öppna det vid ett senare tillfälle." + ' ' +
            "Filen kan även öppnas i Google Earth."
          ), 
          React.createElement("a", {href: url}, "Hämta export")
        )
      )
    }
  },

  /**
   * Render the dialog component.
   * @instance
   * @param {boolean} visible
   * @return {external:ReactElement} component
   */
  renderDialog: function(visible) {
    if (!visible) return null;

    function enter(e) {
      if (e.keyCode == 13) {
        update.call(this);
      }
    }

    function abort() {
      this.props.model.set('dialog', false);
      this.refs.textInput.blur();
      this.props.model.removeEditFeature();
    }

    function update() {
      this.refs.textInput.blur();
      this.props.model.set('dialog', false);
      this.props.model.setPointText(this.refs.textInput.value);
    }

    return (
      React.createElement("div", {className: "modal"}, 
        React.createElement("div", {className: "modal-dialog"}, 
          React.createElement("div", {className: "modal-content"}, 
            React.createElement("div", {className: "modal-header"}, 
              React.createElement("h4", {className: "modal-title"}, "Ange text")
            ), 
            React.createElement("div", {className: "modal-body"}, 
              React.createElement("input", {ref: "textInput", onKeyDown: enter.bind(this)})
            ), 
            React.createElement("div", {className: "modal-footer"}, 
              React.createElement("button", {type: "button", className: "btn btn-default", "data-dismiss": "modal", onClick: update.bind(this)}, "Spara"), " ", 
              React.createElement("button", {type: "button", className: "btn btn-default", "data-dismiss": "modal", onClick: abort.bind(this)}, "Avbryt")
            )
          )
        )
      )
    );
  },

  /**
   * Render the view.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var showLabels = this.state.showLabels ? "checked" : ""
    ,   symbology  = this.renderSymbology(this.state.symbology)
    ,   dialog     = this.renderDialog(this.state.dialog)
    ,   exportRes  = this.renderExportResult(this.state.exportUrl)
    ,   importRes  = this.renderImport(this.state.kmlImport);

    return (
        React.createElement(Panel, {title: "Rita och mäta", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
          React.createElement("div", {className: "draw-tools"}, 
            React.createElement("div", {id: "labels"}, 
              React.createElement("input", {id: "labels-checkbox", onChange: this.toggleLabels, type: "checkbox", checked: showLabels}), 
              React.createElement("label", {htmlFor: "labels-checkbox", id: "drawLabel"}, "Visa areal, längd eller koordinater på ritade objekt")
            ), 
            React.createElement("ul", null, 
              React.createElement("li", {id: "Text", onClick: this.activateDrawTool.bind(this, "Text")}, 
                React.createElement("i", {className: "fa fa-font fa-0"}), " ", React.createElement("span", null, "Skriv text")
              ), 
              React.createElement("li", {id: "Point", onClick: this.activateDrawTool.bind(this, "Point")}, 
                React.createElement("i", {className: "fa fa-circle fa-0"}), " ", React.createElement("span", null, "Rita punkt")
              ), 
              React.createElement("li", {id: "Circle", onClick: this.activateDrawTool.bind(this, "Circle")}, 
                React.createElement("i", {className: "iconmoon-punkt"}), " ", React.createElement("span", null, "Rita cirkel")
              ), 
              React.createElement("li", {id: "LineString", onClick: this.activateDrawTool.bind(this, "LineString")}, 
                React.createElement("i", {className: "iconmoon-linje"}), " ", React.createElement("span", null, "Rita linje")
              ), 
              React.createElement("li", {id: "Polygon", onClick: this.activateDrawTool.bind(this, "Polygon")}, 
                React.createElement("i", {className: "iconmoon-yta"}), " ", React.createElement("span", null, "Rita yta")
              ), 
              React.createElement("li", {id: "Box", onClick: this.activateDrawTool.bind(this, "Box")}, 
                React.createElement("i", {className: "fa fa-crop fa-0"}), " ", React.createElement("span", null, "Rita ruta")
              ), 
              React.createElement("li", {id: "move", onClick: this.activateMoveTool}, 
                React.createElement("i", {className: "fa fa-arrows fa-0"}), " ", React.createElement("span", null, "Flytta objekt")
              ), 
              React.createElement("li", {id: "edit", onClick: this.activateEditTool}, 
                React.createElement("i", {className: "fa fa-edit fa-0"}), " ", React.createElement("span", null, "Ändra objekt")
              ), 
              React.createElement("li", {id: "delete", onClick: this.activateRemovalTool}, 
                React.createElement("i", {className: "fa fa-eraser fa-0"}), " ", React.createElement("span", null, "Radera objekt")
              ), 
              React.createElement("li", {id: "clear", onClick: this.alertClear}, 
                React.createElement("i", {className: "fa fa-trash fa-0"}), " ", React.createElement("span", null, "Rensa allt")
              ), 
              React.createElement("li", {id: "clear", onClick: this.import}, 
                React.createElement("i", {className: "fa fa-file-o fa-0"}), " ", React.createElement("span", null, "Importera")
              ), 
              React.createElement("li", {id: "clear", onClick: this.export}, 
                React.createElement("i", {className: "fa fa-save fa-0"}), " ", React.createElement("span", null, "Exportera")
              ), 
              React.createElement("li", {id: "abort", className: "green", onClick: this.abort}, 
                React.createElement("i", {className: "fa fa-check fa-0"}), " ", React.createElement("span", null, "Klar")
              )
            )
          ), 
          React.createElement("div", {className: "panel-body"}, 
            symbology, 
            exportRes, 
            importRes
          ), 
          React.createElement("div", null, dialog, 
            this.renderAlert()
          )
        )

    );
  }
};

/**
 * DrawPanelView module.<br>
 * Use <code>require('views/drawpanel')</code> for instantiation.
 * @module DrawPanelView-module
 * @returns {DrawPanelView}
 */
module.exports = React.createClass(DrawPanelView);

},{"alert":"alert","components/colorpicker":"components/colorpicker","views/panel":"views/panel"}],"views/editpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var Alert = require('alert');

var AttributeEditor = React.createClass({displayName: "AttributeEditor",

  getInitialState: function() {
    return {
      disabled: true,
      formValues: {}
    };
  },

  componentWillUnmount: function () {
  },

  componentWillMount: function () {
  },

  componentDidMount: function () {
    this.props.model.on('change:editFeature', (attr) => {
      var valueMap = {}
      ,   feature = this.props.model.get('editFeature')
      ,   source = this.props.model.get('editSource')
      ,   props
      ,   defaultValue = '';

      if (!feature || !source) return;

      props = feature.getProperties();
      source.editableFields.map(field => {
        field.initialRender = true;
        if (field.textType === "flerval") {
          valueMap[field.name] = field.values.map(value => {
            return {
              name: value,
              checked: typeof props[field.name] === "string" ?
                       props[field.name].split(';').find(v => v === value) !== undefined :
                       false
            }
          });
        } else {
          valueMap[field.name] = props[field.name] || defaultValue;
        }

      });

      this.state.formValues = valueMap;
    });
  },

  updateFeature: function () {
    var props = this.props.model.get('editFeature').getProperties();
    Object.keys(this.state.formValues).forEach(key => {
      var value = this.state.formValues[key];
      if (value === '') value = null;
      if (Array.isArray(value)) {
        value = value.filter(v => v.checked).map(v => v.name).join(';');
      }
      props[key] = value;
    });
    this.props.model.get('editFeature').setProperties(props);
  },

  checkInteger: function (name, value) {
    if (/^\d+$/.test(value) || value === "") {
      this.state.formValues[name] = value;
      this.updateFeature();      
    } else {                  
      if (!this.state.formValues[name]) {
        this.state.formValues[name] = "";
      }               
    }    
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkNumber: function (name, value) {
    if (/^\d+([\.\,](\d+)?)?$/.test(value) || value === "") {
      value = value.replace(',', '.');
      this.state.formValues[name] = value;
      this.updateFeature();      
    } else {                  
      if (!this.state.formValues[name]) {
        this.state.formValues[name] = "";
      }               
    }
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkUrl: function (name, value) {

    var regex = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
    var valid = regex.test(value);

    if (valid || value === '') {
      this.state.formValues[name] = value;
    } else {
      this.state.formValues[name] = '';
      this.props.panel.setState({
        alert: true,
        loading: false,
        alertMessage: `Fältet ${name} är av typen URL, vilken måste följa formatet http://www.google.se.`,
        confirm: false
      });
    }

    this.updateFeature();
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkText: function (name, value) {
    this.state.formValues[name] = value;
    this.updateFeature();
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkSelect: function (name, value) {
    this.state.formValues[name] = value;
    this.updateFeature();
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkMultiple: function (name, checked, value, index) {
    this.state.formValues[name][index].checked = checked;
    this.updateFeature();
    this.setState({
      formValues: this.state.formValues
    });
  },

  checkDate: function (name, date) {
    var value = "";
    if (date.format) {
      value = date.format('Y-MM-DD HH:mm:ss');
    } else {
      value = date;
    }
    this.state.formValues[name] = value;
    this.updateFeature();
    this.setState({
      formValues: this.state.formValues
    });
  },

  setChanged: function() {
    if (this.props.model.get('editFeature').modification !== 'added' &&
        this.props.model.get('editFeature').modification !== 'removed') {
      this.props.model.get('editFeature').modification = 'updated';
    }
  },

  getValueMarkup: function (field) {

    if (field.dataType === "int") {
      field.textType = "heltal";
    }

    if (field.dataType === "number") {
      field.textType = "nummer";
    }

    if (field.dataType === "date") {
      field.textType = "datum";
    }

    var value = this.state.formValues[field.name];

    if (value === "" && field.initialRender) {
      value = field.defaultValue;
      this.state.formValues[field.name] = value;
    }

    switch (field.textType) {
      case "heltal":
        return (
          React.createElement("input", {className: "form-control", type: "text", value: value, onChange: (e) => {                            
              this.setChanged();
              this.checkInteger(field.name, e.target.value);
              field.initialRender = false;
            }}
          )
        );
      case "nummer":
        return (
          React.createElement("input", {className: "form-control", type: "text", value: value, onChange: (e) => {
              this.setChanged();
              this.checkNumber(field.name, e.target.value);
              field.initialRender = false;
            }}
          )
        );
      case "datum":
        if (typeof value === "string") {
          value = value.replace('T', ' ').replace('Z','')
        }
        return (
          React.createElement(Datetime, {closeOnSelect: true, disableOnClickOutside: true, dateFormat: "Y-MM-DD", timeFormat: "HH:mm:ss", value: value, onChange: (date) => {
              this.setChanged();
              this.checkDate(field.name, date);
              field.initialRender = false;
            }}
          )
        );
      case "url":
      case "fritext":
        return (
          React.createElement("input", {
            className: "form-control", 
            type: "text", 
            value: value, 
            onChange: (e) => {
              this.setChanged();
              this.checkText(field.name, e.target.value);
              field.initialRender = false;
            }, 
            onBlur: (e) => {
              this.setChanged();
              if (field.textType === "url") {
                this.checkUrl(field.name, e.target.value);
              }
              field.initialRender = false;
            }}
          )
        );
      case "flerval":
        let defaultValues = [];
        if (typeof field.defaultValue === "string") {
          defaultValues = field.defaultValue.split(',');
        }
        if (field.initialRender) {
          defaultValues.forEach(defaultValue => {
            value.forEach(val => {
              if (defaultValue === val.name) {
                val.checked = true;
              }
            });
          });
        }

        let checkboxes = field.values.map(
          (val, i) => {

            var id = field.name + i
            ,   item = value.find(item => item.name === val) || {checked: false};

            return (
              React.createElement("div", {key: i}, 
                React.createElement("input", {type: "checkbox", id: id, checked: item.checked, onChange: (e) => {
                  this.setChanged();
                  this.checkMultiple(field.name, e.target.checked, val, i);
                  field.initialRender = false;
                }}), 
                React.createElement("label", {htmlFor: id}, val)
              )
            )
          }
        );
        return React.createElement("div", null, checkboxes);
      case "lista":
        let options = null;
        if (Array.isArray(field.values))
          options = field.values.map((val, i) => React.createElement("option", {key: i, value: val}, val));

        return (
          React.createElement("select", {className: "form-control", value: value, onChange: (e) => {
              this.setChanged();
              this.checkSelect(field.name, e.target.value);
              field.initialRender = false;
            }
          }, 
            React.createElement("option", {value: ""}, "-Välj värde-"), 
            options
          )
        );
      case null:
        return (React.createElement("span", null, value));
      default:
        return (React.createElement("span", null, value));
    }
  },

  render: function () {

    if (!this.props.feature) return null;

    var markup = this.props.source.editableFields.map((field, i) => {

      var value = this.getValueMarkup(field)
      ,   className = ''
      ;

      if (field.hidden) {
        className = "hidden"
      }

      this.updateFeature();

      return (
        React.createElement("div", {key: i, ref: field.name, className: "field", className: className}, 
          React.createElement("div", null, field.name), 
          React.createElement("div", null, value)
        )
      )
    });

    return (
      React.createElement("div", null, markup)
    );
  }
});

var Toolbar = React.createClass({displayName: "Toolbar",

  getInitialState: function() {
    return {
      activeTool: undefined
    };
  },

  componentWillUnmount: function () {
    this.props.model.off('change:layer');
  },

  componentWillMount: function () {
    this.props.model.on('change:layer', () => {
      if (this.props.model.get('layer')) {
        this.props.model.get('layer').dragLocked = true;
        this.props.model.deactivateTools();
        this.setState({
          activeTool: undefined
        });
      }
    });
  },

  componentDidMount: function () {
  },

  changeTool: function(type) {

    if (this.state.activeTool === type.toLowerCase()) {
      this.props.model.deactivateDrawTool(true);
      if (type === 'move') {        
        this.props.model.get('layer').dragLocked = true;
      }

      return this.setState({
        activeTool: undefined
      });
    }

    switch (type) {
      case "Point":
      case "LineString":
      case "Polygon":
        this.props.model.activateDrawTool(type);
        this.props.model.setRemovalToolMode('off');
        break;
      case "remove":
        this.props.model.deactivateDrawTool(type);
        break;
      case "move":
        this.props.model.deactivateDrawTool(type);
        this.props.model.setRemovalToolMode('off');
        break;
    }
  },

  onAddPointClicked: function() {
    this.props.model.get('layer').dragLocked = true;
    this.setState({activeTool: "point"});
    this.changeTool('Point');
  },

  onAddLineClicked: function() {
    this.props.model.get('layer').dragLocked = true;
    this.setState({activeTool: "linestring"});
    this.changeTool('LineString');
  },

  onAddPolygonClicked: function() {
    this.props.model.get('layer').dragLocked = true;
    this.setState({activeTool: "polygon"});
    this.changeTool('Polygon');
  },

  onRemoveClicked: function() {
    this.props.model.get('layer').dragLocked = true;
    this.props.model.setRemovalToolMode(this.state.activeTool === "remove" ? "off" : "on");
    this.setState({activeTool: "remove"});
    this.changeTool('remove');
  },

  onMoveClicked: function() {
    this.props.model.get('layer').dragLocked = false;
    this.setState({activeTool: "move"});
    this.changeTool('move');
  },

  onSaveClicked: function () {

    var getMessage = (data) => {
      if (!data)
        return `Uppdatateringen lyckades men det upptäcktes inte några ändringar.`;
      if (data.ExceptionReport) {
        return `Uppdateringen misslyckades: ${data.ExceptionReport.Exception.ExceptionText.toString()}`;
      }
      if (data.TransactionResponse && data.TransactionResponse.TransactionSummary) {
        return `Uppdateringen lyckades:
          antal skapade objekt: ${data.TransactionResponse.TransactionSummary.totalInserted}
          antal borttagna objekt: ${data.TransactionResponse.TransactionSummary.totalDeleted}
          antal uppdaterade objekt: ${data.TransactionResponse.TransactionSummary.totalUpdated}
        `
      } else {
        return 'Status för uppdateringen kunde inte avläsas ur svaret från servern.'
      }
    };

    if (!this.props.model.get('editSource'))
      return;

    this.props.panel.setState({
      loading: true
    });

    this.props.model.save((data) => {
      this.props.panel.setState({
        alert: true,
        loading: false,
        alertMessage: getMessage(data),
        confirm: false
      });
      this.props.model.setRemovalToolMode('off');
      this.props.model.filty = false;
      this.props.panel.setLayer(this.props.model.get('editSource'));
    });

  },

  onCancelClicked: function() {
    this.props.model.get('layer').dragLocked = true;
    this.props.model.deactivate();
    this.props.panel.setState({
      checked: false,
      enabled: false
    });
    this.setState({
      activeTool: undefined
    });
  },

  render: function () {

    var disabled = !this.props.enabled;
    var isActive = (type) => {
      return this.state.activeTool === type ? "btn btn-primary" : "btn btn-default";
    };

    var source = this.props.model.get('editSource');
    var editPoint = editPolygon = editLine = false;

    if (source) {
      editPoint = source.editPoint;
      editLine = source.editLine;
      editPolygon = source.editPolygon;
    }

    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "edit-toolbar-wrapper"}, 
          React.createElement("div", {className: "btn-group btn-group-lg map-toolbar"}, 
            React.createElement("button", {
              disabled: disabled === false ? !editPoint : disabled, 
              onClick: () => {this.onAddPointClicked()}, 
              className: isActive("point"), 
              type: "button", 
              title: "Lägg till punkt"
            }, 
              React.createElement("i", {className: "iconmoon-punkt"})
            ), 
            React.createElement("button", {
              disabled: disabled === false ? !editLine : disabled, 
              onClick: () => {this.onAddLineClicked()}, 
              className: isActive("linestring"), 
              type: "button", 
              title: "Lägg till linje"
            }, 
              React.createElement("i", {className: "iconmoon-linje"})
            ), 
            React.createElement("button", {
              disabled: disabled === false ? !editPolygon : disabled, 
              onClick: () => {this.onAddPolygonClicked()}, 
              className: isActive("polygon"), 
              type: "button", 
              title: "Lägg till yta"
            }, 
              React.createElement("i", {className: "iconmoon-yta"})
            ), 
            React.createElement("button", {
              disabled: disabled, 
              onClick: () => {this.onMoveClicked()}, 
              className: isActive("move"), 
              type: "button", 
              title: "Flytta geometri"
            }, 
              React.createElement("i", {className: "fa fa-arrows icon"})
            ), 
            React.createElement("button", {
              disabled: disabled, 
              onClick: () => {this.onRemoveClicked()}, 
              className: isActive("remove"), 
              type: "button", 
              title: "Ta bort geometri"
            }, 
              React.createElement("i", {className: "fa fa-eraser icon"})
            ), 
            React.createElement("button", {
              disabled: disabled, 
              onClick: (e) => {this.onSaveClicked()}, 
              className: "btn btn-default", 
              type: "button", 
              title: "Spara"
            }, 
              React.createElement("i", {className: "fa fa-floppy-o icon"})
            ), 
            React.createElement("button", {
              disabled: disabled, 
              onClick: (e) => {this.onCancelClicked()}, 
              className: "btn btn-default", 
              type: "button", 
              title: "Avbryt"
            }, 
              React.createElement("i", {className: "fa fa-ban icon"})
            )
          )
        )
      )
    )
  }
});

/**
 * @class
 */
var EditPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false,
      enabled: false,
      checked: false
    };
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.off('change:editFeature');
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {

    this.props.model.on('change:editFeature', (attr) => {
      this.setState({
        editFeature: this.props.model.get('editFeature'),
        editSource: this.props.model.get('editSource')
      });
    });

    this.props.model.on('change:removeFeature', (attr) => {
      if (this.props.model.get('removeFeature')) {
        this.setState({
          alert: true,
          alertMessage: ` Vill du ta bort markerat obekt?

            Tryck därefter på sparaknappen för definitiv verkan.
          `,
          confirm: true,
          confirmAction: () => {
            var feature = this.props.model.get('removeFeature')
            this.props.model.get('select').getFeatures().remove(feature);
            feature.modification = 'removed';
            feature.setStyle(this.props.model.getHiddenStyle());
          },
          denyAction: () => {
            this.setState({ alert: false });
            this.props.model.set('removeFeature', undefined)
          }
        });
      }
    });
  },

  /**
   * Set active layer to edit
   * @instance
   * @param {external:"ol.source"} source
   *
   */
  setLayer: function (source) {

    var clear = () => {
      var time = new Date().getTime() - timer;
      if (time < 1000) {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000 - time);
      } else {
        this.setState({ loading: false });
      }
    };

    var changeActiveLayer = () => {
      this.setState({
        checked: source.caption,
        loading: true,
        enabled: true
      });
      this.props.model.setLayer(source, clear);
    }

    var timer = new Date().getTime();

    if (this.props.model.filty) {
      this.setState({
        alert: true,
        alertMessage: `Du har en aktiv redigeringssession startad dina ändringar kommer att gå förlorade.

        Vill du forstätta ändå?`,
        confirm: true,
        confirmAction: () => {
          changeActiveLayer();
        },
        denyAction: () => {
          this.setState({ alert: false });
        }
      });
    } else {
      changeActiveLayer();
    }
  },

  /**
   * Render the component.
   * @instance
   * @return {Alert} component
   */
  renderAlert: function () {
    var options = {
      visible: this.state.alert,
      message: this.state.alertMessage,
      confirm: this.state.confirm,
      confirmAction: () => {
        this.state.confirmAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      denyAction: () => {
        this.state.denyAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      onClick: () => {
        this.setState({
          alert: false,
          alertMessage: ""
        })
      }
    };

    if (this.state.alert) {
      return React.createElement(Alert, {options: options})
    } else {
      return null;
    }
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    var visible = true, options, loader;

    options = () => {
      return this.props.model.get('sources').map(
        (source, i) => {
          var id = "edit-layer-" + i;
          return (
            React.createElement("div", {key: i, className: "list-item"}, 
              React.createElement("input", {id: id, type: "radio", name: "source", checked: this.state.checked === source.caption, onChange: (e) => {
                this.setLayer(source)
              }}), 
              React.createElement("label", {htmlFor: id}, source.caption)
            )
          )
        }
      )
    };

    if (this.state.loading) {
      loader = React.createElement("div", {className: "layer-loader"});
    }

    return (
      React.createElement("div", null, 
        React.createElement(Panel, {title: "Editera lager", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
          React.createElement("div", {className: "edit-tools"}, 
            React.createElement("div", {className: "loading-bar"}, 
              loader
            ), 
            React.createElement(Toolbar, {ref: "toolbar", enabled: this.state.enabled, loading: this.state.loading, model: this.props.model, panel: this}), 
            React.createElement("ul", {className: "edit-layers"}, 
              options()
            ), 
            React.createElement(AttributeEditor, {ref: "attributeEditor", feature: this.state.editFeature, source: this.state.editSource, model: this.props.model, activeTool: this.state.activeTool, panel: this})
          )
        ), 
        this.renderAlert()
      )
    );
  }
};

/**
 * EditPanelView module.<br>
 * Use <code>require('views/editpanel')</code> for instantiation.
 * @module EditPanelView-module
 * @returns {EditPanelView}
 */
module.exports = React.createClass(EditPanelView);

},{"alert":"alert","views/panel":"views/panel"}],"views/exportpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');

var ExportTiffSettings = React.createClass({displayName: "ExportTiffSettings",

  getInitialState: function() {
    return {
    };
  },

  removePreview: function () {
    this.props.model.removeTiffPreview();
  },

  addPreview: function (map) {
    var center = this.props.model.getPreviewFeature() ?
                 ol.extent.getCenter(this.props.model.getPreviewFeature().getGeometry().getExtent()) :
                 map.getView().getCenter();
    this.props.model.addTiffPreview(center);
  },

  exportTIFF: function () {
    this.props.model.exportTIFF(() => {
    });
  },



  componentWillUnmount: function () {
    this.removePreview();
  },

  render: function () {

    var map = this.props.olMap
    ,   loader = null;

    if (this.state.loading) {
      loader = React.createElement("i", {className: "fa fa-refresh fa-spin"});
    }
    
    if (this.props.model.previewLayer.getSource().getFeatures().length === 0) {
      this.addPreview(map);    
    }    
    
    //downloadlänk
    if (this.props.model.get("downloadingTIFF")) {
      downloadLink = React.createElement("p", null, "Hämtar...")
    } else if (this.props.model.get("urlTIFF")) {
      downloadLink = React.createElement("a", {href: this.props.model.get("urlTIFF"), target: "_blank"}, React.createElement("p", null, "Ladda ner TIFF"))
    } else {
      downloadLink = null;
    }
    
    return (
      React.createElement("div", {className: "export-settings"}, 
        React.createElement("div", null, 
          React.createElement("div", null, 
            React.createElement("button", {onClick: this.exportTIFF, className: "btn btn-primary"}, "Skapa TIFF ", loader)
          ), 
          React.createElement("div", null, downloadLink), 
          React.createElement("br", null), 
          React.createElement("div", {id: "tiff"})
        )
      )
    );

  }
});

var ExportPdfSettings = React.createClass({displayName: "ExportPdfSettings",
  resolutions: [72, 96, 150, 200, 300],
  paperFormats: ["A2", "A3", "A4"],

  getInitialState: function() {
    return {
      selectFormat: 'A4',
      selectOrientation: 'S',
      selectScale: '500',
      manualScale: '2500',
      selectResolution: '72',
      center: this.props.model.getPreviewFeature() ?
        this.props.model.getPreviewCenter() :
        this.props.olMap.getView().getCenter(),
      loading: false
    };
  },

  getPaperMeasures: function () {

    var pageSize = format => {
      switch (format) {
        case 'A4':
          return {
            width:  this.getOrientation() === 'L' ? 297 : 210,
            height: this.getOrientation() === 'L' ? 210 : 297
          }
        case 'A3':
          return {
            width:  this.getOrientation() === 'L' ? 420 : 297,
            height: this.getOrientation() === 'L' ? 297 : 420
          }
        case 'A2':
          return {
              width:  this.getOrientation() === 'L' ? 594 : 420,
              height: this.getOrientation() === 'L' ? 420 : 594
          }
        default: {
          return {
            width: 0,
            height: 0
          }
        }
      }
    }

    var width  = pageSize(this.getFormat()).width
    ,   height = pageSize(this.getFormat()).height;

    return {
      width: ((width / 25.4)),
      height:  ((height / 25.4))
    };
  },

  getPreviewPaperMeasures: function() { 
    var size = this.getPaperMeasures()
    ,   inchInMillimeter = 25.4
    ,   defaultPixelSizeInMillimeter = 0.28
    ,   dpi = (inchInMillimeter / defaultPixelSizeInMillimeter); // ~90
    return {
      width: size.width * dpi,
      height:  size.height * dpi
    };
  },

  getScale: function () {
    return (this.state.selectScale === 'other') ? this.state.manualScale : this.state.selectScale;
  },

  getResolution: function () {
    return this.state.selectResolution;
  },

  getOrientation: function () {
    return this.state.selectOrientation;
  },

  getFormat: function () {
    return this.state.selectFormat;
  },

  setFormat: function (e) {
    this.setState({
      selectFormat: e.target.value
    });
  },

  setResolution: function(e) {
    this.setState({
      selectResolution: e.target.value
    });
  },

  setScale: function(e) {
    this.setState({
      selectScale: e.target.value
    });
  },

  setCenter: function(val) {
    this.setState({
      center: val
    });
  },

  setManualScale: function(e) {
    if (e.target.value.startsWith('1:')) {
      e.target.value = e.target.value.split(':')[1];
    }

    var val = this.getScale();
    //if (e.target.value < 250) {
    //  val = 250;
    //} else if (e.target.value > 250000) {
    //  val = 250000;
    //} else {
      val = e.target.value;
    //}
    this.setState({
      manualScale: val
    });
  },

  setOrientation: function(e) {
    this.setState({
      selectOrientation: e.target.value
    });
  },

  removePreview: function () {
    this.props.model.removePreview();
  },

  addPreview: function (map) {
    var scale  = this.getScale()
    ,   paper  = this.getPreviewPaperMeasures()
    //,   center = this.state.center;
    ,   center = this.props.model.getPreviewFeature() ?
                 ol.extent.getCenter(this.props.model.getPreviewFeature().getGeometry().getExtent()) :
                 map.getView().getCenter();

    this.props.model.addPreview(scale, paper, center);


    var preScale = undefined;

    switch(scale){
      case "250":
        preScale = 6;
        break;
      case "500":
        preScale = 6;
        break;
      case "1000":
        preScale = 5;
        break;
      case "2500":
        preScale = 4;
        break;
      case "5000":
        preScale = 3;
        break;
      case "10000":
        preScale = 2;
        break;
      case "25000":
        preScale = 1;
        break;
      case "50000":
        preScale = 1;
        break;
      case "100000":
        preScale = 0;
        break;
      case "250000":
        preScale = 0;
        break;
      default:
        preScale = map.getView().getZoom();
        break;
    }
    if(this.props.model.get('autoScale') && isMobile && mobilAnpassningEnabled && preScale < map.getView().getZoom()){
      map.getView().setZoom(preScale);
    }
  },

  exportPDF: function () {
    this.setState({
      loading: true
    });
    var node = $(ReactDOM.findDOMNode(this)).find('#pdf')
    ,   options = {
          size: this.getPaperMeasures(),
          format: this.getFormat(),
          orientation: this.getOrientation(),
          scale: this.getScale(),
          resolution: this.getResolution()
        }
    ;
    node.html('');
    this.props.model.exportPDF(options, () => {
      this.setState({
        loading: false
      });
    });
  },

  componentWillUnmount: function () {
    this.removePreview();
  },

  render: function () {
    var map = this.props.olMap
    ,   scales = this.props.model.get('scales')
    ,   options
    ,   resolutionOptions
    ,   paperFormatOptions
    ,   loader = null
    ,   downloadLink = null
    ;

    if (this.state.loading) {
      loader = React.createElement("i", {className: "fa fa-refresh fa-spin"});
    }

    if (!this.props.visible) return null;

    options = scales.map((s, i) => React.createElement("option", {key: i, value: s}, "1:", s));

    resolutionOptions = this.resolutions.map((s, i) => {
      if (this.state.selectFormat === 'A2') {
        return s !== 300 
          ? React.createElement("option", {key: i, value: s}, s)
          : React.createElement("option", {key: i, value: s, disabled: true}, s);
        } else {
          return React.createElement("option", {key: i, value: s}, s);
        }
      });
    paperFormatOptions = this.paperFormats.map((s, i) => {
      if (this.state.selectResolution === '300') {
        return s !== 'A2'
          ? React.createElement("option", {key: i, value: s}, s)
          : React.createElement("option", {key: i, value: s, disabled: true}, s);
        } else {
          return React.createElement("option", {key: i, value: s}, s);
        }
    });
        
    this.addPreview(map);

    //downloadlänk
    if (this.props.model.get("downloadingPdf")) {
      downloadLink = React.createElement("p", null, "Hämtar...")
    } else if (this.props.model.get("urlPdf")) {
      downloadLink = React.createElement("a", {href: this.props.model.get("urlPdf"), target: "_blank"}, React.createElement("p", null, "Ladda ner PDF"))
    } else {
      downloadLink = null;
    }

    return (
      React.createElement("div", {className: "export-settings"}, 
        React.createElement("div", {className: "panel panel-default"}, 
          React.createElement("div", {className: "panel-heading"}, "Välj pappersstorlek"), 
          React.createElement("div", {className: "panel-body"}, 
            React.createElement("select", {onChange: this.setFormat, value: this.state.selectFormat}, 
              paperFormatOptions
            )
          )
        ), 
        React.createElement("div", {className: "panel panel-default"}, 
          React.createElement("div", {className: "panel-heading"}, "Välj orientering"), 
          React.createElement("div", {className: "panel-body"}, 
            React.createElement("select", {onChange: this.setOrientation, value: this.state.selectOrientation}, 
              React.createElement("option", {value: "P"}, "stående"), 
              React.createElement("option", {value: "L"}, "liggande")
            )
          )
        ), 
        React.createElement("div", {className: "panel panel-default"}, 
          React.createElement("div", {className: "panel-heading"}, "Välj skala"), 
          React.createElement("div", {className: "panel-body"}, 
            React.createElement("select", {onChange: this.setScale, value: this.state.selectScale}, 
              options, 
              React.createElement("option", {value: "other"}, "Annan skala")
            ), 
            this.state.selectScale==='other' && React.createElement("input", {type: "text", onChange: this.setManualScale, value: this.state.manualScale})
          )
        ), 
        React.createElement("div", {className: "panel panel-default"}, 
          React.createElement("div", {className: "panel-heading"}, "Välj upplösning"), 
          React.createElement("div", {className: "panel-body"}, 
            React.createElement("select", {onChange: this.setResolution, value: this.state.selectResolution}, 
              resolutionOptions
            )
          )
        ), 
        React.createElement("div", null, 
          React.createElement("button", {onClick: this.exportPDF, className: "btn btn-main"}, "Skapa PDF ", loader), 
          React.createElement("br", null), 
          downloadLink
        ), 
        React.createElement("br", null), 
        React.createElement("div", {id: "pdf"})
      )
    )
  }
});

/**
 * @class
 */
var ExportPanelView = {

  componentDidMount: function () {
    this.props.model.on('change:activeTool', () => {
      this.setState({
        activeTool: this.props.model.get('activeTool')
      });
    });
    this.props.model.on('change:urlPdf', () => {
      this.setState({
        downloadUrl: this.props.model.get('urlPdf')
      });
    });
    this.props.model.on('change:downloadingPdf', () => {
      this.setState({
        downloading: this.props.model.get('downloadingPdf')
      });
    });
    this.props.model.on('change:urlTIFF', () => {
      this.setState({
        downloadUrl: this.props.model.get('urlTIFF')
      });
    });
    this.props.model.on('change:downloadingTIFF', () => {
      this.setState({
        downloadingTIFF: this.props.model.get('downloadingTIFF')
      });
    });

  },

  componentWillUnmount: function () {
    this.props.model.off('change:activeTool');
    this.props.model.off('change:urlPdf');
    this.props.model.off('change:downloadingPdf');
    this.props.model.off('change:urlTIFF');
    this.props.model.off('change:downloadingTIFF');
  },

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      showExportSettings: true,
      activeTool: this.props.model.get('activeTool')
    };
  },

  /**
   * Set the export setting property, this vill trigger set state.
   * @instance
   * @param {boolean} value
   */
  setExportSettings: function (value) {
    this.setState({
      showExportSettings: value
    });
  },

  /**
   * Export the image.
   * @instance
   */
  exportImage: function () {
    var node = $(ReactDOM.findDOMNode(this)).find('#image');
    node.html('');
    this.props.model.exportImage((anchor) => {
      node.html(anchor);
    });
  },

  activateTool: function (name) {
    if (this.props.model.get('activeTool') === name) {
      this.props.model.setActiveTool(undefined);
    } else {
      this.props.model.setActiveTool(name);
    }
  },

  getClassNames: function (type) {
    return this.state.activeTool === type
      ? "btn btn-primary"
      : "btn btn-default";
  },

  renderToolbar: function () {
    const activeFormats = [];
    if (this.props.model.get('pdfActive')) {
        activeFormats.push('pdf');
    }
    if (this.props.model.get('tiffActive')) {
        activeFormats.push('tiff');
    }
    return (
      React.createElement("div", null, 
        React.createElement("div", null, "Välj format"), 
        React.createElement("div", {className: "btn-group"}, 
          activeFormats.map((format, i) =>
            React.createElement("button", {key: i, onClick: () => this.activateTool(format), type: "button", className: this.getClassNames(format)}, 
              format.toUpperCase()
            )
          )
        )
      )
    );
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var activeTool = this.props.model.get('activeTool');
    var tool = React.createElement("div", null, "Välj utdataformat.")
    if (activeTool === 'pdf' && this.props.model.get('olMap')) {
      tool = React.createElement(ExportPdfSettings, {
        visible: this.state.showExportSettings, 
        model: this.props.model, 
        olMap: this.props.model.get('olMap')});
    }
    if (activeTool === 'tiff' && this.props.model.get('olMap')) {
      tool = React.createElement(ExportTiffSettings, {
        model: this.props.model, 
        olMap: this.props.model.get('olMap')});
    }
    return (
      React.createElement(Panel, {title: "Skriv ut karta", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "export-panel"}, 
          React.createElement("div", null, 
            this.renderToolbar()
          ), 
          React.createElement("br", null), 
          tool
        )
      )
    );
  }
};

/**
 * ExportPanelView module.<br>
 * Use <code>require('views/exportpanel')</code> for instantiation.
 * @module ExportPanelView-module
 * @returns {ExportPanelView}
 */
module.exports = React.createClass(ExportPanelView);

},{"views/panel":"views/panel"}],"views/infopanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var FeatureInfo = require('components/featureinfo');

/**
 * @class
 */
var InfoPanelView = {
  /**
   * @property {Array<{external:"ol.feature"}>}
   * @instance
   */
  features: [],

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      featureinfo: [],
      activeIndex: 0
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.model.get("features").on("reset", this.handleReset);
    this.props.model.on("change:loadFinished", this.handleAdd);
    this.props.model.on("change:selectedFeature", this.handleChangeSelectedFeature);
    this.features = this.props.model.get("features").map(f => f.get("information"));
    this.setState({
      featureinfo: this.features
    });
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.off("change:selectedFeature", this.handleChangeSelectedFeature);
    this.props.model.off("change:loadFinished", this.handleAdd);
    this.props.model.get("features").off("reset", this.handleReset);
    this.props.model.clearHighlight();
  },

  /**
   * Reset the features of this panel.
   * @instance
   */
  handleReset: function() {
    this.features = [];
  },

  /**
   * Add feature to the info panel view, this will trigger set state.
   * @instance
   * @param {external:"ol.feature"}
   */
  handleAdd: function (feature, collection) {
    if (this.props.model.get('loadFinished') === true) {
      this.features = this.props.model.get('features').map(f => f.get('information'));
      this.setState({
        featureinfo: this.features
      });
    }
  },

  /**
   * Change selected feature.
   * @instance
   * @param {object} s
   * @param {external:"ol.feature"}
   */
  handleChangeSelectedFeature: function (s, feature) {
    this.setState({
      activeIndex: this.props.model.get('features').toArray().indexOf(feature)
    });
  },

  /**
   * Go to next feature.
   * @instance
   */
  decreaseIndex: function () {
    var newIndex = this.state.activeIndex > 0 ?
      this.state.activeIndex - 1 :
      this.state.activeIndex;
    var feature = this.props.model.get('features').at(newIndex);
    if (feature) {
      this.props.model.set('selectedFeature', feature);
    }
  },

  /**
   * Go to the previous feature.
   * @instance
   */
  increaseIndex: function () {
    var newIndex = this.state.activeIndex < this.state.featureinfo.length - 1 ?
      this.state.activeIndex + 1 :
      this.state.activeIndex;
    var feature = this.props.model.get('features').at(newIndex);
    if (feature) {
      this.props.model.set('selectedFeature', feature);
    }
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    var infos = this.state.featureinfo;
    var current = this.state.activeIndex;
    var that = this;
    var info;
    infos.sort((a, b) =>
      a.layerindex === b.layerindex ? 0 :
        a.layerindex > b.layerindex ? -1 : 1
    );

    info = infos[current];

    return (
      React.createElement(Panel, {title: "Information", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized}, 
        React.createElement("div", {className: "info-panel panel-content"}, 
          (function () {
            if (current !== -1) {
              return [
                React.createElement("div", {key: "0", className: "navigation"}, 
                  React.createElement("span", {onClick: that.decreaseIndex, className: "fa fa-arrow-circle-left left"}), 
                    current + 1, " av ", infos.length, 
                  React.createElement("span", {onClick: that.increaseIndex, className: "fa fa-arrow-circle-right right"})
                ),
                React.createElement(FeatureInfo, {key: "1", info: info})
              ];
            } else {
              return React.createElement("div", {className: "no-info"}, "Klicka på objekt i kartan för att få mer information...");
            }
          }())
        )
      )
    );
  }
};

/**
 * InfoPanelView module.<br>
 * Use <code>require('views/infopanel')</code> for instantiation.
 * @module InfoPanelView-module
 * @returns {InfoPanelView}
 */
module.exports = React.createClass(InfoPanelView);

},{"components/featureinfo":"components/featureinfo","views/panel":"views/panel"}],"views/layeritem":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
var LayerItemView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      caption: "",
      visible: false,
      expanded: false,
      name: "",
      legend: [],
      status: "ok",
      infoVisible: false,
      infoTitle: "",
      infoText: "",
      infoUrl: "",
      infoUrlText: "",
      infoOwner: "",
      infoExpanded: false,
      instruction: ""
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.layer.on("change:status", this.onStatusChanged, this);
    this.props.layer.on("change:visible", this.onVisibleChanged, this);
    this.props.layer.on("change:legend", this.onLegendChanged, this);
    this.props.layer.on('change:showLegend', this.onShowLegendChanged, this);
    this.props.layer.on('change:showInfo', this.onShowInfoChanged, this);
    this.setState({
      status: this.props.layer.get('status'),
      caption: this.props.layer.getCaption(),
      visible: this.props.layer.getVisible(),
      showLegend: this.props.layer.get('showLegend'),
      legend: this.props.layer.getLegend(),
      infoVisible: this.props.layer.getInfoVisible(),
      infoTitle: this.props.layer.getInfoTitle(),
      infoText: this.props.layer.getInfoText(),
      infoUrl: this.props.layer.getInfoUrl(),
      infoUrlText: this.props.layer.getInfoUrlText(),
      infoOwner: this.props.layer.getInfoOwner(),
      showInfo: this.props.layer.get('showInfo')
    });
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.layer.off("change:visible", this.onVisibleChanged, this);
    this.props.layer.off("change:legend", this.onLegendChanged, this);
    this.props.layer.off('change:showLegend', this.onShowLegendChanged, this);
    this.props.layer.off("change:status", this.onStatusChanged, this);
    this.props.layer.off('change:showInfo', this.onShowInfoChanged, this);
  },

  /**
   * On status change event handler.
   * @instance
   */
  onStatusChanged: function () {
    this.setState({
      status: this.props.layer.get('status')
    });
  },

  /**
   * On visible change event handler.
   * @instance
   */
  onVisibleChanged: function () {
    if (this.props.layer) {
      this.props.layer.getLayer().setVisible(this.props.layer.getVisible());
    }
    this.setState({
      visible: this.props.layer.getVisible()
    });
  },

  /**
   * On legend change event handler.
   * @instance
   */
  onLegendChanged: function () {
    this.setState({ legend: this.props.layer.getLegend() });
  },

  /**
   * On show legend change event handler.
   * @instance
   */
  onShowLegendChanged: function () {
    this.setState({ showLegend: this.props.layer.get('showLegend') });
  },

  /**
   * On show info change event handler.
   * @instance
   */
  onShowInfoChanged: function () {
    this.setState({ showInfo: this.props.layer.get('showInfo') });
  },

  /**
   * Toggle visibility of this layer item.
   * @instance
   */
  toggleVisible: function (e) {
    e.stopPropagation();
    this.props.layer.setVisible(!this.state.visible);
  },

  /**
   * Toggle legend visibility
   * @instance
   */
  toggleLegend: function (e) {
    e.stopPropagation();
    this.props.layer.set('showLegend', !this.state.showLegend);
  },

  /**
   * Toggle info visibility
   * @instance
   */
  toggleInfo: function (e) {
    e.stopPropagation();
    this.props.layer.set('showInfo', !this.state.showInfo);
  },

  /**
   * Render the load information component.
   * @instance
   * @return {external:ReactElement}
   */
  renderStatus: function () {
    return this.state.status === "loaderror" ?
    (
      React.createElement("span", {href: "#", className: "tooltip", title: "Lagret kunde inte laddas in. Kartservern svarar inte."}, 
        React.createElement("span", {title: "", className: "fa fa-exclamation-triangle tile-load-warning"})
      )
    ) : null;
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var caption       = this.state.caption
    ,   expanded      = this.state.showLegend
    ,   visible       = this.state.visible
    ,   toggleLegend  = (e) => { this.toggleLegend(e) }
    ,   toggleVisible = (e) => { this.toggleVisible(e) }
    ,   toggleInfo  = (e) => { this.toggleInfo(e) }
    ,   infoVisible   = this.state.infoVisible
    ,   infoTitle     = this.state.infoTitle
    ,   infoText      = this.state.infoText
    ,   infoUrl       = this.state.infoUrl
    ,   infoUrlText       = this.state.infoUrlText
    ,   infoOwner     = this.state.infoOwner
    ,   infoExpanded  = this.state.showInfo;

    if (!caption) {
      return null;
    }

    var components = this.props.layer.getExtendedComponents({
      legendExpanded: expanded
    });

    var innerBodyClass = expanded && components.legend.legendPanel ? "panel-body" : "hidden";

    var statusClass = this.state.status === "loaderror" ? "fa fa-exclamation-triangle tile-load-warning tooltip" : "";

    var componentsInfo = this.props.layer.getExtendedComponents({
      infoExpanded: infoExpanded
    });

    var innerInfoBodyClass = infoExpanded && componentsInfo.legend.legendPanel ? "dropdown" : "hidden";
    
    var infoUrlText =  this.state.infoUrlText && this.state.infoUrlText.length ? this.state.infoUrlText : this.state.infoUrl;

    return (
      React.createElement("div", {className: "panel panel-default layer-item"}, 
        React.createElement("div", {className: "panel-heading unselectable", onClick: toggleLegend}, 
          React.createElement("span", {onClick: toggleVisible, className: "clickable", style: { position: 'relative', top: '3px'}}, 
            React.createElement("i", {className: visible ? 'fa fa-check-square-o': 'fa fa-square-o', style: { width: '15px'}}), " ", 
            this.renderStatus(), 
            React.createElement("label", {className: visible ? 'layer-item-header-text active-group' : 'layer-item-header-text'}, caption), " "
          ), 
          components.legend.legendButton, 

          React.createElement("span", {onClick: infoVisible ? toggleInfo : null}, 
            infoVisible ? components.legend.infoButton : null
          )

        ), 
        React.createElement("div", {className: innerInfoBodyClass}, 
          React.createElement("p", {className: "info-title", dangerouslySetInnerHTML: {__html: this.state.infoTitle}}), 
          React.createElement("p", {className: "info-text", dangerouslySetInnerHTML: {__html: this.state.infoText}}), 
          React.createElement("a", {className: "info-text", href: this.state.infoUrl, target: "_blank", dangerouslySetInnerHTML: {__html: infoUrlText}}), 
          React.createElement("p", {className: "info-text", dangerouslySetInnerHTML: {__html: this.state.infoOwner}})
        ), 

        React.createElement("div", {className: innerBodyClass}, 
          expanded ? components.legend.legendPanel : null
        )
      )
    );
  }
};

/**
 * LayerItemView module.<br>
 * Use <code>require('views/layeritem')</code> for instantiation.
 * @module LayerItemView-module
 * @returns {LayerItemView}
 */
module.exports = React.createClass(LayerItemView);

},{}],"views/layerpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var LayerItem = require('views/layeritem');
var BackgroundSwitcher = require('components/backgroundswitcher');

/**
 * @class
 */
var LayerPanelView = {
  /**
   * Mounted layers
   * @property {object}
   * @instance
   */
  mountedLayers: {},
  renderedLayerGroups: {},

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    this.renderedLayerGroups = {};
    return {
      visible: false,
      mapConfigurations : [],
      dropDownValue : HAJK2.configFile
    }
  },

  componentWillMount: function() {
    this.props.model.setExpanded(this.props.model.get('groups'));
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    if(this.props.model.get('dropdownThemeMaps')){
      this.populateThemeMaps()
    }

    this.props.model.on('change:layerCollection', this.onLayerCollectionChanged, this);
    this.props.model.get('layerCollection').forEach(layer => {
      layer.on('change:visible', () => {
        this.updateGroupToggledCheckbox(layer);
      });
    });
    this.setState({
      layers: this.props.model.get("layerCollection")
    });


  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.off("change:layerCollection", this.onLayerCollectionChanged, this);
    this.props.model.get('layerCollection').forEach(layer => {
      layer.off('change:visible');
    });
  },

  /**
   * On layer collection change event handler.
   * @instance
   */
  onLayerCollectionChanged: function () {
    this.setState({ layers: this.props.model.get("layerCollection") });
  },

  /**
   * Find group in config tree.
   * @instance
   * @param {object[]} groups
   * @param {number} groupId
   * @return {object} group
   */
  findGroupInConfig: function recursive(groups, id) {
    var found = false;
    groups.forEach(group => {
      if (group.id === id) {
        found = group;
      } else {
        if (group.hasOwnProperty('groups')) {
          if (!found) {
            found = recursive(group.groups, id);
          }
        }
      }
    });
    return found;
  },

  /**
   * Find layers in given group.
   * @instance
   * @param {group} group
   * @return {Layer[]} layers
   */
  getLayersForGroup: function(group) {

    var layersInModel = this.props.model.get("layerCollection")
    ,   layers = [];

    if (!layersInModel || !group) {
      return [];
    }

    group.layers.forEach(inLayer => {
      var layer = layersInModel.find(layer => layer.id === inLayer.id);
      if (layer) {
        layer.set('group', group.id);
        layers.push(layer);
      }
    });

    return layers;
  },

  /**
   * Get a flattened list of ALL layers per group.
   * @instance
   * @param {object} group
   * @return {Layer[]} layers
   */
  drillGroupForLayers: function recursive(group) {

    var groups = group.groups
    ,   layers = this.getLayersForGroup(group);

    if (groups) {
      groups.forEach((subgroup) => {
        layers = layers.concat(recursive.call(this, subgroup));
      })
    }

    return layers;
  },

  /**
   * Toggle the whole group
   * @deprecated
   * @instance
   * @param {object} group
   * @param {object} e
   */
  toggleGroup: function (group, e) {
    var layers = this.drillGroupForLayers(group);
    if (group.autoChecked !== undefined && group.autoChecked !== group.checked) {
      group.checked = group.autoChecked
    }
    group.checked = !group.checked;
    this.forceUpdate();
    layers.forEach((layer) => {
      var groupId = layer.get('group');
      layer.set('visible', group.checked);
    });
  },

  /**
   * Update the group checkbox, checked if all the layers are visible.
   * @deprecated
   * @instance
   * @param {object} group
   * @param {object} e
   */
  updateGroupToggledCheckbox: function recur(layer) {

    if (!layer) return;

    var groupId = typeof layer === 'string' ? layer : (layer.get ? layer.get('group') : undefined)
    ,   group
    ,   layers;

    if (groupId) {

      group  = this.findGroupInConfig(this.groups, groupId);
      layers = this.drillGroupForLayers(group);

      if (group.parent && group.parent != -1) {
        recur.call(this, group.parent);
      }

      let checked = layers.every(layer => layer.getVisible() === true)
      ,   $ref = $(this.refs["group_" + group.id])
      ,   checkedClass = "fa-check-square-o"
      ,   uncheckedClass = "fa-square-o"
      ,   activeGroup = layers.some(layer => layer.getVisible() === true)
      ,   $refGroup = $(this.refs[group.id])
      ,   activeClass = "active-group";

      if (checked) {
        $ref.removeClass(uncheckedClass);
        $ref.addClass(checkedClass);
        group.autoChecked = true;
      } else  {
        $ref.removeClass(checkedClass);
        $ref.addClass(uncheckedClass);
        group.autoChecked = false;
      }

      if (activeGroup) {
        $refGroup.addClass("active-group");
      } else {
        $refGroup.removeClass("active-group");
      }
    }
  },

  /**
   * Toggle the visibility of all layers in given group.
   * @instance
   * @param {object} group
   * @param {object} e
   */
  toggleGroupVisibility: function (group, e) {
    var state = {}
    ,   value
    ,   id = "group_" + group.id;

    value = state[id] = this.state[id] === "hidden" ? "visible" : "hidden";
    this.props.model.set(id, value);
    this.setState(state);
  },

  /**
   * Render layers in group.
   * @instance
   * @param {object} group
   * @return {LayerItemView[]}
   */
  renderLayers: function (group) {

    var layers = this.getLayersForGroup(group);

    if (layers.length === 0) {
      return null;
    }

    return layers.map((layer, index) => {
      return (React.createElement(LayerItem, {key: "layer_" + Math.random(), layer: layer}));
    });
  },

  /**
   * Render groups.
   * @instance
   * @param {object[]} groups
   * @return {external.ReactElement} groups
   */
  renderGroups: function recursive(groups) {
    return groups.map((group, i) => {
      if (!this.renderedLayerGroups.hasOwnProperty(group.id)) {
        this.renderedLayerGroups[group.id] = this.renderLayers(group);        
      }
      
      var layers = this.renderedLayerGroups[group.id]
      ,   subgroups
      ,   id = "group_" + group.id
      ,   toggleGroup
      ,   buttonClassName
      ,   toggleClassName;

      if (layers) {
        layers.forEach(layer => {
          var id = layer.props.layer.get('id');
          if (!this.mountedLayers.hasOwnProperty(id)) {
            this.mountedLayers[id] = layer.props.layer;
          }
        });
      }

      if (!this.state.hasOwnProperty(id)) {
        this.state[id] = this.props.model.get(id);
      }

      if (group.hasOwnProperty("groups")) {
        subgroups = recursive.call(this, group.groups);
      }

      buttonClassName = this.state[id] === "hidden" ?
        "fa fa-angle-right clickable arrow" :
        "fa fa-angle-up clickable arrow";

      toggleClassName = group.checked ? "fa fa-check-square-o" : "fa fa-square-o";

      toggleGroup = group.toggled
      ? (React.createElement("i", {
          className: toggleClassName, 
          style: {cursor: 'pointer', marginLeft: "4px", width: "14px"}, 
          ref: id, 
          onClick: this.toggleGroup.bind(this, group), 
          id: id}
        ))
      : null;

      return (
        React.createElement("div", {className: "layer-group", key: i}, 
          React.createElement("div", null, 
            React.createElement("span", {className: buttonClassName, onClick: this.toggleGroupVisibility.bind(this, group)}), 
            toggleGroup, 
            React.createElement("label", {style: {cursor: 'pointer', 'marginLeft': '4px'}, ref: group.id, onClick: this.toggleGroupVisibility.bind(this, group), id: group.id}, group.name)
          ), 
          React.createElement("div", {className: this.state[id]}, 
            layers, 
            subgroups
          )
        )
      )
    })
  },

  /**
   * Toggle all layers
   * @instance
   */
   toggleAllOff() {
     this.props.model.toggleAllOff();
   },

   /**
   * Loads new config into HAJK2
   * @instance
   * @param {event} e
   */

   setThemeMap : function(e) {
    var configurationName = e.target.value; 
    var index = e.nativeEvent.target.selectedIndex;
    var configurationTitle = e.nativeEvent.target[index].text;
    this.props.model.setThemeMap(configurationName, configurationTitle);
   },

   populateThemeMaps : function() {
     this.props.model.loadThemeMaps(mapConfigurations => {      
      this.setState({
        mapConfigurations: mapConfigurations,
        dropDownValue : HAJK2.configFile
      })

    });
   },


  /**
   * Change layer-list configuration.
   * @deprecated
   * @instance
   * @param {object} e
   */
  selectTheme: function (e) {

    var value  = e.target.value
    ,   before = this.mountedLayers;

    this.mountedLayers = {};
    this.props.model.set("selectedTheme", parseInt(value));
    this.setState({
      selectedTheme: value
    });

    // Set visible layers to false,
    // if they are missing on the new collection.
    // The set timeout is to force React to
    // set the new state before this is done.
    setTimeout(() => {

      var p = this.mountedLayers
      ,   a = Object.keys(before)
      ,   b = Object.keys(p)
      ,   m = a.filter(e => b.indexOf(e) < 0)
      ,   l = this.state.layers;

      l.toArray().filter(layer =>
        m.find(v =>
          parseInt(v) === layer.get('id')
        )
      ).forEach(layer => {
        layer.setVisible(false);
      });

    }, 0)
  },

  openInstruction: function (){
    var element = $("#instructionText");
    element.toggle();
  },
  /**
   * Render themes select options.
   * @deprecated
   * @instance
   * @param {object[]} themes
   * @return {external.ReactElement} theme options
   */
  renderThemeOptions: function (themes) {
    return themes.map((theme, i) => (
      React.createElement("option", {key: i, value: theme.id}, theme.name)
    ));
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var mapConfigurations = [];
    if (typeof this.state.mapConfigurations !== "undefined" && this.state.mapConfigurations != null){
      mapConfigurations = this.state.mapConfigurations.map((map, i) => React.createElement("option", {value: map.mapConfigurationName, key: i}, map.mapConfigurationTitle));
    }
    var groups, toggleAllButton, dropdownThemeMaps, themeMapHeaderCaption;

    this.groups = this.props.model.get('groups');

    groups = this.renderGroups(this.props.model.get('groups'));

    this.props.model.get('layerCollection').forEach(layer => {
      this.updateGroupToggledCheckbox(layer);
    });

    if (this.props.model.get('toggleAllButton')) {
      toggleAllButton = (
        React.createElement("div", {style: {marginBottom: "10px"}}, 
          React.createElement("button", {className: "btn btn-main btn-inverse", onClick: () => this.toggleAllOff()}, "Släck alla lager")
        )
      );
    }

    if (this.props.model.get('themeMapHeaderCaption') !== null && 
        this.props.model.get('themeMapHeaderCaption').length > 0) {
      themeMapHeaderCaption = (
        React.createElement("span", {style: {marginRight: "10px"}}, this.props.model.get('themeMapHeaderCaption'))
      );
    }

    if (this.props.model.get('dropdownThemeMaps')){
      dropdownThemeMaps = (
        React.createElement("div", null, 
        themeMapHeaderCaption, 
        React.createElement("select", {onChange: this.setThemeMap, style: {marginBottom: "10px", width : "100%"}, value: this.state.dropDownValue}, 
        mapConfigurations
        )
        )
      );
    };

    return (
      React.createElement(Panel, {title: "Kartlager", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "layer-panel"}, 
          dropdownThemeMaps, 
          toggleAllButton, 
          React.createElement(BackgroundSwitcher, {layers: this.props.model.getBaseLayers(), model: this.props.model}), 
          React.createElement("br", null), 
          groups
        )
      )
    );
  }
};

/**
 * LayerPanelView module.<br>
 * Use <code>require('views/layerpanel')</code> for instantiation.
 * @module LayerPanelView-module
 * @returns {LayerPanelView}
 */
module.exports = React.createClass(LayerPanelView);

},{"components/backgroundswitcher":"components/backgroundswitcher","views/layeritem":"views/layeritem","views/panel":"views/panel"}],"views/locationpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
/**
 * @class
 */
var Location = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {

    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    $('.ol-viewport').css('cursor', 'crosshair');
    this.props.model.activate();
    this.props.model.on('change:imageDate', () => {
      this.setState({
        imageDate: this.props.model.get('imageDate')
      });
    });
  },

  componentWillUnmount: function () {
    $('.ol-viewport').css('cursor', 'default');
    this.props.model.deactivate();
    this.props.model.off('change:imageDate');
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
  }
};

/**
 * StreetViewPanelView module.<br>
 * Use <code>require('views/anchorpanel')</code> for instantiation.
 * @module StreetViewPanel-module
 * @returns {StreetViewPanel}
 */
module.exports = React.createClass(Location);

},{"views/panel":"views/panel"}],"views/map":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
var MapView = {
  /**
   * Get default properties.
   * @instance
   * @return {object}
   */
  getDefaultProps : function () {
    return {
      id: "",
      loaded: function () {}
    };
  },

  shouldComponentUpdate: function () {
    return false;
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    return (
      React.createElement("div", {id: this.props.id, className: "map-fullscreen"})
    );
  }
};

/**
 * MapView module.<br>
 * Use <code>require('views/map')</code> for instantiation.
 * @module MapView-module
 * @returns {MapView}
 */
module.exports = React.createClass(MapView);

},{}],"views/measurepanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var Alert = require('alert');
//var ColorPicker = require('components/colorpicker');

/**
 * @class
 */
var MeasurePanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false,
      lineWidth: this.props.model.get('lineWidth'),
      lineStyle: this.props.model.get('lineStyle'),
      polygonLineWidth: this.props.model.get('polygonLineWidth'),
      polygonLineStyle: this.props.model.get('polygonLineStyle'),
      polygonFillOpacity: this.props.model.get('polygonFillOpacity'),
      buttonDisabled: true
    };
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
    this.props.model.abort();
    this.props.model.off('change:dialog');
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
    this.setState({
      showLabels: this.props.model.get('showLabels')
    });
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.model.on('change:dialog', () => {
      this.setState({
        dialog: this.props.model.get('dialog')
      });
      this.refs.textInput.focus();
    });
  },

  /**
   * Render alert component
   * @instance
   * @return {AlertView}
   */
  renderAlert: function () {
    var options = {
      visible: this.state.alert,
      message: this.state.alertMessage,
      confirm: this.state.confirm,
      confirmAction: () => {
        this.state.confirmAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      denyAction: () => {
        this.state.denyAction();
        this.setState({
          alert: false,
          confirm: false,
          alertMessage: ""
        })
      },
      onClick: () => {
        this.setState({
          alert: false,
          alertMessage: ""
        })
      }
    };

    if (this.state.alert) {
      return React.createElement(Alert, {options: options})
    } else {
      return null;
    }
  },

  /**
   * Remove all drawings from map.
   * @instance
   */
  clear: function () {
    this.props.model.clear();
  },

  /**
   * Confirm before clear.
   * @instance
   */
  alertClear: function(){
    this.setState({
      alert: true,
      alertMessage: ` Vill du verkligen rensa allt?`,
      confirm: true,
      confirmAction: () => {
        this.clear();
      },
      denyAction: () => {
        this.setState({ alert: false });
      }
    });
  },

  /**
   * Abort any operation and deselect any tool.
   * @instance
   */
  abort: function () {
    this.props.model.abort();
    $('.measure-tool-item').removeClass('selected');
    this.setState({
      symbology: "",
      buttonDisabled: true
    })
    this.props.model.measureTooltip.setPosition(null);
  },


  /**
   * Handle change event of the show labels checkbox.
   * @instance
   */
  toggleLabels: function () {
    this.setState({
      showLabels: this.props.model.toggleLabels()
    });
  },

  /**
   * Activate the removal tool and update visuals.
   * @instance
   */
  activateRemovalTool: function () {
    this.props.model.activateRemovalTool();
    $('.measure-tool-item').removeClass('selected');
    $('#delete').addClass('selected');
    this.setState({ buttonDisabled: false });
  },

  /**
   * Activate move tool and update visuals.
   * @instance
   */
  activateMoveTool: function () {
    this.props.model.activateMoveTool();
    $('.measure-tool-item').removeClass('selected');
    $('#move').addClass('selected');
    this.setState({ buttonDisabled: false });
  },

  /**
   * Activate move tool and update visuals.
   * @instance
   */
  activateEditTool: function () {
    this.props.model.activateEditTool();
    $('.measure-tool-item').removeClass('selected');
    $('#edit').addClass('selected');
    this.setState({ buttonDisabled: false });
  },

  /*
   * Activate given draw tool and update visuals.
   * @instance
   * @param {string} type
   */
  activateDrawTool: function (type) {
    this.props.model.activateDrawTool(type);
    $('.measure-tool-item').removeClass('selected');
    $('#' + type).addClass('selected');
    this.setState({ buttonDisabled: false });
  },

  /**
   * Set marker image.
   * @instance
   * @param {object} e
   */
  setMarkerImg: function(e) {
    this.props.model.set('markerImg', e.target.src);
    this.forceUpdate();
  },

  /**
   * Render the dialog component.
   * @instance
   * @param {boolean} visible
   * @return {external:ReactElement} component
   */
  renderDialog: function(visible) {
    if (!visible) return null;

    function enter(e) {
      if (e.keyCode == 13) {
        update.call(this);
      }
    }

    function abort() {
      this.props.model.set('dialog', false);
      this.refs.textInput.blur();
      this.props.model.removeEditFeature();
    }

    function update() {
      this.refs.textInput.blur();
      this.props.model.set('dialog', false);
      this.props.model.setPointText(this.refs.textInput.value);
    }

    return (
      React.createElement("div", {className: "modal"}, 
        React.createElement("div", {className: "modal-dialog"}, 
          React.createElement("div", {className: "modal-content"}, 
            React.createElement("div", {className: "modal-header"}, 
              React.createElement("h4", {className: "modal-title"}, "Ange text")
            ), 
            React.createElement("div", {className: "modal-body"}, 
              React.createElement("input", {ref: "textInput", onKeyDown: enter.bind(this)})
            ), 
            React.createElement("div", {className: "modal-footer"}, 
              React.createElement("button", {type: "button", className: "btn btn-default", "data-dismiss": "modal", onClick: update.bind(this)}, "Spara"), " ", 
              React.createElement("button", {type: "button", className: "btn btn-default", "data-dismiss": "modal", onClick: abort.bind(this)}, "Avbryt")
            )
          )
        )
      )
    );
  },

  /**
   * Render the view.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var dialog = this.renderDialog(this.state.dialog);

    return (
        React.createElement(Panel, {title: "Mät", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
          React.createElement("div", {className: "draw-tools measure-tools"}, 
            React.createElement("ul", null, 
              React.createElement("li", {className: "measure-tool-item", id: "LineString", onClick: this.activateDrawTool.bind(this, "LineString")}, 
                React.createElement("i", {className: "iconmoon-linje"}), " ", React.createElement("span", null, "Mät avstånd")
              ), 
              React.createElement("li", {className: "measure-tool-item", id: "Polygon", onClick: this.activateDrawTool.bind(this, "Polygon")}, 
                React.createElement("i", {className: "iconmoon-yta"}), " ", React.createElement("span", null, "Mät yta")
              ), 
              React.createElement("li", {className: "measure-tool-item", id: "clear", onClick: this.alertClear}, 
                React.createElement("i", {className: "fa fa-trash fa-0"}), " ", React.createElement("span", null, "Rensa allt")
              )
            )
          ), 
          React.createElement("div", null, 
            dialog, 
            this.renderAlert()
          )
        )

    );
  }
};

/**
 * MeasurePanelView module.<br>
 * Use <code>require('views/MeasurePanel')</code> for instantiation.
 * @module MeasurePanelView-module
 * @returns {MeasurePanelView}
 */
module.exports = React.createClass(MeasurePanelView);

},{"alert":"alert","views/panel":"views/panel"}],"views/navigationpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var panels = {
  'infopanel': require('views/infopanel'),
  'layerpanel': require('views/layerpanel'),
  'bookmarkpanel': require('views/bookmarkpanel'),
  'searchpanel': require('views/searchpanel'),
  'coordinatespanel': require('views/coordinatespanel'),
  'exportpanel': require('views/exportpanel'),
  'drawpanel': require('views/drawpanel'),
  'editpanel': require('views/editpanel'),
  'anchorpanel': require('views/anchorpanel'),
  'streetviewpanel': require('views/streetviewpanel'),
  'bufferpanel': require('views/bufferpanel'),
  'routingpanel': require('views/routingpanel'),
  'presetpanel': require('views/presetpanel'),
  'measurepanel': require('views/measurepanel')
};

var Alert = require('alert');

/**
 * @class
 */
var NavigationPanelView = {
  /**
   * Get default properties.
   * @instance
   * @return {object}
   */
  getDefaultProps : function () {
    return {
      items: [],
      alertVisible: false
    };
  },

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function () {
    return {
      toggled: false,
      minimized: false,
      activePanel: undefined
    };
  },

  forced: false,

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.props.model.on("change:activePanel", (sender, panel) => {
      this.forced = true;
      this.setState({
        activePanel: panel,
        minimized: false
      });
      this.forced = false;
    });

    this.props.model.on('change:alert', (e, value) => {
      this.setState({alertVisible: value});
    });

    this.props.model.on("change:visible", (sender, visible) => {
      this.setState({toggled: visible});
      if (visible) {
        this.forced = true;
      }
      setTimeout(() => {
        this.forced = false;
      }, 100);
    });

    this.props.model.on("change:toggled", (sender, visible) => {
      var minimized = true;
      if (this.forced) {
            minimized = false;
        }
        this.setState({minimized: minimized});
        this.forced = false;
    });

      this.props.model.on('change:r', () => {
          this.maximize();
    });
  },

  /**
   * Toggle the panel to/from minimized mode.
   * @instance
   */
  toggle: function () {
    if (this.state.activePanel) {
      this.props.model.set("toggled", !this.props.model.get("toggled"));
    }
  },

  /**
   * Maximize the panel.
   * @instance
   */
  maximize: function () {
    if (this.state.minimized) {
      this.setState({
        minimized: false
      });
    }
  },

  /**
   * Minimize the panel.
   * @instance
   */
  minimize: function () {
    if (!this.state.minimized) {
      this.setState({
        minimized: true
      });
    }
  },

  /**
   * Generate specification object for alert panel
   * @instance
   */
  getAlertOptions: function() {
    return {
      visible: this.state.alertVisible,
      confirm: true,
      message: "Du har en aktiv redigeringssession startad, vill du avbryta?",
      denyAction: () => {
        this.props.model.set('alert', false);
        this.props.model.deny();
      },
      confirmAction: () => {
        this.props.model.set('alert', false);
        this.props.model.ok();
      }
    }
  },

  unmount: function() {
    var model = this.props.model.get('activePanel').model;
    model.set({'visible': false});
    this.props.model.set({'visible': false});
    this.props.model.set({'activePanelType': undefined});
    this.props.model.set({'activePanel': undefined});
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {

    var classes = this.state.toggled ? 'navigation-panel' : 'navigation-panel folded'
    ,   panelInstance = null
    ,   Panel = null;

    if (this.state.minimized) {
      classes += " minimized";
    }

    if (this.state.activePanel) {
      if (panels.hasOwnProperty(this.state.activePanel.type.toLowerCase())) {
        Panel = panels[this.state.activePanel.type.toLowerCase()];
        panelInstance = (
          React.createElement(Panel, {
            model: this.state.activePanel.model, 
            minimized: this.state.minimized, 
            navigationPanel: this, 
            onCloseClicked: () => { this.toggle() }, 
            onUnmountClicked: () => { this.unmount() }}
          )
        );
      } else {
        console.error("Panel reference is not found. See Navigationpanel.jsx.");
      }
    }

    return (
      React.createElement("div", null, 
        React.createElement(Alert, {options: this.getAlertOptions()}), 
        React.createElement("div", {id: "navigation-panel", className: classes, onClick: this.maximize}, 
          panelInstance
        )
      )
    );
  }
};

/**
 * NavigationPanelView module.<br>
 * Use <code>require('views/navigationpanel')</code> for instantiation.
 * @module NavigationPanelView-module
 * @returns {NavigationPanelView}
 */
module.exports = React.createClass(NavigationPanelView);

},{"alert":"alert","views/anchorpanel":"views/anchorpanel","views/bookmarkpanel":"views/bookmarkpanel","views/bufferpanel":"views/bufferpanel","views/coordinatespanel":"views/coordinatespanel","views/drawpanel":"views/drawpanel","views/editpanel":"views/editpanel","views/exportpanel":"views/exportpanel","views/infopanel":"views/infopanel","views/layerpanel":"views/layerpanel","views/measurepanel":"views/measurepanel","views/presetpanel":"views/presetpanel","views/routingpanel":"views/routingpanel","views/searchpanel":"views/searchpanel","views/streetviewpanel":"views/streetviewpanel"}],"views/panel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */

var panelIs = undefined;

var PanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      instruction: ""
    };
  },

  clickSwipeBtn: function(){

    if ($("#sidebar-toggle-swipe")[0].classList.contains("sidebar-open-after")) {
      $(".container-swipe-menu").removeClass("open-sidebar");
      $("#sidebar-toggle-swipe").removeClass("sidebar-open-after");
      $("#sidebar-toggle-swipe").addClass("sidebar-close-after");
    } else {
      $(".container-swipe-menu").addClass("open-sidebar");
      $("#sidebar-toggle-swipe").removeClass("sidebar-close-after");
      $("#sidebar-toggle-swipe").addClass("sidebar-open-after");
    }

  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    if(isMobile && mobilAnpassningEnabled) {
      $(".swipe-area").swipe({
        swipeStatus: function (event, phase, direction, distance, duration, fingers) {
          if (phase == "move" && direction == "right") {
            panelIs = true;
            $(".container-swipe-menu").addClass("open-sidebar");
            $("#sidebar-toggle-swipe").removeClass("sidebar-close-after");
            $("#sidebar-toggle-swipe").addClass("sidebar-open-after");
          }
          if (phase == "move" && direction == "left") {
            panelIs = false;
            $(".container-swipe-menu").removeClass("open-sidebar");
            $("#sidebar-toggle-swipe").removeClass("sidebar-open-after");
            $("#sidebar-toggle-swipe").addClass("sidebar-close-after");
          }
        }
      });
    }
    $("#instructionsText").hide();
  },

  openInstruction: function (){
    var element = $("#instructionsText");
    element.toggle();
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    if (!isMobile || !mobilAnpassningEnabled) {
      var instructionBtn;
      var instructionTxt;
      if(typeof this.props.instruction !== 'undefined' && this.props.instruction !== null && this.props.instruction.length > 0){
        instructionBtn = (
          React.createElement("button", {onClick: () => this.openInstruction(), className: "btn-info", id: "instructionBox1"}, React.createElement("img", {src: infologo}))
        );
        instructionTxt = (
          React.createElement("div", {className: "panel-body-instruction", id: "instructionsText", dangerouslySetInnerHTML: {__html: this.props.instruction}})
        );
      }


      var navPanel = document.getElementById("navigation-panel");
      navPanel.style.width = "417px";

      var toggleIcon = this.props.minimized ? "fa fa-plus" : "fa fa-minus";
      var closeIcon = this.props.minimized ? "fa fa-plus" : "fa fa-times";
      toggleIcon += " pull-right clickable panel-close";
      closeIcon += " pull-right clickable panel-close";
      return (
        React.createElement("div", {className: "panel navigation-panel-inner"}, 
          React.createElement("div", {className: "panel-heading"}, 
            React.createElement("span", null, this.props.title), 
            instructionBtn, 
            React.createElement("i", {className: closeIcon, onClick: () => {
              if (this.props.onUnmountClicked) {
                this.props.onUnmountClicked();
              }
            }}), 
            React.createElement("i", {className: toggleIcon, onClick: this.props.onCloseClicked}), 
            instructionTxt
          ), 
          React.createElement("div", {className: "panel-body"}, 
            this.props.children
          )
        )
      );
    } else {
      // mobile

      var navPanel = document.getElementById("navigation-panel");
      navPanel.style.width = "50px";

      var toggleIcon = this.props.minimized ? "fa fa-plus" : "fa fa-minus";
      var closeIcon = this.props.minimized ? "fa fa-plus" : "fa fa-times";
      toggleIcon += " pull-right clickable panel-close";
      closeIcon += " pull-right clickable panel-close";

      var containerClass = "container-swipe-menu open-sidebar";
      var sidebarClass = "sidebar-open-after";
      if (typeof firstTimeOpenedPanelMobile == "undefined" || firstTimeOpenedPanelMobile < 2) {
        if (typeof firstTimeOpenedPanelMobile == "undefined"){
          firstTimeOpenedPanelMobile = 0;
        } else {
          firstTimeOpenedPanelMobile += 1;
        }
        containerClass = "container-swipe-menu";
        sidebarClass = "sidebar-close-after";
      }

      var instructionBtn;
      var instructionTxt;
      if(typeof this.props.instruction !== 'undefined' && this.props.instruction !== null && this.props.instruction.length > 0){
        instructionBtn = (
          React.createElement("i", {onClick: () => this.openInstruction(), className: "btn-info", id: "instructionBox1"}, React.createElement("img", {src: infologo}))
        );
        instructionTxt = (
          React.createElement("div", {className: "panel-body-instruction", id: "instructionsText", dangerouslySetInnerHTML: {__html: this.props.instruction}})
        );
      }

      return (
        React.createElement("div", {className: containerClass}, 
          React.createElement("div", {id: "sidebar-swipe"}, 
            React.createElement("div", {className: "panel navigation-panel-inner"}, 
              React.createElement("div", {className: "panel-heading"}, 
                React.createElement("span", null, this.props.title), 
                instructionBtn, 
                React.createElement("i", {className: closeIcon, onClick: () => {
                  if (this.props.onUnmountClicked) {
                    this.props.onUnmountClicked();
                  }
                }}), 
                instructionTxt
              ), 
              React.createElement("div", {className: "panel-body"}, 
                this.props.children
              )
            )
          ), 
          React.createElement("div", {className: "main-content-swipe"}, 
            React.createElement("div", {className: "swipe-area"}), 
            React.createElement("a", {"data-toggle": ".container-swipe-menu", id: "sidebar-toggle-swipe", className: sidebarClass, onClick: () => {this.clickSwipeBtn()}}, 
              React.createElement("span", {className: "bar-swipe"})
            )
          )
        ));
    }


  }
};

/**
 * PanelView module.<br>
 * Use <code>require('views/panel')</code> for instantiation.
 * @module PanelView-module
 * @returns {PanelView}
 */
module.exports = React.createClass(PanelView);

},{}],"views/presetpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/Johkar/Hajk2

var Panel = require('views/panel');
/**
 * @class
 */
var PresetPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      anchor: ""
    };
  },

  /**
   * Delegate to handle insertion of presets.
   * @instance
   * @param {object} e - Syntetic DOM event.
   */
  onSubmitForm: function (e) {
    e.preventDefault();
    var name = ReactDOM.findDOMNode(this.refs.name).value;
    this.props.model.addPreset(name, () => this.forceUpdate());
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.generate();
  },

  /**
   * Generete anchor text.
   * @instance
   */
  generate: function () {
    this.setState({
      anchor: this.props.model.generate()
    });
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var anchor = this.props.model.get('anchor');
    var presetName = this.props.model.get('presetName');
    var presetUrl = this.props.model.get('presetUrl');

    var getUrl = this.props.model.getUrl();
    var presets = this.props.model.getPresets();
    var items = null;

    if (presets) {
      items = presets.map((preset, i) => {
        return (
          React.createElement("a", {key: i, href: getUrl + preset.presetUrl}, 
            React.createElement("li", null, 
              preset.name, " ", React.createElement("i", {className: "fa fa-bookmark preset-icon"})
            )
          )
        );
      });
    }
    
    return (
      React.createElement(Panel, {title: "Snabbval", onUnmountClicked: this.props.onUnmountClicked, onCloseClicked: this.props.onCloseClicked, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "bookmark-panel panel-content"}, 
          React.createElement("label", null, "Tillgängliga snabbval"), 
            React.createElement("ul", null, 
              items
            )
        )
      )
    );
  }
};

/**
 * PresetPanelView module.<br>
 * Use <code>require('views/presetpanel')</code> for instantiation.
 * @module PresetPanelView-module
 * @returns {PresetPanelView}
 */
module.exports = React.createClass(PresetPanelView);

},{"views/panel":"views/panel"}],"views/routingpanel":[function(require,module,exports){
var Panel = require('views/panel');

/**
 * @class
 */
var RoutingPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      selectTravelMode: "walk"
    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
  },

  /**
   * Generete anchor text.
   * @instance
   */
  generate: function () {

  },

  getTravelMode: function () {
    return this.state.selectTravelMode;
  },

  setTravelMode: function(e) {
    this.setState({
      selectTravelMode: e.target.value
    });
  },

  activateStartMode: function(){
    if (isMobile) {
      this.props.navigationPanel.minimize();
    }

    this.props.model.activateStartMode(); // working
  },
  activateEndMode: function(){
    if (isMobile) {
      this.props.navigationPanel.minimize();
    }

    this.props.model.activateEndMode(); // working
  },

  showResult: function(routeResult) {
  },

  showImage: function(src, id) {
    var img = document.createElement("img");
    img.src = src;
    img.id = id;

    document.body.appendChild(img);
  },

  openInstruction: function (){
    var element = $("#instructionText");
    element.toggle();
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    this.props.model.initStartPoint();

    return(
      React.createElement(Panel, {title: "Navigation", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "panel-content"}, 
          React.createElement("div", {className: "panel panel-default"}, 
              React.createElement("div", {className: "panel-heading"}, " ①. Välj startpunkt "), 
                React.createElement("div", {className: "panel-body"}, 
                  React.createElement("button", {onClick: () => this.props.model.turnOnGPSClicked(), className: "btn btn-main", id: "naviGPS"}, "Välj befintlig position"), " ", 
                  React.createElement("button", {onClick: () => this.activateStartMode(), className: "btn btn-main", id: "startBtn"}, "Välj position på kartan")
                )
          ), 
          React.createElement("div", {className: "panel panel-default"}, 
             React.createElement("div", {className: "panel-heading"}, " ②. Välj destination "), 
               React.createElement("div", {className: "panel-body"}, 
                 React.createElement("button", {onClick: () => this.activateEndMode(), className: "btn btn-main", id: "startBtn"}, "Välj position på kartan")
             )
          ), 
          React.createElement("div", {className: "panel panel-default"}, 
             React.createElement("div", {className: "panel-heading"}, " ③. Välj färdsätt "), 
             React.createElement("div", {className: "panel-body"}, 
               React.createElement("button", {className: "btn btn-default", onClick: () => this.props.model.setTravelMode('walking')}, React.createElement("img", {src: "/assets/icons/gaRouting.png"})), 
               React.createElement("button", {className: "btn btn-default", onClick: () => this.props.model.setTravelMode('driving')}, React.createElement("img", {src: "/assets/icons/koraRouting.png"})), 
               React.createElement("button", {className: "btn btn-default", onClick: () => this.props.model.setTravelMode('bicycling')}, React.createElement("img", {src: "/assets/icons/cyklaRouting.png"})), 
               React.createElement("button", {className: "btn btn-default", onClick: () => this.props.model.setTravelMode('transit')}, React.createElement("img", {src: "/assets/icons/kollektivRouting.png"}))
             )
          ), 
          React.createElement("div", {className: "panel panel-default-transparent"}, 
             React.createElement("button", {onClick: () => this.props.model.activateRoutingMode(), className: "btn btn-main", id: "startBtn"}, "Sök resa"), " ", 
             React.createElement("button", {onClick: () => this.props.model.deleteLayers(), className: "btn btn-main", id: "startBtn"}, "Rensa")
          ), 
          React.createElement("div", {className: "panel panel-default"}, 
             React.createElement("div", {className: "panel-heading"}, " Resultat "), 
                 React.createElement("div", {className: "panel-body"}, 
                   React.createElement("div", {id: "resultList"})
                 )
          )
        )
      )
    );
  }
};

/**
 * RoutingPanelView module.<br>
 * Use <code>require('views/routingpanel')</code> for instantiation.
 * @module RoutingPanel-module
 * @returns {RoutingPanelView}
 */
module.exports = React.createClass(RoutingPanelView);

},{"views/panel":"views/panel"}],"views/searchpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
var Search = require('components/search');

/**
 * @class
 */
var SearchPanelView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      visible: false
    };
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
  },

  /**
   * Triggered when component unmounts.
   * @instance
   */
  componentWillUnmount: function () {
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    return (
      React.createElement(Panel, {title: "Sökning", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, minimized: this.props.minimized, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement(Search, {model: this.props.model, navigationPanel: this.props.navigationPanel})
      )
    );
  }
};

/**
 * SearchPanelView module.<br>
 * Use <code>require('views/searchpanel')</code> for instantiation.
 * @module SearchPanelView-module
 * @returns {SearchPanelView}
 */
module.exports = React.createClass(SearchPanelView);

},{"components/search":"components/search","views/panel":"views/panel"}],"views/shell":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var MapView = require('views/map');
var MapModel = require('models/map');
var Toolbar = require('views/toolbar');
var LayerCollection = require('collections/layers');
var Toolcollection = require('collections/tools');
var NavigationPanel = require('views/navigationpanel');
var NavigationPanelModel = require("models/navigation");
var SearchBar = require("components/searchbar");

/**
 * @class
 */
var ShellView = {
  /**
   * Get default properties.
   * @instance
   * @return {object}
   */
  getDefaultProps : function () {
    return {
      config: {
        layers: [],
        tools: [],
        map: {}
      }
    };
  },

  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function () {
    return {
      mapModel: undefined,
      toolsCollection: undefined,
      navigationModel: undefined,
      scale: 1
    };
  },

  shouldComponentUpdate: function () {
    return true;
  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function () {
    this.model = this.props.model;
    this.setState({
      views: [
        React.createElement(MapView, {key: this.model.cid, id: this.model.cid})
      ]
    });
  },

  /**
   * Format scale
   * @instance
   * @param {number} scale
   * @return {string} formatted
   */
  formatScale: function(scale) {
    return Math.round(scale).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  configure: function() {
    this.model.configure.call(this.model);
    this.setState({
      views: [
        React.createElement(MapView, {key: this.model.cid, id: this.model.cid}),
        React.createElement(Toolbar, {key: "toolbar", model: this.model.get('toolCollection'), navigationModel: this.model.get('navigation')}),
        React.createElement(NavigationPanel, {key: "navigation", model: this.model.get('navigation')})
      ],
      scale: this.formatScale(this.model.getMap().getScale())
    });

    var bindViewScaleEvents = () => {
      var view = this.model.getMap().getMap().getView();
      view.on('change:resolution', () => {
        this.setState({
          scale: this.formatScale(this.model.getMap().getScale())
        });
      });
    };

    bindViewScaleEvents();
    this.model.getMap().getMap().on('change:view', bindViewScaleEvents);
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    this.configure();

    this.model.on('change:configUpdated', () => {
      var config = this.model.getConfig();
      this.model.get('map').update(config.map);
      //
      // TODO:
      // Implementera inläsning av configobjekt för lager.
      this.model.get('layerCollection').update(config.layers);
      //
      // Implementera inläsning av configobjekt för verktyg.
      // this.model.get('toolCollection').update(config.toolCollection);
    });

  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var views = this.state.views
    ,   scale
    ,   popup
    ,   searchbar
    ,   logo
    ,   pil;

    if (views.length === 3) {
      if (this.model.get('map').get('logo')) {
        logo = (
          React.createElement("div", {className: "map-logo"}, 
            React.createElement("img", {src: this.model.get('map').get('logo')})
          )
        );
      }

      if (this.model.get('map').get('pil') && isMobile) {
        pil = (
          React.createElement("div", {className: "map-pil"}, 
            React.createElement("img", {src: this.model.get('map').get('pil')})
          )
        );
      }

      scale = (
        React.createElement("div", {id: "map-scale", className: "map-scale"}, 
          React.createElement("div", {id: "map-scale-bar"}), 
          React.createElement("div", {className: "map-scale-text"}, "1:", this.state.scale)
        )
      )

      popup = (
        React.createElement("div", {id: "popup", className: "ol-popup"}, 
          React.createElement("a", {href: "#", id: "popup-closer", className: "ol-popup-closer"}), 
          React.createElement("div", {id: "popup-content"})
        )
      )

      var searchTool = this.model.get('toolCollection').find(tool => tool.get('type') === 'search');
      if (searchTool && searchTool.get('onMap')) {
        searchbar = (
          React.createElement("div", {className: "search-bar-holder"}, 
            React.createElement(SearchBar, {model: this.model.get('toolCollection').find(tool => tool.get('type') === 'search')})
          )
        )
      }
    }

    return (
      React.createElement("div", {className: "shell"}, 
        logo, 
        pil, 
        scale, 
        popup, 
        searchbar, 
        views
      )
    );
  }
};

/**
 * ShellView module.<br>
 * Use <code>require('views/shell')</code> for instantiation.
 * @module ShellView-module
 * @returns {ShellView}
 */
module.exports = React.createClass(ShellView);

},{"collections/layers":"collections/layers","collections/tools":"collections/tools","components/searchbar":"components/searchbar","models/map":"models/map","models/navigation":"models/navigation","views/map":"views/map","views/navigationpanel":"views/navigationpanel","views/toolbar":"views/toolbar"}],"views/streetviewpanel":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

var Panel = require('views/panel');
/**
 * @class
 */
var StreetView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {
      imageDate: ""
    };
  },

  /**
   * Triggered when component updates.
   * @instance
   */
  componentDidUpdate: function () {
  },

  /**
   * Triggered when the component is successfully mounted into the DOM.
   * @instance
   */
  componentDidMount: function () {
    $('.ol-viewport').css('cursor', 'crosshair');
    this.props.model.activate();
    this.props.model.on('change:imageDate', () => {
      this.setState({
        imageDate: this.props.model.get('imageDate')
      });
    });
    this.props.model.on('change:location', () => {
      this.props.navigationPanel.maximize();
    });
  },

  componentWillUnmount: function () {
    $('.ol-viewport').css('cursor', 'default');
    this.props.model.deactivate();
    this.props.model.off('change:imageDate');
  },

  renderInfoText: function () {
    if (!this.props.model.get('location')) {
      return React.createElement("div", null, "Klicka i kartan för att aktivera street view. ", React.createElement("br", null), 
        "Förstora fönstret genom att trycka på symbolen i övre högra hörnet. ", React.createElement("br", null)
      );
    }
  },

  /**
   * Render the view
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    return (
      React.createElement(Panel, {title: "Street View", onCloseClicked: this.props.onCloseClicked, onUnmountClicked: this.props.onUnmountClicked, instruction: atob(this.props.model.get('instruction'))}, 
        React.createElement("div", {className: "panel-content"}, 
          React.createElement("h3", null, "Street view"), 
          this.renderInfoText(), 
          React.createElement("div", {id: "street-view-window"}), 
          React.createElement("div", {id: "image-date"}, this.state.imageDate ? this.state.imageDate : "")
        )
      )
    );
  }
};

/**
 * StreetViewPanelView module.<br>
 * Use <code>require('views/streetviewpanel')</code> for instantiation.
 * @module StreetViewPanel-module
 * @returns {StreetViewPanel}
 */
module.exports = React.createClass(StreetView);
},{"views/panel":"views/panel"}],"views/toolbar":[function(require,module,exports){
// Copyright (C) 2016 Göteborgs Stad
//
// Denna programvara är fri mjukvara: den är tillåten att distribuera och modifiera
// under villkoren för licensen CC-BY-NC-SA 4.0.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the CC-BY-NC-SA 4.0 licence.
//
// http://creativecommons.org/licenses/by-nc-sa/4.0/
//
// Det är fritt att dela och anpassa programvaran för valfritt syfte
// med förbehåll att följande villkor följs:
// * Copyright till upphovsmannen inte modifieras.
// * Programvaran används i icke-kommersiellt syfte.
// * Licenstypen inte modifieras.
//
// Den här programvaran är öppen i syfte att den skall vara till nytta för andra
// men UTAN NÅGRA GARANTIER; även utan underförstådd garanti för
// SÄLJBARHET eller LÄMPLIGHET FÖR ETT VISST SYFTE.
//
// https://github.com/hajkmap/Hajk

/**
 * @class
 */
var ToolbarView = {
  /**
   * Get initial state.
   * @instance
   * @return {object}
   */
  getInitialState: function() {
    return {};
  },

  componentDidMount: function(){

  },

  /**
   * Triggered before the component mounts.
   * @instance
   */
  componentWillMount: function() {
    this.props.navigationModel.on('change:activePanelType', () => {
      this.setState({
        activeTool: this.props.navigationModel.get('activePanelType')
      });
    });
  },

  /**
   * Render the panel component.
   * @instance
   * @return {external:ReactElement}
   */
  render: function () {
    var layerSwitcherTool = this.props.model
      .filter(t => t.get('toolbar'))
      .filter(tool => tool.get('type') === 'layerswitcher')
      .map((tool, index) => {
        tool.set('toolbar', isMobile ? 'stable' : 'bottom');
      });


    var tools = this.props.model
      .filter(t => t.get('toolbar'))
      .filter(tool => tool.get('toolbar') === 'bottom' || (tool.get('toolbar') === 'stable' && !mobilAnpassningEnabled))
      .map((tool, index) => {
        var a = tool.get('panel').toLowerCase()
          ,   b = this.state.activeTool
          ,   c = a === b ? 'btn btn-primary' : 'btn btn-default';
        var id = tool.get('Id');

        if (tool.get('active') === false) {
          return null;
        }

        return (
          React.createElement("button", {
            id: id, 
            type: "button", 
            className: c, 
            onClick: () => {
              tool.clicked();
              if (tool.get('type') !== 'information') {
                this.props.navigationModel.set('r', Math.random());
              }
            }, 
            key: index, 
            title: tool.get("title")}, 
            React.createElement("i", {className:  tool.get("icon") })
          )
        );
      });

    // stable button
    var stableButton = this.props.model
      .filter(t => t.get('toolbar'))
      .filter(tool => tool.get('toolbar') === 'stable' && mobilAnpassningEnabled)
      .map((tool, index) => {
        var a = tool.get('panel').toLowerCase()
          ,   b = this.state.activeTool
          ,   c = a === b ? 'btn btn-primary' : 'btn btn-default';
        var id = tool.get('Id');

        if (tool.get('active') === false) {
          return null;
        }

        return (
          React.createElement("button", {
            id: id, 
            type: "button", 
            className: c, 
            onClick: () => {
              tool.clicked();
              if (tool.get('type') !== 'information') {
                this.props.navigationModel.set('r', Math.random());
              }
            }, 
            key: index, 
            title: tool.get("title")}, 
            React.createElement("i", {className:  tool.get("icon") })
          )
        );
      });

    var widgets = this.props.model
      .filter(t => t.get('toolbar'))
      .filter(tool => tool.get('toolbar') === 'top-right')
      .map((tool, index) => {
        var className = tool.get('active') ? 'btn btn-primary' : 'btn btn-default';
        tool.on('change:active', () => {
          this.forceUpdate();
        });
        return (
          React.createElement("button", {
            id: tool.get("Id"), 
            type: "button", 
            className: className, 
            onClick: () => {
              tool.clicked();
            }, 
            key: index, 
            title: tool.get("title")}, 
            React.createElement("i", {className:  tool.get("icon") })
          )
        );
      });

    return (
      React.createElement("div", {id: "toolbar-"}, 
        React.createElement("div", {className: "map-toolbar-wrapper"}, 
          React.createElement("div", {className: "map-toolbar"}, 
            React.createElement("div", {className: "btn-group btn-group-lg stable-toolbar"}, stableButton), 
              React.createElement("div", {
                className: "btn-group btn-group-lg bottom-toolbar", 
                role: "group", 
                id: "arrow", 
                "aria-label": "toolbar"}, 
                tools
              )
          ), 
          React.createElement("div", {className: "upper-toolbar"}, widgets), 
          React.createElement("div", {className: "information", id: "information"})
        )
      )
    );
  }
};

/**
 * ToolbarView module.<br>
 * Use <code>require('views/toolbar')</code> for instantiation.
 * @module ToolbarView-module
 * @returns {ToolbarView}
 */
module.exports = React.createClass(ToolbarView);

},{}]},{},[2]);
